// import React, { useEffect, useState } from "react";
// import { collection, query, onSnapshot } from "firebase/firestore";
// import { db } from "../firebase";
// export default function useSaberPrice() {
//   const [saber, setSaber] = useState([]);
//   const getData = async () => {
//     let q = query(collection(db, "services", "data", "saber"));
//     const unsub = onSnapshot(q, (querySnapShot) => {
//       setSaber([]);
//       if (!querySnapShot.empty) {
//         querySnapShot.forEach((val) => {
//           setSaber((prev) => [...prev, val.data()]);
//         });
//       }
//     });

//     return () => unsub();
//   };
//   useEffect(() => {
//     getData();
//   }, []);

//   return { saber };
// }
import { useEffect, useState, useCallback } from "react"; // Import your GraphQL query for destination
import { useQuery } from "@apollo/client";
import { GET_ALL_EXTRASABER } from "../../Graphql/queries/servicesQueries";
function useGetAllExtraSaber(trigger, paginationFilters) {
  const [saber, setExtraSaber] = useState([]);
  const [count, setCount] = useState(0);

  const { loading, error, data, refetch } = useQuery(
    GET_ALL_EXTRASABER, // Use GET_All_DRIVER when ownerId is not provided
    {
      variables: { page: paginationFilters?.pageNo },
      fetchPolicy: "network-only",
    }
  );

  const getExtraSaber = useCallback(() => {
    if (loading) return;
    if (error) {
      console.log("Error:", error.message);
      return;
    }
    const {allSabers} = data
    if (!allSabers) {
      console.log("No driver data available.");
      return;
    }
    const { sabers, totalDocs } = allSabers;
    setExtraSaber(sabers);
    setCount(totalDocs);
  }, [loading, error, data]);

  useEffect(() => {
    getExtraSaber();
    if (trigger) {
      refetch({ page: paginationFilters?.pageNo });
    }
  }, [trigger, getExtraSaber, paginationFilters?.pageNo, refetch]);

  return { loading, error, saber, count };
}

export default useGetAllExtraSaber;
