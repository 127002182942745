// import React, { useEffect, useState } from "react";
// import {
//   collection,
//   query,
//   onSnapshot,
//   where,
//   orderBy,
// } from "firebase/firestore";
// import { db } from "../firebase";
// export default function useRelation() {
//   const [relation, setRelation] = useState([]);
//   const getData = async () => {
//     let q = query(collection(db, "relations"));
//     const unsub = onSnapshot(q, (querySnapShot) => {
//       setRelation([]);
//       if (!querySnapShot.empty) {
//         querySnapShot.forEach((val) => {
//           setRelation((prev) => [...prev, val.data()]);
//         });
//       }
//     });

//     return () => unsub();
//   };
//   useEffect(() => {
//     getData();
//   }, []);

//   return { relation };
// }
import { useEffect, useState, useCallback } from "react"; // Import your GraphQL query for destination
import { useQuery } from "@apollo/client";
// import { GET_ALL_EXTRASABER } from "../../Graphql/queries/servicesQueries";
import { Relations_Query } from "../../Graphql/queries/relationQueries";
function useGetAllRelation(trigger, paginationFilters) {
  const [relation, setRelation] = useState([]);
  const [count, setCount] = useState(0);

  const { loading, error, data, refetch } = useQuery(
    Relations_Query, // Use GET_All_DRIVER when ownerId is not provided
    {
      variables: { page: paginationFilters?.pageNo },
      fetchPolicy: "network-only",
    }
  );

  const getRelations = useCallback(() => {
    if (loading) return;
    if (error) {
      console.log("Error:", error.message);
      return;
    }
    const {relations} = data
    if (!relations) {
      console.log("No driver data available.");
      return;
    }
    const { totalDocs } = relations;
    setRelation(relations.relations);
    setCount(totalDocs);
  }, [loading, error, data]);

  useEffect(() => {
    getRelations();
    if (trigger) {
      refetch({ page: paginationFilters?.pageNo });
    }
  }, [trigger, getRelations, paginationFilters?.pageNo, refetch]);

  return { loading, error, relation, count };
}

export default useGetAllRelation;