import React, { useEffect, useState } from 'react'
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { db } from '../firebase';

const useGetMaintenanceRequest = () => {
    const [maintenanceRequest, setMaintenanceRequest] = useState([])
    const getMaintenanceRequest = async () => {
        const q = query(collection(db, "maintenance-service_request"));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const arr = [];
            querySnapshot.forEach((doc) => {
                arr.push(doc.data());
            });
            setMaintenanceRequest(arr)
        });
        return () => unsubscribe()
    }

    useEffect(() => {
        getMaintenanceRequest()
    }, [])

    return { maintenanceRequest }
}

export default useGetMaintenanceRequest
