import React from "react";
import { Input, Table, message } from "antd";
import { SearchOutlined, EyeOutlined, SendOutlined } from "@ant-design/icons";
import useGetAllClearanceRequest from "../Hooks/useGetAllClearanceRequest";
import { useNavigate } from "react-router-dom";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import useSeaFreightRequest from "../Hooks/useSeaFreightRequest";

const SeaFreightCompleteTable = () => {
  const { seaFreight } = useSeaFreightRequest();
  const navigate = useNavigate();
  const createdRequest = seaFreight.filter((el) => el.status === "complete");

  const updateRequestHandler = async (record) => {
    console.log(record.id);
    // try {
    //   const ref = doc(db, "sea-freight", record.uid);

    //   await updateDoc(ref, {
    //     status: "underprocess",
    //   });
    //   message.success("Forwarded");
    // } catch (error) {
    //   alert(error.message);
    // }
  };
  const columns = [
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
    },
    {
      title: "Destination",
      dataIndex: "destination",
      key: "destination",
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
    },
    {
      title: "Customer Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Container Type",
      dataIndex: "containertype",
      key: "containertype",
    },
    {
      title: "Container Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Container Weight",
      render: (record) => {
        return <span>{record.weight}(kg)</span>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Actions",
      render(record) {
        return (
          <div style={{ display: "flex", gap: "1rem" }}>
            <span
              className="eyes-btn"
              onClick={() => {
                navigate(`/sea-freight/${record.uid}`);
              }}
            >
              <EyeOutlined />
            </span>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div style={{ marginBottom: ".5rem" }}>
        <Input prefix={<SearchOutlined />} placeholder="search..." />
      </div>
      <Table dataSource={createdRequest} columns={columns} />
    </>
  );
};

export default SeaFreightCompleteTable;
