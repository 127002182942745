import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  Button,
  Input,
  Space,
  Popconfirm,
  Pagination,
  Switch,
  Tag,
} from "antd";
import "../Tables/table.css";
import {
  SearchOutlined,
  EyeOutlined,
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import Loader from "../Loaders/Loader";
import InvoiceModal from "../Modals/InvoiceModal";
import useCustomers from "../Hooks/useCustomers";
import { DeleteCustomer, RestoreCustomer, UpdateCustomer } from "../API/API";
import { useTranslation } from "react-i18next";
import { db } from "../firebase";
import { doc, updateDoc } from "firebase/firestore";

import useUser from "../Hooks/useUser";
import { MdOutlineRestore } from "react-icons/md";

const CustomerArchive = () => {
    const navigate = useNavigate();
    const { customers } = useCustomers();
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { t } = useTranslation();
    const showModal = () => {
      setIsModalVisible(!isModalVisible);
    };
    const filteredCustomer = customers.filter((ele=> ele.isDeleted == false))
    console.log(filteredCustomer)
    const columns = [
      {
        title: t("tableTabs.name"),
        dataIndex: "name",
        key: "name",
        editable: true,
      },
      {
        title: t("tableTabs.email"),
        dataIndex: "email",
        key: "email",
        editable: true,
      },
      {
        title: "isRelation",
        key: "proVerified",
        render: (recored) => (
          <Space>
            <Switch
              checked={recored?.isRelation === true}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              onChange={async (value) => {
                if (recored.emailVerified) {
                  let data = { isRelation: value };
                  await UpdateCustomer(recored.uid, data);
                } else {
                  if (
                    window.confirm("Are you sure to make relations with Owners")
                  ) {
                    let data = { isRelation: value };
                    await UpdateCustomer(recored.uid, data);
                  }
                }
              }}
            />
          </Space>
        ),
      },
      {
        title: t("tableTabs.proVerified"),
        key: "proVerified",
        render: (recored) => (
          <Space>
            <Switch
              checked={
                recored.proVerified !== undefined && recored.proVerified === true
              }
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              onChange={async (value) => {
                if (recored.emailVerified) {
                  let data = { proVerified: value };
                  await UpdateCustomer(recored.uid, data);
                } else {
                  if (
                    window.confirm(
                      "Are you sure to approve this user is not Pro verified"
                    )
                  ) {
                    let data = { proVerified: value };
                    await UpdateCustomer(recored.uid, data);
                  }
                }
              }}
            />
          </Space>
        ),
      },
      {
        title: t("Hold"),
        // render: (record) => (
        //   <Space>
        //     {user?.proVerified ? (
        //       <span
        //         onClick={() => {
        //           setRequestData(record);
        //           showInvoiveModal();
        //         }}
        //         style={{ border: "1px solid black" }}
        //         className={`${
        //           record?.offlinePaid ? "btn-offline" : "btn-offline-products"
        //         }`}
        //       >
        //         <GlobalOutlined />
        //         {t("tableTabs.OfflinePay")}
        //       </span>
        //     ) : (
        //       ""
        //     )}
        //   </Space>
        // ),
        render: (recored) => (
          <Space>
            <Switch
              checked={recored.isHold !== undefined && recored.isHold === true}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              onChange={async (value) => {
                if (recored.emailVerified) {
                  let data = { isHold: value };
                  await UpdateCustomer(recored.uid, data);
                } else {
                  if (window.confirm("Are you sure")) {
                    let data = { isHold: value };
                    await UpdateCustomer(recored.uid, data);
                  }
                }
              }}
            />
          </Space>
        ),
      },
      {
        title: t("tableTabs.verified"),
        render: (recored) => (
          <span>
            <Tag
              key={recored.uid}
              color={recored.emailVerified ? "green" : "pink"}
            >
              {recored.emailVerified ? "Yes" : "No"}
            </Tag>
          </span>
        ),
      },
      {
        title: t("tableTabs.approved"),
        key: "approval",
        render: (recored) => (
          <Space>
            <Switch
              checked={
                recored.isApproved !== undefined && recored.isApproved === true
              }
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              onChange={(value) => {
                let data = { isApproved: value };
                UpdateCustomer(recored.uid, data);
              }}
            />
          </Space>
        ),
      },
      {
        title: t(" Action"),
  
        dataIndex: "",
        key: "x",
        align: "center",
        render: (record) => (
          <Space>
            <Popconfirm
              title={"Are you sure you want to Restore?"}
              okText="Ok"
              cancelText="Cancel"
              onConfirm={() => {
                RestoreCustomer(record?.uid, record);
              }}
            >
              <span className="ant-btn ant-btn-danger">
                <MdOutlineRestore />
              </span>
            </Popconfirm>
            
          </Space>
        ),
      },
    ];

  return (
    <div>
    {loading ? <Loader /> : ""}
    {/* <AddDriverModal
      isModalVisible={isModalVisible}
      setIsModalVisible={setIsModalVisible}
    /> */}

    {/* <div className="table_topar_add_div_">
      <Button
        type="light"
        // shape="circle"
        icon={<PlusCircleOutlined />}
        size={"middle"}
        onClick={showModal}
      >
        {"Add Driver"}
      </Button>
    </div> */}
    <div className="table_search_div">
      <Input
        allowClear
        size="middle"
        placeholder={t("placeholders.search")}
        prefix={<SearchOutlined />}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
      />
    </div>
    <Table
      columns={columns}
      // expandable={{
      //   expandedRowRender: (record) => (
      //     <p
      //       style={{
      //         margin: 0,
      //       }}
      //     >
      //       {record.description}
      //     </p>
      //   ),
      //   rowExpandable: (record) => record.name !== "Not Expandable",
      // }}

      dataSource={filteredCustomer.filter((val) => {
        if (search == "") {
          return val;
        } else if (
          val &&
          Object.keys(val).some((v) =>
            val[v]
              .toString()
              .toLowerCase()
              .includes(search.toString().toLowerCase())
          )
        ) {
          return val;
        }
      })}
    />
  </div>
  )
}

export default CustomerArchive