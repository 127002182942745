import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Input, Space, Popconfirm, Row, Col, message } from "antd";
import "./table.css";
import {
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import Loader from "../Loaders/Loader";

//Hooks
import useFormCancelEmail from "../Hooks/useFormCancelEmail";
import { useTranslation } from "react-i18next";
import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../firebase";

export default function FormCancelEmails() {
  const { cancelForm } = useFormCancelEmail();
  console.log("🚀 ~ file: FormCancelEmails.jsx:19 ~ FormCancelEmails ~ cancelForm:", cancelForm)
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();




  const handleDelete = async (data) => {
    try {
      const docRef = doc(db, "form-cancel-emails", data.uid);
      await deleteDoc(docRef);
      message.error(`Document with ID ${data.id} deleted.`);
    } catch (error) {
      console.error("Error deleting document:", error);
    }
  };

  const columns = [
    {
    title:"Emails",
      render: (record) =>
        
          <span>{record.email}</span>
   
    },
 

    {
      title: t("tableTabs.action"),
      dataIndex: "",
      key: "x",
      align: "center",
      render: (record) => (
        <Space>
          <Popconfirm
            title={"Are you sure you want to delete?"}
            okText="Ok"
            cancelText="Cancel"
            onConfirm={() => {
              handleDelete(record);
            }}
          >
            <span className="ant-btn ant-btn-danger">
              <DeleteOutlined />
            </span>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      {loading ? <Loader /> : ""}

      <Table columns={columns} dataSource={cancelForm.length > 0 && cancelForm} />
    </div>
  );
}
