import { gql } from "@apollo/client";
const CITY_CREATE = gql`
  mutation CreateCity($cityInput: CityInput) {
    createCity(cityInput: $cityInput) {
      code
      message
      success
      data {
        _id
        admin {
          _id
        }
        cityName
        createdAt
      }
    }
  }
`;
const CREATE_CAPACITY = gql`
  mutation CreateService($serviceInput: ServiceInput) {
    createService(serviceInput: $serviceInput) {
      code
      message
      success
      data {
        admin {
          _id
        }
        capacity
        createdAt
        price
      }
    }
  }
`;
const CREATE_HEIGHT = gql`
  mutation CreateHeight($heightInput: HeightInput) {
    createHeight(heightInput: $heightInput) {
      code
      message
      success
      data {
        _id
        admin {
          _id
        }
        createdAt
        height
        price
      }
    }
  }
`;
const CREATE_TYPE = gql`
  mutation CreateType($typeInput: TypeInput) {
    createType(typeInput: $typeInput) {
      code
      message
      success
      data {
        admin {
          _id
        }
        createdAt
        price
        type
      }
    }
  }
`;
const CREATE_SOURCE = gql`
mutation Mutation($sourceInput: SourceInput) {
    createSource(sourceInput: $sourceInput) {
      success
      message
      code
      data {
        source
        createdAt
        admin {
          _id
        }
      }
    }
  }
`;
const CREATE_DESTINATION = gql`
mutation CreateDestination($registerDestinationInput: RegisterDestinationInput) {
  createDestination(registerDestinationInput: $registerDestinationInput) {
    code
    message
    success
    data {
      _id
      admin {
        _id
      }
      createdAt
      destination
    }
  }
}
`;
const CREATE_EXTRACARE = gql`
mutation CreateCare($registerCareInput: RegisterCareInput) {
  createCare(registerCareInput: $registerCareInput) {
    code
    data {
      _id
      admin {
        _id
      }
      createdAt
      extraCare
    }
    message
    success
  }
}
`;
const CREATE_SABER = gql`
mutation CreateSaber($registerSaberInput: RegisterSaberInput) {
  createSaber(registerSaberInput: $registerSaberInput) {
    code
    message
    success
    data {
      _id
      admin {
        _id
      }
      createdAt
      saber
    }
  }
}
`;

const UPDATE_CITY = gql`
  mutation UpdateCity($id: ID!, $updateCityInput: CityUpdateInput) {
    updateCity(ID: $id, updateCityInput: $updateCityInput) {
      code
      message
      success
      data {
        _id
      }
    }
  }
`;
const UPDATE_SOURCE = gql`
  mutation Mutation($id: ID!, $updateSourceInput: SourceUpdateInput) {
    updateSource(ID: $id, updateSourceInput: $updateSourceInput) {
      success
      message
      code
      data {
        _id
        source
        createdAt
        admin {
          _id
        }
      }
    }
  }
`;
const UPDATE_CAPACITY = gql`
  mutation UpdateService($id: ID!, $updateServiceInput: ServiceUpdateInput) {
    updateService(ID: $id, updateServiceInput: $updateServiceInput) {
      code
      data {
        admin {
          _id
        }
        capacity
        price
        createdAt
      }
      message
      success
    }
  }
`;
const UPDATE_HEIGHT = gql`
  mutation UpdateHeight($id: ID!, $updateHeightInput: HeightUpdateInput) {
    updateHeight(ID: $id, updateHeightInput: $updateHeightInput) {
      code
      message
      success
      data {
        _id
        admin {
          _id
        }
        createdAt
        price
        height
      }
    }
  }
`;
const UPDATE_TYPE = gql`
  mutation UpdateType($id: ID!, $updateTypeInput: TypeUpdateInput) {
    updateType(ID: $id, updateTypeInput: $updateTypeInput) {
      code
      data {
        admin {
          _id
        }
        createdAt
        price
        type
      }
      message
      success
    }
  }
`;
const UPDATE_DESTINATION = gql`
mutation Mutation($id: ID!, $updateDestinationInput: UpdateDestinationInput) {
  updateDestination(ID: $id, updateDestinationInput: $updateDestinationInput) {
    code
    message
    success
    data {
      _id
      admin {
        _id
      }
      createdAt
      destination
    }
  }
}
`;
const UPDATE_EXTRACARE = gql`
mutation UpdateCare($id: ID!, $updateCareInput: UpdateCareInput) {
  updateCare(ID: $id, updateCareInput: $updateCareInput) {
    code
    message
    success
    data {
      _id
      admin {
        _id
      }
      createdAt
      extraCare
    }
  }
}
`;
const UPDATE_SABER = gql`
mutation UpdateSaber($id: ID!, $updateSaberInput: UpdateSaberInput) {
  updateSaber(ID: $id, updateSaberInput: $updateSaberInput) {
    data {
      _id
      admin {
        _id
      }
      createdAt
      saber
    }
    code
    message
    success
  }
}
`;

const DELETE_CITY = gql`
  mutation UpdateCity($id: ID!, $updateCityInput: CityUpdateInput) {
    updateCity(ID: $id, updateCityInput: $updateCityInput) {
      code
      message
      success
      data {
        _id
      }
    }
  }
`;
const DELETE_SOURCE = gql`
  mutation DeleteSource($id: ID!) {
    deleteSource(ID: $id) {
      success
      message
      code
      data {
        _id
        source
        createdAt
        admin {
          _id
        }
      }
    }
  }
`;
const DELETE_CAPACITY = gql`
  mutation Mutation($id: ID!) {
    deleteService(ID: $id) {
      code
      message
      success
      data {
        admin {
          _id
        }
        capacity
        createdAt
        price
      }
    }
  }
`;
const DELETE_HEIGHT = gql`
  mutation DeleteHeight($id: ID!) {
    deleteHeight(ID: $id) {
      code
      message
      success
      data {
        _id
        admin {
          _id
        }
        createdAt
        height
        price
      }
    }
  }
`;
const DELETE_TYPE = gql`
  mutation DeleteType($id: ID!) {
    deleteType(ID: $id) {
      code
      data {
        _id
        admin {
          _id
        }
        createdAt
        price
        type
      }
      message
      success
    }
  }
`;
const DELETE_DESTINATION = gql`
mutation DeleteDestination($id: ID!) {
  deleteDestination(ID: $id) {
    code
    data {
      _id
      admin {
        _id
      }
      createdAt
      destination
    }
    message
    success
  }
}
`;
const DELETE_EXTRACARE = gql`
mutation DeleteCare($id: ID!) {
  deleteCare(ID: $id) {
    code
    data {
      _id
      admin {
        _id
      }
      createdAt
      extraCare
    }
    message
    success
  }
}
`;
const DELETE_SABER = gql`
mutation DeleteSaber($id: ID!) {
  deleteSaber(ID: $id) {
    code
    data {
      _id
      admin {
        _id
      }
      createdAt
      saber
    }
  }
}
`;

export {
  CITY_CREATE,
  DELETE_CITY,
  UPDATE_CITY,
  DELETE_CAPACITY,
  CREATE_CAPACITY,
  UPDATE_CAPACITY,
  UPDATE_HEIGHT,
  CREATE_HEIGHT,
  DELETE_HEIGHT,
  CREATE_TYPE,
  DELETE_TYPE,
  UPDATE_TYPE,
  CREATE_SOURCE,
  DELETE_SOURCE,
  UPDATE_SOURCE,
  DELETE_DESTINATION,
  CREATE_DESTINATION,
  UPDATE_DESTINATION,
  CREATE_EXTRACARE,
  DELETE_EXTRACARE,
  UPDATE_EXTRACARE,
  CREATE_SABER,
  DELETE_SABER,
  UPDATE_SABER
};
