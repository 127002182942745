import React, { useState } from "react";
import { Table, Input, Popconfirm, Space, Button } from "antd";
import { SearchOutlined, DeleteOutlined } from "@ant-design/icons";
import { deleteRelationCombination } from "../API/API";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useRelation from "../Hooks/useRelation";
import { useNavigate } from "react-router-dom";

const ViewCombination = () => {
  const { customerId } = useParams();
  const { relation } = useRelation();

  const data = relation.filter((item) => item.customerId === customerId);
  const filterredCombinations = data[0]?.data;

  const [search, setSearch] = useState("");

  const navigate = useNavigate();

  const { t } = useTranslation();

  const columns = [
    {
      title: t("tableTabs.from"),
      dataIndex: "from",
      key: "from",
    },
    {
      title: t("tableTabs.to"),
      dataIndex: "to",
      key: "to",
    },
    {
      title: t("tableTabs.distance"),
      dataIndex: "distance",
      key: "distance",
    },
    {
      title: t("tableTabs.price"),
      dataIndex: "price",
      key: "price",
    },
    {
      title: t("tableTabs.time"),
      dataIndex: "time",
      key: "time",
    },

    {
      title: t("tableTabs.type"),
      dataIndex: "type",
      key: "type",
    },
    {
      title: t("tableTabs.capacities"),
      dataIndex: "capacity",
      key: "capacity",
    },
    {
      title: t("tableTabs.hight"),
      dataIndex: "height",
      key: "height",
    },
    {
      title: "VAT",
      dataIndex: "vat",
      key: "vat",
    },

    {
      title: t("tableTabs.action"),
      dataIndex: "",
      key: "x",
      align: "center",
      render: (record) => (
        <Space>
          <Popconfirm
            title={"Are you sure you want to delete?"}
            okText="Ok"
            cancelText="Cancel"
            onConfirm={() => {
              deleteRelationCombination(record?.uid, record,customerId);
            }}
          >
            <span className="ant-btn ant-btn-danger">
              <DeleteOutlined />
            </span>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div className="table_topar_add_div_">
        <Button
          className="login-btn"
          size={"middle"}
          onClick={() => navigate("/relations")}
        >
          back
        </Button>
      </div>
      <div className="table_search_div">
        <Input
          allowClear
          size="middle"
          placeholder={t("placeholders.search")}
          prefix={<SearchOutlined />}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      </div>
      <Table
        columns={columns}
        dataSource={
          filterredCombinations &&
          filterredCombinations.length > 0 &&
          filterredCombinations.filter((val) => {
            if (search == "") {
              return val;
            } else if (
              val &&
              Object.keys(val).some((v) =>
                val[v]
                  .toString()
                  .toLowerCase()
                  .includes(search.toString().toLowerCase())
              )
            ) {
              return val;
            }
          })
        }
      />
    </div>
  );
};

export default ViewCombination;
