import { Table, Space, Switch } from "antd";
import React from "react";
import useGetMaintenanceRequest from "../Hooks/useGetMaintenanceRequest";
import { useTranslation } from "react-i18next";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { UpdateMaintenance } from "../API/API";
const MaintenanceRequestTable = () => {
  const { maintenanceRequest } = useGetMaintenanceRequest();
  const { t } = useTranslation();

  const columns = [
    {
      title: t("truck"),
      dataIndex: "truck",
      key: "truck",
    },
    {
      title: t("services"),
      render(record) {
        return <span>{record.services.map((el) => el.children + ", ")}</span>;
      },
    },
    {
      title: t("totalamount"),
      render(record) {
        let amount = record.services.map((el) => el.value);
        let totalAmount = amount.reduce((pre, next) => {
          return pre + next;
        });
        return <span>{totalAmount}</span>;
      },
    },
    {
      title: "Completed",
      render: (recored) => (
        <Space>
          <Switch
            checked={recored?.isCompleted === true}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            onChange={async (value) => {
              if (window.confirm("Are you sure you want to delete?")) {
                let data = { isCompleted: value };
                UpdateMaintenance(recored.uid, { ...recored, ...data });
              }
            }}
          />
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Table dataSource={maintenanceRequest} columns={columns} />
    </div>
  );
};

export default MaintenanceRequestTable;
