import React from "react";
import { useNavigate } from "react-router-dom";
import "./sidebar.css";
import { Badge, Layout, Menu } from "antd";
import { LogoutOutlined, PullRequestOutlined } from "@ant-design/icons";
import { ImCoinDollar } from "react-icons/im";

import { HiOutlineUsers } from "react-icons/hi";
import { FiUsers } from "react-icons/fi";
import { MdOutlineMiscellaneousServices } from "react-icons/md";
import { TbSteeringWheel } from "react-icons/tb";
import {
  BsPersonCircle,
  BsFillPersonLinesFill,
  BsTools,
  BsFillFileEarmarkPostFill,
} from "react-icons/bs";
import { SiLinuxcontainers } from "react-icons/si";
import {MdAutoDelete} from 'react-icons/md'

import { useTranslation } from "react-i18next";
import { Logout, readNotifications } from "../../API/API";
import useNotifications from "../../Hooks/useNotifications";
const { Sider } = Layout;

export default function SideBar({ collapsed }) {
  const { t } = useTranslation();
  const { notifications } = useNotifications();
  const navigate = useNavigate();
  let owners =
    notifications &&
    notifications.filter(
      (v) => !v.isRead && v.status?.toLowerCase().includes("owners")
    );
  let customers =
    notifications &&
    notifications.filter(
      (v) => !v.isRead && v.status?.toLowerCase().includes("customers")
    );
  let requests =
    notifications &&
    notifications.filter(
      (v) => !v.isRead && v.type?.toLowerCase().includes("request")
    );
  let itemsNav = [
    {
      key: "owners",
      icon: <HiOutlineUsers />,
      label: (
        <Badge
          onClick={() => {
            readNotifications(owners);
          }}
          dot={owners && owners.length > 0 ? true : false}
        >
          <span className="badge_span">{t("routes.owners")}</span>
        </Badge>
      ),

      onClick: () => {
        navigate("/owners");
      },
    },
    {
      // key: "/customers",
      key: "customers",
      icon: <FiUsers />,
      label: (
        <Badge
          onClick={() => {
            readNotifications(customers);
          }}
          dot={customers && customers.length > 0 ? true : false}
        >
          <span className="badge_span">{t("routes.customers")}</span>
        </Badge>
      ),
      onClick: () => {
        navigate("/customers");
      },
    },
    {
      // key: "/customers",
      key: "transportation",
      icon: <PullRequestOutlined />,
      label: (
        <Badge dot={requests && requests.length > 0 ? true : false}>
          <span className="badge_span">{t("transportation")}</span>
        </Badge>
      ),
      onClick: () => {
        navigate("/requests");
      },
    },
    {
      key: "drivers",
      icon: <TbSteeringWheel />,
      label: t("routes.drivers"),
      onClick: () => {
        navigate("/drivers");
      },
    },
    {
      key: "services",
      icon: <MdOutlineMiscellaneousServices />,
      label: t("routes.services"),
      onClick: () => {
        navigate("/services");
      },
    },
    {
      key: "permission",
      icon: <BsPersonCircle />,
      label: t("Permission"),
      onClick: () => {
        navigate("/permission");
      },
    },
    {
      key: "relations",
      icon: <BsFillPersonLinesFill />,
      label: t("Relations"),
      onClick: () => {
        navigate("/Relations");
      },
    },

    {
      key: "finance",
      icon: <ImCoinDollar />,
      label: t("routes.finance"),
      onClick: () => {
        navigate("/finance");
      },
    },
    {
      key: "Maintenance",
      icon: <BsTools />,
      label: t("routes.maintenance"),
      onClick: () => {
        navigate("/maintenance");
      },
    },
    {
      key: "Clearance",
      icon: <BsFillFileEarmarkPostFill />,
      label: "Clearance",
      onClick: () => {
        navigate("/clearance");
      },
    },
    {
      key: "See Freight",
      icon: <SiLinuxcontainers />,
      label: "Sea Freight",
      onClick: () => {
        navigate("/see-freight");
      },
    },
    {
      key: "Archive ",
      icon: < MdAutoDelete />,
      label: "Archive",
      onClick: () => {
        navigate("/archive");
      },
    },
  ];
  let filterNavs = (itemsNav) => {
    const info = JSON.parse(sessionStorage.getItem("info"));
    console.log("🚀 ~ file: Sidebar.jsx:171 ~ filterNavs ~ info:", info)

    let result = [];
    if (true) {
      result = itemsNav;
    } else {
      result = itemsNav.filter((val) =>
        info.permissions.some((v) => v === val.key)
      );
    }
    result.push({
      key: "/",
      icon: <LogoutOutlined />,
      label: t("routes.logout"),
      onClick: Logout,
    });
    return result;
  };
  return (
    <div>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="logo">
          <div className="logo_div">
            <img width={60} src="/assets/logo.png" alt="img" />
          </div>
        </div>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={["1"]}
          // selectedKeys={["1"]}
          // selectedKeys={[window.location.pathname]}
          items={filterNavs(itemsNav)}
        >
          {/* <Menu.Item key={"truck"} icon={<BsTruck />}>
              <NavLink to={"/"}>Truck</NavLink>
            </Menu.Item> */}
        </Menu>
      </Sider>
    </div>
  );
}
