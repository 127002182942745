import React, { useEffect, useState,useRef,useMemo } from "react";
import {
  collection,
  query,
  onSnapshot,
  where,
  orderBy,
  getDoc,
  getDocs,
} from "firebase/firestore";
import { db } from "../firebase";
import useOwners from "./useOwners";
import useTransports from "./useTransports";
export default function useOnTheWayRequests() {
  const { owners } = useOwners();
  const { transports } = useTransports();
  // const { current: myOwners } = useRef([]);
  // const { current: myTransports } = useRef([]);
  const id = JSON.parse(sessionStorage.getItem("uid"));
  const [onthewayrequests, setonthewayrequests] = useState([]);
  const getAllRequests = async () => {
    let q = query(
      collection(db, "customer_transport_requests"),
      orderBy("createdAt", "desc")
    );
    // let Transports = await getDocs(collection(db, "transports"));
    const unsub = onSnapshot(q, (querySnapShot) => {
      setonthewayrequests([]);
      if (!querySnapShot.empty) {
        querySnapShot.forEach((val) => {
          if (
            val.data().status !== "finished" &&
            val.data().isStarted !== undefined && val.data().isStarted &&
            val.data().isAccept !== undefined &&
            val.data().isAccept &&
            owners.length > 0 &&
            transports.length > 0
          ) {

            let ownerId = val.data().acceptedBy?.ownerId;
            let transportId = val.data().acceptedBy?.transportId;

            let owner = owners.find((v) => v.uid === ownerId);
            let transport = transports.find((v) => v.uid === transportId);
            setonthewayrequests((prev) => [
              ...prev,
              { request: val.data(), owner, transport: transport },
            ]);
          }
        });
      }
    });

    return () => unsub();
  };
  useEffect(() => {
    getAllRequests();
  
  }, [JSON.stringify(transports),JSON.stringify(owners)]);

  return { onthewayrequests };
}

