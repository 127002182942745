import { gql } from "@apollo/client";

const GET_MY_DRIVER = gql`
query Query($ownerId: ID!, $page: Int!) {
    myDrivers(ownerId: $ownerId, page: $page) {
      currentPage
      totalDocs
      totalPages
      drivers {
        _id
      age
      createdAt
      idNo
      isTruck
      imageUrl
      name
      address
      nationality
      owner {
        _id
        name
      }
      status
      }
    }
  }
`;
const GET_All_DRIVER = gql`
query AllDrivers($page: Int!) {
  allDrivers(page: $page) {
    currentPage
    totalDocs
    totalPages
    drivers {
      _id
      age
      createdAt
      idNo
      isTruck
      imageUrl
      name
      address
      nationality
      owner {
        _id
        name
      }
      status
    }
  }
}
`;
const GET_DRIVER_ID = gql`
  query Customer($id: ID!) {
    customer(ID: $id) {
      email
      password
      isTruck
    }
  }
`;
const GET_All_DRIVERS = gql`
query Drivers {
  Drivers {
    _id
    address
    age
    createdAt
    idNo
    isTruck
    imageUrl
    name
    nationality
    owner {
      _id
    }
    status
  }
}
`;

export { GET_DRIVER_ID, GET_MY_DRIVER, GET_All_DRIVERS, GET_All_DRIVER };
