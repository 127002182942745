import { Input, Modal, Row, Col, Button, Space, Form, DatePicker } from "antd";
import { doc, updateDoc } from "firebase/firestore";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { sendNotification, uploadImage } from "../API/API";
import dayjs from "dayjs";

import { db } from "../firebase";
import Loader from "../Loaders/Loader";
export default function InvoiceModal({
  isModalVisible,
  setIsModalVisible,
  state,
}) {
  const [uploadfile, setUploadFile] = useState("");
  const [uploadOd, setUploadOd] = useState("");
  const [uploadBi, setUploadBi] = useState("");
  const [uploadIro, setUploadIro] = useState("");
  const [uploadDate, setUploadDate] = useState("");
  const [file, setFile] = useState("");
  const [datee, setDatee] = useState("");
  const [od, setOd] = useState("");
  const [bi, setBi] = useState("");
  const [iro, setIro] = useState("");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const disabledDate = (current) => {
    return current && current < dayjs().startOf("day");
  };
  const handleOk = () => {
    setIsModalVisible(!isModalVisible);
  };

  const handleCancel = () => {
    setIsModalVisible(!isModalVisible);
  };
  const handleUpload = async () => {
    if (uploadfile !== "") {
      try {
        setLoading(true);
        await uploadImage("request/", uploadfile).then(async (res) => {
          setFile(res);

          await updateDoc(doc(db, "customer_transport_requests", state.uid), {
            invoice: res,
          });
          let nData = {
            title: "Invoice Uploaded",
            desc: "Your request Invoice is Uploaded.",
            type: "request",
            to: [state.customerId],
            status: "created",
          };
          sendNotification(state.notificationId, nData); //notification id and ndata
          setLoading(false);
        });
        console.log(state.customerId, "state.customerId");
      } catch (e) {
        setLoading(false);
        console.log(e);
      }
    } else {
      alert("choose file first");
    }
  };

  const handleOdUploads = async () => {
    if (uploadOd !== "") {
      try {
        setLoading(true);
        await uploadImage("request/", uploadOd).then(async (res) => {
          setOd(res);
          await updateDoc(doc(db, "customer_transport_requests", state.uid), {
            odUpload: res,
          });
          setLoading(false);
        });
      } catch (e) {
        setLoading(false);
        console.log(e);
      }
    } else {
      alert("choose file first");
    }
  };
  const handleBiUploads = async () => {
    if (uploadBi !== "") {
      try {
        setLoading(true);
        await uploadImage("request/", uploadBi).then(async (res) => {
          setBi(res);
          await updateDoc(doc(db, "customer_transport_requests", state.uid), {
            blNumberUpload: res,
          });
          setLoading(false);
        });
      } catch (e) {
        setLoading(false);
        console.log(e);
      }
    } else {
      alert("choose file first");
    }
  };

  const handleIroUploads = async () => {
    if (uploadIro !== "") {
      try {
        setLoading(true);
        await uploadImage("request/", uploadIro).then(async (res) => {
          setIro(res);
          await updateDoc(doc(db, "customer_transport_requests", state.uid), {
            iroUpload: res,
          });
          setLoading(false);
        });
      } catch (e) {
        setLoading(false);
        console.log(e);
      }
    } else {
      alert("choose file first");
    }
  };

  const handleIroDateUpload = async () => {
    if (uploadDate !== "") {
      try {
        setLoading(true);
        await updateDoc(doc(db, "customer_transport_requests", state.uid), {
          iroDate: uploadDate._d,
        });
        setDatee(uploadDate._d);
        setLoading(false);
        alert("Date uploaded successfully!");
      } catch (e) {
        setLoading(false);
      }
    } else {
      alert("Please choose a date first");
    }
  };
  useEffect(() => {
    if (state?.invoice !== undefined) {
      setFile(state?.invoice);
    } else {
      setFile("");
    }

    if (state?.odUpload !== undefined) {
      setOd(state?.odUpload);
    } else {
      setOd("");
    }

    if (state?.blNumberUpload !== undefined) {
      setBi(state?.blNumberUpload);
    } else {
      setBi("");
    }

    if (state?.iroUpload !== undefined) {
      setIro(state?.iroUpload);
    } else {
      setIro("");
    }
    if (state?.iroDate !== undefined) {
      setDatee(state?.iroDate);
    } else {
      setDatee("");
    }
  }, [state]);

  const [showOtherUpload, setShowOtherUpload] = useState(false);
  const showUploadFields = () => {
    setShowOtherUpload(!showOtherUpload);
  };
  return (
    <div>
      {loading ? <Loader /> : ""}
      <Modal
        title={t("Modal.attachFile")}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose
        footer={null}
      >
        <Space direction="vertical">
          <div>
            <h4>{t("Modal.invoice")}</h4>
          </div>
          <Row gutter={"24"}>
            <Col span={"18"}>
              <Input
                style={{ border: "2px solid #2d808b " }}
                type="file"
                onClick={(e) => e.target.value}
                onChange={(e) => {
                  setUploadFile(e.target.files[0]);
                }}
              />
            </Col>
            <Col span={"6"}>
              <Button onClick={handleUpload} className="check-btn" size="large">
                {t("Modal.upload")}
              </Button>
            </Col>
          </Row>

          {file !== "" ? (
            <Space>
              <img
                className="img"
                src={file}
                width={100}
                height={100}
                alt={"Click to view"}
                onClick={() => {
                  window.open(file);
                }}
              />
              <span>{t("Modal.receipt")}</span>
            </Space>
          ) : (
            <span>{t("Modal.notYetUpload")}</span>
          )}
        </Space>

        <Button
          onClick={showUploadFields}
          className="check-btn"
          size="medium"
          style={{ margin: "10px" }}
        >
          Upload Other Files
        </Button>

        {showOtherUpload && (
          <>
            <div>
              <h4>IRO</h4>
            </div>
            <Space direction="vertical">
              <Row gutter={"24"}>
                <Col span={"18"}>
                  <Input
                    style={{ border: "2px solid #2d808b " }}
                    type="file"
                    onClick={(e) => e.target.value}
                    onChange={(e) => {
                      setUploadIro(e.target.files[0]);
                    }}
                  />
                </Col>
                <Col span={"6"}>
                  <Button
                    onClick={handleIroUploads}
                    className="check-btn"
                    size="large"
                  >
                    {t("Modal.upload")}
                  </Button>
                </Col>
              </Row>
              {iro !== "" ? (
                <Space>
                  <img
                    className="img"
                    src={iro}
                    width={100}
                    height={100}
                    alt={"Click to view"}
                    onClick={() => {
                      window.open(iro);
                    }}
                  />
                  <span>{t("Modal.receipt")}</span>
                </Space>
              ) : (
                <span>{t("Modal.notYetUpload")}</span>
              )}
            </Space>

            <div>
              <h4>BI Number</h4>
            </div>
            <Space direction="vertical">
              <Row gutter={"24"}>
                <Col span={"18"}>
                  <Input
                    style={{ border: "2px solid #2d808b " }}
                    type="file"
                    onClick={(e) => e.target.value}
                    onChange={(e) => {
                      setUploadBi(e.target.files[0]);
                    }}
                  />
                </Col>
                <Col span={"6"}>
                  <Button
                    onClick={handleBiUploads}
                    className="check-btn"
                    size="large"
                  >
                    {t("Modal.upload")}
                  </Button>
                </Col>
              </Row>
              {bi !== "" ? (
                <Space>
                  <img
                    className="img"
                    src={bi}
                    width={100}
                    height={100}
                    alt={"Click to view"}
                    onClick={() => {
                      window.open(bi);
                    }}
                  />
                  <span>{t("Modal.receipt")}</span>
                </Space>
              ) : (
                <span>{t("Modal.notYetUpload")}</span>
              )}
            </Space>

            <div>
              <h4>O/D</h4>
            </div>
            <Space direction="vertical">
              <Row gutter={"24"}>
                <Col span={"18"}>
                  <Input
                    style={{ border: "2px solid #2d808b " }}
                    type="file"
                    onClick={(e) => e.target.value}
                    onChange={(e) => {
                      setUploadOd(e.target.files[0]);
                    }}
                  />
                </Col>
                <Col span={"6"}>
                  <Button
                    onClick={handleOdUploads}
                    className="check-btn"
                    size="large"
                  >
                    {t("Modal.upload")}
                  </Button>
                </Col>
              </Row>
              {od !== "" ? (
                <Space>
                  <img
                    className="img"
                    src={od}
                    width={100}
                    height={100}
                    alt={"Click to view"}
                    onClick={() => {
                      window.open(od);
                    }}
                  />
                  <span>{t("Modal.receipt")}</span>
                </Space>
              ) : (
                <span>{t("Modal.notYetUpload")}</span>
              )}
            </Space>
            <Space direction="vertical">
              <Row gutter={"24"}>
                <Col span={"18"}>
                  
                    <DatePicker
                      style={{ border: "2px solid #1890ff" }}
                      inputReadOnly
                      placeholder="IRO"
                      className="dateiro"
                      disabledDate={disabledDate}
                      onChange={(date, dateString) => {
                        setUploadDate(date);
                        if (date) {
                          alert("Date selected: " + dateString);
                        }
                      }}
                    />
                  
                </Col>
                <Col span={"6"}>
                  <Button
                    onClick={handleIroDateUpload}
                    className="check-btn"
                    size="large"
                  >
                    {t("Modal.upload")}
                  </Button>
                </Col>
              </Row>
            </Space>
          </>
        )}
      </Modal>
    </div>
  );
}
