import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  let token = JSON.parse(sessionStorage.getItem("token"));
  console.log("🚀 ~ file: PrivateRoutes.jsx:6 ~ PrivateRoute ~ token:", token)

  return token? children : <Navigate to="/" />;
};

export default PrivateRoute;
