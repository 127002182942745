
  import { gql } from "@apollo/client";

const COMBINATION_CREATE = gql`
mutation CreateCombination($combinationInput: CombinationInput) {
  createCombination(combinationInput: $combinationInput) {
    code
    data {
      capacity
      commission
      createdAt
      distance
      estimateTime
      from
      height
      isRelation
      price
      to
      type
      vat
    }
    message
    success
  }
}
`;
const COMBINATION_LOGIN = gql`
mutation Mutation($loginInput: LoginInput) {
  loginAdmin(loginInput: $loginInput) {
    code
    message
    success
    data {
      _id
      email
      password
      token
      url
      userName
    }
  }
}
`;
const COMBINATION_DELETE = gql`
mutation Mutation($id: ID!) {
    deleteCombination(ID: $id) {
      code
      message
      success
      data {
        commission
        capacity
        createdAt
      }
    }
  }
`;
export { COMBINATION_LOGIN, COMBINATION_CREATE, COMBINATION_DELETE };
