import React from "react";
import { useTranslation } from "react-i18next";
import SendRequestTable from "../Tables/SendRequestTable";
export default function SendRequests() {
  const { t } = useTranslation();
  return (
    <div>
      <div>
        <h3>{"Send Request To Owners"}</h3>
      </div>
      <div>
        <SendRequestTable />
      </div>
    </div>
  );
}
