import React, { useState } from "react";
import {
  Modal,
  Button,
  Input,
  Form,
  Upload,
  Row,
  Col,
  Select,
  message,
} from "antd";

import { UploadOutlined } from "@ant-design/icons";
import { AddTruck, uploadImage } from "../API/API";
import Loader from "../Loaders/Loader";
import useCapacites from "../Hooks/useCapacities";
import useHeights from "../Hooks/useHeights";
import useTypes from "../Hooks/useTypes";
import { t } from "i18next";
import { TRUCK_CREATE } from "../../Graphql/mutation/truckMutation";
import { useMutation } from "@apollo/client";
import useGetMyTrucks from "../Hooks/useTrucks";
export default function AddTruckModal({ isModalVisible, setIsModalVisible, ownerId }) {
  const { Option } = Select;
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [paginationFilters, setPaginationFilters] = useState({
    pageNo: 1,
    pageSize: 5,
    limitSkip: 0,
    limit: 10,
  });
  const [createTruck] = useMutation(TRUCK_CREATE);
  const { services } = useCapacites(updateTrigger, paginationFilters);
  console.log("🚀 ~ file: AddTruckModal.jsx:35 ~ AddTruckModal ~ services:", services)
  const { types } = useTypes(updateTrigger, paginationFilters);
  const { heights } = useHeights(updateTrigger, paginationFilters);
  const { trucks } = useGetMyTrucks(updateTrigger, paginationFilters, ownerId);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const handleOk = () => {
    setIsModalVisible(!isModalVisible);
  };
  const handleCancel = () => {
    setIsModalVisible(!isModalVisible);
  };
  const [formData, setFormData] = useState({
    truckLicense: "",
  });
 const trigger = () =>{
  setUpdateTrigger(true);
 }
  const onFinish = async (values) => {
    
    if (!trucks?.some((v) => v.truckPlate === values.truckPlate)) {
      setLoading(true);
      if (formData.truckLicense !== "") {
        await uploadImage("Trucks/", formData.truckLicense).then((res) => {
          formData.truckLicense = res;
          setFormData({ ...formData });
        });
      }
      let dataT = {
        height: values.truckHeight,
        imageUrl: formData.truckLicense,
        isDriver: false,
        capacity: values.truckCapacity, // Update with the actual image URL
        model: values.truckModel,
        noPlate: values.truckPlate,
        owner: ownerId, // Update with the owner ID
        serviseType: values.truckType, // Update with the correct field name
        status: "active",
        type: values.truckType, // Update with the correct field name
      };
      try {
        const { data } = await createTruck({
          variables: {
            registerTruckInput: dataT,
          },
        });
         if (data) {
          setLoading(false);
          form.resetFields();
          setUpdateTrigger(true);
          handleCancel();
          message.success(data.createTruck.message);
         }
      } catch (error) {
        message.error(error);
      }
     
        // .then((response) => {
        //   setLoading(false);
        //   form.resetFields();
        //   handleCancel();
        //   message.success("Add Successful!");
        // })
        // .catch((error) => {
        //   setLoading(false);
        //   message.error(error.message);
        // });
    } else {
      alert("This ID number already exists. Please change it.");
    }
    //   await AddTruck(data)
    //     .then(() => {
    //       setLoading(false);
    //       form.resetFields();
    //       handleCancel();
    //       message.success("Add Successull!");
    //     })
    //     .catch((e) => {
    //       setLoading(false);
    //       message.error(JSON.stringify(e));
    //     });
    // } else {
    //   alert("truck plate number already exist please change it");
    // }
  };
  return (
    <div>
      <Modal
        title={t("Modal.addNewTruck")}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose
        footer={null}
      >
        {loading ? <Loader /> : ""}
        <div>
          <Form form={form} name="add_trcuk_form" onFinish={onFinish}>
            <Input.Group>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="truckModel"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Input placeholder={t("placeholders.modal")} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="truckPlate"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Input placeholder={t("placeholders.plate")} />
                  </Form.Item>
                </Col>
              </Row>
            </Input.Group>

            <Input.Group>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="truckCapacity"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Select onClick={trigger} placeholder={t("placeholders.selectCapacity")}>
                      {services &&
                        services.map((v, i) => {
                          return (
                            <Option key={i} value={v.capacity}>
                              {v.capacity}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="truckHeight"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Select placeholder={t("placeholders.selectHight")}>
                      {}
                      {heights &&
                        heights.map((v, i) => {
                          return (
                            <Option key={i} value={v.height}>
                              {v.height}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Input.Group>
            <Input.Group>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="truckType"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Select placeholder={t("placeholders.selectType")}>
                      {types &&
                        types.map((v, i) => {
                          return (
                            <Option key={i} value={v.type}>
                              {v.type}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="truckLicenseUpload"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Upload
                      // accept="image/*"
                      multiple={false}
                      maxCount={1}
                      name="logo"
                      listType="picture"
                      onChange={(e) => {
                        if (e.file.status === "removed") {
                          formData.truckLicense = "";
                          setFormData({
                            ...formData,
                          });
                        } else {
                          formData.truckLicense = e.file.originFileObj;
                          setFormData({
                            ...formData,
                          });
                        }
                      }}
                    >
                      <Button icon={<UploadOutlined />}>{t("placeholders.uploadLiense")}</Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>
            </Input.Group>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                {t("Modal.add")}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
}
