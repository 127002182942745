// import React, { useEffect, useState } from "react";
// import { collection, query, onSnapshot,orderBy } from "firebase/firestore";
// import { db } from "../firebase";
// export default function useTypes() {
//   const [types, setTypes] = useState([]);
//   const getData = async () => {
//     let q = query(collection(db, "services", "data", "types"),orderBy("price","asc"));
//     const unsub = onSnapshot(q, (querySnapShot) => {
//       setTypes([]);
//       if (!querySnapShot.empty) {
//         querySnapShot.forEach((val) => {
//           setTypes((prev) => [...prev, val.data()]);
//         });
//       }
//     });

//     return () => unsub();
//   };
//   useEffect(() => {
//     getData();
//   }, []);

//   return { types };
// }
import { useEffect, useState, useCallback } from "react"; // Import your GraphQL query for types
import { useQuery } from "@apollo/client";
import { GET_ALL_TYPE } from "../../Graphql/queries/servicesQueries";

function useGetAllTypes(trigger, paginationFilters) {
  const [types, setTypes] = useState([]);
  const [count, setCount] = useState(0);

  const { loading, error, data, refetch } = useQuery(GET_ALL_TYPE, {
    variables: { page: paginationFilters?.pageNo },
    fetchPolicy: "network-only",
  });

  const getAllTypes = useCallback(async () => {
    if (loading) return;
    if (error) {
      console.log("Error:", error.message);
      return;
    }
    const { types } = data;
    if (!types) {
      console.log("No Owner data available.");
      return;
    }
    const { totalDocs } = types;
    setTypes(types.types);
    setCount(totalDocs);
  }, [loading, error, data]);

  useEffect(() => {
    getAllTypes();
    if (trigger) {
      refetch({ page: paginationFilters?.pageNo });
    }
  }, [trigger, getAllTypes, paginationFilters?.pageNo, refetch]);

  return { loading, error, types, count };
}

export default useGetAllTypes;