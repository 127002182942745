import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Input,
  Space,
  Popconfirm,
  Pagination,
  Switch,
  Tag,
  message,
} from "antd";
import "./table.css";
import {
  PlusCircleOutlined,
  SearchOutlined,
  UploadOutlined,
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import AddDriverModal from "../Modals/AddDriverModal";
import useOwners from "../Hooks/useOwners";
import { DeleteOwner, UpdateOwner } from "../API/API";
import { useNavigate } from "react-router-dom";
import Loader from "../Loaders/Loader";
import { async } from "@firebase/util";
import { useTranslation } from "react-i18next";
import useGetAllOwners from "../Hooks/useOwner";
import { OWNER_DELETE, OWNER_UPDATE } from "../../Graphql/mutation/ownerMutation";
import { useMutation } from "@apollo/client";

export default function DriverManagmentTable() {
  // const [trigger, settrigger] = useState("");
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [paginationFilters, setPaginationFilters] = useState({
    pageNo: 1,
    pageSize: 5,
    limitSkip: 0,
    limit: 10,
  });
  const [DeleteOwner] = useMutation(OWNER_DELETE);
  const [updateOwner] = useMutation(OWNER_UPDATE);
  const { owners} = useOwners();
  // const { owners, count} = useGetAllOwners(updateTrigger,paginationFilters);
  console.log("🚀 ~ file: OwnersTables.jsx:42 ~ DriverManagmentTable ~ owners:", owners)
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  
  
  // const [count, setCount] = useState(0);

  const filteredOwner = owners
  // const showModal = () => {
  //   setIsModalVisible(!isModalVisible);
  // };
  const handleChange = (value, pageSize) => {
    const limitSkip = value * pageSize;
    const limit = pageSize;
    setPaginationFilters({ pageNo: value, pageSize, limitSkip, limit });
    setLoading(true);
    setUpdateTrigger(!updateTrigger);
  };
  const confirm = async (owner, action) => {
    debugger;
    setUpdateTrigger(false);
    try {
      const { data } = await DeleteOwner({
        variables: { id: owner._id },
      });
      message.success(data?.deleteOwner?.message);
      setUpdateTrigger(true);
    } catch (error) {
      // Handle the error if needed
      console.log(error);
    }
  };
  const columns = [
    {
      title: t("tableTabs.name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("tableTabs.email"),
      dataIndex: "email",
      key: "email",
    },
    {
      title: t("tableTabs.verified"),
      render: (recored) => (
        <span>
          <Tag
            key={recored._id}
            color={recored.emailVerified ? "green" : "pink"}
          >
            {recored.emailVerified ? "Yes" : "No"}
          </Tag>
        </span>
      ),
    },
    {
      title: t("tableTabs.approved"),
      key: "approval",
      render: (recored) => (
        <Space>
          <Switch
            checked={
              recored.isApproved !== undefined && recored.isApproved === true
            }
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            onChange={async (value) => {
              if (recored.emailVerified) {
                let data = { isApproved: value };
                // await UpdateOwner(recored._id, data);
                await updateOwner({
                  variables: {
                      id:recored._id,
                      updateOwnerInput: data
                  },
                  });
              } else {
                if (
                  window.confirm(
                    "Are you sure to approve this user is not verified!"
                  )
                ) {
                  let data = { isApproved: value };
                  await updateOwner({
                    variables: {
                        id:recored._id,
                        updateOwnerInput: data
                    },
                    });
                }
              }
            }}
          />
        </Space>
      ),
    },
    {
      title: "Accept Request",
      key: "accept",
      render: (recored) => (
        <Space>
          <Switch
            checked={
              recored.isAccept !== undefined && recored.isAccept === true
            }
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}

            onChange={async (value) => {
              if (recored.emailVerified) {
                let dataa = { isAccept: value };
                await updateOwner({
                  variables: {
                      id:recored._id,
                      updateOwnerInput: dataa
                  },
                  });
              } else {
                if (
                  window.confirm(
                    "Are you sure to allow this user for request ?"
                  )
                ) {
                  let dataa = { isAccept: value };
                  await updateOwner({
                    variables: {
                        id:recored._id,
                        updateOwnerInput: dataa
                    },
                    });
                }
              }
            }}
          />
        </Space>
      ),
    },
    {
      title: "VAT",
      key: "accept",
      render: (recored) => (
        <Space>
          <Switch
            checked={
              recored.isVAT !== undefined && recored.isVAT === true
            }
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}

            onChange={async (value) => {
              if (recored.emailVerified) {
                let dataa = { isVAT: value };
                await updateOwner({
                  variables: {
                      id:recored._id,
                      updateOwnerInput: dataa
                  },
                  });
              } else {
                if (
                  window.confirm(
                    "Are you sure to allow this user for request ?"
                  )
                ) {
                  let dataa = { isVAT: value };
                  await updateOwner({
                    variables: {
                        id:recored._id,
                        updateOwnerInput: dataa
                    },
                    });
                }
              }
            }}
          />
        </Space>
      ),
    },
    {
      title: "OARR", //only Accept Relation Request
      key: "accept",
      render: (recored) => (
        <Space>
          <Switch
            checked={
              recored.rRA !== undefined && recored.rRA === true
            }
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}

            onChange={async (value) => {
              if (recored.emailVerified) {
                let dataa = { rRA: value };
                await updateOwner({
                  variables: {
                      id:recored._id,
                      updateOwnerInput: dataa
                  },
                  });
              } else {
                if (
                  window.confirm(
                    "Are you sure to allow this user for request ?"
                  )
                ) {
                  let dataa = { rRA: value };
                  await updateOwner({
                    variables: {
                        id:recored._id,
                        updateOwnerInput: dataa
                    },
                    });
                }
              }
            }}
          />
        </Space>
      ),
    },

    {
      title: t("tableTabs.action"),

      dataIndex: "",
      key: "x",
      align: "center",
      render: (record) => (
        <Space>
          <span
            className="eyes-btn"
            onClick={() => {
              sessionStorage.setItem("ownerId", JSON.stringify(record._id));
              navigate(`/owner_detail/${record._id}`,{
                state: { ownerDetails: record },
              });
            }}
          >
            <EyeOutlined />
          </span>

          {/* <Popconfirm
            title={"Are you sure?"}
            okText="Ok"
            cancelText="Cancel"
            onConfirm={async () => {
              setLoading(true);
              await DeleteOwner(record?._id, record)
                .then(() => {
                  setLoading(false);
                })
                .catch((e) => {
                  setLoading(false);
                  alert(JSON.stringify(e));
                });
            }}
          >
            <span className="ant-btn ant-btn-danger">
              <DeleteOutlined />
            </span>
          </Popconfirm> */}
          <Popconfirm
            title={"Are you sure you want to delete?"}
            okText="Ok"
            cancelText="Cancel"
            onConfirm={() => confirm(record, "del")}
          >
            <span className="ant-btn ant-btn-danger">
              <DeleteOutlined />
            </span>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  useEffect(() => {
    if (owners.length > 0) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [owners]);
  // console.log(owners?.length > 0 ? true : false);
  return (
    <div>
      {loading ? <Loader /> : ""}

      <div className="table_search_div">
        <Input
          allowClear
          size="middle"
          placeholder={t("placeholders.search")}
          prefix={<SearchOutlined />}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      </div>
      <Table
        showHeader={true}
        pagination={false}
        // loading={owners ? true : false}
        columns={columns}
        dataSource={filteredOwner?.filter((val) => {
          if (search == "") {
            return val;
          } else if (
            val &&
            Object.keys(val).some((v) =>
              val[v]
                .toString()
                .toLowerCase()
                .includes(search.toString().toLowerCase())
            )
          ) {
            return val;
          }
        })}
        
      />
     {/* Add Pagination component */}
     {/* <Pagination
          defaultCurrent={1}
          defaultPageSize={paginationFilters.pageSize}
          onChange={handleChange}
          total={count}
          className="Pagination"
        /> */}
    </div>
  );
}
