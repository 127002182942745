// import React, { useEffect, useState } from "react";
// import {
//   collection,
//   query,
//   onSnapshot,
//   orderBy,
// } from "firebase/firestore";
// import { db } from "../firebase";
// export default function useCapacities() {
//   const [capacities, setcapacities] = useState([]);
//   const getData = async () => {
//     let q = query(collection(db, "services", "data", "capacities"),orderBy("price","asc"));
//     const unsub = onSnapshot(q, (querySnapShot) => {
//       setcapacities([]);
//       if (!querySnapShot.empty) {
//         querySnapShot.forEach((val) => {
//           setcapacities((prev) => [...prev, val.data()]);
//         });
//       }
//     });

//     return () => unsub();
//   };
//   useEffect(() => {
//     getData();
//   }, []);

//   return { capacities };
// }
import { useEffect, useState, useCallback } from "react";
import { useQuery } from "@apollo/client";
import { GET_ALL_TYPEOFSERVICE } from "../../Graphql/queries/servicesQueries";

function useGetAllCapacities(trigger, paginationFilters) {
  console.log("🚀 ~ file: useCapacities.js:35 ~ useGetAllCapacities ~ paginationFilters:", paginationFilters)
  console.log("🚀 ~ file: useCapacities.js:35 ~ useGetAllCapacities ~ trigger:", trigger)
  const [services, setServices] = useState([]);
  const [count, setCount] = useState(0);

  const { loading, error, data, refetch } = useQuery(GET_ALL_TYPEOFSERVICE, {
    variables: { page: paginationFilters?.pageNo },
    fetchPolicy: "network-only",
  });

  const getAllCapacities = useCallback(async () => {
    if (loading) return;
    if (error) {
      console.log("Error:", error.message);
      return;
    }
    
    const { services } = data;
    if (!services) {
      console.log("No Owner data available.");
      return;
    }
    const { totalDocs } = services;
    setServices(services.services);
    setCount(totalDocs);
  }, [loading, error, data]);

  useEffect(() => {
    getAllCapacities();
    if (trigger) {
      refetch({ page: paginationFilters?.pageNo });
    }
  }, [trigger, getAllCapacities, paginationFilters?.pageNo, refetch]);

  return { loading, error, services, count };
}

export default useGetAllCapacities;