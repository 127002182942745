import React from "react";

import { Input, Table, message } from "antd";
import { SearchOutlined, EyeOutlined, SendOutlined } from "@ant-design/icons";
import useGetAllClearanceRequest from "../Hooks/useGetAllClearanceRequest";
import { useNavigate } from "react-router-dom";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
const ClearanceUnderProcessRequest = () => {
  const { clearanceRequestData } = useGetAllClearanceRequest();
  const navigate = useNavigate();
  const createdRequest = clearanceRequestData.filter(
    (el) => el.status === "underprocess"
  );

  const updateRequestHandler = async (record) => {
    console.log(record.uid);
    try {
      const ref = doc(db, "clearance_request", record.uid);

      await updateDoc(ref, {
        status: "finalizing",
      });
      message.success("Forwarded");
    } catch (error) {
      alert(error.message);
    }
  };
  const columns = [
    {
      title: "Pill Of Lading",
      dataIndex: "pilloflading",
      key: "pillofleading",
    },
    {
      title: "Customer Name",
      dataIndex: "cname",
      key: "cname",
    },
    // {
    //   title: "Invoice",
    //   dataIndex: "invoice",
    //   key: "invoice",
    // },
    // {
    //   title: "Saber",
    //   dataIndex: "saber",
    //   key: "saber",
    // },
    // {
    //   title: "SFDA",
    //   dataIndex: "sfda",
    //   key: "sfda",
    // },
    // {
    //   title: "D/O",
    //   dataIndex: "DO",
    //   key: "DO",
    // },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Actions",
      render(record) {
        return (
          <div style={{ display: "flex", gap: "1rem" }}>
            <span
              className="eyes-btn"
              onClick={() => {
                navigate(`/clearance_details/${record.uid}`);
              }}
            >
              <EyeOutlined />
            </span>
            <span
              className="ant-btn "
              onClick={() => {
                updateRequestHandler(record);
              }}
            >
              <SendOutlined style={{ color: "red" }} />
            </span>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div style={{ marginBottom: ".5rem" }}>
        <Input prefix={<SearchOutlined />} placeholder="search..." />
      </div>
      <Table dataSource={createdRequest} columns={columns} />
    </>
  );
};

export default ClearanceUnderProcessRequest;
