import { gql } from "@apollo/client";

const SUBADMIN_CREATE = gql`
mutation CreateSubAdmin($registerSubAdminInput: RegisterSubAdminInput) {
    createSubAdmin(registerSubAdminInput: $registerSubAdminInput) {
      code
      data {
        _id
        email
        password
        permissions
        role
        status
        token
        userName
      }
      message
      success
    }
  }
`;
const SUBADMIN_LOGIN = gql`
mutation Mutation($loginInput: LoginInput) {
  loginAdmin(loginInput: $loginInput) {
    code
    message
    success
    data {
      _id
      email
      password
      token
      url
      userName
    }
  }
}
`;
const SUBADMIN_DELETE = gql`
mutation DeleteSubAdmin($id: ID!) {
    deleteSubAdmin(ID: $id) {
      code
      message
      success
      data {
        _id
        email
        password
        permissions
        role
        status
        token
        userName
      }
    }
  }
`;
const SUBADMIN_UPDATE = gql`
mutation UpdateSubAdmin($id: ID!, $updateSubAdminInput: UpdateSubAdminInput) {
    updateSubAdmin(ID: $id, updateSubAdminInput: $updateSubAdminInput) {
      code
      data {
        _id
        email
        password
        permissions
        role
        status
        token
        userName
      }
      message
      success
    }
  }
`;
export { SUBADMIN_LOGIN, SUBADMIN_CREATE, SUBADMIN_DELETE, SUBADMIN_UPDATE };
