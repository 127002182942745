import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SearchOutlined, DeleteOutlined, EyeOutlined,SmileOutlined } from "@ant-design/icons";
import { MdOutlineCancelPresentation } from "react-icons/md";
import { Table, Input, Popconfirm, Space } from "antd";
import useOnTheWayRequests from "../Hooks/useOntheWayRequests";
import { useTranslation } from "react-i18next";
import offline from "./images/offline.png";
import online from "./images/online.png";

export default function OnTheWayRequetsTable() {
  const navigate = useNavigate();
  const { onthewayrequests } = useOnTheWayRequests();

  const [search, setSearch] = useState("");
  const { t } = useTranslation();
  let columns = [
    {
      title: t(" request"),
      children: [
        {
          title: t("tableTabs.id"),
          render: (record) => (
            <span title={record?.request?.requestId}>
              {record?.request?.requestId}
            </span>
          ),
        },
        {
          title: t("Name "),
          render: (recored) => <span>{recored.request?.name}</span>,
        },
        {
          title: t("tableTabs.phoneNumber"),
          render: (recored) => <span>{recored.request?.phoneNumber}</span>,
        },
        {
          title: t("tableTabs.from"),
          render: (recored) => <span>{recored.request?.from}</span>,
        },
        {
          title: t("tableTabs.to"),
          render: (recored) => <span>{recored.request?.to}</span>,
        },
        {
          title: t("tableTabs.price"),
          render: (recored) => <span>{recored.request?.price}</span>,
        },
      ],
    },
    {
      title: t("tableTabs.startedBy"),
      children: [
        {
          title: t("tableTabs.owner"),
          render: (recored) => <span>{recored.owner?.name}</span>,
        },
        {
          title: t("tableTabs.phoneNumber"),
          render: (recored) => <span>{recored.owner?.phoneNumber}</span>,
        },
        {
          title: t("tableTabs.driver"),
          render: (recored) => (
            <span>{recored?.transport?.driver?.driverName}</span>
          ),
        },
        {
          title: t("tableTabs.driverId"),
          render: (recored) => (
            <span>{recored?.transport?.driver?.driverIdNumber}</span>
          ),
        },
        {
          title: t("tableTabs.truck"),
          render: (recored) => (
            <span>{recored?.transport?.truck?.truckPlate}</span>
          ),
        },
      ],
    },
    {
      title: t("tableTabs.action"),
      dataIndex: "",
      key: "x",
      align: "center",
      render: (record) => (
        <Space>
          <span
            title="View Request"
            className="eyes-btn"
            onClick={() => {
              navigate(`/ontheway_request/${record.request.uid}`, {
                state: record,
              });
            }}
          >
            <EyeOutlined />
          </span>
          {record.offlinePaid ? (
            <span>
              <img
                src={offline}
                style={{
                  width: "40px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  marginBottom: "9px",
                }}
                alt="offline"
              />
            </span>
          ) : (
            <span >
              <img
                src={online}
                style={{
                  width: "42px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  marginBottom: "9px",
                }}
                alt="online"
              />
            </span>
          )}
        </Space>
      ),
    },
  ];
  return (
    <div>
      <div>
        <div className="table_search_div">
          <Input
            allowClear
            size="middle"
            placeholder={t("placeholders.search")}
            prefix={<SearchOutlined />}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </div>
        <Table
          bordered
          columns={columns}
          dataSource={
            onthewayrequests &&
            onthewayrequests?.length > 0 &&
            onthewayrequests?.filter((val) => {
              if (search == "") {
                return val;
              } else if (
                val &&
                Object.keys(val).some((v) =>
                  val[v]
                    .toString()
                    .toLowerCase()
                    .includes(search.toString().toLowerCase())
                )
              ) {
                return val;
              }
            })
          }
        />
      </div>
    </div>
  );
}
