import React from "react";
import { Badge, Tabs } from "antd";
import { useTranslation } from "react-i18next";
import useCheckActiveTab from "../Hooks/useCheckActiveTab";
import useSeaFreightRequest from "../Hooks/useSeaFreightRequest";
import SeaFreightNewRequestTable from "../Tables/SeaFrieghtNewRequestTable";
import SeaFreightUnderProcessTable from "../Tables/SeaFreightUnderProcessTable";
import SeaFreightFinalizingTable from "../Tables/SeaFreightFinalizingTable";
import SeaFreightFinanceTable from "../Tables/SeaFreightFinanceTable";
import SeaFreightCompleteTable from "../Tables/SeaFreightCompleteTable";

const SeeFreight = () => {
  const { handleTabChange, activeTab } = useCheckActiveTab();
  const { seaFreight } = useSeaFreightRequest();
  console.log(seaFreight);
  const { t } = useTranslation();
  const { TabPane } = Tabs;
  return (
    <>
      <div>
        <h3>Sea Freight</h3>
      </div>
      <div>
        <Tabs onChange={handleTabChange} activeKey={activeTab}>
          <TabPane
            tab={
              <Badge
                offset={[5, -6]}
                count={
                  <span
                    style={{ backgroundColor: "green" }}
                    className="Span-reqest"
                  >
                    {seaFreight.filter((el) => el.status === "created").length}
                  </span>
                }
              >
                <span>New Request</span>
              </Badge>
            }
            key="1"
          >
            <SeaFreightNewRequestTable />
          </TabPane>
          <TabPane
            tab={
              <Badge
                offset={[5, -6]}
                count={
                  <span
                    style={{ backgroundColor: "blue" }}
                    className="Span-reqest"
                  >
                    {
                      seaFreight.filter((el) => el.status === "underprocess")
                        .length
                    }
                  </span>
                }
              >
                <span>Under Process Request</span>
              </Badge>
            }
            key="2"
          >
            <SeaFreightUnderProcessTable />
          </TabPane>
          <TabPane
            tab={
              <Badge
                offset={[5, -6]}
                count={
                  <span
                    style={{ backgroundColor: "brown" }}
                    className="Span-reqest"
                  >
                    {
                      seaFreight.filter((el) => el.status === "finalizing")
                        .length
                    }
                  </span>
                }
              >
                <span>Finalizing</span>
              </Badge>
            }
            key="3"
          >
            <SeaFreightFinalizingTable />
          </TabPane>
          <TabPane
            tab={
              <Badge
                offset={[5, -6]}
                count={
                  <span
                    style={{ backgroundColor: "#001529" }}
                    className="Span-reqest"
                  >
                    {seaFreight.filter((el) => el.status === "finance").length}
                  </span>
                }
              >
                <span>Finance</span>
              </Badge>
            }
            key="4"
          >
            <SeaFreightFinanceTable />
          </TabPane>
          <TabPane
            tab={
              <Badge
                offset={[5, -6]}
                count={
                  <span
                    style={{ backgroundColor: "#d06d2c" }}
                    className="Span-reqest"
                  >
                    {seaFreight.filter((el) => el.status === "complete").length}
                  </span>
                }
              >
                <span>Complete</span>
              </Badge>
            }
            key="5"
          >
            <SeaFreightCompleteTable />
          </TabPane>
        </Tabs>
      </div>
    </>
  );
};

export default SeeFreight;
