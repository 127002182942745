import React, { useState } from "react";
import {
  Modal,
  Button,
  Input,
  Form,
  Upload,
  Row,
  Col,
  Select,
  message,
} from "antd";

import { UploadOutlined } from "@ant-design/icons";
import { AddTruck, MergeTruckDriver } from "../API/API";
import Loader from "../Loaders/Loader";
import useDrivers from "../Hooks/useDrivers";
import useTrucks from "../Hooks/useTrucks";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import { GET_All_DRIVERS } from "../../Graphql/queries/driverQueries";
import { GET_ALL_TRUCKS } from "../../Graphql/queries/truckQueries";
import { UPDATE_DRIVER } from "../../Graphql/mutation/driverMutation";
import { DRIVER_TRUCK_CREATE } from "../../Graphql/mutation/driverTruckMutation";
import { UPDATE_TRUCK } from "../../Graphql/mutation/truckMutation";
import { GET_MY_DRIVER_TRUCK } from "../../Graphql/queries/driverTrucksQueries";
export default function AddTruckDriverModal({ isModalVisible, setIsModalVisible, ownerId }) {
  console.log("🚀 ~ file: AddTruckDriverModal.jsx:27 ~ AddTruckDriverModal ~ ownerId:", ownerId)
  const { Option } = Select;
  const { refetch, driverTrucks } = useQuery(GET_MY_DRIVER_TRUCK, {
    variables: { page: 1, ownerId },
    skip: true,
  });
  // const { drivers } = useDrivers();
  // const { trucks } = useTrucks();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  // Fetch all drivers and trucks using Apollo Client
  const { data: driversData } = useQuery(GET_All_DRIVERS);
  const { data: trucksData } = useQuery(GET_ALL_TRUCKS);
  const [updateDriver] = useMutation(UPDATE_DRIVER);
  const [updateTruck] = useMutation(UPDATE_TRUCK);
  const [createDriverTruck] = useMutation(DRIVER_TRUCK_CREATE);
  const Drivers = driversData?.Drivers || [];
  const Trucks = trucksData?.Trucks || [];
  const handleOk = () => {
    setIsModalVisible(!isModalVisible);
  };
  const handleCancel = () => {
    setIsModalVisible(!isModalVisible);
  };
  const [formData, setFormData] = useState({
    truck: "",
    driver: "",
  });
  const onFinish = async (values) => {
    debugger;
    setLoading(true);
    let data = {
      driver: { id: formData.driver._id },
      truck: { id: formData.truck._id },
    };
  
    let datat = {
      driver: data.driver.id,
      driverName: formData.driver?.name,
      truck: data.truck.id,
      owner: ownerId,
      truckNo: formData.truck.noPlate,
    };
  
    try {
      const res = await createDriverTruck({
        variables: {
          createDriverTruckInput: datat,
        },
      });
  
      setLoading(false);
  
      if (res.data.createDriverTruck.success) {
        await updateDriver({
          variables: {
            id: data.driver.id,
            updateDriverInput: {
              isTruck: true,
            },
          },
        });
    
        await updateTruck({
          variables: {
            id: data.truck.id,
            updateTruckInput: {
              isDriver: true,
            },
          },
        });
        setSuccess(true);
        handleCancel();
        setFormData({
          truck: "",
          driver: "",
        });
        message.success(res.data.createDriverTruck.message);
      } else {
        message.error(res.data.createDriverTruck.message);
      }
      await refetch();
    } catch (error) {
      setLoading(false);
      message.error(error.message);
    }
  };
  
  return (
    <div>
      <Modal
        title={`${t("Modal.merge")} ${t("tableTabs.truck")} & ${t("tableTabs.driver")}`}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose
        footer={null}
      >
        {loading ? <Loader /> : ""}
        <div>
          <Form form={form} name="add_trcuk_form" onFinish={onFinish}>
            <Input.Group>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item>
                    <Select
                      placeholder={t("placeholders.chooseDriver")}
                      value={formData.driver?.name}
                      onChange={(value) => {
                        formData.driver = Drivers[value];
                        setFormData({
                          ...formData,
                        });
                      }}
                    >
                      {Drivers.length > 0 ? (
                        Drivers
                          // .filter(
                          //   (val) => val.isTruck === undefined && !val.isTruck
                          // )
                          .map((v, i) => {
                      
                            return (
                              <Option
                              value={i}
                                disabled={v.isTruck !== undefined && v.isTruck===true}
                              >
                                {v.name}
                              </Option>
                            );
                          })
                      ) : (
                        <Option disabled>No Driver Avaiable</Option>
                      )}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item>
                    <Select
                      placeholder={t("placeholders.chooseTruck")}
                      value={formData.truck?.idNo}
                      onChange={(value) => {
                        formData.truck = Trucks[value];
                        // formData.truck["uid"] = formData.truck["truckId"];
                        setFormData({
                          ...formData,
                        });
                      }}
                    >
                      {Trucks.length > 0 ? (
                        Trucks
                          // .filter(
                          //   (val) => val.isDriver === undefined && !val.isDriver
                          // )
                          .map((v, i) => {
                            return <Option disabled={v.isDriver===true} value={i}>{v.noPlate}</Option>;
                          })
                      ) : (
                        <Option disabled>No Truck Avaiable</Option>
                      )}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Input.Group>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                {t("Modal.merge")}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
}
