import React from "react";
import { Tabs } from "antd";
import DriverManagmentTable from "../Tables/DriverManagmentTable";
import TruckDriverTable from "../Tables/TruckDriverTable";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import OwnerProfile from "./OwnerProfile";
import TruckManagmentTable from "../Tables/TruckManagmentTable";
export default function OwnerDetail() {
  const { state } = useLocation();
  console.log("🚀 ~ file: OwnerDetail.jsx:11 ~ OwnerDetail ~ ownerDetails:", state.ownerDetails)
  const { id } = useParams();
  console.log("🚀 ~ file: OwnerDetail.jsx:13 ~ OwnerDetail ~ _id:", id)
  const { t } = useTranslation();
  const { TabPane } = Tabs;
  return (
    <div>
      <div>
        <h3>{t("tableTabs.ownerDetail")}</h3>
      </div>
      <div>
        <Tabs defaultActiveKey="1">
          <TabPane tab={t("tableTabs.profile")} key="1">
            <OwnerProfile owner={state.ownerDetails} />
          </TabPane>
          <TabPane tab={t("tableTabs.driver")} key="2">
            <DriverManagmentTable ownerId={id} />
          </TabPane>
          <TabPane tab={t("tableTabs.truck")} key="3">
            <TruckManagmentTable ownerId={id} />
          </TabPane>
          <TabPane
            tab={`${t("tableTabs.truck")} & ${t("tableTabs.driver")} `}
            key="4"
          >
            <TruckDriverTable ownerId={id} />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
}
