import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Input,
  Form,
  Row,
  Col,
  Select,
  message,
  InputNumber,
} from "antd";
import Loader from "../Loaders/Loader";
import { getPreciseDistance } from "geolib";
//Api
import { AddCombination, UpdateCombinations } from "../API/API";

//hooks
import useCapacites from "../Hooks/useCapacities";
import useCities from "../Hooks/useCities";
import useHeights from "../Hooks/useHeights";
import useTypes from "../Hooks/useTypes";
import { useTranslation } from "react-i18next";
export default function UpdateCombination({
  isUpdateModalVisible,
  setIsUpdateModalVisible,
  record 
}) {
  const [form] = Form.useForm();
  const { Option } = Select;
  const [loading, setLoading] = useState(false);
  const { cities } = useCities();
  const { capacities } = useCapacites();
  const { types } = useTypes();
  const { heights } = useHeights();
  const [commission,setcommission] = useState(0);
  const [commissionPrice, setcommissionPrice] = useState(0);
  const [vat, setVat] = useState(0);
  const [vatPrice, setVatPrice] = useState(0);
  const [cprice, setCPrice] = useState(0);
  const [distance, setDistance] = useState("");
const [type, setType] = useState("");
const [from, setFrom] = useState("");
const [to, setTo] = useState("");
const [height, setHeight] = useState("");
const [capacity, setCapacity] = useState("");
const [price, setPrice] = useState("");
  const { t } = useTranslation();

  const handleOk = () => {
    setIsUpdateModalVisible(!isUpdateModalVisible);
    form.resetFields();


  };

  const handleCancel = () => {
    setIsUpdateModalVisible(!isUpdateModalVisible);
    form.resetFields();

  };


// const onFinish = (values) => {
//     setLoading(true);
//     let data = {
//       ...values,
//     };

//     UpdateCombinations({ ...data,from} ,`${record?.uid}`)

//       .then(() => {
//         setLoading(false);
//         form.resetFields();
//         handleCancel();
//       })
//       .catch((e) => {
//         setLoading(false);
//         message.error(JSON.stringify(e));
//       });
//   };

const onFinish = (values) => {
  setLoading(true);

  const { from, to, ...data } = values;

  if (from && to) {
    data.from = from;
    data.to = to;

    UpdateCombinations(data, `${record?.uid}`)
      .then(() => {
        setLoading(false);
        form.resetFields();
        handleCancel();
      })
      .catch((e) => {
        setLoading(false);
        message.error(JSON.stringify(e));
      });
  } else {
    setLoading(false);
    message.error("Please select 'from' and 'to' locations.");
  }
};



const getDistanceTimePrice = () => {
    let distance = (getPreciseDistance(from.origin, to.origin) / 1000).toFixed(
      2
    );
    console.log(distance);
    let time = (parseInt(distance * 0.7 + 30) / 60).toFixed(2);
    let typePrices =
      parseFloat(type.price) +
      parseFloat(height.price) +
      parseFloat(capacity.price);
    let price = typePrices * distance;
    price = price.toFixed(2);
    form.setFieldsValue({ time: time });
    form.setFieldsValue({ distance: distance });
    form.setFieldsValue({ price: parseInt(price) });
    setPrice(parseInt(price));
    setCPrice(parseInt(price));
  };
  
  useEffect(() => {
    if (
      type !== "" &&
      height !== "" &&
      capacity !== "" &&
      from !== "" &&
      to !== ""
    ) {
      getDistanceTimePrice();
    }
  }, [type, height, capacity, from, to]);
  useEffect(() => {
    if (cprice !== 0 && commission !== 0) {
      setcommissionPrice((parseInt(commission) * parseInt(cprice)) / 100);
    }
  }, [cprice, commission]);

  useEffect(() => {
    if (cprice !== 0 && vat !== 0) {
      setVatPrice((parseInt(vat) * parseInt(cprice)) / 100);
    }
  }, [cprice, vat]);
  

  useEffect(() => {
    if (record) {
        form.setFieldsValue({
            type: record.type,
            height: record.height,
            capacity: record.capacity,
            from: record.from,
            to: record.to,
            price: record.price,
            time:record.time,
            distance:record.distance,
            commission:record.commission,
          });
      }
  }, [record,form]);

  
  useEffect(() => {
    if (isUpdateModalVisible) {
      form.setFieldsValue(record);
    }
  }, [isUpdateModalVisible, record]);


  return (
    <div>
      <Modal
        title={t("Modal.updatecombination")}
        visible={isUpdateModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose
        footer={null}
      >
        {loading ? <Loader /> : ""}
        <div>
          <Form form={form} name="make_request_form" onFinish={onFinish}>
            <Input.Group>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="from"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Requerd Field!",
                    //   },
                    // ]}
                  >
                    <Select
                      style={{ border: "2px solid  #2d808b" }}
                      placeholder={t("placeholders.from")}
                      onChange={(val) => {
                        let value = cities[val];
                        form.setFieldsValue({ from: value.city }); 
                        setFrom(value); 
                      }}
                    >
                      {cities &&
                        cities.map((v, i) => {
                          return (
                            <Option key={i} value={i}>
                              {v.city}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="to"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Requerd Field!",
                    //   },
                    // ]}
                  >
                    <Select
                      style={{ border: "2px solid  #2d808b" }}
                      placeholder={t("placeholders.to")}
                      onChange={(val) => {
                        let value = cities[val];
                        form.setFieldsValue({ to: value.city });
                        setTo(value);
                      }}
                    >
                      {cities &&
                        cities.map((v, i) => {
                          return (
                            <Option key={i} value={i}>
                              {v.city}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Input.Group>

            <Input.Group>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="capacity"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Requerd Field!",
                    //   },
                    // ]}
                  >
                    <Select
                      style={{ border: "2px solid  #2d808b" }}
                      placeholder={t("placeholders.selectCapacity")}
                      onChange={(val) => {
                        let value = capacities[val];
                        form.setFieldsValue({ capacity: value.capacity });
                        setCapacity(value);
                      }}
                    >
                      {capacities &&
                        capacities.map((v, i) => {
                          return (
                            <Option key={i} value={i}>
                              {v.capacity}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="height"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Requerd Field!",
                    //   },
                    // ]}
                  >
                    <Select
                      style={{ border: "2px solid  #2d808b" }}
                      placeholder={t("placeholders.selectHight")}
                      onChange={(val) => {
                        let value = heights[val];
                        form.setFieldsValue({ height: value.height });
                        setHeight(value);
                      }}
                    >
                      {heights &&
                        heights.map((v, i) => {
                          return (
                            <Option key={i} value={i}>
                              {v.height}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Input.Group>
            <Input.Group>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="type"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Requerd Field!",
                    //   },
                    // ]}
                  >
                    <Select
                      style={{ border: "2px solid  #2d808b" }}
                      placeholder={t("placeholders.selectType")}
                      onChange={(val) => {
                        let value = types[val];
                        form.setFieldsValue({ type: value.type });
                        setType(value);
                      }}
                    >
                      {types &&
                        types.map((v, i) => {
                          return (
                            <Option key={i} value={i}>
                              {v.type}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="price"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Input
                      style={{ border: "2px solid  #2d808b" }}
                      type={"number"}
                      value={price}
                      placeholder={t("placeholders.price")}
                      addonAfter={t("Modal.riyal")}
                      onChange={(e) => {
                        setCPrice(e.target.value);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Input.Group>
            <Input.Group>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="time"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Input
                      placeholder={t("placeholders.estimateTime")}
                      style={{ border: "2px solid  #2d808b" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="distance"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Input
                      style={{ border: "2px solid  #2d808b" }}
                      value={distance}
                      placeholder={t("placeholders.distance")}
                      addonAfter={t("Modal.km")}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Input.Group>
            <Input.Group>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="commission"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Input
                      type={"number"}
                      // addonBefore={commissionPrice}
                      placeholder={t("placeholders.commission")}
                      style={{ border: "2px solid  #2d808b" }}
                      addonAfter={"%"}
                      onChange={(e) => {
                        setcommission(e.target.value);
                        form.setFieldsValue({ commission: e.target.value });
                        console.log(e.target.value);
                      }}
                    />
                    <span>
                      {commissionPrice > 0
                        ? `Commision in Price ${commissionPrice} Riyal`
                        : ""}
                    </span>
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="vat"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Input
                      type={"number"}
                      // addonBefore={commissionPrice}
                      placeholder="VAT"
                      style={{ border: "2px solid  #2d808b" }}
                      addonAfter={"%"}
                      onChange={(e) => {
                        setVat(e.target.value);
                        form.setFieldsValue({ vat: e.target.value });
                        console.log(e.target.value);
                      }}
                    />
                    <span>
                      {vatPrice > 0
                        ? `Commision in Price ${vatPrice} Riyal`
                        : ""}
                    </span>
                  </Form.Item>
                </Col>
              </Row>
            </Input.Group>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-btn"
                // className="login-form-button"
              >
                {t("Modal.Update")}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
}
