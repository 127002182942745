import React, { useEffect, useState } from "react";
import { Table, Button, Input, Popconfirm, Space, Select, Tag, Pagination, message } from "antd";
import {
  PlusCircleOutlined,
  SearchOutlined,
  UploadOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import AddTruckModal from "../Modals/AddTruckModal";
import useTrucks from "../Hooks/useTrucks";
import { DeleteTruck } from "../API/API";
import EditTruckModal from "../Modals/EditTruckModal";
import { useTranslation } from "react-i18next";
import useGetMyTrucks from "../Hooks/useTrucks";
import Loader from "../Loaders/Loader";
import { DELETE_TRUCK } from "../../Graphql/mutation/truckMutation";
import { useMutation } from "@apollo/client";
import SimpleLoader from "../Loaders/simpleLoader";
const TruckManagmentTable = ({ownerId}) => {
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [paginationFilters, setPaginationFilters] = useState({
    pageNo: 1,
    pageSize: 5,
    limitSkip: 0,
    limit: 10,
  });
  const { trucks, count} = useGetMyTrucks(updateTrigger,paginationFilters, ownerId);
  console.log("🚀 ~ file: TruckManagmentTable.jsx:30 ~ TruckManagmentTable ~ ownerId:", ownerId)
  const handleChange = (value, pageSize) => {
    const limitSkip = value * pageSize;
    const limit = pageSize;
    setPaginationFilters({ pageNo: value, pageSize, limitSkip, limit });
    setLoading(true);
    setUpdateTrigger(!updateTrigger);
  };
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [deleteTruck] = useMutation(DELETE_TRUCK);
  const [truck, setTruck] = useState({});
  const { t } = useTranslation();
  const showModal = () => {
    setIsModalVisible(true);
  };
  const showEditModal = () => {
    setIsEditModalVisible(!isEditModalVisible);
  };
  const confirm = async (truck, action) => {
    debugger;
    setUpdateTrigger(false);
    try {
      const { data } = await deleteTruck({
        variables: { id: truck._id },
      });
      // Handle the response data if needed
      console.log(data);
      // Remove the deleted user from the list
      // setDrivers((prevUsers) =>
      //   prevUsers.filter((user) => user._id !== truck._id)
      // );
      // Update the total count of users
      // setCount(count - 1);
      message.success("Truck Deleted Successfully");
      setUpdateTrigger(true);
    } catch (error) {
      // Handle the error if needed
      console.log(error);
    }
  };
  const columns = [
    {
      title: t("tableTabs.modal"),
      dataIndex: "model",
      key: "model",
    },
    {
      title: t("tableTabs.plate"),
      dataIndex: "noPlate",
      key: "noPlate",
    },
    {
      title: t("tableTabs.type"),
      dataIndex: "type",
      key: "type",
    },
    {
      title: t("tableTabs.capacity"),
      dataIndex: "capacity",
      key: "capacity",
    },
    {
      title: t("tableTabs.hight"),
      dataIndex: "height",
      key: "heifgt",
    },
    {
      title: t("tableTabs.merged"),
      render: (record) => (
        <span>
          <Tag color={record.isDriver ? "green" : "pink"}>
            {record.isDriver ? "Yes" : "No"}
          </Tag>
        </span>
      ),
    },

    {
      title: t("Action"),
      dataIndex: "",
      key: "x",
      align: "center",
      render: (record) => (
        <Space>
          <span
            onClick={() => {
              setTruck(record);
              showEditModal();
            }}
            className="ant-btn ant-btn-primary"
          >
            <EditOutlined />
          </span>

          <Popconfirm
            title={"Are you sure you want to delete?"}
            okText="Ok"
            cancelText="Cancel"
            onConfirm={() => confirm(record, "del")}
            // onConfirm={() => {
            //   if (record?.isDriver) {
            //     alert("Please remove merging tarnsport first");
            //   } else {
            //     DeleteTruck(record?.uid, record);
            //   }
            // }}
          >
            <span className="ant-btn ant-btn-danger">
              <DeleteOutlined />
            </span>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  useEffect(() => {
    // setDrivers(drivers);
    if (trucks.length > 0) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [trucks]);
  return (
    <div>
    <AddTruckModal
      isModalVisible={isModalVisible}
      setIsModalVisible={setIsModalVisible}
      ownerId={ownerId}
    />
    <EditTruckModal
      isModalVisible={isEditModalVisible}
      setIsModalVisible={setIsEditModalVisible}
      data={truck}
    />

    <div className="table_topar_add_div_">
      <Button
        type="light"
        // shape="circle"
        icon={<PlusCircleOutlined />}
        size={"middle"}
        onClick={showModal}
      >
        {t("tableTabs.addTruck")}
      </Button>
    </div>
    <div className="table_search_div">
      <Input
        allowClear
        size="middle"
        placeholder={t("placeholders.search")}
        prefix={<SearchOutlined />}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
      />
    </div>
    {loading ? <SimpleLoader /> : ""}
    <Table
      columns={columns}
      pagination={false}
      // expandable={{
      //   expandedRowRender: (record) => (
      //     <p
      //       style={{
      //         margin: 0,
      //       }}
      //     >
      //       {record.description}
      //     </p>
      //   ),
      //   rowExpandable: (record) => record.name !== "Not Expandable",
      // }}
      dataSource={trucks.filter((val) => {
        if (search == "") {
          return val;
        } else if (
          val &&
          Object.keys(val).some((v) =>
            val[v]
              .toString()
              .toLowerCase()
              .includes(search.toString().toLowerCase())
          )
        ) {
          return val;
        }
      })}
    />
    <Pagination
        defaultCurrent={1}
        defaultPageSize={paginationFilters.pageSize}
        onChange={handleChange}
        total={count}
        className="Pagination"
      />
  </div>
  )
}

export default TruckManagmentTable
