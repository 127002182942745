// import React, { useEffect, useState } from "react";
// import { collection, query, onSnapshot, where } from "firebase/firestore";
// import { db } from "../firebase";
// export default function useTrucks() {
//   const ownerId = JSON.parse(sessionStorage.getItem("ownerId"));
//   const [trucks, setTrucks] = useState([]);
//   const getTrucks = async () => {
//     let q = query(collection(db, "trucks"), where("ownerId", "==", ownerId));
//     const unsub = onSnapshot(q, (querySnapShot) => {
//       setTrucks([]);
//       if (!querySnapShot.empty) {
//         querySnapShot.forEach((val) => {
//           setTrucks((prev) => [...prev, val.data()]);
//         });
//       }
//     });

//     return () => unsub();
//   };
//   useEffect(() => {
//     getTrucks();
//   }, []);


//   return { trucks };
// }
// import React, { useEffect, useState } from "react";
// import { collection, query, onSnapshot,where, } from "firebase/firestore";
// import { db } from "../firebase";
// export default function useDrivers() {
//  const ownerId = JSON.parse(sessionStorage.getItem("ownerId"));
//   const [trucks, setTrucks] = useState([]);
//   const getDrivers = async () => {
//     let q = query(collection(db, "trucks"), where("ownerId", "==", ownerId));
//     const unsub = onSnapshot(q, (querySnapShot) => {
//       setTrucks([]);
//       if (!querySnapShot.empty) {
//         querySnapShot.forEach((val) => {
//           setTrucks((prev) => [...prev, val.data()]);
//         });
//       }
//     });

//     return () => unsub();
//   };
//   useEffect(() => {
//     getDrivers();
//   }, []);

//   return { trucks };
// }
// import { getDoc, doc } from "firebase/firestore";
// import { useEffect, useState } from "react";
// import { db } from "../firebase";

// export default function useOwner() {
//   const [owner, setOwner] = useState({});
//   let uid = JSON.parse(sessionStorage.getItem("ownerId"));

//   const getData = async () => {
//     let userRef = doc(db, "trucks", uid);
//     let snap = await getDoc(userRef);
//     if (snap.exists()) {
//       setOwner(snap.data());
//     }
//   };

//   useEffect(() => {
//     getData();
//   }, [uid]);

//   return { owner };
// }
import { useEffect, useState, useCallback } from "react"; // Import your GraphQL query for trucks
import { useQuery } from "@apollo/client";
import { GET_MY_TRUCK } from "../../Graphql/queries/truckQueries";

function useGetMyTrucks(trigger, paginationFilters, ownerId) {
  console.log("🚀 ~ file: useTrucks.js:79 ~ useGetMyTrucks ~ paginationFilters:", paginationFilters)
  // const ress = JSON.parse(sessionStorage.getItem("user"));
//   // let token = window.atob(ress.token);
//  const ownerId = JSON.parse(sessionStorage.getItem("ownerId"));
//  console.log("🚀 ~ file: useDrivers.js:53 ~ useGetMyTrucks ~ ownerId:", ownerId)

//   console.log("🚀 ~ file: useDrivers.js:56 ~ useGetMyTrucks ~ ownerId:", ownerId)
  const [trucks, setTrucks] = useState([]);
  const [count, setCount] = useState(0);

  const { loading, error, data, refetch } = useQuery(GET_MY_TRUCK, {
    variables: { page: paginationFilters?.pageNo, ownerId },
    fetchPolicy: "network-only",
  });

  const getMyTrucks = useCallback(async () => {
    if (loading) return;
    if (error) {
      console.log("Error:", error.message);
      return;
    }
    const { myTrucks } = data;
    if (!myTrucks) {
      console.log("No Owner data available.");
      return;
    }
    const { trucks, totalDocs } = myTrucks;
    setTrucks(trucks);
    setCount(totalDocs);
  }, [loading, error, data]);

  useEffect(() => {
    getMyTrucks();
    if (trigger) {
      refetch({ page: paginationFilters?.pageNo });
    }
  }, [trigger, getMyTrucks, paginationFilters?.pageNo, refetch]);

  return { loading, error, trucks, count };
}

export default useGetMyTrucks;
