import { gql } from "@apollo/client";

const GET_ALL_REQUEST = gql`
query CustomerRequests($page: Int!) {
  customerRequests(page: $page) {
    currentPage
    totalDocs
    totalPages
    requests {
      _id
      acceptedBy {
        driverId
        ownerId
        transportId
      }
      blNumber
      blUpload
      commission
      coordinates {
        latitude
        longitude
      }
      createdAt
      customer {
        crNumber
        crNumberUpload
        createdAt
        email
        emailVerified
        idNumber
        idNumberUpload
        isApproved
        isDeleted
        isHold
        name
        phoneNumber
        proVerified
        profileImage
        token
        type
        userName
      }
      deliveryNote
      distance
      driverStarted
      eir
      estimateTime
      finalByan
      from
      height
      invoice
      iroDate {
        nanoseconds
        seconds
      }
      iroUpload
      isAccept
      isFinished
      isNewRequest
      isSeen
      isStarted
      location
      name
      notificationId
      odUpload
      offlinePaid
      phoneNumber
      price
      quantity
      receipt
      receiverContact
      sendTo
      senderContact
      serviceType
      status
      to
      type
    }
  }
}
`;
const GET_REQUEST_ID = gql`
query CustomerRequest($id: ID!) {
  customerRequest(ID: $id) {
    _id
    acceptedBy {
      driverId
      ownerId
      transportId
    }
    blNumber
    commission
    blUpload
    coordinates {
      latitude
      longitude
    }
    createdAt
    customer {
      crNumber
      createdAt
      crNumberUpload
    }
    deliveryNote
    distance
    driverStarted
    eir
    estimateTime
    finalByan
    from
    height
    invoice
    iroDate {
      nanoseconds
      seconds
    }
    iroUpload
    isAccept
    isFinished
    isNewRequest
    isSeen
    isStarted
    location
    name
    notificationId
    odUpload
    offlinePaid
    phoneNumber
    price
    quantity
    receipt
    receiverContact
    sendTo
    senderContact
    serviceType
    status
    to
    type
  }
}
`;
const GET_REQUEST_WITH_STATUS = gql`
query GetRequestsWithStatus($status: String!) {
  getRequestsWithStatus(status: $status) {
    _id
    acceptedBy {
      driverId
      ownerId
      transportId
    }
    blNumber
    commission
    blUpload
    coordinates {
      latitude
      longitude
    }
    createdAt
    customer {
      crNumber
      createdAt
      crNumberUpload
    }
    deliveryNote
    distance
    driverStarted
    eir
    estimateTime
    finalByan
    from
    height
    invoice
    iroDate {
      nanoseconds
      seconds
    }
    iroUpload
    isAccept
    isFinished
    isNewRequest
    isSeen
    isStarted
    location
    name
    notificationId
    odUpload
    offlinePaid
    phoneNumber
    price
    quantity
    receipt
    receiverContact
    sendTo
    senderContact
    serviceType
    status
    to
    type
  }
}

`;
const GET_REQUEST_EMAIL = gql`
  query CustomerEmail($email: String) {
    customerEmail(email: $email) {
      email
      firebaseEmail
      id
      token
      uid
      userName
      url
    }
  }
`;

export { GET_REQUEST_ID, GET_ALL_REQUEST, GET_REQUEST_EMAIL,GET_REQUEST_WITH_STATUS };
