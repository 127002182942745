import { getDoc, doc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../firebase";

export default function useRequest(uid) {
  const [request, setRequest] = useState({});
  const getData = async () => {
    let requestRef = doc(db, "customer_transport_requests", uid);
    let snap = await getDoc(requestRef);
    if (snap.exists()) {
      setRequest(snap.data());
    }
  };

  useEffect(() => {
    getData();
  }, [uid]);

  return request;
}
