import { useState, useEffect } from "react";
import {
  Button,
  Form,
  Input,
  Select,
  message,
  Row,
  Space,
  Col,
  Popconfirm,
  Table,
  Pagination,
} from "antd";
import { LockOutlined, MailOutlined, DeleteOutlined } from "@ant-design/icons";
import { EditOutlined } from "@ant-design/icons";
import axios from 'axios';
import {updatePassword } from "firebase/auth";

import FullPageLoading from "../Loaders/Loader";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../firebase";
import {
  collection,
  setDoc,
  doc,
  query,
  onSnapshot,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import PermissionModal from "../Modals/PermissionModal";
import useGetAllSubAdmin from "../Hooks/useSubAdmin";
import { SUBADMIN_CREATE, SUBADMIN_DELETE } from "../../Graphql/mutation/subAdminMutation";
import { useMutation } from "@apollo/client";
// import axios from "axios
const { Option } = Select;

function Pushnotification() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [editFormData, setEditFormData] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [changeBtn, setChangeBtn] = useState(false);
  const [permission, setPermission] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [paginationFilters, setPaginationFilters] = useState({
    pageNo: 1,
    pageSize: 5,
    limitSkip: 0,
    limit: 10,
  });
  const [deleteSubadmin] = useMutation(SUBADMIN_DELETE);
  const [createSubadmin] = useMutation(SUBADMIN_CREATE);
  const { subadmin, count } = useGetAllSubAdmin(
    updateTrigger,
    paginationFilters
  );
  const permissionFiltered = subadmin;
  // const permissionFiltered = subadmin.filter((ele) => ele.isPermission == true);

  const handleChange = (value, pageSize) => {
    const limitSkip = value * pageSize;
    const limit = pageSize;
    setPaginationFilters({ pageNo: value, pageSize, limitSkip, limit });
    setLoading(true);
    setUpdateTrigger(!updateTrigger);
  };
  const showModal = () => {
    setIsModalVisible(!isModalVisible);
  };
  const showEditModal = () => {
    setIsEditModalVisible(true);
  };

  const handleSend = async (values) => {
debugger;
      const { name, email, password, permissions } = values;
      try {
        const registerSubAdminInput = {
          userName:name,
          email:email,
          password:password,
          permissions:permissions,
          role:"subAdmin",
          status:"active",
          // Populate your Subadmin input data
        };
  
        const { data } = await createSubadmin({
          variables: { registerSubAdminInput },
        });
  
        if (data.createSubAdmin.success) {
          Form.resetFields();
          updateTrigger(true);
        } else {
          // Handle the case where Subadmin creation was not successful
        }
      } catch (error) {
        console.error(error);
      }
    
  };
 

  // const deletePermission = async (record) => {
  //   try {
  //     await updateDoc(doc(db, "admin", record.uid), {
  //       isPermission: false,
  //     });
  //     message.destroy("deleted!");
  //   } catch (error) {
  //     message.error(error.message);
  //   }
  // };
  // useEffect(() => {
  //   const getAdmins = async () => {
  //     let q = query(collection(db, "admin"));
  //     const unsub = onSnapshot(q, (querySnapShot) => {
  //       if (!querySnapShot.empty) {
  //         let arr = [];
  //         querySnapShot.forEach((val) => {
  //           arr.push(val.data());
  //           if (arr.length === querySnapShot.size) {
  //             setData([...arr]);
  //           }
  //         });
  //       }
  //     });

  //     return () => unsub();
  //   };
  //   getAdmins();
  // }, [loading]);

  const people = [
    "owners",
    "customers",
    "transportation",
    "drivers",
    "services",
    "permission",
    "relations",
    "finance",
    "Maintenance",
    "Clearance",
    "See Freight",
  ];
  const confirm = async (subadmin, action) => {
    setUpdateTrigger(false);
    try {
      const { data } = await deleteSubadmin({
        variables: { id: subadmin._id },
      });
      message.success("Subadmin Deleted Successfully");
      setUpdateTrigger(true);
    } catch (error) {
      // Handle the error if needed
      console.log(error);
    }
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "userName",
      key: "userName",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Permissions",
      key: "permissions",
      render: (record) => <span>{record?.permissions?.join(", ")}</span>,
    },
    {
      title: "Actions",
      render: (record) => {
        return (
          <Space>
            <span
              onClick={() => {
                setPermission(record);
                showEditModal();
              }}
              className="eyes-btn"
              style={{ marginRight: "7px" }}
            >
              <EditOutlined />
            </span>

            <Popconfirm
              title={"Are you sure you want to delete?"}
              okText="Ok"
              cancelText="Cancel"
              onConfirm={() => confirm(record, "del")}
            >
              <span className="ant-btn ant-btn-danger">
                <DeleteOutlined />
              </span>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];
  useEffect(() => {
    if (editMode === true) {
      form.setFieldsValue(editFormData);
    }
    if (editMode === false) {
      // form.setFieldsValue({});
      setEditFormData({})
    }
  }, [editMode]);
  const toggleEditMode = () => {
    setEditMode(!editMode);
  };
  return (
    <>
       <PermissionModal
        isModalVisible={isEditModalVisible}
        setIsModalVisible={setIsEditModalVisible}
      
        data={permission}
      />
      <h1 style={{ textAlign: "center" }}>Add Sub Admins:</h1>
      {loading ? <FullPageLoading /> : ""}

      <Form
        form={form}
        layout="vertical"
        className="push-notification-form"
        onFinish={handleSend}
        initialValues={editFormData}
      >
        <Row justify="space-between">
          <Col span={7}>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "Name is required",
                },
              ]}
            >
              <Input className="input-primary" placeholder="Enter name" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Email is required",
                },
                {
                  type: "email",
                  message: "Invalid Email(abc@gmail.com)",
                },
              ]}
            >
              <Input
                className="input-primary"
                placeholder="Enter email"
                type="email"
                prefix={<MailOutlined className="site-form-item-icon" />}
              />
            </Form.Item>
          </Col>
          {/* {!editMode ?  */}
        <Col span={7}>
        <Form.Item
          name="password"
          hasFeedback
          rules={[
            {
              required: true,
              message: "Password is required",
            },
            {
              min: 8,
              message: "Password must be 8 character long",
            },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Enter your Password"
            minLength={8}
          />
        </Form.Item>
      {/* </Col> : <Col span={7}> <button onClick={toggleEditMode} style={{
        background:'transparent',
        border:'none',
        color:'blue',
        cursor:'pointer',
      }}>Change Password !</button> </Col>
        } */}
          </Col>
        </Row>

        <Form.Item
          name="permissions"
          rules={[
            {
              required: true,
              message: "Permission is required",
            },
          ]}
        >
          <Select placeholder="Enter Permissions" allowClear mode="multiple">
            {people?.map((v) => {
              return (
                <Option value={v}>
                  {v.charAt(0).toUpperCase() + v.slice(1)}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item>
          <Button
            htmlType="submit"
            className="btn-primary"
            type="primary"
            onClick={form.resetFields()}
          >
            {!changeBtn ? "Save" : "Update"}
          </Button>
        </Form.Item>
      </Form>
      <Table
      pagination={false}
        dataSource={permissionFiltered.filter(
          (val) => val.email !== "admin@gmail.com"
        )}
        columns={columns}
      />
       <Pagination
        defaultCurrent={1}
        defaultPageSize={paginationFilters.pageSize}
        onChange={handleChange}
        total={count}
        className="Pagination"
      />
    </>
  );
}

export default Pushnotification;
