import { gql } from "@apollo/client";

const OWNER_CREATE = gql`
mutation CreateOwner($registerOwnerInput: RegisterOwnerInput) {
  createOwner(registerOwnerInput: $registerOwnerInput) {
    code
    message
    success
    data {
      _id
      bankInfo {
        bankIdCode
        beneficiaryName
        iBanNumber
      }
      crNumber
      crNumberUpload
      createdAt
      email
      emailVerified
      idNumber
      idNumberUpload
      isAccept
      isApproved
      isDeleted
      name
      ownerName
      phoneNumber
      profileImage
      rRA
      token
      type
    }
  }
}
`;
const OWNER_UPDATE = gql`
mutation Mutation($id: ID!, $updateOwnerInput: UpdateOwnerInput) {
  updateOwner(ID: $id, updateOwnerInput: $updateOwnerInput) {
    code
    data {
      _id
      bankInfo {
        bankIdCode
        beneficiaryName
        iBanNumber
      }
      crNumber
      crNumberUpload
      createdAt
      email
      emailVerified
      idNumber
      idNumberUpload
      isAccept
      isApproved
      isDeleted
      name
      ownerName
      phoneNumber
      profileImage
      rRA
      token
      type
    }
    message
    success
  }
}
`;

const OWNER_DELETE = gql`
mutation DeleteOwner($id: ID!) {
  deleteOwner(ID: $id) {
    code
    message
    success
    data {
      _id
      bankInfo {
        bankIdCode
        beneficiaryName
        iBanNumber
      }
      crNumber
      crNumberUpload
      createdAt
      email
      emailVerified
      idNumber
      idNumberUpload
      isAccept
      isApproved
      isDeleted
      name
      ownerName
      phoneNumber
      profileImage
      rRA
      token
      type
    }
  }
}
`;
export { OWNER_UPDATE, OWNER_CREATE, OWNER_DELETE };
