import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Row, Col, Descriptions, Space, Button, Popconfirm, Image } from "antd";
import moment from "moment/moment";
import { MdOutlineCancelPresentation } from "react-icons/md";

import { updateRequest } from "../API/API";
import { useTranslation } from "react-i18next";
export default function ViewAcceptedRequest() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { t } = useTranslation();
  return (
    <div>
      <div>
        <div>
          <h3>{`${t("tableTabs.requst")} ${t("tableTabs.detail")}`}</h3>
        </div>
        <div>
          <div>
            <div className="upper_btn_div">
              <Space>
                <Button
                  type="light"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  {t("tableTabs.back")}
                </Button>
                {state.request.isStarted === true ? (
                  ""
                ) : (
                  <>
                    {/* <Popconfirm
                      title={"Are you sure?"}
                      okText="Ok"
                      cancelText="Cancel"
                      onConfirm={() => {
                        let data = {
                          isStarted: true,
                          sendTo: [state.request.acceptedBy.ownerId],
                        };
                        updateRequest(state.request.uid, data).then(() => {
                          navigate(-1);
                        });
                      }}
                    >
                      <span
                        title="start riding"
                        className="ant-btn ant-btn-primary"
                      >
                        
                        {"start"}
                      </span>
                    </Popconfirm> */}
                    {/* <Popconfirm
                      title={"Are you sure?"}
                      okText="Ok"
                      cancelText="Cancel"
                      onConfirm={() => {
                      
                          let arr = state.request.sendTo;
                          let index = state.request.sendTo.indexOf(
                            state.request.acceptedBy.ownerId
                          );
                          arr.splice(index, 1);

                          let data = {
                            isStarted: false,
                            acceptedBy: {},
                            sendTo: arr,
                            isAccept: false,
                          };
                          updateRequest(state.request.uid, data).then(() => {
                            navigate(-1);
                          });
                      }}
                    >
                      <span className="ant-btn ant-btn-danger">
                       
                        <i title="cancel">
                          <MdOutlineCancelPresentation />
                        </i>
                      </span>
                    </Popconfirm> */}
                  </>
                )}
              </Space>
            </div>
          </div>
          <div>
            <Row gutter={24}>
              <Col span={24}>
                <Descriptions
                  bordered
                  title={t("tableTabs.requst")}
                  layout={"vertical"}
                >
                  <Descriptions.Item label={t("tableTabs.customerName")}>
                    {state?.request?.name}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("tableTabs.phoneNumber")}>
                    {state?.request?.phoneNumber}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("tableTabs.capacity")}>
                    {state?.request?.capacity}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("tableTabs.hight")}>
                    {state?.request?.height}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("tableTabs.type")}>
                    {state?.request?.type}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("tableTabs.from")}>
                    {state?.request?.from}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("tableTabs.senderContact")}>
                    {state?.request?.senderContact}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("tableTabs.to")}>
                    {state?.request?.to}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("tableTabs.receiverContact")}>
                    {state?.request?.receiverContact}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("tableTabs.distance")}>
                    {state?.request?.distance}
                  </Descriptions.Item>
                  <Descriptions.Item span={2} label={t("tableTabs.price")}>
                    {state?.request?.price}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("tableTabs.blNumber")}>
                    {state?.request?.blNumber}
                  </Descriptions.Item>
                  <Descriptions.Item
                    span={2}
                    label={`${t("tableTabs.blNumber")} ${t(
                      "tableTabs.upload"
                    )}`}
                  >
                    <img
                      className="img"
                      onClick={() => {
                        window.open(state?.request?.blNumberUpload);
                      }}
                      alt={"Click to View"}
                      src={state?.request?.blNumberUpload}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label={`IRO ${t("tableTabs.date")}`}>
                    {moment(state?.request?.iroDate?.seconds * 1000).format(
                      "lll"
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item
                    span={2}
                    label={`IRO ${t("tableTabs.upload")}`}
                  >
                    <img
                      className="img"
                      onClick={() => {
                        window.open(state?.request?.iroUpload);
                      }}
                      alt={"Click to View"}
                      src={state?.request?.iroUpload}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label={t("tableTabs.finalByan")}>
                    {state?.request?.finalByan &&
                    state?.request?.finalByan !== null ? (
                      <img
                        className="img"
                        onClick={() => {
                          window.open(state?.request?.finalByan);
                        }}
                        alt={"Click to View"}
                        src={state?.request?.finalByan}
                      />
                    ) : (
                      <span>Not upload yet</span>
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item span={2} label={t("tableTabs.ticket")}>
                    {state?.request?.ticket ? (
                      <img
                        className="img"
                        onClick={() => {
                          window.open(state?.request?.ticket);
                        }}
                        alt={"Click to View Doc"}
                        src={state?.request?.ticket}
                      />
                    ) : (
                      <span>Not upload yet</span>
                    )}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Descriptions
                  bordered
                  title={t("tableTabs.startedBy")}
                  layout={"vertical"}
                >
                  <Descriptions.Item label={t(" Owner Name")}>
                    {state.owner.name}
                  </Descriptions.Item>

                  <Descriptions.Item
                    span={2}
                    label={`${t("tableTabs.owner")} ${t(
                      "tableTabs.phoneNumber"
                    )} `}
                  >
                    {state.owner.phoneNumber}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("tableTabs.driverName")}>
                    {state?.transport?.driver?.driverName}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("tableTabs.driverId")}>
                    {state?.transport?.driver?.driverIdNumber}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("tableTabs.driverAddress")}>
                    {state?.transport?.driver?.driverAddress}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={`${t("tableTabs.driverId")} ${t(
                      "tableTabs.upload"
                    )}`}
                  >
                    <img
                      className="img"
                      onClick={() => {
                        window.open(state?.transport?.driver?.driverIdUpload);
                      }}
                      alt={"Click to View"}
                      src={state?.transport?.driver?.driverIdUpload}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label={t("tableTabs.driverLicenseUpload")}>
                    <img
                      className="img"
                      onClick={() => {
                        window.open(
                          state?.transport?.driver?.driverLicenseUpload
                        );
                      }}
                      alt={"Click to View"}
                      src={state?.transport?.driver?.driverLicenseUpload}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label={t("tableTabs.driverPortIdUpload")}>
                    <img
                      className="img"
                      onClick={() => {
                        window.open(state?.transport?.driver?.portIdUpload);
                      }}
                      alt={"Click to View"}
                      src={state?.transport?.driver?.portIdUpload}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label={t("tableTabs.truck")}>
                    {state?.transport?.truck?.truckModel}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("tableTabs.truckNumber")}>
                    {state?.transport?.truck?.truckPlate}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("tableTabs.truckCapacity")}>
                    {state?.transport?.truck?.truckCapacity}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("tableTabs.truckHeight")}>
                    {state?.transport?.truck?.truckHeight}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("tableTabs.truckType")}>
                    {state?.transport?.truck?.truckType}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("tableTabs.truckLicenseUpload")}>
                    <img
                      className="img"
                      onClick={() => {
                        window.open(
                          state?.transport?.truck?.truckLicenseUpload
                        );
                      }}
                      alt={"Click to View"}
                      src={state?.transport?.truck?.truckLicenseUpload}
                    />
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}
