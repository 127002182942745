import React, { useState } from "react";
import { Button, Form, Modal, Input, Select, Row, Col, message } from "antd";
import Loader from "../Loaders/Loader";
import { useTranslation } from "react-i18next";
import { LockOutlined, MailOutlined, DeleteOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import axios from "axios";
import { SUBADMIN_UPDATE } from "../../Graphql/mutation/subAdminMutation";
import { useMutation } from "@apollo/client";

const PermissionModal = ({ isModalVisible, setIsModalVisible, data }) => {
  const people = [
    "owners",
    "customers",
    "transportation",
    "drivers",
    "services",
    "permission",
    "relations",
    "finance",
    "Maintenance",
    "Clearance",
    "See Freight",
  ];
  const [loading, setLoading] = useState(false);
  const [updateSubadmin] = useMutation(SUBADMIN_UPDATE);
  const { Option } = Select;

  const [form] = Form.useForm();
  const { t } = useTranslation();
  
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({});

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleOk = () => {
    setIsModalVisible(!isModalVisible);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };
  
  const onFinish =async (values)=>{
    try {
      await updateSubadmin({
        variables: {
          id: data._id,
          updateSubAdminInput: values
        },
      });
      message.success("Subadmin Updated Successfully");
      setIsModalVisible(!isModalVisible);

    } catch (error) {
      console.log("Error:", error);
    }

  }
  useEffect(() => {
    if (Object.keys(data).length > 0) {
      form.setFieldsValue(data);
      setFormData(data);
    }
  }, [data, form]);

  useEffect(() => {
    if (isModalVisible) {
      form.setFieldsValue(formData);
    }
  }, [isModalVisible, formData, form]);


  return (
    <div>
      <Modal
        title={t("editPermission")}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose
        footer={null}
      >
        {loading ? <Loader /> : ""}
        <div>
          <Form
            form={form}
           layout="horizontal"
            className="push-notification-form"
            onFinish={onFinish}
          >
            <Row justify="space-between">
              <Col span={7}>
                <Form.Item
                  name="userName"
                  rules={[
                    {
                      required: true,
                      message: "Name is required",
                    },
                  ]}
                >
                  <Input className="input-primary" placeholder="Enter name" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="email"
                  
                  rules={[
                    {
                      required: true,
                      message: "Email is required",
                    },
                    {
                      type: "email",
                      message: "Invalid Email(abc@gmail.com)",
                    },
                  ]}
                >
                  <Input
                    className="input-primary"
                    placeholder="Enter email"
                    type="email"
                    prefix={<MailOutlined className="site-form-item-icon" />}
                  />
                </Form.Item>
              </Col>
              <Col span={7}>
        {showPassword && (
          <Form.Item
            name="password"
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Password is required',
              },
              {
                min: 8,
                message: 'Password must be 8 characters long',
              },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Enter your Password"
              minLength={8}
            />
          </Form.Item>
        )}
        {!showPassword && (
          <Button type="primary" onClick={togglePasswordVisibility}>
            Change Password
          </Button>
        )}
      </Col>
            </Row>

            <Form.Item
              name="permissions"
              rules={[
                {
                  required: true,
                  message: "Permission is required",
                },
              ]}
            >
              <Select
                placeholder="Enter Permissions"
                allowClear
                mode="multiple"
              >
                {people?.map((v) => {
                  return (
                    <Option value={v}>
                      {v.charAt(0).toUpperCase() + v.slice(1)}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item>
              <Button
                htmlType="submit"
                className="btn-primary"
                type="primary"
              >
                Update
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default PermissionModal;
