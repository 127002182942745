import React from "react";
import { Tabs } from "antd";
import { useTranslation } from "react-i18next";

import AddCitiesTable from "../Tables/AddCitiesTables";
import AddCapcityTable from "../Tables/AddCapcityTable";
import AddTypesTable from "../Tables/AddTypesTable";
import AddHeightsTable from "../Tables/AddHeightsTable";
import CombinationsTable from "../Tables/CombinationsTable";
import SourceTable from "../Tables/SourceTable";
import DestinationTable from "../Tables/DestinationTable";
import ExtraCarePrice from "../Tables/ExtraCarePrice";
import SaberPrice from "../Tables/SaberPrice";
import RelationCombination from "../Tables/RelationCombination";
import FormCancelEmails from "../Tables/FormCancelEmails";

export default function Services() {
  const { TabPane } = Tabs;
  const { t } = useTranslation();
  return (
    <div>
      <div>
        <h3>{t("routes.services")}</h3>
      </div>
      <div>
        <Tabs defaultActiveKey="1">
          <TabPane tab={t("tableTabs.combinations")} key="1">
            <CombinationsTable />
          </TabPane>
          <TabPane tab={t("tableTabs.cities")} key="2">
            <AddCitiesTable />
          </TabPane>
          <TabPane tab={t("tableTabs.capacities")} key="3">
            <AddCapcityTable />
          </TabPane>
          <TabPane tab={t("tableTabs.heights")} key="4">
            <AddHeightsTable />
          </TabPane>
          <TabPane tab={t("tableTabs.types")} key="5">
            <AddTypesTable />
          </TabPane>
          <TabPane tab="Source" key="6">
            <SourceTable />
          </TabPane>
          <TabPane tab="Destination" key="7">
            <DestinationTable />
          </TabPane>
          <TabPane tab="Extra Care" key="8">
            <ExtraCarePrice />
          </TabPane>
          <TabPane tab="Saber" key="9">
            <SaberPrice />
          </TabPane>
          <TabPane tab="IsRelation Combinations" key="10">
          <RelationCombination />
          </TabPane>
          <TabPane tab="Form Cancel Emails" key="11">
          <FormCancelEmails />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
}
