import React, { useEffect, useState } from "react";
import {
  collection,
  query,
  onSnapshot,
  where,
  orderBy,
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "../firebase";
export default function useAllDrivers() {
 

  const [allDrivers, setAllDrivers] = useState([]);
  const getData = async () => {
    let q = query(collection(db, "drivers"), orderBy("createdAt", "desc"));
    const unsub = onSnapshot(q, (querySnapShot) => {
      setAllDrivers([]);
        if (!querySnapShot.empty) {
          let arr = [];
            querySnapShot.forEach(async (val) => {
            let data={}
          let snap = await getDoc(doc(db, "owners", val.data().ownerId));
                if (snap.exists()) {
                    data = {
                      ...val.data(),
                      ownerName: snap.data().name,
                    };
              arr.push(data)
            setAllDrivers(arr);
          }
        });
      }
    });
    return () => unsub();
  };
  useEffect(() => {
    getData();
  }, []);

  return { allDrivers };
}
