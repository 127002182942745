import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import ViewRequestModal from "../Components/Modals/ViewRequestModel";
import Login from "../Components/Auth/Login";
import PrivateRoute from "./PrivateRoutes";
import Layout from "../Components/Layout/Layout";
import Owners from "../Components/Owners/Owners";
import OwnerDetail from "../Components/Owners/OwnerDetail";
import Customers from "../Components/Customers/Customers";
import CustomerDetail from "../Components/Customers/CustomerDetail";
import Requests from "../Components/Requests/Requests";
import SendRequests from "../Components/Requests/SendRequests";
import Services from "../Components/Services/Services";
import ViewAcceptedRequest from "../Components/Requests/ViewAcceptedRequest";
import OntheWayRequest from "../Components/Requests/OntheWayRequest";
import Finance from "../Components/Finance/Finance";
import Drivers from "../Components/Drivers/Drivers";
// import { auth } from "../Components/firebase";
import { useState } from "react";
import FinanceDetails from "../Components/Finance/details/FinanceDetails";
import Permission from "../Components/permission/Permission";
import Relations from "../Components/Relations/Relations";
import Maintenance from "../Components/maintenance/Maintenance";
import Clearance from "../Components/Clearance/Clearance";
import ClearanceDetails from "../Components/Clearance/ClearanceDetails";
import SeaFreight from "../Components/sea-freight/SeaFreight";
import SeaFreightDetails from "../Components/sea-freight/SeaFreightDetails";
import ArchiveAdmin from "../Components/Archive/ArchiveAdmin";
import AddCombination from "../Components/Relations/AddCombination";
import ViewCombination from "../Components/Relations/ViewCombination";
import EmailVerification from "../pages/EmailVerification";

export default function AppRoutes() {
  let token = JSON.parse(sessionStorage.getItem("token"));
  let data = JSON.parse(sessionStorage.getItem("infu"));
  let [user, setUser] = useState(null);
  useEffect(() => {
    // auth.onAuthStateChanged((user) => {
    //   setUser(user);
    // });
    setUser(data);
  }, [data]);
  return (
    <>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              token !== null && user !== null ? (
                <Navigate to={"/owners"} />
              ) : (
                <Login />
              )
            }
          />

          <Route
            path="/owners"
            element={
              <PrivateRoute>
                <Layout>
                  <Owners />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/owner_detail/:id"
            element={
              <PrivateRoute>
                <Layout>
                  <OwnerDetail />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/customers"
            element={
              <PrivateRoute>
                <Layout>
                  <Customers />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/customer_detail/:id"
            element={
              <PrivateRoute>
                <Layout>
                  <CustomerDetail />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/requests"
            element={
              <PrivateRoute>
                <Layout>
                  <Requests />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/send_request/:id"
            element={
              <PrivateRoute>
                <Layout>
                  <SendRequests />
                </Layout>
              </PrivateRoute>
            }
          />

          <Route
            path="/request-details/:id"
            element={
              <PrivateRoute>
                <Layout>
                  <ViewRequestModal />
                </Layout>
              </PrivateRoute>
            }
          />

          <Route
            path="/request_detail/:id"
            element={
              <PrivateRoute>
                <Layout>
                  <ViewAcceptedRequest />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/ontheway_request/:id"
            element={
              <PrivateRoute>
                <Layout>
                  <OntheWayRequest />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/drivers"
            element={
              <PrivateRoute>
                <Layout>
                  <Drivers/>
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/services"
            element={
              <PrivateRoute>
                <Layout>
                  <Services />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/finance"
            element={
              <PrivateRoute>
                <Layout>
                  <Finance />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/finance-details/:finID"
            element={
              <PrivateRoute>
                <Layout>
                  <FinanceDetails />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/permission"
            element={
              <PrivateRoute>
                <Layout>
                  <Permission />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/relations"
            element={
              <PrivateRoute>
                <Layout>
                  <Relations />
                </Layout>
              </PrivateRoute>
            }
          />
           <Route
            path="/relations/combination/:customerId"
            element={
              <PrivateRoute>
                <Layout>
                  <AddCombination />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="verify/:ownerId"
            element={
              
             
                  <EmailVerification/>
                
              
            }
          />
           <Route
            path="/relations/viewcombination/:customerId"
            element={
              <PrivateRoute>
                <Layout>
                  <ViewCombination />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/maintenance"
            element={
              <PrivateRoute>
                <Layout>
                  <Maintenance />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/clearance"
            element={
              <PrivateRoute>
                <Layout>
                  <Clearance />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/clearance_details/:id"
            element={
              <PrivateRoute>
                <Layout>
                  <ClearanceDetails />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/see-freight"
            element={
              <PrivateRoute>
                <Layout>
                  <SeaFreight />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/archive"
            element={
              <PrivateRoute>
                <Layout>
                  <ArchiveAdmin/>
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/sea-freight/:id"
            element={
              <PrivateRoute>
                <Layout>
                  <SeaFreightDetails />
                </Layout>
              </PrivateRoute>
            }
          />
        </Routes>
      </Router>
    </>
  );
}
