import React, { useEffect, useState } from "react";
import {
  collection,
  query,
  onSnapshot,
  where,
  getDoc,
  doc,
} from "firebase/firestore";
import { db } from "../firebase";
export default function useTransports(id) {
  const [transportss, setTransports] = useState([]);
  const getTrucks = async () => {
    // let q = query(collection(db, "transports"));
    // const unsub = onSnapshot(q, async(querySnapShot) => {
    
    //   if (!querySnapShot.empty) {
    //     setTransports([]);
    //       querySnapShot.forEach(async (val) => {
    //         let driver = await getDoc(doc(db, "drivers", val?.data().driver?.uid));
    //         let truck = await getDoc(doc(db, "trucks", val?.data().truck?.uid));
    //         if (driver.exists() && truck.exists()) {
    //           let data = {
    //             ...val.data(),
    //             driver: driver.data(),
    //             truck: truck.data(),
    //           };
    //           setTransports((prev) => [...prev, data]);
    //         }
    //       })
    //   }
    // });

    // return () => unsub();
  };
  useEffect(() => {
    getTrucks();
  }, [id]);
  let key = "uid";
  let transports = [
    ...new Map(transportss.map((item) => [item[key], item])).values(),
  ];

  return { transports };
}
