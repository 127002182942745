import { gql } from "@apollo/client";

const GET_ALL_TYPEOFSERVICE = gql`
  query Services($page: Int!) {
    services(page: $page) {
      currentPage
      totalDocs
      totalPages
      services {
        _id
        admin {
          _id
        }
        capacity
        createdAt
        price
      }
    }
  }
`;
const ALL_SERVICES = gql`
query AllServices {
  allServices {
    _id
    admin {
      _id
    }
    capacity
    createdAt
    price
  }
}
`;
const GET_ALL_HEIGHTS = gql`
  query Heights($page: Int!) {
    heights(page: $page) {
      currentPage
      totalDocs
      totalPages
      heights {
        _id
        admin {
          _id
        }
        createdAt
        height
        price
      }
    }
  }
`;
const GET_ALL_CITIES = gql`
  query Query($page: Int!) {
    cities(page: $page) {
      currentPage
      totalDocs
      totalPages
      cities {
        _id
        cityName
        createdAt
        admin {
          _id
        }
      }
    }
  }
`;
const GET_ALL_TYPE = gql`
  query Types($page: Int!) {
    types(page: $page) {
      currentPage
      totalDocs
      totalPages
      types {
        _id
        type
        price
        createdAt
        admin {
          _id
        }
      }
    }
  }
`;
const GET_ALL_SOURCES = gql`
  query Sources($page: Int!) {
    sources(page: $page) {
      currentPage
      totalDocs
      totalPages
      sources {
        _id
        admin {
          _id
        }
        createdAt
        source
      }
    }
  }
`;
const GET_ALL_DESTINATION = gql`
  query AllDestinations($page: Int!) {
    allDestinations(page: $page) {
      currentPage
      totalDocs
      totalPages
      destinations {
        _id
        admin {
          _id
        }
        createdAt
        destination
      }
    }
  }
`;
const GET_ALL_EXTRACARE = gql`
query Query($page: Int!) {
  allCares(page: $page) {
    cares {
      _id
      admin {
        _id
      }
      createdAt
      extraCare
    }
    currentPage
    totalDocs
    totalPages
  }
}
`;
const GET_ALL_EXTRASABER = gql`
query Query($page: Int!) {
  allSabers(page: $page) {
    currentPage
    totalDocs
    totalPages
    sabers {
      _id
      admin {
        _id
      }
      createdAt
      saber
    }
  }
}
`;
export {
  GET_ALL_TYPEOFSERVICE,
  GET_ALL_HEIGHTS,
  GET_ALL_CITIES,
  GET_ALL_TYPE,
  GET_ALL_SOURCES,
  GET_ALL_DESTINATION,
  GET_ALL_EXTRACARE,
  GET_ALL_EXTRASABER,
  ALL_SERVICES
};
