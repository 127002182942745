import { gql } from "@apollo/client";
const CUSTOMER_CREATE = gql`
mutation CreateDriver($registerDriverInput: RegisterDriverInput) {
    createDriver(registerDriverInput: $registerDriverInput) {
      code
      message
      success
      data {
        _id
        age
        idNo
        imageUrl
        name
        nationality
        owner {
          _id
          ownerName
          email
        }
        password
        status
      }
    }
  }
`;

const UPDATE_CUSTOMER = gql`
  mutation Mutation($id: ID!, $updateCustomerInput: UpdateCustomerInput) {
    updateCustomer(ID: $id, updateCustomerInput: $updateCustomerInput) {
      code
      message
      data {
        email
        firebaseEmail
        _id
        password
        token
        uid
        url
        userName
        isRelation
      }
      success
    }
  }
`;

const DELETE_CUSTOMER = gql`
mutation Mutation($id: ID!) {
  deleteCustomer(ID: $id) {
    code
    message
    success
    data {
      _id
      crNumber
      crNumberUpload
      createdAt
      email
      emailVerified
      idNumber
      idNumberUpload
      isApproved
      isRelation
      isDeleted
      isHold
      name
      phoneNumber
      proVerified
      profileImage
      token
      type
      userName
    }
  }
}
`;

export { CUSTOMER_CREATE, DELETE_CUSTOMER, UPDATE_CUSTOMER };
