
  import { gql } from "@apollo/client";

  const REQUEST_UPDATE = gql`
  mutation UpdateRequest($id: ID!, $updateRequestInput: UpdateRequestInput!) {
    updateRequest(ID: $id, updateRequestInput: $updateRequestInput) {
      code
      message
      success
      data {
        _id
        acceptedBy {
          driverId
          ownerId
          transportId
        }
        blNumber
        blUpload
        commission
        coordinates {
          latitude
          longitude
        }
        createdAt
        deliveryNote
        distance
        driverStarted
        eir
        estimateTime
        finalByan
        from
        height
        invoice
        iroDate {
          nanoseconds
          seconds
        }
        iroUpload
        isAccept
        isFinished
        isNewRequest
        isSeen
        isStarted
        location
        name
        notificationId
        odUpload
        offlinePaid
        phoneNumber
        price
        quantity
        receipt
        receiverContact
        sendTo
        senderContact
        serviceType
        status
        to
        type
      }
    }
  }
  `;
  export { REQUEST_UPDATE };
  