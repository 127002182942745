import { gql } from "@apollo/client";

const GET_ALL_OWNER = gql`
query AllOwners($page: Int!) {
  allOwners(page: $page) {
    currentPage
    totalDocs
    totalPages
    owners {
      _id
      bankInfo {
        bankIdCode
        beneficiaryName
        iBanNumber
      }
      crNumber
      crNumberUpload
      createdAt
      emailVerified
      email
      idNumber
      idNumberUpload
      isAccept
      isApproved
      isDeleted
      name
      ownerName
      phoneNumber
      profileImage
      rRA
      token
      type
    }
  }
}
`;
const GET_OWNER_ID = gql`
query Owner($id: ID!) {
  owner(ID: $id) {
    _id
    bankInfo {
      iBanNumber
      beneficiaryName
      bankIdCode
    }
    crNumber
    crNumberUpload
    createdAt
    email
    emailVerified
    idNumber
    idNumberUpload
    isAccept
    isApproved
    isDeleted
    name
    ownerName
    phoneNumber
    profileImage
    rRA
    token
    type
  }
}
`;
const GET_OWNER_EMAIL = gql`
query OwnerEmail($email: String) {
  ownerEmail(email: $email) {
    _id
    bankInfo {
      iBanNumber
      beneficiaryName
      bankIdCode
    }
    crNumber
    crNumberUpload
    createdAt
    email
    emailVerified
    idNumber
    idNumberUpload
    isAccept
    isApproved
    isDeleted
    name
    ownerName
    phoneNumber
    profileImage
    rRA
    token
    type
  }
}
`;
const OWNERS = gql`
query Owners {
  owners {
    _id
    bankInfo {
      bankIdCode
      iBanNumber
      beneficiaryName
    }
    crNumber
    crNumberUpload
    createdAt
    email
    emailVerified
    idNumber
    idNumberUpload
    isAccept
    isApproved
    isDeleted
    name
    ownerName
    phoneNumber
    profileImage
    rRA
    token
    type
  }
}
`;

export { GET_OWNER_ID, GET_ALL_OWNER, GET_OWNER_EMAIL, OWNERS };
