import React, { useEffect, useState } from 'react'
import { doc, getDoc } from "firebase/firestore";
import { db } from '../firebase';

const useGetSingleClearanceRequest = (id) => {
    const [singleRequest, setSingleRequest] = useState(null)
    const getSingleRequest = async () => {
        try {
            const docRef = doc(db, "clearance_request", id);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                setSingleRequest(docSnap.data())
            } else {
                console.log("No such document!");
            }
        } catch (error) {
            alert(error.message)
        }
    }
    useEffect(() => {
        getSingleRequest()
    }, [id])

    return { singleRequest }

}

export default useGetSingleClearanceRequest
