import { getDoc, doc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../firebase";

export default function useUser(uid, type) {
  const [user, setUser] = useState({});

  const getUser = async () => {
    let userRef = doc(db, type, uid);
    let snap = await getDoc(userRef);
    if (snap.exists()) {
      setUser(snap.data());
    }
  };

  useEffect(() => {
    if (uid && type) {
      getUser();
    }
  }, [uid, type]);

  return { user };
}
