import React, { useState } from "react";
import { Table, Input, Popconfirm, Space, Tag, Pagination } from "antd";
import { SearchOutlined, DeleteOutlined } from "@ant-design/icons";

// import useAllRequests from "../Hooks/useAllRequests";

// import { DeleteRequest, HoldRequest } from "../API/API";
import { useTranslation } from "react-i18next";
import offline from "./images/offline.png";
import online from "./images/online.png";
import useGetAllRequests from "../Hooks/useAllRequests";

export default function AllRequestTable() {
  // const { allRequests } = useAllRequests();
  //  const requests = allRequests;
  const [loading, setLoading] = useState(false);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [paginationFilters, setPaginationFilters] = useState({
    pageNo: 1,
    pageSize: 5,
    limitSkip: 0,
    limit: 10,
  });
  const { requests, count} = useGetAllRequests(updateTrigger, paginationFilters);
  console.log("🚀 ~ file: AllRequestsTable.jsx:25 ~ AllRequestTable ~ requests:", requests)
  // setDrivers(drivers);
  const handleChange = (value, pageSize) => {
    const limitSkip = value * pageSize;
    const limit = pageSize;
    setPaginationFilters({ pageNo: value, pageSize, limitSkip, limit });
    setLoading(true);
    setUpdateTrigger(!updateTrigger);
  };
  const [search, setSearch] = useState("");
  const { t } = useTranslation();

  const columns = [
    {
      title: t("tableTabs.id"),
      render: (record) => (
        <span title={record?.blNumber}>{record?._id.slice(-4)}</span>
      ),
    },
    {
      title: t("tableTabs.createdAt"),
      render: (record) => (
        
        <span>
          {new Date(record?.createdAt?.seconds * 1000).toLocaleString("en-US", {
            month: "short",
            day: "2-digit",
            year: "2-digit",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })}
        </span>
      ),
    },
    {
      title: t("Name "),
      render: (record) => (
        <span title={record?.__typename}>{record?.__typename}</span>
      ),
      // dataIndex: "name",
      // key: "name",
    },

    {
      title: t("tableTabs.from"),
      dataIndex: "from",
      key: "from",
    },
    {
      title: t("tableTabs.to"),
      dataIndex: "to",
      key: "to",
    },
    {
      title: t("tableTabs.distance"),
      dataIndex: "distance",
      key: "distance",
    },
    {
      title: t("tableTabs.price"),
      dataIndex: "price",
      key: "price",
    },

    {
      title: t("Type"),
      dataIndex: "type",
      key: "type",
    },

    {
      title: t("tableTabs.status"),
      key: "status",
      render: (record) => (
        <span>
          <Tag className='check-btn' >{record.status}</Tag>
        </span>
      ),
    },
    {
      title: t("tableTabs.sendTo"),
      key: "send_to",
      render: (record) => (
        <span>
          {record?.sendTo !== undefined && record?.sendTo?.length > 0
            ? record?.sendTo?.length
            : "Not Sended yet"}
        </span>
      ),
    },
    {
      title: "Payment",
      dataIndex: "",
      key: "x",
      align: "center",
      render: (record) => (
        <Space>
          {record?.offlinePaid ? (
            <span>
              <img
                src={offline}
                style={{
                  width: "40px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  marginBottom: "9px",
                }}
                alt="offline"
              />
            </span>
          ) : (
            <span >
              <img
                src={online}
                style={{
                  width: "42px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  marginBottom: "9px",
                }}
                alt="online"
              />
            </span>
          )}

        </Space>
      ),
    },
 
    

    // {
    //   title: "Action",
    //   dataIndex: "",
    //   key: "x",
    //   align: "center",
    //   render: (record) => (
    //     <Space>
    //       <Popconfirm
    //         title={"Are you sure?"}
    //         okText="Ok"
    //         cancelText="Cancel"
    //         onConfirm={() => {
    //           DeleteRequest(record?.uid, record);
    //         }}
    //       >
    //         <span className="ant-btn ant-btn-danger">
    //           <DeleteOutlined />
    //         </span>
    //       </Popconfirm>
    //     </Space>
    //   ),
    // },
  ];

  return (
    <div>
      <div className="table_search_div">
        <Input
          allowClear
          size="middle"
          placeholder={t("placeholders.search")}
          prefix={<SearchOutlined />}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      </div>
      <Table
      pagination={false}
        columns={columns}
        dataSource={
          requests &&
          requests?.length > 0 &&
          requests?.filter((val) => {
            if (search == "") {
              return val;
            } else if (
              val &&
              Object.keys(val).some((v) =>
                val[v]
                  .toString()
                  .toLowerCase()
                  .includes(search.toString().toLowerCase())
              )
            ) {
              return val;
            }
          })
        }
      />
        <Pagination
          defaultCurrent={1}
          defaultPageSize={paginationFilters.pageSize}
          onChange={handleChange}
          total={count}
          className="Pagination"
        />
    </div>
  );
}
