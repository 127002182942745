
import React, { useState } from "react";
import DriverArchive from "./DriverArchive";
import AdminArchive from "./AdminArchive";
import './Archive.css';
import CustomerArchive from "./CustomerArchive";
import OwnerArchive from "./OwnerArchive";

const ArchiveAdmin = () => {

  const [showDriver, setShowDriver] = useState(true);
  const [showAdmin, setShowAdmin] = useState(false);
  const [showCustomer, setShowCustomer] = useState(false);
  const [showOwner, setShowOwner] = useState(false);


  const handleDriverClick = () => {
    setShowDriver(true);
    setShowAdmin(false);
    setShowCustomer(false)
    setShowOwner(false)

    
  };

  const handleAdminClick = () => {
    setShowDriver(false);
    setShowAdmin(true);
    setShowCustomer(false)
    setShowOwner(false)
  };

  const handleCustomerClick = () =>{
    setShowAdmin(false)
    setShowCustomer(true)
    setShowDriver(false)
    setShowOwner(false)
  }
  const handleOwnerClick = () =>{
    setShowAdmin(false)
    setShowCustomer(false)
    setShowDriver(false)
    setShowOwner(true)
  }
  
    return (
      <div>
        <div className="btns">
        <button onClick={handleDriverClick} className={showDriver ? "active" : "inactive"}>Driver Archive</button>
      <button onClick={handleAdminClick} className={showAdmin ? "active" : "inactive"}>Admin Archive</button>
      <button onClick={handleCustomerClick} className={showCustomer ? "active" : "inactive"}>Customer Archive</button>
      <button onClick={handleOwnerClick} className={showOwner ? "active" : "inactive"}>Owner Archive</button>
      </div>

      {showDriver && <DriverArchive />}
      {showAdmin && <AdminArchive />}
      {showCustomer && <CustomerArchive/>}
      {showOwner && <OwnerArchive/>}
      </div>
    );
}

export default ArchiveAdmin