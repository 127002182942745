import { gql } from "@apollo/client";

const GET_MY_TRUCK = gql`
query MyTrucks($ownerId: ID!, $page: Int!) {
    myTrucks(ownerId: $ownerId, page: $page) {
      currentPage
      totalDocs
      totalPages
      trucks {
        _id
        createdAt
        isDriver
        capacity
        height
        imageUrl
        model
        noPlate
        serviseType
        status
        type
      }
    }
  }
`;
const GET_ALL_TRUCKS = gql`
  query Query {
  Trucks {
    _id
    capacity
    createdAt
    height
    isDriver
    imageUrl
    model
    noPlate
    owner {
      _id
    }
    serviseType
    status
    type
  }
}
`;
const GET_TRUCK_EMAIL = gql`
  query CustomerEmail($email: String) {
    customerEmail(email: $email) {
      email
      firebaseEmail
      id
      token
      uid
      userName
      url
    }
  }
`;

export {GET_MY_TRUCK, GET_TRUCK_EMAIL, GET_ALL_TRUCKS };
