import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Input, Space, Popconfirm, Row, Col, Pagination, message } from "antd";
import "./table.css";
import {
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import Loader from "../Loaders/Loader";
import { addService, updateHeight, deleteService } from "../API/API";
//Hooks
// import useHeights from "../Hooks/useHeights";
import { useTranslation } from "react-i18next";
import useGetAllHeights from "../Hooks/useHeights";
import { CREATE_HEIGHT, DELETE_HEIGHT, UPDATE_HEIGHT } from "../../Graphql/mutation/servicesMutation";
import { useMutation } from "@apollo/client";
export default function AddHeightsTable() {
  // const { heights } = useHeights();
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");
  const [isAdd, setIsAdd] = useState(false);
  const { t } = useTranslation();
  const [formData, setFormData] = useState({ height: "", price: 0 });
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [paginationFilters, setPaginationFilters] = useState({
    pageNo: 1,
    pageSize: 5,
    limitSkip: 0,
    limit: 10,
  });
  const [deleteHeight] = useMutation(DELETE_HEIGHT);
  const [updateHeight] = useMutation(UPDATE_HEIGHT);
  const [createHeight] = useMutation(CREATE_HEIGHT);
  const { heights, count} = useGetAllHeights(updateTrigger, paginationFilters);
  console.log("🚀 ~ file: AddHeightsTable.jsx:34 ~ AddHeightsTable ~ heights:", heights)
  // setDrivers(drivers);;
  const handleChange = (value, pageSize) => {
    const limitSkip = value * pageSize;
    const limit = pageSize;
    setPaginationFilters({ pageNo: value, pageSize, limitSkip, limit });
    setLoading(true);
    setUpdateTrigger(!updateTrigger);
  };
  const handleAdd = async () => {
    debugger;
    try {
      const heightInput = {
        height: formData.height,
        price:formData.price,
        admin:"64f5b4c14091bd9d3c2fdaa2"
        // You might need to include other fields as needed
      };
  
      const { data } = await createHeight({
        variables: {
          heightInput,
        },
      });
  
      if (data.createHeight.success) {
        // City created successfully, you can reset your form or do any other necessary actions
        setFormData({ height: "", price:"" });
        setIsAdd(false);
        setUpdateTrigger(true);
        setId(""); // This will trigger a re-fetch of your city data
      } else {
        // Handle the case where city creation was not successful
        // You can display an error message or take appropriate action
      }
    } catch (error) {
      // Handle any errors that occur during the mutation
      console.error(error);
      // You can display an error message or take appropriate action
    }
  };
  const handleEdit = async (data) => {
    debugger
   try {
    await updateHeight({
      variables: {
        id: data._id, // Provide the customer ID to be updated
        updateHeightInput:formData // Pass the updated values
      },
    });
    message.success("Height Updated Successfully");
    setFormData({ height: "", price:"" });
      setUpdateTrigger(true);
      setId("");
   } catch (error) {
    console.log("🚀 ~ file: AddCitiesTables.jsx:66 ~ handleEdit ~ error:", error)
    
   }
  };

  const confirm = async (height, action) => {
    debugger;
    setUpdateTrigger(false);
    try {
      const { data } = await deleteHeight({
        variables: { id: height._id},
      });
      console.log("🚀 ~ file: AddCitiesTables.jsx:84 ~ confirm ~ data:", data)
      message.success("Capacity Deleted Successfully");
      setUpdateTrigger(true);
    } catch (error) {
      // Handle the error if needed
      console.log(error);
    }
  };

  const columns = [
    {
      title: t("tableTabs.hight"),
      render: (record) =>
        id === record._id ? (
          <input
            placeholder={t("tableTabs.hight")}
            className="ant-input"
            defaultValue={record.height}
            onChange={(e) => {
              formData.height = e.target.value;
              setFormData({ ...formData });
            }}
          />
        ) : (
          <span>{record.height}</span>
        ),
    },
    {
      title: `${t("tableTabs.price")}/${t("tableTabs.km")}`,
      render: (record) =>
        id === record._id? (
          <input
            placeholder={`${t("tableTabs.price")}/${t("tableTabs.km")}`}
            className="ant-input"
            defaultValue={record.price}
            type={"number"}
            onChange={(e) => {
              formData.price = e.target.value;
              setFormData({ ...formData });
            }}
          />
        ) : (
          <span>{record.price}</span>
        ),
    },

    {
      title: t("tableTabs.action"),
      dataIndex: "",
      key: "x",
      align: "center",
      render: (record) => (
        <Space>
          {id === record._id ? (
            <Space>
              <button
                className="ant-btn ant-btn-danger"
                onClick={() => {
                  setId("");
                  setFormData({ height: "", price: 0 });
                }}
              >
                cancel
              </button>
              <button
                className="ant-btn ant-btn-primary"
                onClick={() => {
                  handleEdit(record);
                }}
              >
                Done
              </button>
            </Space>
          ) : (
            <span
              className="eyes-btn"
              onClick={() => {
                setFormData({ height: record.height, price: record.price });
                setId(record._id);
              }}
            >
              <EditOutlined />
            </span>
          )}

          <Popconfirm
            title={"Are you sure you want to delete?"}
            okText="Ok"
            cancelText="Cancel"
            onConfirm={() => confirm(record, "del")}

          >
            <span className="ant-btn ant-btn-danger">
              <DeleteOutlined />
            </span>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      {loading ? <Loader /> : ""}
      <div className="table_topar_add_div_">
        <button
          className="ant-btn ant-btn-primary login-btn"
          onClick={() => {
            setIsAdd(!isAdd);
          }}
        >
          {t("Add Height")}
        </button>
      </div>
      {isAdd && (
        <div className="add_div">
          <Row gutter={24}>
            <Col span={10}>
              <Input
                placeholder={t("tableTabs.hight")}
                value={formData.height}
                onChange={(e) => {
                  formData.height = e.target.value;
                  setFormData({ ...formData });
                }}
              />
            </Col>

            <Col span={10}>
              <Input
                type={"number"}
                placeholder={`${t("tableTabs.price")}/${t("tableTabs.km")}`}
                value={formData.price}
                onChange={(e) => {
                  formData.price = e.target.value;
                  setFormData({ ...formData });
                }}
              />
            </Col>
            <Col span={4}>
              <button
                className="ant-btn ant-btn-primary login-btn"
                onClick={handleAdd}
              >
                {t("tableTabs.add")}
              </button>
            </Col>
          </Row>
        </div>
      )}
      {/* <div className="table_search_div">
        <Input
          allowClear
          size="middle"
          placeholder="Serach"
          prefix={<SearchOutlined />}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      </div> */}
      <Table
      pagination={false}
        columns={columns}
        dataSource={
          heights.length > 0 && heights
        }
      />
      <Pagination
          defaultCurrent={1}
          defaultPageSize={paginationFilters.pageSize}
          onChange={handleChange}
          total={count}
          className="Pagination"
        />
    </div>
  );
}
