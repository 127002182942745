import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
import { Table, Input, Space, Popconfirm, Row, Col, message, Pagination } from "antd";
import "./table.css";
import {
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import Loader from "../Loaders/Loader";

// import { addService, updateService, deleteService } from "../API/API";
//Hooks
// import useExtraCare from "../Hooks/useExtraCare";
import { useTranslation } from "react-i18next";
import {
  CREATE_EXTRACARE,
  DELETE_EXTRACARE,
  UPDATE_EXTRACARE,
} from "../../Graphql/mutation/servicesMutation";
import useGetAllExtraCare from "../Hooks/useExtraCare";
import { useMutation } from "@apollo/client";

export default function ExtraCarePrice() {
  // const { extraCare } = useExtraCare();
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");
  const [isAdd, setIsAdd] = useState(false);
  const { t } = useTranslation();
  const [formData, setFormData] = useState({ extraCare: "" });
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [paginationFilters, setPaginationFilters] = useState({
    pageNo: 1,
    pageSize: 5,
    limitSkip: 0,
    limit: 10,
  });
  const [deleteCare] = useMutation(DELETE_EXTRACARE);
  const [updateCare] = useMutation(UPDATE_EXTRACARE);
  const [createCare] = useMutation(CREATE_EXTRACARE);
  const { extraCare, count } = useGetAllExtraCare(
    updateTrigger,
    paginationFilters
  );
  const handleChange = (value, pageSize) => {
    const limitSkip = value * pageSize;
    const limit = pageSize;
    setPaginationFilters({ pageNo: value, pageSize, limitSkip, limit });
    setLoading(true);
    setUpdateTrigger(!updateTrigger);
  };
  const handleAdd = async () => {
    debugger;
    try {
      const registerCareInput = {
        extraCare: formData.extraCare,
        admin: "64f5b4c14091bd9d3c2fdaa2",
        // You might need to include other fields as needed
      };

      const { data } = await createCare({
        variables: {
          registerCareInput,
        },
      });

      if (data.createCare.success) {
        // City created successfully, you can reset your form or do any other necessary actions
        setFormData({ extraCare: "" });
        setIsAdd(false);
        setUpdateTrigger(true);
        setId(""); // This will trigger a re-fetch of your city data
      } else {
        // Handle the case where city creation was not successful
        // You can display an error message or take appropriate action
      }
    } catch (error) {
      // Handle any errors that occur during the mutation
      console.error(error);
      // You can display an error message or take appropriate action
    }
  };
  const handleEdit = async (data) => {
    debugger;
    try {
      await updateCare({
        variables: {
          id: data._id, // Provide the customer ID to be updated
          updateCareInput: {
            extraCare: formData.extraCare,
          }, // Pass the updated values
        },
      });
      message.success("ExtraCare Updated Successfully");
      setFormData({ extraCare: "" });
      setUpdateTrigger(true);
      setId("");
    } catch (error) {
      console.log(
        "🚀 ~ file: AddCitiesTables.jsx:66 ~ handleEdit ~ error:",
        error
      );
    }
  };

  const confirm = async (ExtraCare, action) => {
    debugger;
    setUpdateTrigger(false);
    try {
      const { data } = await deleteCare({
        variables: { id: ExtraCare._id },
      });
      console.log("🚀 ~ file: AddCitiesTables.jsx:84 ~ confirm ~ data:", data);
      message.success("ExtraCare Deleted Successfully");
      setUpdateTrigger(true);
    } catch (error) {
      // Handle the error if needed
      console.log(error);
    }
  };

  const columns = [
    {
      //   title: t("tableTabs.extraCare"),
      title: "Price",
      render: (record) =>
        id === record._id ? (
          <input
            // placeholder={t("tableTabs.extraCare")}
            placeholder="Extra Care Charges"
            className="ant-input"
            defaultValue={record.extraCare}
            onChange={(e) => {
              formData.extraCare = e.target.value;
              setFormData({ ...formData });
            }}
          />
        ) : (
          <span>{record.extraCare}</span>
        ),
    },

    {
      title: t("tableTabs.action"),
      dataIndex: "",
      key: "x",
      align: "center",
      render: (record) => (
        <Space>
          {id === record._id ? (
            <Space>
              <button
                className="ant-btn ant-btn-danger"
                onClick={() => {
                  setId("");
                  setFormData({ extraCare: "" });
                }}
              >
                cancel
              </button>
              <button
                className="ant-btn ant-btn-primary"
                onClick={() => {
                  handleEdit(record);
                }}
              >
                Done
              </button>
            </Space>
          ) : (
            <span
              className="eyes-btn"
              onClick={() => {
                setFormData({ extraCare: record.extraCare });
                setId(record._id);
              }}
            >
              <EditOutlined />
            </span>
          )}

          <Popconfirm
            title={"Are you sure you want to delete?"}
            okText="Ok"
            cancelText="Cancel"
            onConfirm={() => confirm(record, "del")}
          >
            <span className="ant-btn ant-btn-danger">
              <DeleteOutlined />
            </span>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      {loading ? <Loader /> : ""}

      <div className="table_topar_add_div_">
        <button
          className="ant-btn ant-btn-primary login-btn"
          onClick={() => {
            setIsAdd(!isAdd);
          }}
        >
          Add Extra Care Charges
        </button>
      </div>
      {isAdd && (
        <div className="add_div">
          <Row gutter={24}>
            <Col span={10}>
              <Input
                // placeholder={t("tableTabs.extraCare")}
                placeholder="extraCare"
                value={formData.extraCare}
                onChange={(e) => {
                  formData.extraCare = e.target.value;
                  setFormData({ ...formData });
                }}
              />
            </Col>

            <Col span={4}>
              <button
                className="ant-btn ant-btn-primary login-btn"
                onClick={handleAdd}
              >
                {t("tableTabs.add")}
              </button>
            </Col>
          </Row>
        </div>
      )}

      <Table pagination={false} columns={columns} dataSource={extraCare.length > 0 && extraCare} />
      <Pagination
        defaultCurrent={1}
        defaultPageSize={paginationFilters.pageSize}
        onChange={handleChange}
        total={count}
        className="Pagination"
      />
    </div>
  );
}
