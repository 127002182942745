import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Row, Col, Descriptions, Space, Button, Popconfirm, Image } from "antd";
import moment from "moment/moment";
import ActiveRideMap from "./ActiveRideMap";
import { useTranslation } from "react-i18next";
export default function OntheWayRequest() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { t } = useTranslation();
  // console.log(state);
  return (
    <div>
      <div>
        <div>
          <h3>{`${t("tableTabs.requst")} ${t("tableTabs.detail")}`}</h3>
        </div>
        <div>
          <div>
            <div className="upper_btn_div">
              <Space>
                <Button
                  type="light"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  {t("tableTabs.back")}
                </Button>
              </Space>
            </div>
          </div>
          <div>
            <Row gutter={24}>
              <Col span={24}>
                <Descriptions
                  bordered
                  title={t("tableTabs.requst")}
                  layout={"vertical"}
                >
                  <Descriptions.Item label={t("tableTabs.customerName")}>
                    {state?.request?.name}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("tableTabs.phoneNumber")}>
                    {state?.request?.phoneNumber}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("tableTabs.capacity")}>
                    {state?.request?.capacity}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("tableTabs.hight")}>
                    {state?.request?.height}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("tableTabs.type")}>
                    {state?.request?.type}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("tableTabs.from")}>
                    {state?.request?.from}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("tableTabs.senderContact")}>
                    {state?.request?.senderContact}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("tableTabs.to")}>
                    {state?.request?.to}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("tableTabs.receiverContact")}>
                    {state?.request?.receiverContact}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("tableTabs.distance")}>
                    {state?.request?.distance}
                  </Descriptions.Item>
                  <Descriptions.Item span={2} label={t("tableTabs.price")}>
                    {state?.request?.price}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("tableTabs.blNumber")}>
                    {state?.request?.blNumber}
                  </Descriptions.Item>
                  <Descriptions.Item
                    span={2}
                    label={`${t("tableTabs.blNumber")} ${t(
                      "tableTabs.upload"
                    )}`}
                  >
                    {/*  */}
                    <img
                      className="img"
                      onClick={() => {
                        window.open(state?.request?.blNumberUpload);
                      }}
                      alt={"Click to View"}
                      src={state?.request?.blNumberUpload}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label={`IRO ${t("tableTabs.date")}`}>
                    {moment(state?.request?.iroDate?.seconds * 1000).format(
                      "lll"
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item
                    span={2}
                    label={`IRO ${t("tableTabs.upload")}`}
                  >
                    <img
                      className="img"
                      onClick={() => {
                        window.open(state?.request?.iroUpload);
                      }}
                      alt={"Click to View"}
                      src={state?.request?.iroUpload}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label={t("tableTabs.finalByan")}>
                    {state?.request?.finalByan ? (
                      <img
                        className="img"
                        onClick={() => {
                          window.open(state?.request?.finalByan);
                        }}
                        alt={"Click to View"}
                        src={state?.request?.finalByan}
                      />
                    ) : (
                      <span>Not upload yet</span>
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item span={2} label={t("tableTabs.ticket")}>
                    {state?.request?.ticket ? (
                      <img
                        className="img"
                        onClick={() => {
                          window.open(state?.request?.ticket);
                        }}
                        alt={"Click to View Doc"}
                        src={state?.request?.ticket}
                      />
                    ) : (
                      <span>Not upload yet</span>
                    )}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Descriptions
                  bordered
                  title={t("tableTabs.startedBy")}
                  layout={"vertical"}
                >
                  <Descriptions.Item label={t("Owner Name")}>
                    {state.owner.name}
                  </Descriptions.Item>
                  <Descriptions.Item span={2} label={t("Owner Phone Number")}>
                    {state.owner.phoneNumber}
                  </Descriptions.Item>

                  <Descriptions.Item label={t("tableTabs.driverName")}>
                    {state?.transport?.driver?.driverName}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("tableTabs.driverId")}>
                    {state?.transport?.driver?.driverIdNumber}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("tableTabs.driverAddress")}>
                    {state?.transport?.driver?.driverAddress}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("Driver ID Upload")}>
                    <img
                      className="img"
                      onClick={() => {
                        window.open(state?.transport?.driver?.driverIdUpload);
                      }}
                      alt={"Click to View"}
                      src={state?.transport?.driver?.driverIdUpload}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label={t("tableTabs.driverLicenseUpload")}>
                    <img
                      className="img"
                      onClick={() => {
                        window.open(
                          state?.transport?.driver?.driverLicenseUpload
                        );
                      }}
                      alt={"Click to View"}
                      src={state?.transport?.driver?.driverLicenseUpload}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label={t("tableTabs.driverPortIdUpload")}>
                    <img
                      className="img"
                      onClick={() => {
                        window.open(state?.transport?.driver?.portIdUpload);
                      }}
                      alt={"Click to View"}
                      src={state?.transport?.driver?.portIdUpload}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label={t("tableTabs.truck")}>
                    {state?.transport?.truck?.truckModel}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("tableTabs.truckNumber")}>
                    {state?.transport?.truck?.truckPlate}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("tableTabs.truckCapacity")}>
                    {state?.transport?.truck?.truckCapacity}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("tableTabs.truckHeight")}>
                    {state?.transport?.truck?.truckHeight}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("tableTabs.truckType")}>
                    {state?.transport?.truck?.truckType}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("tableTabs.truckLicenseUpload")}>
                    <img
                      className="img"
                      onClick={() => {
                        window.open(
                          state?.transport?.truck?.truckLicenseUpload
                        );
                      }}
                      alt={"Click to View"}
                      src={state?.transport?.truck?.truckLicenseUpload}
                    />
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <div>
        <ActiveRideMap
          coordinates={state?.request?.coordinates}
          id={state?.request?.uid}
        />
      </div>
    </div>
  );
}
