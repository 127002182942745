import React, { Children, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Table,
  Button,
  Input,
  Popconfirm,
  Space,
  Select,
  Dropdown,
  Menu,
} from "antd";

import { SearchOutlined, DeleteOutlined } from "@ant-design/icons";
// import MakeRequestModal from "../Modals/MakeRequestModal";
import useCustomerRequests from "../Hooks/useCustomerRequests";
import { DeleteRequest } from "../API/API";
import { useTranslation } from "react-i18next";

export default function CustomerRequestTable() {
  const navigate = useNavigate();
  const { customerRequests } = useCustomerRequests();
  const [search, setSearch] = useState("");
  const { t } = useTranslation();

  const columns = [
    {
      title: t("tableTabs.id"),
      render: (record) => (
        <span title={record?.uid}>{record?.uid?.slice(0, 6)}</span>
      ),
    },
    {
      title: t("tableTabs.name"),
      dataIndex: "name",
      key: "name",
    },

    {
      title: t("tableTabs.from"),
      dataIndex: "from",
      key: "from",
    },
    {
      title: t("tableTabs.to"),
      dataIndex: "to",
      key: "to",
    },
    {
      title: t("tableTabs.distance"),
      dataIndex: "distance",
      key: "distance",
    },
    {
      title: t("tableTabs.price"),
      dataIndex: "price",
      key: "price",
    },

    {
      title: t("tableTabs.type"),
      dataIndex: "type",
      key: "type",
    },
    {
      title: t("tableTabs.capacity"),
      dataIndex: "capacity",
      key: "capacity",
    },
    {
      title: t("tableTabs.createdAt"),
      render: (record) => (
        <span>
          {new Date(record?.createdAt?.seconds * 1000).toLocaleString("en-US", {
            month: "short",
            day: "2-digit",
            year: "2-digit",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })}
        </span>
      ),
    },
    {
      title: t("tableTabs.sendTo"),
      key: "send_to",
      render: (record) => <span>{"Not Sended yet"}</span>,
    },
    // {
    //   title: "Action",
    //   dataIndex: "",
    //   key: "x",
    //   align: "center",
    //   render: (record) => (
    //     <Space>
    //       <Popconfirm
    //         title={"Are you sure?"}
    //         okText="Ok"
    //         cancelText="Cancel"
    //         onConfirm={() => {
    //           DeleteRequest(record?.uid, record);
    //         }}
    //       >
    //         <span className="ant-btn ant-btn-danger">
    //           <DeleteOutlined />
    //         </span>
    //       </Popconfirm>
    //     </Space>
    //   ),
    // },
  ];
  return (
    <div>
      {/* <MakeRequestModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      /> */}

      {/* <div className="table_topar_add_div_">
        <Button
          type="light"
          // shape="circle"
          icon={<PlusCircleOutlined />}
          size={"middle"}
          onClick={showModal}
        >
          {"Make New Request"}
        </Button>
      </div> */}
      <div className="table_search_div">
        <Input
          allowClear
          size="middle"
          placeholder={t("placeholders.search")}
          prefix={<SearchOutlined />}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      </div>
      <Table
        columns={columns}
        // expandable={{
        //   expandedRowRender: (record) => (
        //     <p
        //       style={{
        //         margin: 0,
        //       }}
        //     >
        //       {record.description}
        //     </p>
        //   ),
        //   rowExpandable: (record) => record.name !== "Not Expandable",
        // }}
        dataSource={
          customerRequests &&
          customerRequests.length > 0 &&
          customerRequests.filter((val) => {
            if (search == "") {
              return val;
            } else if (
              val &&
              Object.keys(val).some((v) =>
                val[v]
                  .toString()
                  .toLowerCase()
                  .includes(search.toString().toLowerCase())
              )
            ) {
              return val;
            }
          })
        }
      />
    </div>
  );
}
