import React, { Children, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Input, Popconfirm, Space, message, Pagination } from "antd";
import { FiUpload } from "react-icons/fi";
import {
  doc,
  getDoc,
  updateDoc,
  setDoc,
  serverTimestamp,
  addDoc,
  collection,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import offline from "./images/offline.png";
import online from "./images/online.png";

import {
  SearchOutlined,
  DeleteOutlined,
  SendOutlined,
  SmileOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import ViewRequestModal from "../Modals/AddDriverModal";
import { sendNotification, uploadImage } from "../API/API";
import useTransports from "../Hooks/useTransports";
import { DeleteRequest, updateRequest } from "../API/API";
import InvoiceModal from "../Modals/InvoiceModal";
import { useTranslation } from "react-i18next";
import useOwners from "../Hooks/useOwners";
import useRelation from "../Hooks/useRelation";
import useGetAllRequests from "../Hooks/useAllRequests";

export default function PendingTable() {
  const { owners } = useOwners();
  // const { transports } = useTransports();
  // const { requests } = useAllRequests();
  const {relation} = useRelation()
  const [loading, setLoading] = useState(false);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [paginationFilters, setPaginationFilters] = useState({
    pageNo: 1,
    pageSize: 5,
    limitSkip: 0,
    limit: 10,
  });
  const { requests, count} = useGetAllRequests(updateTrigger,paginationFilters);
  // setDrivers(drivers);
  const handleChange = (value, pageSize) => {
    const limitSkip = value * pageSize;
    const limit = pageSize;
    setPaginationFilters({ pageNo: value, pageSize, limitSkip, limit });
    setLoading(true);
    setUpdateTrigger(!updateTrigger);
  };
  //logic for sending request to owners that if owner is in relation he cannot pick anyother request only recive relation customer request
  const requestFilter =requests?.filter(
    (v) =>
      // v.status === "pending" &&
      v?.offlinePaid === true &&
      v?.isPaid !== false
  )
  const finalCustomerIDs =  requestFilter?.map((item)=> item.customer._id)
  const ownersArrayFromRelation = relation
  .filter((relationItem) => finalCustomerIDs?.includes(relationItem.customerId))
  .map((matchedRelationItem) => matchedRelationItem.owners);
const ownerIds = ownersArrayFromRelation.flatMap((ownersArray) => ownersArray?.map((owner) => owner.ownerId));
//end whole logic
  
  const filteredOwners = owners?.filter((item) => item.isAccept === true && item.rRA === false);
  let ownersUIDS;
  if (filteredOwners?.length > 0) {
    ownersUIDS = filteredOwners?.map((o) => o.uid);
  }


  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [requestData, setRequestData] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { t } = useTranslation();
  const [isModalInvoiceVisible, setIsModalInvoiceVisible] = useState(false);

  // console.log(requests, "requests");

  const showInvoiveModal = () => {
    setIsModalInvoiceVisible(!isModalVisible);
  };

  const showModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  const columns = [
    {
      title: t("tableTabs.id"),
      render: (record) => (
        <span title={record?.requestId}>{record?._id.slice(-4)}</span>
      ),
    },
    {
      title: t("tableTabs.createdAt"),
      render: (record) => (
        <span>
          {record?.createdAt}
        </span>
      ),
    },
    {
      title: t("tableTabs.name"),
      dataIndex: "name",
      key: "name",
    },

    {
      title: t("tableTabs.from"),
      dataIndex: "from",
      key: "from",
    },
    {
      title: t("tableTabs.to"),
      dataIndex: "to",
      key: "to",
    },
    {
      title: t("tableTabs.distance"),
      dataIndex: "distance",
      key: "distance",
    },
    {
      title: t("tableTabs.price"),
      dataIndex: "price",
      key: "price",
    },

    {
      title: t("tableTabs.type"),
      dataIndex: "type",
      key: "type",
    },

    {
      title: t("tableTabs.sendTo"),
      key: "send_to",
      render: (record) => (
        <span>
          {" "}
          {record.sendTo !== undefined && record?.sendTo?.length > 0
            ? record.sendTo?.length
            : "Not Sended yet"}
        </span>
      ),
    },

    // {
    //   title: t("tableTabs.unHoldRequest"),
    //   key: "hold",
    //   render: (record) => (
    //     <span>
    //       <Popconfirm
    //         title={"Are you sure?"}
    //         okText="Ok"
    //         cancelText="Cancel"
    //         onConfirm={async () => {
    //           let data = {
    //             ...record,
    //             status: "created",
    //           };
    //           await updateRequest(record.uid, data);
    //         }}
    //       >
    //         <button className="ant-btn atn-btn-primary">unHold</button>
    //       </Popconfirm>
    //     </span>
    //   ),
    // },

    {
      title: t("tableTabs.action"),
      dataIndex: "",
      key: "x",
      align: "center",
      render: (record) => (
        <Space>
          <span className="eyes-btn">
            <FiUpload
              onClick={() => {
                setRequestData(record);
                showInvoiveModal();
              }}
            />
          </span>

          {/* <span
            onClick={() => {
              setRequestData(record);
              showInvoiveModal();
            }}
            className="eyes-btn"
          > 
            <GlobalOutlined />
            {"  Offline Payment"}
          </span> */}
          <span
            className="eyes-btn"
            onClick={() => {
              // let transport = transports?.find(
              //   (v) => v.uid === record.acceptedBy?.transportId
              // );
              let data = {
                request: record,
                // transport: transport ? transport : {},
              };
              navigate(`/request-details/${record._id}`, {
                state: data,
              });
            }}
          >
            <EyeOutlined />
          </span>
          {/* <Popconfirm
            title={"Are you sure you want to start?"}
            okText="Ok"
            cancelText="Cancel"
            onConfirm={async () => {
              const data = {
                ...record,
                status: "underProcess",
              };
             

              if (record.isSpecific) {
                data.sendTo = record.specific;
              } else {
                if (ownersUIDS === undefined || ownersUIDS === null) {
                  message.error("No Owner Available");
                  return;
                } else {
                  if(ownerIds.length > 0){
                    data.sendTo = ownerIds
                  }
                  else{

                    data.sendTo = ownersUIDS;
                  }
                }
              }

              if (ownersUIDS) {
                data.status = "underProcess";
              }

              //create a multiple copies of request if quantity is greater than 1
              if (data.quantity > 1) {
                const pricePerQuantity = data.price / data.quantity;

                for (let i = 0; i < data.quantity; i++) {
                  const newData = {
                    ...data,
                    sendTo: ownersUIDS,
                    quantity: 1,
                    price: pricePerQuantity,
                  };

                  // Create a copy of the request in the "customer_transport_requests" collection
                  const copyRef = await addDoc(
                    collection(db, "customer_transport_requests"),
                    newData
                  );
                  //update the document id with the uid
                  await updateDoc(copyRef, { uid: copyRef.id });
                }
                await deleteDoc(
                  doc(db, "customer_transport_requests", record.uid)
                );
              } else {
                // Quantity is 1, so perform a single updateDoc operation
                await updateDoc(
                  doc(db, "customer_transport_requests", record.uid),
                  { ...data }
                );
              }

              const nData = {
                title: "Customer hold request!",
                desc: "Admin proved the customer request and send the owner!",
                type: "request",
                status: "underProcess",
              };
              if (ownersUIDS) {
                nData.to = ownersUIDS;
              }

              await setDoc(doc(db, "finance", record.uid), {
                receivedFromCustomer: true,
                sendToOwner: false,
                customerId: record.customerId,
                uid: record.uid,
                fare: record.price,
                commission: record.commission,
                requestId: record.requestId,
                createdAt: serverTimestamp(),
                paymentMode: "offline",
              });

              if (ownersUIDS) {
                sendNotification(record?.notificationId, nData);
              }
            }}
          >
            <span className="ant-btn ">
              <SendOutlined style={{ color: "red" }} />
            </span>
          </Popconfirm> */}

          {/* <Button
            style={{ width: "80px", fontSize: "13px" }}
            className={`${
              record?.isPaid ? "btn-offline" : "btn-offline-products"
            }`}
          >
            {t("tableTabs.paid")}
            
          </Button> */}
          {/* {`${record?.isPaid ?  : t("tableTabs.unpaid")}`} */}
          {/* <span className="ant-btn  ant-btn-warn" title="send Request">
            <SendOutlined />
          </span> */}
          <Popconfirm
            title={"Are you sure you want to delete?"}
            okText="Ok"
            cancelText="Cancel"
            // onConfirm={() => {
            //   DeleteRequest(record?._id, record);
            // }}
          >
            <span className="ant-btn ant-btn-danger">
              <DeleteOutlined />
            </span>
          </Popconfirm>
          {record.offlinePaid ? (
            <span>
              <img
                src={offline}
                style={{
                  width: "40px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  marginBottom: "9px",
                }}
                alt="offline"
              />
            </span>
          ) : (
            <span>
              <img
                src={online}
                style={{
                  width: "42px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  marginBottom: "9px",
                }}
                alt="online"
              />
            </span>
          )}
        </Space>
      ),
    },
  ];

  return (
    <div>
      <ViewRequestModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        state={requestData}
      />
      <InvoiceModal
        isModalVisible={isModalInvoiceVisible}
        setIsModalVisible={setIsModalInvoiceVisible}
        state={requestData}
      />
      <div className="table_search_div">
        <Input
          allowClear
          size="middle"
          placeholder={t("placeholders.search")}
          prefix={<SearchOutlined />}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      </div>
      <Table
        columns={columns}
        dataSource={
          requests &&
          requests?.length > 0 &&
          requests.filter(
              (v) =>
                // v.status === "pending" &&
                // v?.offlinePaid === true &&
                v?.isPaid !== false
            )
            .filter((val) => {
              if (search == "") {
                return val;
              } else if (
                val &&
                Object.keys(val).some((v) =>
                  val[v]
                    .toString()
                    .toLowerCase()
                    .includes(search.toString().toLowerCase())
                )
              ) {
                return val;
              }
            })
        }
      />
      <Pagination
          defaultCurrent={1}
          defaultPageSize={paginationFilters.pageSize}
          onChange={handleChange}
          total={count}
          className="Pagination"
        />
    </div>
  );
}
