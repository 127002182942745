// import React, { useEffect, useState } from "react";
// import { collection, query, onSnapshot } from "firebase/firestore";
// import { db } from "../firebase";
// export default function useCities() {
//   const [cities, setcities] = useState([]);
//   const getData = async () => {
//     let q = query(collection(db, "services", "data", "cities"));
//     const unsub = onSnapshot(q, (querySnapShot) => {
//       setcities([]);
//       if (!querySnapShot.empty) {
//         querySnapShot.forEach((val) => {
//           setcities((prev) => [...prev, val.data()]);
//         });
//       }
//     });

//     return () => unsub();
//   };
//   useEffect(() => {
//     getData();
//   }, []);

//   return { cities }
// }
import { useEffect, useState, useCallback } from "react";
import { useQuery } from "@apollo/client";
import { GET_ALL_CITIES } from "../../Graphql/queries/servicesQueries";

function useGetAllCities(trigger, paginationFilters) {
  console.log("🚀 ~ file: useCities.js:30 ~ useGetAllCities ~ trigger:", trigger)
  console.log("🚀 ~ file: useCities.js:30 ~ useGetAllCities ~ paginationFilters:", paginationFilters)
  
  const [cities, setCities] = useState([]);
  const [count, setCount] = useState(0);

  const { loading, error, data, refetch } = useQuery(GET_ALL_CITIES, {
    variables: { page: paginationFilters?.pageNo },
    fetchPolicy: "network-only",
  });

  const getAllCities = useCallback(async () => {
    if (loading) return;
    if (error) {
      console.log("Error:", error.message);
      return;
    }
    
    const { cities } = data;
    if (!cities) {
      console.log("No Owner data available.");
      return;
    }
    const { totalDocs } = cities;
    setCities(cities.cities);
    setCount(totalDocs);
  }, [loading, error, data]);

  useEffect(() => {
    getAllCities();
    if (trigger) {
      refetch({ page: paginationFilters?.pageNo });
    }
  }, [trigger, getAllCities, paginationFilters?.pageNo, refetch]);

  return { loading, error, cities, count };
}

export default useGetAllCities;