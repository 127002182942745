import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Input, Space, Popconfirm, Row, Col, Pagination, message } from "antd";
import "./table.css";
import {
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import Loader from "../Loaders/Loader";

import { addService, updateService, deleteService } from "../API/API";
//Hooks
// import useCities from "../Hooks/useCities";
import SelectLocation from "./SelectLocation";
import { useTranslation } from "react-i18next";
import useGetAllCities from "../Hooks/useCities";
import { CITY_CREATE, DELETE_CITY, UPDATE_CITY } from "../../Graphql/mutation/servicesMutation";
import { useMutation } from "@apollo/client";

export default function AddCitiesTable() {
  // const { cities } = useCities();
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");
  const [isAdd, setIsAdd] = useState(false);
  const [search, setSearch] = useState("");
  const [formData, setFormData] = useState({ cityName: "" });
  const { t } = useTranslation();
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [paginationFilters, setPaginationFilters] = useState({
    pageNo: 1,
    pageSize: 5,
    limitSkip: 0,
    limit: 10,
  });
  const [deleteCity] = useMutation(DELETE_CITY);
  const [updateCity] = useMutation(UPDATE_CITY);
  const [createCity] = useMutation(CITY_CREATE);
  const { cities, count} = useGetAllCities(updateTrigger, paginationFilters);
  console.log("🚀 ~ file: AddCitiesTables.jsx:34 ~ AddCitiesTable ~ cities:", cities)
  // setDrivers(drivers);;
  const handleChange = (value, pageSize) => {
    const limitSkip = value * pageSize;
    const limit = pageSize;
    setPaginationFilters({ pageNo: value, pageSize, limitSkip, limit });
    setLoading(true);
    setUpdateTrigger(!updateTrigger);
  };
  const handleAdd = async () => {
    debugger;
    try {
      const cityInput = {
        cityName: formData.city,
        admin:"64f5b4c14091bd9d3c2fdaa2"
        // You might need to include other fields as needed
      };
  
      const { data } = await createCity({
        variables: {
          cityInput,
        },
      });
  
      if (data.createCity.success) {
        // City created successfully, you can reset your form or do any other necessary actions
        setFormData({ cityName: "" });
        setIsAdd(false);
        setUpdateTrigger(true);
        setId(""); // This will trigger a re-fetch of your city data
      } else {
        // Handle the case where city creation was not successful
        // You can display an error message or take appropriate action
      }
    } catch (error) {
      // Handle any errors that occur during the mutation
      console.error(error);
      // You can display an error message or take appropriate action
    }
  };
  const handleEdit = async (data) => {
    debugger
   try {
    await updateCity({
      variables: {
        id: data._id, // Provide the customer ID to be updated
        updateCityInput:{
          cityName:formData.city
        }, // Pass the updated values
      },
    });
    message.success("City Updated Successfully");
      setUpdateTrigger(true);
   } catch (error) {
    console.log("🚀 ~ file: AddCitiesTables.jsx:66 ~ handleEdit ~ error:", error)
    
   }
  };

  const confirm = async (city, action) => {
    debugger;
    setUpdateTrigger(false);
    try {
      const { data } = await deleteCity({
        variables: { id: city._id},
      });
      console.log("🚀 ~ file: AddCitiesTables.jsx:84 ~ confirm ~ data:", data)
      message.success("City Deleted Successfully");
      setUpdateTrigger(true);
    } catch (error) {
      // Handle the error if needed
      console.log(error);
    }
  };
  const columns = [
    {
      title: t("tableTabs.city"),
      render: (record) =>
        id === record._id ? (
          <SelectLocation formData={formData} setFormData={setFormData} />
        ) : (
          <span>{record.cityName}</span>
        ),
    },

    {
      title: t("tableTabs.action"),
      dataIndex: "",
      key: "x",
      align: "center",
      render: (record) => (
        <Space>
          {id === record._id ? (
            <Space>
              <button
                className="ant-btn ant-btn-danger"
                onClick={() => {
                  setId("");
                  setFormData({ cityName: ""});
                }}
              >
                cancel
              </button>
              <button
                className="ant-btn ant-btn-primary"
                onClick={() => {
                  setId("");
                  handleEdit(record);
                }}
              >
                Done
              </button>
            </Space>
          ) : (
            <span
              className="eyes-btn"
              onClick={() => {
                setFormData({ cityName: record.cityName });
                setId(record._id);
              }}
            >
              <EditOutlined />
            </span>
          )}

          <Popconfirm
            title={"Are you sure you want to delete?"}
            okText="Ok"
            cancelText="Cancel"
            onConfirm={() => confirm(record, "del")}
          >
            <span className="ant-btn ant-btn-danger">
              <DeleteOutlined />
            </span>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      {loading ? <Loader /> : ""}

      <div className="table_topar_add_div_">
        <button
          className="ant-btn ant-btn-primary login-btn"
          onClick={() => {
            setIsAdd(!isAdd);
          }}
        >
          {t("Add City")}
        </button>
      </div>
      {isAdd && (
        <div className="add_div">
          <Row gutter={24}>
            <Col span={20}>
              <SelectLocation formData={formData} setFormData={setFormData} />
            </Col>

            <Col span={4}>
              <button className=" eyes-btn" onClick={handleAdd}>
                {t("tableTabs.add")}
              </button>
            </Col>
          </Row>
        </div>
      )}
      <div className="table_search_div">
        <Input
          allowClear
          size="middle"
          placeholder={t("placeholders.search")}
          prefix={<SearchOutlined />}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      </div>
      <Table
      pagination={false}
        columns={columns}
        dataSource={
          cities.length > 0 &&
          cities.filter((val) => {
            if (search == "") {
              return val;
            } else if (
              val &&
              Object.keys(val).some((v) =>
                val[v]
                  .toString()
                  .toLowerCase()
                  .includes(search.toString().toLowerCase())
              )
            ) {
              return val;
            }
          })
        }
      />
      <Pagination
          defaultCurrent={1}
          defaultPageSize={paginationFilters.pageSize}
          onChange={handleChange}
          total={count}
          className="Pagination"
        />
    </div>
  );
}
