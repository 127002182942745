// import React, { useEffect, useState } from "react";
// import { collection, query, onSnapshot } from "firebase/firestore";
// import { db } from "../firebase";
// export default function useSource() {
//   const [source, setSource] = useState([]);
//   const getData = async () => {
//     let q = query(collection(db, "services", "data", "source"));
//     const unsub = onSnapshot(q, (querySnapShot) => {
//       setSource([]);
//       if (!querySnapShot.empty) {
//         querySnapShot.forEach((val) => {
//           setSource((prev) => [...prev, val.data()]);
//         });
//       }
//     });

//     return () => unsub();
//   };
//   useEffect(() => {
//     getData();
//   }, []);

//   return { source };
// }
// import React, { useEffect, useState } from "react";
// import { collection, query, onSnapshot,orderBy } from "firebase/firestore";
// import { db } from "../firebase";
// export default function useTypes() {
//   const [sources, setSoures] = useState([]);
//   const getData = async () => {
//     let q = query(collection(db, "services", "data", "sources"),orderBy("price","asc"));
//     const unsub = onSnapshot(q, (querySnapShot) => {
//       setSoures([]);
//       if (!querySnapShot.empty) {
//         querySnapShot.forEach((val) => {
//           setSoures((prev) => [...prev, val.data()]);
//         });
//       }
//     });

//     return () => unsub();
//   };
//   useEffect(() => {
//     getData();
//   }, []);

//   return { sources };
// }
import { useEffect, useState, useCallback } from "react"; // Import your GraphQL query for sources
import { useQuery } from "@apollo/client";
import { GET_ALL_SOURCES } from "../../Graphql/queries/servicesQueries";

function useGetAllSource(trigger, paginationFilters) {
  const [source, setSoures] = useState([]);
  const [count, setCount] = useState(0);

  const { loading, error, data, refetch } = useQuery(GET_ALL_SOURCES, {
    variables: { page: paginationFilters?.pageNo },
    fetchPolicy: "network-only",
  });

  const getAllSources = useCallback(async () => {
    if (loading) return;
    if (error) {
      console.log("Error:", error.message);
      return;
    }
    const { sources } = data;
    if (!sources) {
      console.log("No Sources data available.");
      return;
    }
    const { totalDocs } = sources;
    setSoures(sources.sources);
    setCount(totalDocs);
  }, [loading, error, data]);

  useEffect(() => {
    getAllSources();
    if (trigger) {
      refetch({ page: paginationFilters?.pageNo });
    }
  }, [trigger, getAllSources, paginationFilters?.pageNo, refetch]);

  return { loading, error, source, count };
}

export default useGetAllSource;
