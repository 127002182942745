import { gql } from "@apollo/client";

const RELATION_CREATE = gql`
mutation CreateRelation($relationInput: RelationInput) {
    createRelation(relationInput: $relationInput) {
      code
      message
      success
      data {
        _id
        admin {
          _id
        }
        customer {
          _id
          crNumber
        }
        createdAt
        owner {
          _id
          currentPage
          owners {
            _id
          }
          totalDocs
          totalPages
          name
        }
      }
    }
  }
`;
const RELATION_UPDATE = gql`
mutation Mutation($id: ID!, $updateOwnerInput: UpdateOwnerInput) {
  updateOwner(ID: $id, updateOwnerInput: $updateOwnerInput) {
    code
    data {
      _id
      bankInfo {
        bankIdCode
        beneficiaryName
        iBanNumber
      }
      crNumber
      crNumberUpload
      createdAt
      email
      emailVerified
      idNumber
      idNumberUpload
      isAccept
      isApproved
      isDeleted
      name
      ownerName
      phoneNumber
      profileImage
      rRA
      token
      type
    }
    message
    success
  }
}
`;

const RELATION_DELETE = gql`
mutation DeleteOwner($id: ID!) {
  deleteOwner(ID: $id) {
    code
    message
    success
    data {
      _id
      bankInfo {
        bankIdCode
        beneficiaryName
        iBanNumber
      }
      crNumber
      crNumberUpload
      createdAt
      email
      emailVerified
      idNumber
      idNumberUpload
      isAccept
      isApproved
      isDeleted
      name
      ownerName
      phoneNumber
      profileImage
      rRA
      token
      type
    }
  }
}
`;
export { RELATION_UPDATE, RELATION_CREATE, RELATION_DELETE };

