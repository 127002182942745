import { collection, query, onSnapshot } from "firebase/firestore";
import React, { useEffect, useState } from 'react'
import { db } from "../firebase";

const useMaintenanceAllServices = () => {
    const [maintenanceServices, setMaintenanceServices] = useState([])
    const getAllMaintenanceServices = async () => {
        const q = query(collection(db, "maintenance-services"));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const arr = [];
            if (!querySnapshot.empty) {
                querySnapshot.forEach((doc) => {
                    arr.push(doc.data());
                });
                setMaintenanceServices(arr)
            }
        });
        return () => unsubscribe()
    }
    useEffect(() => {
        getAllMaintenanceServices()
    }, [])

    return { maintenanceServices }
}

export default useMaintenanceAllServices
