import React, { Children, useState } from "react";
import { Table, Input, Popconfirm, Space, Button } from "antd";
import {
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import useCombinations from "../Hooks/useCombinations";
import MakeCombinationModal from "../Modals/MakeCombinationModal";
import { deleteCombination } from "../API/API";
import { useTranslation } from "react-i18next";
import UpdateCombination from "../Modals/UpdateCombination";

export default function RelationCombination() {
  const { combinations } = useCombinations();
  const filterredCombinations = combinations.filter((item => item.isToggleOn == true))
  const [search, setSearch] = useState("");
  const [id, setId] = useState("");
  // const [formData, setFormData] = useState({});
  const [selectedRecord, setSelectedRecord] = useState(null);


  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
  const { t } = useTranslation();
  const showModal = () => {
    setIsModalVisible(!isModalVisible);
  };
  const showUpdateModal = () => {
    setIsUpdateModalVisible(!isUpdateModalVisible);
  };
  const columns = [
    {
      title: t("tableTabs.from"),
      dataIndex: "from",
      key: "from",
      // render: (from) => from.city
    },
    {
      title: t("tableTabs.to"),
      dataIndex: "to",
      key: "to",
    },
    {
      title: t("tableTabs.distance"),
      dataIndex: "distance",
      key: "distance",
    },
    {
      title: t("tableTabs.price"),
      dataIndex: "price",
      key: "price",
    },
    {
      title: t("tableTabs.time"),
      dataIndex: "time",
      key: "time",
    },

    {
      title: t("tableTabs.type"),
      dataIndex: "type",
      key: "type",
    },
    {
      title: t("tableTabs.capacities"),
      dataIndex: "capacity",
      key: "capacity",
    },
    {
      title: t("tableTabs.hight"),
      dataIndex: "height",
      key: "height",
    },
    {
      title: t("placeholders.commission"),
      dataIndex: "commission",
      key: "commision",
    },
    {
      title: "VAT",
      dataIndex: "vat",
      key: "vat",
    },

    {
      title: t("tableTabs.action"),
      dataIndex: "",
      key: "x",
      align: "center",
      render: (record) => (
        <Space>
          <span
            className="eyes-btn"
            onClick={() => {
              setSelectedRecord(record);
              showUpdateModal();
            }}
          >
            <EditOutlined />
          </span>
          <Popconfirm
            title={"Are you sure you want to delete?"}
            okText="Ok"
            cancelText="Cancel"
            onConfirm={() => {
              deleteCombination(record?.uid, record);
            }}
          >
            <span className="ant-btn ant-btn-danger">
              <DeleteOutlined />
            </span>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  return (
    <div>
      {/* <MakeCombinationModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      /> */}
      <UpdateCombination
        isUpdateModalVisible={isUpdateModalVisible}
        setIsUpdateModalVisible={setIsUpdateModalVisible}
        record={selectedRecord}
      />

      {/* <div className="table_topar_add_div_">
        <Button
          className="login-btn"
          // shape="circle"
          icon={<PlusCircleOutlined />}
          size={"middle"}
          onClick={showModal}
        >
          {t("tableTabs.makeNewCombination")}
        </Button>
      </div> */}
      <div className="table_search_div">
        <Input
          allowClear
          size="middle"
          placeholder={t("placeholders.search")}
          prefix={<SearchOutlined />}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      </div>
      <Table
        columns={columns}
        dataSource={
            filterredCombinations &&
            filterredCombinations.length > 0 &&
            filterredCombinations.filter((val) => {
            if (search == "") {
              return val;
            } else if (
              val &&
              Object.keys(val).some((v) =>
                val[v]
                  .toString()
                  .toLowerCase()
                  .includes(search.toString().toLowerCase())
              )
            ) {
              return val;
            }
          })
        }
      />
    </div>
  );
}
