import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Input, Popconfirm, Space } from "antd";
import offline from "./images/offline.png";
import online from "./images/online.png";

import {
  SearchOutlined,
  DeleteOutlined,
  SmileOutlined,
  SendOutlined,
  EyeOutlined,
} from "@ant-design/icons";
// import ViewRequestModal from "../Modals/ViewRequest";
import useAllRequests from "../Hooks/useAllRequests";
// import useTransports from "../Hooks/useTransport";
import useTransports from "../Hooks/useTransports";
import { DeleteRequest, updateRequest } from "../API/API";
import { FiUpload } from "react-icons/fi";
import { useTranslation } from "react-i18next";

export default function FinishedRequestTable() {
  const navigate = useNavigate();
  const { allRequests } = useAllRequests();
  const { transports } = useTransports();

  const [search, setSearch] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { t } = useTranslation();

  const showModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  const columns = [
    {
      title: t("tableTabs.id"),
      render: (record) => (
        <span title={record?.requestId}>{record?.requestId}</span>
      ),
    },
    {
      title: t("tableTabs.createdAt"),
      render: (record) => (
        <span>
          {new Date(record?.createdAt?.seconds * 1000).toLocaleString("en-US", {
            month: "short",
            day: "2-digit",
            year: "2-digit",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })}
        </span>
      ),
    },
    {
      title: t("tableTabs.name"),
      dataIndex: "name",
      key: "name",
    },

    {
      title: t("tableTabs.from"),
      dataIndex: "from",
      key: "from",
    },
    {
      title: t("tableTabs.to"),
      dataIndex: "to",
      key: "to",
    },
    {
      title: t("tableTabs.distance"),
      dataIndex: "distance",
      key: "distance",
    },
    {
      title: t("tableTabs.price"),
      dataIndex: "price",
      key: "price",
    },

    {
      title: t("tableTabs.type"),
      dataIndex: "type",
      key: "type",
    },

    {
      title: t("tableTabs.sendTo"),
      key: "send_to",
      render: (record) => (
        <span>
          {" "}
          {record.sendTo !== undefined && record.sendTo.length > 0
            ? record.sendTo.length
            : "Not Sended yet"}
        </span>
      ),
    },

    {
      title: t("tableTabs.action"),
      dataIndex: "",
      key: "x",
      align: "center",
      render: (record) => (
        <Space>
          <span
            className="eyes-btn"
            onClick={() => {
              let transport = transports?.find(
                (v) => v.uid === record.acceptedBy?.transportId
              );
              let data = {
                request: record,
                transport: transport ? transport : {},
              };
              navigate(`/request-details/${record.uid}`, {
                state: data,
              });
            }}
          >
            <EyeOutlined />
          </span>
          {record.offlinePaid ? (
            <span>
              <img
                src={offline}
                style={{
                  width: "40px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  marginBottom: "9px",
                }}
                alt="offline"
              />
            </span>
          ) : (
            <span >
              <img
                src={online}
                style={{
                  width: "42px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  marginBottom: "9px",
                }}
                alt="online"
              />
            </span>
          )}

          {/* <span
            onClick={() => {
              setRequestData(record);
              showModal();
            }}
            className="eyes-btn"
          >
            <EyeOutlined />
          </span> */}
        </Space>
      ),
    },
  ];
  return (
    <div>
      {/* <ViewRequestModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        state={requestData}
      /> */}

      <div className="table_search_div">
        <Input
          allowClear
          size="middle"
          placeholder={t("placeholders.search")}
          prefix={<SearchOutlined />}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      </div>
      <Table
        columns={columns}
        dataSource={
          allRequests &&
          allRequests.length > 0 &&
          allRequests
            .filter((v) => v.status === "finished")
            .filter((val) => {
              if (search == "") {
                return val;
              } else if (
                val &&
                Object.keys(val).some((v) =>
                  val[v]
                    .toString()
                    .toLowerCase()
                    .includes(search.toString().toLowerCase())
                )
              ) {
                return val;
              }
            })
        }
      />
    </div>
  );
}
