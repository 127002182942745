import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Input,
  Form,
  Upload,
  Row,
  Col,
  Select,
  message,
} from "antd";

import { UploadOutlined } from "@ant-design/icons";
import { AddTruck, uploadImage, UpdateTruck } from "../API/API";
import Loader from "../Loaders/Loader";
import useCapacites from "../Hooks/useCapacities";
import useHeights from "../Hooks/useHeights";
import useTypes from "../Hooks/useTypes";
import { UPDATE_TRUCK } from "../../Graphql/mutation/truckMutation";
import { useMutation } from "@apollo/client";
export default function EditTruckModal({
  isModalVisible,
  setIsModalVisible,
  data,
}) {
  const { Option } = Select;
  const [updateTruck] = useMutation(UPDATE_TRUCK);

  const { capacities } = useCapacites();
  const { types } = useTypes();
  const { heights } = useHeights();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const handleOk = () => {
    setIsModalVisible(!isModalVisible);
  };
  const handleCancel = () => {
    setIsModalVisible(!isModalVisible);
    form.resetFields();
  };
  const [formData, setFormData] = useState({
    truckLicense: "",
  });
  const [licenseFile, setLicenseFile] = useState([]);

  const onFinish = async (values) => {
    setLoading(true);
    if (typeof formData.truckLicense !== "string") {
      await uploadImage("Trucks/", formData.truckLicense).then((res) => {
        formData.truckLicense = res;
        setFormData({ ...formData });
      });
    }
    // let udata = {
    //   ...values,
    //   truckLicenseUpload: formData.truckLicense,
    // };
    await updateTruck({
      variables: {
        id: data._id, // Provide the customer ID to be updated
        updateTruckInput: values, // Pass the updated values
      },
    })
      .then(() => {
        setLoading(false);
        form.resetFields();
        handleCancel();
        message.success("Update Successull!");
      })
      .catch((e) => {
        setLoading(false);
        message.error(JSON.stringify(e));
      });
  };

  useEffect(() => {
    // if (Object.keys(data).length > 0) {
      form.setFieldsValue(data);
    //   if (data.truckLicenseUpload !== "") {
    //     setLicenseFile([
    //       {
    //         uid: "-1",
    //         name: "License Uplaod",
    //         status: "done",
    //         url: data.truckLicenseUpload,
    //       },
    //     ]);
    //   }

    //   setFormData({ ...formData });
    // }
  }, [data]);
  return (
    <div>
      <Modal
        title="Edit Truck"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose
        footer={null}
      >
        {loading ? <Loader /> : ""}
        <div>
          <Form
            form={form}
            name="add_trcuk_form"
            initialValues={data}
            onFinish={onFinish}
          >
            <Input.Group>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="model"
                  >
                    <Input placeholder="Model" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="noPlate"
                  >
                    <Input placeholder="Plate" />
                  </Form.Item>
                </Col>
              </Row>
            </Input.Group>

            <Input.Group>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="capacity"
                  >
                    <Select placeholder={"select capacity"}>
                      {capacities &&
                        capacities.map((v, i) => {
                          return (
                            <Option key={i} value={v.capacity}>
                              {v.capacity}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="height"
                  >
                    <Select placeholder={"select hight"}>
                      {}
                      {heights &&
                        heights.map((v, i) => {
                          return (
                            <Option key={i} value={v.height}>
                              {v.height}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Input.Group>
            <Input.Group>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="type"
                  >
                    <Select placeholder={"select type"}>
                      {types &&
                        types.map((v, i) => {
                          return (
                            <Option key={i} value={v.type}>
                              {v.type}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="truckLicenseUpload"
                  >
                    <Upload
                      multiple={false}
                      maxCount={1}
                      name="logo"
                      listType="picture"
                      accept="image/*"
                      fileList={licenseFile}
                      onChange={(e) => {
                        if (e.file.status === "removed") {
                          formData.truckLicense = "";
                          setFormData({
                            ...formData,
                          });

                          setLicenseFile([]);
                        } else {
                          formData.truckLicense = e.file.originFileObj;
                          setFormData({
                            ...formData,
                          });
                          setLicenseFile(e.fileList);
                        }
                      }}
                    >
                      <Button icon={<UploadOutlined />}>upload license</Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>
            </Input.Group>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Edit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
}
