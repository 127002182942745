import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "../src/Redux/store";
import "./LanguageTranslater/i18n";
import { registerServiceWorker } from "./register-sw";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";

const root = createRoot(document.getElementById("root"));
const client = new ApolloClient({
  uri: "http://localhost:5000/", // Replace with your GraphQL server URL
  cache: new InMemoryCache(),
});
root.render(
  <React.StrictMode>
     <ApolloProvider client={client}>
     <Provider store={store}>
      <App />
    </Provider>
     </ApolloProvider>
    
  </React.StrictMode>

);

reportWebVitals();
registerServiceWorker();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
