import React, { useEffect, useState } from "react";
import { OWNER_UPDATE } from "../Graphql/mutation/ownerMutation";
import { useMutation } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../Components/Loaders/Loader";
import Swal from 'sweetalert2/dist/sweetalert2.js'
const EmailVerification = () => {
    const navigate = useNavigate();
  const [verifi, setVerifi] = useState(false);
  const [loading, setLoading] = useState(true);
  const [updateOwner] = useMutation(OWNER_UPDATE);
  const { ownerId } = useParams();
  const verification = async () => {
        const res = await updateOwner({
        variables: {
            id:ownerId,
            updateOwnerInput: {
            emailVerified: true,
            },
        },
        });
        const {data} = res;
        // const { updateOwner } = data;
        
        console.log("🚀 ~ file: EmailVerification.jsx:21 ~ verification ~ res:", res)
        setVerifi(data.updateOwner.data.emailVerified);
        if (data.updateOwner.data.emailVerified === true) {
            Swal.fire({
                title: 'Email Verification is SuccessFully',
                
              }).then((result) => {
                if (result.isConfirmed) {
                    navigate("/");
                }
              })
          }
          
  };
  useEffect(() => {
    if (verifi === false) {
      setLoading(true);
    } else {
      setLoading(false);
    }
    verification();
  }, [verifi]);
  return(
    <div>
    {loading ? <Loader /> : ""}
    
    </div>
  );
};

export default EmailVerification;
