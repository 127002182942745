import { gql } from "@apollo/client";
const DRIVER_TRUCK_CREATE = gql`
mutation Mutation($createDriverTruckInput: CreateDriverTruckInput) {
  createDriverTruck(createDriverTruckInput: $createDriverTruckInput) {
    code
    message
    success
    data {
      _id
      createdAt
      driverName
      truckNo
      driver {
        _id
        age
        address
        createdAt
        idNo
        imageUrl
        isTruck
        name
        nationality
      }
      owner {
        _id
      }
      truck {
        _id
        capacity
        createdAt
        height
        imageUrl
        isDriver
        model
        noPlate
        owner {
          _id
        }
        serviseType
      }
    }
  }
}
`;

const UPDATE_DRIVER_TRUCK = gql`
  mutation Mutation($id: ID!, $updateCustomerInput: UpdateCustomerInput) {
    updateCustomer(ID: $id, updateCustomerInput: $updateCustomerInput) {
      code
      message
      data {
        email
        firebaseEmail
        _id
        token
        uid
        url
        userName
      }
      success
    }
  }
`;

const DELETE_DRIVER_TRUCK = gql`
mutation DeleteDriverTruck($id: ID!) {
    deleteDriverTruck(ID: $id) {
      code
      data {
        _id
        createdAt
        driver {
          _id
        }
        driverName
        owner {
          _id
        }
        truck {
          _id
        }
        truckNo
      }
      message
      success
    }
  }
`;

export { DRIVER_TRUCK_CREATE, DELETE_DRIVER_TRUCK, UPDATE_DRIVER_TRUCK };
