import React, { useState, useEffect } from "react";
import { getDoc, doc, onSnapshot } from "firebase/firestore";
import { useNavigate, useParams } from "react-router-dom";
import useRequest from "../../Hooks/useRequest";
import { db } from "../../firebase";
import { Button, Descriptions, Badge, Tag, Popconfirm, message } from "antd";
import { updateFinance } from "../../API/API";
function FinanceDetails() {
  const navigate = useNavigate();
  const { finID } = useParams();
  const [finance, setFinance] = useState();
  const [cus, setCus] = useState();
  const [owner, setOnwer] = useState();
  const request = useRequest(finID);

  const getDetails = async () => {
    let q = doc(db, "finance", finID);
    const unsub = onSnapshot(q, (fince) => {
      const finceData = fince.data();
      const finceId = finceData?.ownerId;
      setFinance(finceData);

      getDoc(doc(db, "owners", finceId)).then((v) => {
        if (v.exists()) {
          console.log("owner data ", v?.data());
          setOnwer(v.data());
        }
      });

      getDoc(doc(db, "customers", request?.customerId)).then((v) => {
        if (v.exists()) {
          setCus(v.data());
        }
      });
    });
    return () => unsub();
  };

  useEffect(() => {
    getDetails();
  }, []);

  return (
    <div>
      <Button onClick={() => navigate(-1)}>Go Back</Button>
      <div style={{ marginTop: "15px" }}>
        <Descriptions title="Finance Details" bordered layout="vertical">
          <Descriptions.Item label="Request ID">
            {request?.requestId || "N/A"}
          </Descriptions.Item>
          <Descriptions.Item label="From">
            {request?.from || "N/A"}
          </Descriptions.Item>
          <Descriptions.Item label="To">
            {request?.to || "N/A"}
          </Descriptions.Item>
          <Descriptions.Item label="Created At">
            {new Date(request?.createdAt?.seconds * 1000).toLocaleString(
              "en-US",
              {
                month: "short",
                day: "2-digit",
                year: "2-digit",
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              }
            ) || "N/A"}
          </Descriptions.Item>
          <Descriptions.Item label="Fare">
            {finance?.fare || "N/A"}
          </Descriptions.Item>
          <Descriptions.Item label="Commission">
            {finance?.commission || "N/A"}
          </Descriptions.Item>
          <Descriptions.Item label="Customer Name">
            {cus?.name || "N/A"}
          </Descriptions.Item>
          <Descriptions.Item label="Customer Phone">
            {cus?.phoneNumber || "N/A"}
          </Descriptions.Item>
          <Descriptions.Item label="Received from Customer">
            {finance?.receivedFromCustomer == true ? (
              <Tag color="success">YES</Tag>
            ) : (
              <Tag color="red">NO</Tag>
            )}
          </Descriptions.Item>

          <Descriptions.Item label="Owner Name">
            {owner?.name || "N/A"}
          </Descriptions.Item>
          <Descriptions.Item label="Owner Phone">
            {owner?.phoneNumber || "N/A"}
          </Descriptions.Item>
          <Descriptions.Item label="Owner Received">
            {finance?.sendToOwner == true ? (
              <Tag color="success">YES</Tag>
            ) : (
              <Tag color="red">NO</Tag>
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Owner Bank Available">
            {owner?.bankInfo ? (
              <Tag color="success">YES</Tag>
            ) : (
              <Tag color="red">NO</Tag>
            )}
          </Descriptions.Item>
          <Descriptions.Item label="IBAN No">
            {owner?.bankInfo?.iBanNumber || "N/A"}
          </Descriptions.Item>
          <Descriptions.Item label="Bank Benificiary Name">
            {owner?.bankInfo?.benificiaryName || "N/A"}
          </Descriptions.Item>
          <Descriptions.Item label="Send Amount to Owner">
            {owner?.bankInfo ? (
              finance?.sendToOwner && finance.ownerId ? (
                <Tag color="green">Already Paid</Tag>
              ) : (
                <Popconfirm
                  title={"Are you sure?"}
                  okText="Ok"
                  cancelText="Cancel"
                  onConfirm={() => {
                    let data = { sendToOwner: true };
                    updateFinance(finance?.uid, data);
                  }}
                >
                  <Button>Send</Button>
                </Popconfirm>
              )
            ) : (
              "Currently Owner Bank Details Not Found"
            )}
          </Descriptions.Item>
        </Descriptions>
      </div>
    </div>
  );
}

export default FinanceDetails;
