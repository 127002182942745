import React, { useEffect, useState } from "react";
import { Button, Descriptions, Image, Space } from "antd";
import useGetSingleSeaFreightRequest from "../Hooks/useGetSingleSeaFreightRequest";
import { useNavigate, useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import { useTranslation } from "react-i18next";

const SeaFreightDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { singleRequest } = useGetSingleSeaFreightRequest(id);

  return (
    <>
      <div className="upper_btn_div car-img">
        <Space>
          <Button
            type="light"
            onClick={() => {
              // navigate(-1);
              window.history.back();
            }}
          >
            {t("tableTabs.back")}
          </Button>
        </Space>
      </div>
      <Descriptions bordered layout="vertical" title="Sea-Freight Details">
        <Descriptions.Item label="Source">
          {singleRequest?.source}
        </Descriptions.Item>
        <Descriptions.Item label="Destination">
          {singleRequest?.destination}
        </Descriptions.Item>
        <Descriptions.Item label="Container Type">
          {singleRequest?.containertype}
        </Descriptions.Item>
        <Descriptions.Item label="Container Weight">
          {singleRequest?.weight} (kg)
        </Descriptions.Item>
        <Descriptions.Item label="Container Quantity">
          {singleRequest?.quantity}
        </Descriptions.Item>
        <Descriptions.Item label="Customer Name">
          {singleRequest?.customerName}
        </Descriptions.Item>
        <Descriptions.Item label="Customer Email">
          {singleRequest?.email}
        </Descriptions.Item>
        <Descriptions.Item label="Customer Phone">
          {singleRequest?.phone}
        </Descriptions.Item>
        <Descriptions.Item label="status">
          {singleRequest?.status}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default SeaFreightDetails;
