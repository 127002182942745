// import React, { useEffect, useState } from "react";
// import {
//   collection,
//   query,
//   onSnapshot,
//   where,
//   orderBy,
// } from "firebase/firestore";
// import { db } from "../firebase";
// export default function useCombinations() {
//   const [combinations, setcombinations] = useState([]);
//   const getData = async () => {
//     let q = query(collection(db, "combinations"), orderBy("createdAt", "desc"));
//     const unsub = onSnapshot(q, (querySnapShot) => {
//       setcombinations([]);
//       if (!querySnapShot.empty) {
//         querySnapShot.forEach((val) => {
//           setcombinations((prev) => [...prev, val.data()]);
//         });
//       }
//     });

//     return () => unsub();
//   };
//   useEffect(() => {
//     getData();
//   }, []);

//   return { combinations };
// }
import { useEffect, useState, useCallback } from "react";
import { useQuery } from "@apollo/client";
import { GET_ALL_COMBINATION } from "../../Graphql/queries/combinationQueries";

function useGetAllCombination(trigger, paginationFilters) {
  const [combinations, setCombination] = useState([]);
  const [count, setCount] = useState(0);

  const { loading, error, data, refetch } = useQuery(GET_ALL_COMBINATION, {
    variables: { page: paginationFilters?.pageNo },
    fetchPolicy: "network-only",
  });

  const getAllCombination = useCallback(async () => {
    if (loading) return;
    if (error) {
      console.log("Error:", error.message);
      return;
    }
    
    const { allCombinations } = data;
    if (!allCombinations) {
      console.log("No Owner data available.");
      return;
    }
    const { combinations, totalDocs } = allCombinations;
    setCombination(combinations);
    setCount(totalDocs);
  }, [loading, error, data]);

  useEffect(() => {
    getAllCombination();
    if (trigger) {
      refetch({ page: paginationFilters?.pageNo });
    }
  }, [trigger, getAllCombination, paginationFilters?.pageNo, refetch]);

  return { loading, error, combinations, count };
}

export default useGetAllCombination;