import { gql } from "@apollo/client";

const GET_ALL_COMBINATION = gql`
query Combinations($page: Int!) {
    allCombinations(page: $page) {
      currentPage
      totalDocs
      totalPages
      combinations {
        _id
        capacity
        commission
        createdAt
        distance
        estimateTime
        from
        height
        isRelation
        price
        to
        type
        vat
      }
    }
  }
`;
const GET_COMBINATION_ID = gql`
query Customer($id: ID!) {
  customer(ID: $id) {
    _id
    crNumber
    crNumberUpload
    createdAt
    email
    emailVerified
    idNumber
    idNumberUpload
    isApproved
    isDeleted
    isHold
    name
    phoneNumber
    proVerified
    profileImage
    token
    type
    userName
  }
}
`;
const GET_COMBINATION_EMAIL = gql`
query CustomerEmail($email: String) {
  customerEmail(email: $email) {
    _id
    crNumber
    crNumberUpload
    createdAt
    email
    emailVerified
    idNumber
    idNumberUpload
    isApproved
    isDeleted
    isHold
    name
    phoneNumber
    proVerified
    profileImage
    token
    type
    userName
  }
}
`;

export { GET_COMBINATION_ID, GET_ALL_COMBINATION, GET_COMBINATION_EMAIL };
