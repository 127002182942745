import React from "react";
import { useTranslation } from "react-i18next";
import OwnersTable from "../Tables/OwnersTables";

export default function Owners() {
  const { t } = useTranslation();
  return (
    <div>
      <div>
        <h3>{t('routes.owners')}</h3>
      </div>
      <div>
        <OwnersTable/>
      </div>
    </div>
  );
}
