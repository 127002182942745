import { gql } from "@apollo/client";
const DRIVER_CREATE = gql`
mutation CreateDriver($registerDriverInput: RegisterDriverInput) {
    createDriver(registerDriverInput: $registerDriverInput) {
      code
      message
      success
      data {
        _id
        age
        idNo
        isTruck
        imageUrl
        address
        name
        nationality
        owner {
          _id
          ownerName
          email
        }
        status
      }
    }
  }
`;

const UPDATE_DRIVER = gql`
mutation UpdateDriver($id: ID!, $updateDriverInput: UpdateDriverInput) {
  updateDriver(ID: $id, updateDriverInput: $updateDriverInput) {
    code
    message
    success
    data {
      _id
      address
      isTruck
      age
    }
  }
}
`;

const DELETE_DRIVER = gql`
mutation Mutation($id: ID!, $isArchive: Boolean) {
    deleteDriver(ID: $id, isArchive: $isArchive) {
      code
      message
      success
      data {
        _id
        age
        createdAt
        idNo
        isTruck
        imageUrl
        name
        nationality
        owner {
          _id
        }
        status
        token
      }
    }
  }
`;

export { DRIVER_CREATE, DELETE_DRIVER, UPDATE_DRIVER };
