// import React, { useEffect, useState } from "react";
// import { collection, query, onSnapshot } from "firebase/firestore";
// import { db } from "../firebase";
// export default function useDestination() {
//   const [destination, setDestination] = useState([]);
//   const getData = async () => {
//     let q = query(collection(db, "services", "data", "destination"));
//     const unsub = onSnapshot(q, (querySnapShot) => {
//       setDestination([]);
//       if (!querySnapShot.empty) {
//         querySnapShot.forEach((val) => {
//           setDestination((prev) => [...prev, val.data()]);
//         });
//       }
//     });

//     return () => unsub();
//   };
//   useEffect(() => {
//     getData();
//   }, []);

//   return { destination };
// }
import { useEffect, useState, useCallback } from "react"; // Import your GraphQL query for destination
import { useQuery } from "@apollo/client";
import {
  GET_All_DRIVER,
  GET_MY_DRIVER,
} from "../../Graphql/queries/driverQueries";
import { GET_ALL_DESTINATION } from "../../Graphql/queries/servicesQueries";

// ... other imports ...

function useGetAllDestination(trigger, paginationFilters) {
  const [destination, setDestination] = useState([]);
  const [count, setCount] = useState(0);

  const { loading, error, data, refetch } = useQuery(
    GET_ALL_DESTINATION, // Use GET_All_DRIVER when ownerId is not provided
    {
      variables: { page: paginationFilters?.pageNo },
      fetchPolicy: "network-only",
    }
  );

  const getDestination = useCallback(() => {
    if (loading) return;
    if (error) {
      console.log("Error:", error.message);
      return;
    }
    const {allDestinations} = data
    if (!allDestinations) {
      console.log("No driver data available.");
      return;
    }
    const { destinations, totalDocs } = allDestinations;
    setDestination(destinations);
    setCount(totalDocs);
  }, [loading, error, data]);

  useEffect(() => {
    getDestination();
    if (trigger) {
      refetch({ page: paginationFilters?.pageNo });
    }
  }, [trigger, getDestination, paginationFilters?.pageNo, refetch]);

  return { loading, error, destination, count };
}

export default useGetAllDestination;
