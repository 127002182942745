import React, { useState } from "react";
import { Descriptions, Image, Space } from "antd";
// import useCustomer from "../Hooks/useCustomer";
import EditProfileModal from "../Modals/EditProfileModal";
import { useTranslation } from "react-i18next";
import profileImage from '../../assets/profile.png';
export default function CustomerProfile({customer}) {
  console.log("🚀 ~ file: CustomerProfile.jsx:8 ~ CustomerProfile ~ customer:", customer)
  // const { customer } = useCustomer();
  const imageUrl = customer?.imageUrl?.includes(
    "https://firebasestorage.googleapis.com"
  )
    ? customer?.imageUrl
    : profileImage;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { t } = useTranslation();
  const showModal = () => {
    setIsModalVisible(!isModalVisible);
  };
  return (
    <div>
      {/* <EditProfileModal
        setIsModalVisible={setIsModalVisible}
        isModalVisible={isModalVisible}
        uid={customer._id}
        type={customer.type}
      /> */}
      <div>
        <h3>{t("tableTabs.customerProfile")}</h3>
      </div>
      <div>
        <Space>
          <button className="ant-btn ant-btn-primary" onClick={showModal}>
            {t("tableTabs.edit")}
          </button>
        </Space>
      </div>
      <div>
        <Descriptions
          bordered
          layout="vertical"
          title={t("tableTabs.customerInfo")}
        >
          <Descriptions.Item span={3} label={t("tableTabs.profileImage")}>
            <Image
              width={250}
              height={100}
              src={imageUrl}
              alt={"No Upload"}
            />
          </Descriptions.Item>
          <Descriptions.Item label={t("tableTabs.name")}>
            {customer.name}
          </Descriptions.Item>
          <Descriptions.Item label={t("tableTabs.email")}>
            {customer.email}
          </Descriptions.Item>
          <Descriptions.Item label={t("tableTabs.phoneNumber")}>
            {customer.phoneNumber}
          </Descriptions.Item>
          <Descriptions.Item label={t("tableTabs.idNumber")}>
            {customer.idNumber}
          </Descriptions.Item>
          <Descriptions.Item
            span={2}
            label={`${t("tableTabs.upload")} ${t("tableTabs.idNumber")}`}
          >
            <img
              className="img"
              src={customer.imageUrl}
              alt={"Click To View"}
              // onClick={() => {
              //   window.open(customer.idNumberUpload);
              // }}
            />
          </Descriptions.Item>

          <Descriptions.Item label={t("CR Number")}>
            {customer.crNumber}
          </Descriptions.Item>
          <Descriptions.Item span={2} label={t("CR Number upload")}>
            <img
              className="img"
              src={customer.imageUrl}
              alt={"Click To View"}
              // onClick={() => {
              //   window.open(customer.crNumberUpload);
              // }}
            />
          </Descriptions.Item>
        </Descriptions>
      </div>
      <div></div>
    </div>
  );
}
