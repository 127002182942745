import { Badge, Tabs } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import useCheckActiveTab from "../Hooks/useCheckActiveTab";
import ClearanceNewRequestTable from "../Tables/ClearanceNewRequestTable";
import ClearanceUnderProcessRequest from "../Tables/ClearanceUnderProcessRequest";
import ClearanceFinalizingRequestTable from "../Tables/ClearanceFinalizingRequestTable";
import ClearanceFinanceRequestTable from "../Tables/ClearanceFinanceRequestTable";
import ClearanceCompleteRequestTable from "../Tables/ClearanceCompleteRequestTable";
import useGetAllClearanceRequest from "../Hooks/useGetAllClearanceRequest";

const Clearance = () => {
  const { handleTabChange, activeTab } = useCheckActiveTab();
  const { t } = useTranslation();
  const { TabPane } = Tabs;
  const { clearanceRequestData } = useGetAllClearanceRequest();

  return (
    <div>
      <div>
        <h3>Clearance</h3>
      </div>
      <div>
        <Tabs onChange={handleTabChange} activeKey={activeTab}>
          <TabPane
            tab={
              <Badge
                offset={[5, -6]}
                count={
                  <span
                    style={{ backgroundColor: "green" }}
                    className="Span-reqest"
                  >
                    {
                      clearanceRequestData.filter(
                        (el) => el.status === "created"
                      ).length
                    }
                  </span>
                }
              >
                <span>New Request</span>
              </Badge>
            }
            key="1"
          >
            <ClearanceNewRequestTable />
          </TabPane>
          <TabPane
            tab={
              <Badge
                offset={[5, -6]}
                count={
                  <span
                    style={{ backgroundColor: "blue" }}
                    className="Span-reqest"
                  >
                    {
                      clearanceRequestData.filter(
                        (el) => el.status === "underprocess"
                      ).length
                    }
                  </span>
                }
              >
                <span>Under Process Request</span>
              </Badge>
            }
            key="2"
          >
            <ClearanceUnderProcessRequest />
          </TabPane>
          <TabPane
            tab={
              <Badge
                offset={[5, -6]}
                count={
                  <span
                    style={{ backgroundColor: "brown" }}
                    className="Span-reqest"
                  >
                    {
                      clearanceRequestData.filter(
                        (el) => el.status === "finalizing"
                      ).length
                    }
                  </span>
                }
              >
                <span>Finalizing</span>
              </Badge>
            }
            key="3"
          >
            <ClearanceFinalizingRequestTable />
          </TabPane>
          <TabPane
            tab={
              <Badge
                offset={[5, -6]}
                count={
                  <span
                    style={{ backgroundColor: "#001529" }}
                    className="Span-reqest"
                  >
                    {
                      clearanceRequestData.filter(
                        (el) => el.status === "finance"
                      ).length
                    }
                  </span>
                }
              >
                <span>Finance</span>
              </Badge>
            }
            key="4"
          >
            <ClearanceFinanceRequestTable />
          </TabPane>
          <TabPane
            tab={
              <Badge
                offset={[5, -6]}
                count={
                  <span
                    style={{ backgroundColor: "#d06d2c" }}
                    className="Span-reqest"
                  >
                    {
                      clearanceRequestData.filter(
                        (el) => el.status === "complete"
                      ).length
                    }
                  </span>
                }
              >
                <span>Complete</span>
              </Badge>
            }
            key="5"
          >
            <ClearanceCompleteRequestTable />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default Clearance;
