import { gql } from "@apollo/client";

const GET_ALL_SUBADMIN = gql`
query AllSubAdmins($page: Int!) {
    allSubAdmins(page: $page) {
      currentPage
      subAdmins {
        _id
        email
        password
        permissions
        role
        status
        token
        userName
      }
      totalDocs
      totalPages
    }
  }
`;
const GET_SUBADMIN_ID = gql`
query Owner($id: ID!) {
  owner(ID: $id) {
    _id
    bankInfo {
      iBanNumber
      beneficiaryName
      bankIdCode
    }
    crNumber
    crNumberUpload
    createdAt
    email
    emailVerified
    idNumber
    idNumberUpload
    isAccept
    isApproved
    isDeleted
    name
    ownerName
    phoneNumber
    profileImage
    rRA
    token
    type
  }
}
`;
const GET_SUBADMIN_EMAIL = gql`
query OwnerEmail($email: String) {
  ownerEmail(email: $email) {
    _id
    bankInfo {
      iBanNumber
      beneficiaryName
      bankIdCode
    }
    crNumber
    crNumberUpload
    createdAt
    email
    emailVerified
    idNumber
    idNumberUpload
    isAccept
    isApproved
    isDeleted
    name
    ownerName
    phoneNumber
    profileImage
    rRA
    token
    type
  }
}
`;

export { GET_SUBADMIN_ID, GET_ALL_SUBADMIN, GET_SUBADMIN_EMAIL };
