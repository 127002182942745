import React from 'react'
import AllDriverTable from '../Tables/AllDriversTables'

export default function Drivers() {
  return (
      <div>
          <AllDriverTable/>
    </div>
  )
}
