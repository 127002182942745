import React, { useEffect, useState } from 'react'
import { collection, query, onSnapshot } from "firebase/firestore";
import { db } from '../firebase'


const useGetAllClearanceRequest = () => {
    const [clearanceRequestData, setClearanceRequestData] = useState([])

    useEffect(() => {
        const q = query(collection(db, "clearance_request"));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const arr = [];
            querySnapshot.forEach((doc) => {
                arr.push(doc.data());
            });
            setClearanceRequestData(arr)
        });

        return () => unsubscribe()

    }, [])
    return { clearanceRequestData }
}

export default useGetAllClearanceRequest
