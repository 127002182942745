import { InputNumber, message, Modal, Select } from "antd";
import React from "react";
import { Button, Form, Input } from "antd";
import { collection, doc, setDoc } from "firebase/firestore";
import { db } from "../firebase";
import { useTranslation } from "react-i18next";

const MaintenanceModal = ({ isModalOpen, setIsModalOpen }) => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const { t } = useTranslation();

  const handleOk = () => {
    setIsModalOpen(!isModalOpen);
  };
  const handleCancel = () => {
    setIsModalOpen(!isModalOpen);
  };

  const onFinish = async (values) => {
    const { service, serviceamount, serviceradius } = values;
    try {
      const docRef = doc(collection(db, "maintenance-services"));
      let id = docRef.id;

      await setDoc(docRef, {
        uid: id,
        service,
        serviceamount,
        serviceradius: serviceradius * 1,
      });
      message.success("added");
      form.setFieldsValue({ service: "" });
      form.setFieldsValue({ serviceamount: "" });
      form.setFieldsValue({ serviceradius: "" });
    } catch (error) {
      alert(error.message);
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div>
      <Modal
        title={t("createservice")}
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >
        <Form
          form={form}
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            // label="Service"
            name="service"
            rules={[
              {
                required: true,
                message: "add  service",
              },
            ]}
          >
            <Input placeholder="service-name" />
          </Form.Item>

          <Form.Item
            // label="Amount"
            name="serviceamount"
            rules={[
              {
                required: true,
                message: "add amount",
              },
            ]}
          >
            <InputNumber placeholder="total amount" style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            // label="ServiceRedius"
            name="serviceradius"
            rules={[
              {
                required: true,
                message: "add amount",
              },
            ]}
          >
            <Select placeholder="service-radius">
              <Option key="1" value="100">
                100Km
              </Option>
              <Option key="2" value="200">
                200Km
              </Option>
              <Option key="3" value="300">
                300Km
              </Option>
            </Select>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              {t("create")}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default MaintenanceModal;
