import React, { useEffect, useState } from "react";
import { collection, query, onSnapshot, orderBy } from "firebase/firestore";
import { db } from "../firebase";
export default function useNotifications() {
  const [alrt, setAlert] = useState(false);
  const [alrtNotifications, setAlertNotifications] = useState([]);
  const [notifications, setNotifications] = useState([]);

  const getData = async () => {
    let q = query(
      collection(db, "notifications"),orderBy('createdAt','desc')
      // where("to", "array-contains", 'admin'),
    );
    const unsub = onSnapshot(q, (querySnapShot) => {
      if (!querySnapShot.empty) {
        setAlertNotifications([]);
        setNotifications([]);
        querySnapShot.forEach((val) => {
          setNotifications((prve) => [...prve, val.data()]);
        });

        querySnapShot.docChanges().forEach((change) => {
          if (change.type === "added") {
            if (change.doc.metadata.hasPendingWrites) {
              setAlertNotifications((prve) => [...prve, change.doc.data()]);
              setAlert(true);
            } else {
              setAlert(false);
            }
          }

          //    if (change.type === "modified") {
          //      console.log("Modified city: ", change.doc.data());
          //    }
          //    if (change.type === "removed") {
          //      console.log("Removed city: ", change.doc.data());
          //    }
        });
      }
    });

    return () => unsub();
  };
  useEffect(() => {
    getData();
  }, []);
  return { alrt, alrtNotifications, notifications };
}
