
import React, { useState } from "react";
import { Table, Input, Space, Popconfirm, Tag } from "antd";
import "../Tables/table.css";
import {
  SearchOutlined,
} from "@ant-design/icons";
import { MdOutlineRestore } from "react-icons/md";
import AddDriverModal from "../Modals/AddDriverModal";
import EditDriverModal from "../Modals/EditDriverModal";
import useDrivers from "../Hooks/useDrivers";
import useAllDrivers from "../Hooks/useAllDrivers";
import { RestoreDriver } from "../API/API";
import { useTranslation } from "react-i18next";

const DriverArchive = () => {

    const [search, setSearch] = useState("");
    const { allDrivers } = useAllDrivers();
  
    const filteredDriver = allDrivers.filter((ele => ele.isDeleted == false))
    console.log("+++++++++++",filteredDriver)
  
      const { t } = useTranslation();
     
      const columns = [
        {
          title: t("tableTabs.ownerName"),
          render: (record) => <span>{record?.ownerName}</span>,
        },
        {
          title: t(" Driver Name"),
          dataIndex: "driverName",
          key: "driverName",
        },
        {
          title: t("tableTabs.age"),
          dataIndex: "driverAge",
          key: "driverAge",
        },
        {
          title: t("tableTabs.nationality"),
          dataIndex: "driverNationality",
          key: "driverNationality",
        },
        {
          title: t("tableTabs.address"),
          dataIndex: "driverAddress",
          key: "driverAddress",
        },
        {
          title: t("tableTabs.idNo"),
          dataIndex: "driverIdNumber",
          key: "driverIdNumber",
        },
        {
          title: t("tableTabs.merged"),
          render: (record) => (
            <span>
              <Tag color={record.isTruck ? "green" : "pink"}>
                {record.isTruck ? "Yes" : "No"}
              </Tag>
            </span>
          ),
        },
        {
          title: t("tableTabs.createdAt"),
          render: (record) => (
            <span>
              {new Date(record?.createdAt?.seconds * 1000).toLocaleString("en-US", {
                month: "short",
                day: "2-digit",
                year: "2-digit",
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              })}
            </span>
          ),
        },
        {
          title: t("tableTabs.action"),
    
          dataIndex: "",
          key: "x",
          align: "center",
          render: (record) => (
            <Space>
            
              <Popconfirm
                title={"Are you sure you want to restore?"}
                okText="Ok"
                cancelText="Cancel"
                onConfirm={() => {
                  RestoreDriver (record?.uid, record)
                }}
              >
                <span className="ant-btn ant-btn-danger">
                  <MdOutlineRestore />
                </span>
              </Popconfirm>
            </Space>
          ),
        },
      ];


  return (
    
    <div>
       
        <div className="table_search_div">
          <Input
            allowClear
            size="middle"
            placeholder={t("placeholders.search")}
            prefix={<SearchOutlined />}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </div>
        <Table
          columns={columns}
          dataSource={filteredDriver.filter((val) => {
            if (search == "") {
              return val;
            } else if (
              val &&
              Object.keys(val).some((v) =>
                val[v]
                  .toString()
                  .toLowerCase()
                  .includes(search.toString().toLowerCase())
              )
            ) {
              return val;
            }
            
          })}
        />
      </div>
  )
}

export default DriverArchive