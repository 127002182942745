import React, { useEffect, useState } from "react";
import { Table, Button, Input, Space, Popconfirm, Tag, Pagination, message } from "antd";
import "./table.css";
import {
  PlusCircleOutlined,
  SearchOutlined,
  UploadOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import AddDriverModal from "../Modals/AddDriverModal";
import EditDriverModal from "../Modals/EditDriverModal";
import useDrivers from "../Hooks/useDrivers";
import useAllDrivers from "../Hooks/useAllDrivers";
import { DeleteDriver } from "../API/API";
import { useTranslation } from "react-i18next";
import useGetMyDrivers from "../Hooks/useDrivers";
import { DELETE_DRIVER } from "../../Graphql/mutation/driverMutation";
import { useMutation } from "@apollo/client";
import Loader from "../Loaders/Loader";

export default function AllDriverTable() {
  // const { drivers } = useGetMyDrivers();
  // const { allDrivers } = useAllDrivers();
  const [search, setSearch] = useState("");
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [driver, setDriver] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [paginationFilters, setPaginationFilters] = useState({
    pageNo: 1,
    pageSize: 5,
    limitSkip: 0,
    limit: 10,
  });
  let ownerId = "";
  const { drivers} =   useGetMyDrivers();
  console.log("🚀 ~ file: AllDriversTables.jsx:37 ~ AllDriverTable ~ drivers:", drivers);
  // setDrivers(drivers);
  const [deleteDriver] = useMutation(DELETE_DRIVER);
  const handleChange = (value, pageSize) => {
    const limitSkip = value * pageSize;
    const limit = pageSize;
    setPaginationFilters({ pageNo: value, pageSize, limitSkip, limit });
    setLoading(true);
    setUpdateTrigger(!updateTrigger);
  };
  // const filteredDriver = drivers?.filter((ele => ele.status == active))
  // console.log("+++++++++++",filteredDriver)

  const showModal = () => {
    setIsModalVisible(!isModalVisible);
  };
  const showEditModal = () => {
    setIsEditModalVisible(!isEditModalVisible);
  };
  const confirm = async (driver, action) => {
    debugger;
    setUpdateTrigger(false);
    try {
      const { data } = await deleteDriver({
        variables: { id: driver._id, isArchive:true },
      });
      // Handle the response data if needed
      console.log(data);
      // Remove the deleted user from the list
      // setDrivers((prevUsers) =>
      //   prevUsers.filter((user) => user._id !== driver._id)
      // );
      // Update the total count of users
      // setCount(count - 1);
      message.success("Cutomer Deleted Successfully");
      setUpdateTrigger(true);
    } catch (error) {
      // Handle the error if needed
      console.log(error);
    }
  };
  const columns = [
    {
      title: t("tableTabs.ownerName"),
      render: (record) => <span>{record?.owner?._id}</span>,
    },
    {
      title: t(" Driver Name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("tableTabs.age"),
      dataIndex: "age",
      key: "age",
    },
    {
      title: t("tableTabs.nationality"),
      dataIndex: "nationality",
      key: "nationality",
    },
    {
      title: t("tableTabs.address"),
      dataIndex: "driverAddress",
      key: "driverAddress",
    },
    {
      title: t("tableTabs.idNo"),
      dataIndex: "idNo",
      key: "idNo",
    },
    {
      title: t("tableTabs.merged"),
      render: (record) => (
        <span>
          <Tag color={record.isTruck ? "green" : "pink"}>
            {record.isTruck ? "Yes" : "No"}
          </Tag>
        </span>
      ),
    },
    {
      title: t("tableTabs.createdAt"),
      render: (record) => (
        <span>
          {/* {record.createdAt} */}
          {new Date(record?.createdAt?.seconds * 1000).toLocaleString("en-US", {
            month: "short",
            day: "2-digit",
            year: "2-digit",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })}
        </span>
      ),
    },
    {
      title: t("tableTabs.action"),

      dataIndex: "",
      key: "x",
      align: "center",
      render: (record) => (
        <Space>
          <span
            onClick={() => {
              setDriver(record);
              showEditModal();
            }}
            className="eyes-btn"
          >
            <EditOutlined />
          </span>
          <Popconfirm
            title={"Are you sure you want to delete?"}
            okText="Ok"
            cancelText="Cancel"
            onConfirm={() => confirm(record, "del")}
          >
            <span className="ant-btn ant-btn-danger">
              <DeleteOutlined />
            </span>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  useEffect(() => {
    // setDrivers(drivers);
    if (drivers.length > 0) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [drivers]);
  return (
    <div>
      {/* <AddDriverModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      /> */}
      <EditDriverModal
        isModalVisible={isEditModalVisible}
        setIsModalVisible={setIsEditModalVisible}
        data={driver}
      />

      {/* <div className="table_topar_add_div_">
        <Button
          type="light"
          icon={<PlusCircleOutlined />}
          size={"middle"}
          onClick={showModal}
        >
          {"Add Driver"}
        </Button>
      </div> */}
      <div className="table_search_div">
        <Input
          allowClear
          size="middle"
          placeholder={t("placeholders.search")}
          prefix={<SearchOutlined />}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      </div>
      {loading ? <Loader /> : ""}
      <Table
        columns={columns}
        pagination={false}
        dataSource={drivers.filter((val) => {
          if (search == "") {
            return val;
          } else if (
            val &&
            Object.keys(val).some((v) =>
              val[v]
                .toString()
                .toLowerCase()
                .includes(search.toString().toLowerCase())
            )
          ) {
            return val;
          }
          
        })}
      />
      {/* <Pagination
          defaultCurrent={1}
          defaultPageSize={paginationFilters.pageSize}
          onChange={handleChange}
          total={count}
          className="Pagination"
        /> */}
    </div>
  );
}
