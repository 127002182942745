import { useEffect, useState, useCallback } from "react"; // Import your GraphQL query for trucks
import { useQuery } from "@apollo/client";
import { GET_MY_DRIVER_TRUCK } from "../../Graphql/queries/driverTrucksQueries";
import { GET_ALL_SUBADMIN } from "../../Graphql/queries/subAdminQueries";

function useGetAllSubAdmin(trigger, paginationFilters) {
  console.log("🚀 ~ file: useTrucks.js:79 ~ useGetMyTrucks ~ paginationFilters:", paginationFilters)
  const [subadmin, setSubAdmin] = useState([]);
  const [count, setCount] = useState(0);

  const { loading, error, data, refetch } = useQuery(GET_ALL_SUBADMIN, {
    variables: { page: paginationFilters?.pageNo },
    fetchPolicy: "network-only",
  });

  const getAllSubAdmin = useCallback(async () => {
    if (loading) return;
    if (error) {
      console.log("Error:", error.message);
      return;
    }
    const { allSubAdmins } = data;
    if (!allSubAdmins) {
      console.log("No Owner data available.");
      return;
    }
    const { subAdmins, totalDocs } = allSubAdmins;
    setSubAdmin(subAdmins);
    setCount(totalDocs);
  }, [loading, error, data]);

  useEffect(() => {
    getAllSubAdmin();
    if (trigger) {
      refetch({ page: 1 });
    }
  }, [trigger, getAllSubAdmin, 1, refetch]);

  return { loading, error, subadmin, count };
}

export default useGetAllSubAdmin;