import React, { useEffect, useState } from "react";
import { Button, Descriptions, Image, Space } from "antd";
import useGetSingleClearanceRequest from "../Hooks/useGetSingleClearanceRequest";
import { useNavigate, useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import { useTranslation } from "react-i18next";

const ClearanceDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { singleRequest } = useGetSingleClearanceRequest(id);

  return (
    <>
      <div className="upper_btn_div car-img">
        <Space>
          <Button
            type="light"
            onClick={() => {
              // navigate(-1);
              window.history.back();
            }}
          >
            {t("tableTabs.back")}
          </Button>
        </Space>
      </div>
      <Descriptions bordered layout="vertical" title="Clearance Details">
        {singleRequest?.attachments?.map((attachment, index) => (
        <Descriptions.Item label="Attachment">
          <Image
            key={index}
            width={250}
            height={150}
            src={attachment}
            alt={`Attachment ${index}`}
          />
        </Descriptions.Item>
        ))}
        <Descriptions.Item label="Pill Of Lading">
          {singleRequest?.pilloflading}
        </Descriptions.Item>
        {/* <Descriptions.Item label="Saber">
          {singleRequest?.saber}
        </Descriptions.Item>
        <Descriptions.Item label="Invoice">
          {singleRequest?.invoice}
        </Descriptions.Item>
        <Descriptions.Item label="SFDA">
          {singleRequest?.sfda}
        </Descriptions.Item>
        <Descriptions.Item label="DO">{singleRequest?.DO}</Descriptions.Item> */}
        <Descriptions.Item label="status">
          {singleRequest?.status}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default ClearanceDetails;
