import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
} from "firebase/firestore";
import { DeleteOutlined,SendOutlined,EyeOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import {
  Button,
  Checkbox,
  Col,
  Form,
  message,
  Input,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Pagination,
} from "antd";
import React from "react";
import useOwners from "../Hooks/useOwners";
import useCustomers from "../Hooks/useCustomers";
import { db } from "../firebase";
import { useEffect } from "react";
import { useState } from "react";
import useCombinations from "../Hooks/useCombinations";
import useCapacites from "../Hooks/useCapacities";
import useCities from "../Hooks/useCities";
import useHeights from "../Hooks/useHeights";
import useTypes from "../Hooks/useTypes";
import { useNavigate } from "react-router-dom";
import { IoEyeOutline } from "react-icons/io5";
import { useMutation, useQuery } from "@apollo/client";
import { CUSTOMERS } from "../../Graphql/queries/customerQueries";
import { OWNERS } from "../../Graphql/queries/adminQueries";
import { RELATION_CREATE, RELATION_DELETE } from "../../Graphql/mutation/relationMutation";
import useGetAllRelation from "../Hooks/useRelation";

const Relations = () => {
  const [form] = Form.useForm();
  // const { owners } = useOwners();
  // const {combinations} = useCombinations()
  // const { customers } = useCustomers();
  const [loading, setLoading] = useState(false);

  const { cities } = useCities();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [paginationFilters, setPaginationFilters] = useState({
    pageNo: 1,
    pageSize: 5,
    limitSkip: 0,
    limit: 10,
  });
  const { relation, count } = useGetAllRelation(
    updateTrigger,
    paginationFilters
  );
  const handleChange = (value, pageSize) => {
    const limitSkip = value * pageSize;
    const limit = pageSize;
    setPaginationFilters({ pageNo: value, pageSize, limitSkip, limit });
    setLoading(true);
    setUpdateTrigger(!updateTrigger);
  };
  // const [getData, setGetData] = useState([]);
  const [createRelation] = useMutation(RELATION_CREATE);
  const [deleteRelation] = useMutation(RELATION_DELETE);
  const [deleteRecord, setDeleteRecord] = useState("");
  const { data: Customer } = useQuery(CUSTOMERS);
  const { data: Owner } = useQuery(OWNERS);
  const customers = Customer?.customers || [];
  const owners = Owner?.owners || [];
  const { Option } = Select;
  const handleSend = async (values) => {
    debugger;
    let customerObj = customers.find((val) => val._id == values.customer);
    // let customerId = customerObj._id;

    let ownersObj = owners.filter((val) => values.owners.includes(val._id));
let finalizeOwners = ownersObj.map((val) => val._id);
    let finalData = {
      customerId: customerObj._id,
      customerName: customerObj.name,
      owners: finalizeOwners,
    };
    console.log("🚀 ~ file: Relations.jsx:73 ~ handleSend ~ finalData:", finalData)

    try {
      const relationInput = {
        admin:"64f5b4c14091bd9d3c2fdaa2",
        customer:finalData.customerId,
        customerName:finalData.customerName,
        owner:finalData.owners
      }
      // let ref = doc(db, "relations", customerId);
      const { data } = await createRelation({
        variables: { relationInput },
      });
      console.log("🚀 ~ file: Relations.jsx:83 ~ handleSend ~ data:", data)
      message.success("added successfully");
      setUpdateTrigger(true);
      form.resetFields();
    } catch (error) {
      message.error(error.message);
    }
  };

  // -------- getting data ------------
  // useEffect(() => {
    // const getAdmins = async () => {
    //   let q = query(collection(db, "relations"));
    //   const unsub = onSnapshot(q, (querySnapShot) => {
    //     if (!querySnapShot.empty) {
    //       let arr = [];
    //       querySnapShot.forEach((val) => {
    //         arr.push(val.data());
    //         if (arr.length === querySnapShot.size) {
    //           setGetData([...arr]);
    //         }
    //       });
    //     }
    //   });
    //   return () => unsub();
    // };
    // getAdmins();
  // }, [Customer, Dta]);

  //    ---------- delete handler --------
  const deleteHandler = async () => {
    console.log(deleteRecord.customerId);
    try {
      await deleteDoc(doc(db, "relations", deleteRecord.customerId));
      message.destroy("deleted!");
    } catch (error) {
      message.error(error.message);
    }
  };

  const columns = [
    {
      title: "customerName",
      // dataIndex: "customerName",
      key: "name",
      render: (record) => (
        <span>{record?.customer?.name}</span>
      ),
    },
    {
      title: "owners",
      key: "owners",
      render: (record) => (
        <span>{record?.owner?.map((item) => item.name + ", ")}</span>
      ),
    },
    {
      title: "Add Combinations",
      key: "combination",
      render: (record) => {
        return (
          <Space>
           <span className="ant-btn " onClick={()=> {navigate(`/relations/combination/${record._id}`)}}>
              <SendOutlined style={{ color: "red" }} />
            </span>
          </Space>
        );
      },
      
    },
    {
      title: "View Combinations",
      key: "combination",
      render: (record) => {
        return (
          <Space>
           <span className="eyes-btn " onClick={()=> {navigate(`/relations/viewcombination/${record._id}`)}}>
           <EyeOutlined />
            </span>
          </Space>
        );
      },
      
    },
    {
      title: "Delete",
      render: (record) => {
        setDeleteRecord(record);
        return (
          <Space>
            <Popconfirm
              title={"Are you sure you want to delete?"}
              okText="Ok"
              cancelText="Cancel"
              onConfirm={() => {
                deleteHandler();
              }}
            >
              <span className="ant-btn ant-btn-danger">
                <DeleteOutlined />
              </span>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];
  return (
    <div>
      <h1 style={{ textAlign: "center" }}>Relations:</h1>
      <Form
        layout="vertical"
        className="push-notification-form"
        onFinish={handleSend}
        form={form}
      >
        <Row gutter={16} justify="space-between">
          <Col span={12}>
            <Form.Item
              name="customer"
              rules={[
                {
                  required: true,
                  message: "Customer is required",
                },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                showSearch
                placeholder="Select a Customer"
              >
                {customers
                  // .filter((item) => item.isRelation)
                  .map((item) => {
                    return <Option value={item._id}>{item.name}</Option>;
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="owners"
              rules={[
                {
                  required: true,
                  message: "Owners is required",
                },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                showSearch
                mode="multiple"
                placeholder="Select a Owners"
              >
                {owners.map((item) => {
                  return (
                    <Option value={item._id} key={item._id}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      
        <Row>
          <Col span={3}>
            <Form.Item>
              <Button htmlType="submit" className="btn-primary" type="primary">
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Table pagination={false} dataSource={relation} columns={columns} />
      <Pagination
        defaultCurrent={1}
        defaultPageSize={paginationFilters.pageSize}
        onChange={handleChange}
        total={count}
        className="Pagination"
      />
    </div>
  );
};

export default Relations;
