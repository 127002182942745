import React, { Children, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Input, Popconfirm, Space, Tag, Button } from "antd";

import {
  SearchOutlined,
  DeleteOutlined,
  SendOutlined,
} from "@ant-design/icons";

import useFinance from "../Hooks/useFinance";
import useGetAllClearanceRequest from "../Hooks/useGetAllClearanceRequest";
import { updateFinance } from "../API/API";
import { useTranslation } from "react-i18next";
import { render } from "@testing-library/react";
const style = {
  margin: "0px",
};
export default function FinanceTable() {
  const { finance } = useFinance();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const { t } = useTranslation();
  const { clearanceRequestData } = useGetAllClearanceRequest();
  console.log("🚀 ~ file: FinanceTable.jsx:25 ~ FinanceTable ~ clearanceRequestData:", clearanceRequestData)

  const combineArray = [
    ...finance,
    ...clearanceRequestData.filter((el) => el.status === "complete"),
  ];
  console.log("------>", combineArray);

  const columns = [
    {
      title: t("tableTabs.id"),
      render: (record) => (
        <span title={record?.requestId}>
          {record.status === "complete" ? "N/A" : record?.requestId}
        </span>
      ),
    },
    // {
    //   title: t("tableTabs.customerName"),
    //   render: (recored) => <span>{recored.customer?.name}</span>,
    // },

    // {
    //   title: t("tableTabs.ownerName"),
    //   render: (recored) => <span>{recored.owner?.name}</span>,
    // },
    // {
    //   title: t("tableTabs.phoneNumber"),
    //   render: (recored) => <span>{recored.owner?.phoneNumber}</span>,
    // },
    // {
    //   title: t("tableTabs.from"),
    //   render: (recored) => <span>{recored.from}</span>,
    // },
    // {
    //   title: t("tableTabs.to"),
    //   render: (recored) => <span>{recored.to}</span>,
    // },
    {
      title: t("tableTabs.fare"),
      render: (recored) => (
        <span>{recored.status === "complete" ? "N/A" : recored.fare}</span>
      ),
    },
    {
      title: t("tableTabs.name"),
      render: (record) => (
        <div>
          {record.status === "complete" ? (
            "N/A"
          ) : (
            <>
              <p style={style}>
                <b>Cutomer: </b>
                {record.customer?.name}
              </p>
              <p style={style}>
                <b>Owner: </b> &nbsp;&nbsp;&nbsp;{record.owner?.name || "N/A"}
              </p>
            </>
          )}
        </div>
      ),
    },

    // {
    //   title: "CreatedAt",
    //   render: (record) => (
    //     <span>
    //       {new Date(record?.createdAt?.seconds * 1000).toLocaleString("en-US", {
    //         month: "short",
    //         day: "2-digit",
    //         year: "2-digit",
    //         hour: "numeric",
    //         minute: "numeric",
    //         hour12: true,
    //       })}
    //     </span>
    //   ),
    // },

    {
      title: "Payment Status",
      render: (record) => {
        return (
          <div>
            {record.status === "complete" ? (
              "N/A"
            ) : (
              <>
                <div>
                  Received:&nbsp;
                  <Tag
                    key={record.uid}
                    color={
                      record?.receivedFromCustomer !== undefined &&
                      record?.receivedFromCustomer
                        ? "green"
                        : "pink"
                    }
                  >
                    {record?.receivedFromCustomer !== undefined &&
                    record?.receivedFromCustomer
                      ? "Yes"
                      : "No"}
                  </Tag>
                </div>
                <div style={{ marginTop: "5px" }}>
                  Sended:&emsp;
                  <Tag
                    key={record.uid}
                    color={
                      record?.sendToOwner !== undefined && record?.sendToOwner
                        ? "green"
                        : "pink"
                    }
                  >
                    {record?.sendToOwner !== undefined && record?.sendToOwner
                      ? "Yes"
                      : "No"}
                  </Tag>
                </div>
              </>
            )}
          </div>
        );
      },
    },
    {
      title: t("tableTabs.phoneNumber"),
      render: (recored) => (
        <div>
          {recored.status === "complete" ? (
            "N/A"
          ) : (
            <>
              <div>{recored.customer?.phoneNumber}</div>
              <div>{recored.owner?.phoneNumber || "N/A"}</div>
            </>
          )}
        </div>
      ),
    },
    // {
    //   title: t("tableTabs.received"),
    //   render: (record) => (
    //     <Tag
    //       key={record.uid}
    //       color={
    //         record?.receivedFromCustomer !== undefined &&
    //         record?.receivedFromCustomer
    //           ? "green"
    //           : "pink"
    //       }
    //     >
    //       {record?.receivedFromCustomer !== undefined &&
    //       record?.receivedFromCustomer
    //         ? "Yes"
    //         : "No"}
    //     </Tag>
    //   ),
    // },
    // {
    //   title: t("tableTabs.sended"),
    //   render: (record) => (
    //     <Tag
    //       key={record.uid}
    //       color={
    //         record?.sendToOwner !== undefined && record?.sendToOwner
    //           ? "green"
    //           : "pink"
    //       }
    //     >
    //       {record?.sendToOwner !== undefined && record?.sendToOwner
    //         ? "Yes"
    //         : "No"}
    //     </Tag>
    //   ),
    // },

    {
      title: t("tableTabs.viewDetails"),
      dataIndex: "",
      key: "x",
      align: "center",
      render: (record) => (
        <Space>
          {/* {record.sendToOwner ? (
            "Paid"
          ) : (
            <Popconfirm
              title={"Are you sure?"}
              okText="Ok"
              cancelText="Cancel"
              onConfirm={() => {
                let data = { sendToOwner: true };
                updateFinance(record.uid, data);
              }}
            > */}
          {record.status === "complete" ? (
            "N/A"
          ) : (
            <span
              className="eyes-btn "
              title="send Request"
              onClick={() => navigate(`/finance-details/${record?.uid}`)}
            >
              <SendOutlined />
            </span>
          )}
          {/* </Popconfirm>
          )} */}

          {/* <Popconfirm
            title={"Are you sure?"}
            okText="Ok"
            cancelText="Cancel"
            onConfirm={() => {
              DeleteRequest(record?.uid, record);
            }}
          >
            <span className="ant-btn ant-btn-danger">
              <DeleteOutlined />
            </span>
          </Popconfirm> */}
        </Space>
      ),
    },
    {
      title: "Send Payment",
      render: (record) => (
        <div>
          {record.status === "complete" ? (
            "N/A"
          ) : (
            <>
              {record.owner?.name ? (
                record.owner?.bankInfo ? (
                  record?.sendToOwner ? (
                    <Tag color="green">Already Paid</Tag>
                  ) : (
                    <Popconfirm
                      title={"Are you sure you want to send?"}
                      okText="Ok"
                      cancelText="Cancel"
                      onConfirm={() => {
                        let data = { sendToOwner: true };
                        updateFinance(record?.uid, data);
                      }}
                    >
                      <Button>Send</Button>
                    </Popconfirm>
                  )
                ) : (
                  "Owner Bank Details Not Found"
                )
              ) : (
                "Owner N/A"
              )}
            </>
          )}
        </div>
      ),
    },

    {
      title: "C_Customer Name",
      render: (record) => {
        return (
          <>
            <span
              style={{
                display: "flex",
                gap: ".5rem",
                alignItems: "center",
              }}
            >
              {record.status === "complete" ? (
                <>
                  <small
                    style={{
                      background: "#ddd",
                      fontSize: ".7rem",
                      position: "absolute",
                      top: "10%",
                      padding: "0rem .1rem",
                      left: "-25%",
                    }}
                  >
                    Clearance
                  </small>
                  <span>{record.cname}</span>
                </>
              ) : (
                "N/A"
              )}
            </span>
          </>
        );
      },
    },
    {
      title: "Clearance Status",
      render: (record) => {
        return (
          <>
            <span>{record.status === "complete" ? record.status : "N/A"}</span>
          </>
        );
      },
    },
  ];
  return (
    <div>
      <div className="table_search_div">
        <Input
          allowClear
          size="middle"
          placeholder={t("placeholders.search")}
          prefix={<SearchOutlined />}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      </div>
      <Table
        // scroll={{ x: "auto" }}
        columns={columns}
        dataSource={
          combineArray
          // finance &&
          // finance.length > 0 &&
          // finance.filter((val) => {
          //   if (search == "") {
          //     return val;
          //   } else if (
          //     val &&
          //     Object.keys(val).some((v) =>
          //       typeof val[v] === "object"
          //         ? Object.keys(val[v]).some((nv) =>
          //             val[v][nv]
          //               .toString()
          //               .toLowerCase()
          //               .includes(search.toString().toLowerCase())
          //           )
          //         : val[v]
          //             .toString()
          //             .toLowerCase()
          //             .includes(search.toString().toLowerCase())
          //     )
          //   ) {
          //     return val;
          //   }
          // })
        }
      />
    </div>
  );
}