import { useState, useEffect } from "react";
import {  message, Space, Popconfirm, Table } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

import {  db } from "../firebase";
import {
  collection,
  setDoc,
  doc,
  query,
  onSnapshot,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import { MdOutlineRestore } from "react-icons/md";

const AdminArchive = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [permission, setPermission] = useState(null);
  const permissionFiltered = data.filter((ele => ele.isPermission == false))

  const deletePermission = async (record) => {
    try {
      await updateDoc(doc(db, "admin", record.uid),{
        isPermission : true
      });
      message.destroy("Restored!");
    } catch (error) {
      message.error(error.message);
    }
  };
  useEffect(() => {
    const getAdmins = async () => {
      let q = query(collection(db, "admin"));
      const unsub = onSnapshot(q, (querySnapShot) => {
        if (!querySnapShot.empty) {
          let arr = [];
          querySnapShot.forEach((val) => {
            arr.push(val.data());
            if (arr.length === querySnapShot.size) {
              setData([...arr]);
            }
          });
        }
      });

      return () => unsub();
    };
    getAdmins();
  }, [loading]);



  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Permissions",
      key: "permissions",
      render: (record) => <span>{record?.permissions?.join(", ")}</span>,
    },
    {
      title: "Actioons",
      render: (record) => {
        return (
          <Space>
            <Popconfirm
              title={"Are you sure you want to Restore?"}
              okText="Ok"
              cancelText="Cancel"
              onConfirm={() => {
                deletePermission(record);
              }}
            >
              <span className="ant-btn ant-btn-danger">
                <MdOutlineRestore />
              </span>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];
  return (
    <div>
      <Table
        dataSource={permissionFiltered.filter((val) => val.email !== "admin@gmail.com")}
        columns={columns}
      />
    </div>
  );
};

export default AdminArchive;
