import React, { Children, useState } from "react";
import { Table, Input, Popconfirm, Space, Button, Pagination, message } from "antd";
import {
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import useCombinations from "../Hooks/useCombinations";
import MakeCombinationModal from "../Modals/MakeCombinationModal";
import { deleteCombination } from "../API/API";
import { useTranslation } from "react-i18next";
import UpdateCombination from "../Modals/UpdateCombination";
import useGetAllCombination from "../Hooks/useCombinations";
import { COMBINATION_DELETE } from "../../Graphql/mutation/combinationMutation";
import { useMutation } from "@apollo/client";

export default function CombinationsTable() {
  // const { combinations } = useCombinations();
  const [search, setSearch] = useState("");
  const [id, setId] = useState("");
  // const [formData, setFormData] = useState({});
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [loading, setLoading] = useState(false);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [paginationFilters, setPaginationFilters] = useState({
    pageNo: 1,
    pageSize: 5,
    limitSkip: 0,
    limit: 10,
  });
  let ownerId = "";
  const { combinations, count} = useGetAllCombination(updateTrigger, paginationFilters);
  const [deleteCombination] = useMutation(COMBINATION_DELETE);

  // const combinations = combination;
  const handleChange = (value, pageSize) => {
    const limitSkip = value * pageSize;
    const limit = pageSize;
    setPaginationFilters({ pageNo: value, pageSize, limitSkip, limit });
    setLoading(true);
    setUpdateTrigger(!updateTrigger);
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
  const { t } = useTranslation();
  const showModal = () => {
    setIsModalVisible(!isModalVisible);
  };
  const showUpdateModal = () => {
    setIsUpdateModalVisible(!isUpdateModalVisible);
  };
  const confirm = async (combination, action) => {
    debugger;
    setUpdateTrigger(false);
    try {
      const { data } = await deleteCombination({
        variables: { id: combination?._id },
      });
      // Handle the response data if needed
      console.log(data);
      // Remove the deleted user from the list
      // setDrivers((prevUsers) =>
      //   prevUsers.filter((user) => user._id !== driver._id)
      // );
      // Update the total count of users
      // setCount(count - 1);
      message.success("Cutomer Deleted Successfully");
      setUpdateTrigger(true);
    } catch (error) {
      // Handle the error if needed
      console.log(error);
    }
  };
  const columns = [
    {
      title: t("tableTabs.from"),
      dataIndex: "from",
      key: "from",
      // render: (from) => from.city
    },
    {
      title: t("tableTabs.to"),
      dataIndex: "to",
      key: "to",
    },
    {
      title: t("tableTabs.distance"),
      dataIndex: "distance",
      key: "distance",
    },
    {
      title: t("tableTabs.price"),
      dataIndex: "price",
      key: "price",
    },
    {
      title: t("tableTabs.time"),
      dataIndex: "time",
      key: "time",
    },

    {
      title: t("tableTabs.type"),
      dataIndex: "type",
      key: "type",
    },
    {
      title: t("tableTabs.capacities"),
      dataIndex: "capacity",
      key: "capacity",
    },
    {
      title: t("tableTabs.hight"),
      dataIndex: "height",
      key: "height",
    },
    {
      title: t("placeholders.commission"),
      dataIndex: "commission",
      key: "commision",
    },
    {
      title: "VAT",
      dataIndex: "vat",
      key: "vat",
    },

    {
      title: t("tableTabs.action"),
      dataIndex: "",
      key: "x",
      align: "center",
      render: (record) => (
        <Space>
          <span
            className="eyes-btn"
            onClick={() => {
              setSelectedRecord(record);
              showUpdateModal();
            }}
          >
            <EditOutlined />
          </span>
          <Popconfirm
            title={"Are you sure you want to delete?"}
            okText="Ok"
            cancelText="Cancel"
            onConfirm={() => confirm(record, "del")}
          >
            <span className="ant-btn ant-btn-danger">
              <DeleteOutlined />
            </span>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  return (
    <div>
      <MakeCombinationModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
      <UpdateCombination
        isUpdateModalVisible={isUpdateModalVisible}
        setIsUpdateModalVisible={setIsUpdateModalVisible}
        record={selectedRecord}
      />

      <div className="table_topar_add_div_">
        <Button
          className="login-btn"
          // shape="circle"
          icon={<PlusCircleOutlined />}
          size={"middle"}
          onClick={showModal}
        >
          {t("tableTabs.makeNewCombination")}
        </Button>
      </div>
      <div className="table_search_div">
        <Input
          allowClear
          size="middle"
          placeholder={t("placeholders.search")}
          prefix={<SearchOutlined />}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      </div>
      <Table
      pagination={false}
        columns={columns}
        dataSource={
          combinations &&
          combinations.length > 0 &&
          combinations.filter((val) => {
            if (search == "") {
              return val;
            } else if (
              val &&
              Object.keys(val).some((v) =>
                val[v]
                  .toString()
                  .toLowerCase()
                  .includes(search.toString().toLowerCase())
              )
            ) {
              return val;
            }
          })
        }
      />
      <Pagination
          defaultCurrent={1}
          defaultPageSize={paginationFilters.pageSize}
          onChange={handleChange}
          total={count}
          className="Pagination"
        />
    </div>
  );
}
