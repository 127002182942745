import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
  useJsApiLoader,
  DirectionsRenderer,
  MarkerF,
} from "@react-google-maps/api";
import Loader from "../Loaders/Loader";
import useActiveRideLocation from "../Hooks/useActiveRideLocation";
import { Space } from "antd";

const mapStyles = {
  height: "50vh",
  width: "100%",
};
{
  /*open AIzaSyABX4LTqTLQGg_b3jFOH8Z6_H5CDqn8tbc */
}
{
  /*wepyk AIzaSyA7EU6iqJ-NAtGUVS8OWch83JsIyhitScw */
}
{
  /*roadgain AIzaSyAi5vOUE0RojYx2MLwQMreG7K98uhVC4K4 */
}
{
  /*oac AIzaSyCzPTFBrd1yMXaumZld-Yj_Se4_6h9jiio */
}
{
  /* ScAvenger AIzaSyC51jUc4Ide-_gQFhS9d7bAlN5YZR3MECo */
}

export default function ActiveRideMap({ coordinates, id }) {
  const [currentPosition, setCurrentPosition] = useState({});
  const { activeRequest } = useActiveRideLocation(id);
  const [directions, setDirections] = useState(null);
  const [distance, setDistance] = useState(null);
  const [duration, setDuration] = useState(null);
  const [locName, setLocName] = useState(null);

  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyC51jUc4Ide-_gQFhS9d7bAlN5YZR3MECo",
  });

  const getDirection = () => {
    const google = window.google;
    const directionsService = new google.maps.DirectionsService();
    directionsService.route(
      {
        origin: new google.maps.LatLng(
          coordinates?.from.latitude,
          coordinates?.from.longitude
        ),
        destination: new google.maps.LatLng(
          coordinates?.from.latitude,
          coordinates?.from.longitude
        ),

        //  origin: {
        //    lat: coordinates?.from.latitude,
        //    lng: coordinates?.from.longitude,
        //  },
        //  destination: {
        //    lat: coordinates?.to.latitude,
        //    lng: coordinates?.to.longitude,
        //  },

        travelMode: google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          setDirections(result);
          setDistance(result.routes[0].legs[0].distance.value);
          setDuration(result.routes[0].legs[0].duration.value);
        } else {
          console.error("error fetching directions", result, status);
        }
      }
    );
  };
  const getLocationName = (currentPosition) => {
    if (Object.keys(currentPosition).length > 0) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode(
        { location: currentPosition },
        function (results, status) {
          if (status === window.google.maps.GeocoderStatus.OK) {
            if (results[0]) {
              setLocName(results[0].formatted_address);
            }
          }
        }
      );
    }
  };

  useEffect(() => {
    if (
      Object.keys(activeRequest).length > 0 &&
      activeRequest.driverLocation !== undefined &&
      isLoaded
    ) {
      let location = {
        lat: activeRequest?.driverLocation?.latitude,
        lng: activeRequest?.driverLocation?.longitude,
      };

      setCurrentPosition(location);
      getLocationName(location);
    }
  }, [JSON.stringify(activeRequest)]);

  function RenderMap() {
    const [map, setMap] = useState(null);
    const onLoad = React.useCallback(function callback(map) {
      const bounds = new window.google.maps.LatLngBounds(currentPosition);
      map.fitBounds(bounds);
      setMap(map);
    }, []);
    const onUnmount = React.useCallback(function callback(map) {
      setMap(null);
    }, []);
    return isLoaded && Object.keys(currentPosition).length > 0 ? (
      <>
        <Space>
          {/* <h6>Distance:{`  ${distance}`}</h6>
            <h6>Duration:{`  ${duration}`}</h6> */}
          <p>Current Location:{`  ${locName}`}</p>
        </Space>
        <GoogleMap
          mapContainerStyle={mapStyles}
          center={currentPosition}
          zoom={0}
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          <MarkerF
            title="Truck"
            position={currentPosition}
            animation="BOUNCE"
            icon={{
              url: "/assets/logo.png",
              scaledSize: { width: 50, height: 40 },
            }}
          />
          {/* { <MarkerF position={currentPosition} />} */}
          {/* {directions && (
              <DirectionsRenderer
                directions={directions}
                options={{
                  polylineOptions: {
                    stokeColor: "green",
                    strokeOpacity: 1,
                    strokeWeight: 6,
                  },
                  markerOptions: {
                    icon: {
                      url: "/assets/origin.png",
                      scaledSize: { width: 50, height: 40 },
                    },
                    // icon: {
                    //   url: "/destination.png",
                    //   scaledSize: { width: 50, height: 40 },
                    // },
                  },
                  icon: { scale: 2 },
                }}
              />
            )} */}
          <></>
        </GoogleMap>
      </>
    ) : (
      <>
        Driver start ride soon...
        {/* <Loader /> */}
      </>
    );
  }

  React.memo(RenderMap);

  return (
    <>
      <div>
        <h>Location</h>
        <div>
          <RenderMap />
        </div>
      </div>
    </>
  );
}
