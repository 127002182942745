import React, { useEffect, useState } from "react";
import {
  collection,
  query,
  onSnapshot,
  where,
  orderBy,
} from "firebase/firestore";
import { db } from "../firebase";
export default function useFormCancelEmail() {
  const [cancelForm, setCancelForm] = useState([]);
  const getCancelEmails = async () => {
    let q = query(collection(db, "form-cancel-emails"), orderBy("timestamp", "desc"));
    const unsub = onSnapshot(q, (querySnapShot) => {
      setCancelForm([]);
      if (!querySnapShot.empty) {
        let arr = [];
        querySnapShot.forEach((val) => {
          arr.push(val.data())
        });
        if (arr.length == querySnapShot.size) {
          setCancelForm([...arr])
        }
      }
    });

    return () => unsub();
  };
  useEffect(() => {
    getCancelEmails();
  }, []);

  return { cancelForm };
}
