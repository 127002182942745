import React from "react";
import { Tabs } from "antd";
import CustomerRequestTable from '../Tables/CustomerRequestTable'
import CustomerProfile from "./CustomerProfile";
import { useLocation, useParams } from "react-router-dom";

import { useTranslation } from "react-i18next";
export default function CustomerDetail() {
  
  const { state } = useLocation();
  const { id } = useParams();
  const { t } = useTranslation();
  const { TabPane } = Tabs;
  return (
    <div>
      <div>
        <h3>{t("routes.customerDetail")}</h3>
      </div>
      <div>
        <Tabs defaultActiveKey="1">
          <TabPane tab={t("tableTabs.profile")} key="1">
            <CustomerProfile customer={state.customerDetails}/>
          </TabPane>
          <TabPane tab={t("tableTabs.request")} key="2">
            <CustomerRequestTable />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
}
