// import { Modal, Descriptions, Image } from "antd";
// import React, { useState, useEffect } from "react";
// import moment from "moment";
// import useTransports from "../Hooks/useTransports";
// import { useTranslation } from "react-i18next";
// export default function ViewRequestModal({
//   isModalVisible,
//   setIsModalVisible,
//   state,
// }) {
//   const [transport, setTranspot] = useState({});
//   const { transports } = useTransports(state?.acceptedBy?.ownerId);
//   const { t } = useTranslation();
//   const handleOk = () => {
//     setIsModalVisible(!isModalVisible);
//   };
//   const handleCancel = () => {
//     setIsModalVisible(!isModalVisible);
//   };
//   useEffect(
//     () => {
//       if (state?.isAccept) {
//         let transport = transports?.find(
//           (v) => v.uid === state?.acceptedBy?.transportId
//         );

//         if (transport) {
//           setTranspot(transport);
//         } else {
//           setTranspot({});
//         }
//       }
//     },
//     [JSON.stringify(transports)],
//     JSON.stringify(state)
//   );

//   return (
//     <div>
//       <Modal
//         title={`${t("tableTabs.requst")} ${t("tableTabs.detail")}`}
//         visible={isModalVisible}
//         onOk={handleOk}
//         onCancel={handleCancel}
//         destroyOnClose
//         footer={null}
//         width={"80%"}
//       >
//         <>
//           <Descriptions
//             bordered
//             title={t("tableTabs.requst")}
//             layout={"vertical"}
//           >
//             <Descriptions.Item label={t("tableTabs.customerName")}>
//               {state?.name}
//             </Descriptions.Item>
//             <Descriptions.Item span={2} label={t("tableTabs.phoneNumber")}>
//               {state?.phoneNumber}
//             </Descriptions.Item>
//             <Descriptions.Item label={t("tableTabs.capacity")}>
//               {state?.capacity}
//             </Descriptions.Item>
//             <Descriptions.Item label={t("tableTabs.height")}>
//               {state?.height}
//             </Descriptions.Item>
//             <Descriptions.Item label={t("tableTabs.type")}>
//               {state?.type}
//             </Descriptions.Item>
//             <Descriptions.Item label={t("tableTabs.from")}>
//               {state?.from}
//             </Descriptions.Item>
//             <Descriptions.Item span={2} label={t("tableTabs.senderContact")}>
//               {state?.senderContact}
//             </Descriptions.Item>
//             <Descriptions.Item label={t("tableTabs.to")}>
//               {state?.to}
//             </Descriptions.Item>
//             <Descriptions.Item span={2} label={t("tableTabs.receiverContact")}>
//               {state?.receiverContact}
//             </Descriptions.Item>
//             <Descriptions.Item label={t("tableTabs.distance")}>
//               {state?.distance}
//             </Descriptions.Item>
//             <Descriptions.Item span={2} label={t("tableTabs.price")}>
//               {state?.price}
//             </Descriptions.Item>
//             <Descriptions.Item label={`Bl ${t("tableTabs.number")}`}>
//               {state?.blNumber}
//             </Descriptions.Item>
//             <Descriptions.Item
//               span={2}
//               label={`Bl ${t("tableTabs.number")} ${t("tableTabs.upload")}`}
//             >
//               <img
//                 className="img"
//                 onClick={() => {
//                   window.open(state?.blNumberUpload);
//                 }}
//                 alt={"Click to View"}
//                 src={state?.blNumberUpload}
//               />
//             </Descriptions.Item>
//             <Descriptions.Item label={`IRO ${t("tableTabs.date")}`}>
//               {moment(state?.iroDate?.seconds * 1000).format("lll")}
//             </Descriptions.Item>
//             <Descriptions.Item span={2} label={`IRO ${t("tableTabs.upload")}`}>
//               <img
//                 className="img"
//                 onClick={() => {
//                   window.open(state?.iroUpload);
//                 }}
//                 alt={"Click to View"}
//                 src={state?.iroUpload}
//               />
//             </Descriptions.Item>
//             <Descriptions.Item label={t("tableTabs.invoice")}>
//               {state?.invoice &&
//               state.invoice !== null &&
//               state.invoice !== "" ? (
//                 <img
//                   className="img"
//                   onClick={() => {
//                     window.open(state?.invoice);
//                   }}
//                   alt={"Click to View"}
//                   src={state?.invoice}
//                 />
//               ) : (
//                 <span>Not upload yet</span>
//               )}
//             </Descriptions.Item>

//             <Descriptions.Item span={2} label={t("tableTabs.receipt")}>
//               {state?.receipt &&
//               state?.receipt !== null &&
//               state?.receipt !== "" ? (
//                 <img
//                   className="img"
//                   onClick={() => {
//                     window.open(state?.receipt);
//                   }}
//                   alt={"Click to View"}
//                   src={state?.receipt}
//                 />
//               ) : (
//                 <span>Not upload yet</span>
//               )}
//             </Descriptions.Item>
//             <Descriptions.Item label={t("tableTabs.finalByan")}>
//               {state?.finalByan &&
//               state?.finalByan !== null &&
//               state?.finalByan !== "" ? (
//                 <img
//                   className="img"
//                   onClick={() => {
//                     window.open(state?.finalByan);
//                   }}
//                   alt={"Click to View"}
//                   src={state?.finalByan}
//                 />
//               ) : (
//                 <span>Not upload yet</span>
//               )}
//             </Descriptions.Item>
//             <Descriptions.Item span={2} label={t("tableTabs.ticket")}>
//               {state?.ticket &&
//               state?.ticket !== null &&
//               state?.ticket !== "" ? (
//                 <img
//                   className="img"
//                   onClick={() => {
//                     window.open(state?.ticket);
//                   }}
//                   alt={"Click to View Doc"}
//                   src={state?.ticket}
//                 />
//               ) : (
//                 <span>Not upload yet</span>
//               )}
//             </Descriptions.Item>
//             <Descriptions.Item label={t("tableTabs.deliveryNote")}>
//               {state?.deliveryNote &&
//               state?.deliveryNote !== null &&
//               state?.deliveryNote !== "" ? (
//                 <img
//                   className="img"
//                   onClick={() => {
//                     window.open(state?.deliveryNote);
//                   }}
//                   alt={"Click to View"}
//                   src={state?.deliveryNote}
//                 />
//               ) : (
//                 <span>Not upload yet</span>
//               )}
//             </Descriptions.Item>
//             <Descriptions.Item span={2} label={"EIR"}>
//               {state?.eir && state?.eir !== null && state?.eir !== "" ? (
//                 <img
//                   className="img"
//                   onClick={() => {
//                     window.open(state?.eir);
//                   }}
//                   alt={"Click to View Doc"}
//                   src={state?.eir}
//                 />
//               ) : (
//                 <span>Not upload yet</span>
//               )}
//             </Descriptions.Item>
//           </Descriptions>
//           <div>
//             <Descriptions
//               bordered
//               title={t("tableTabs.sendTo")}
//               layout={"vertical"}
//             >
//               {Object.keys(transport).length > 0 ? (
//                 <>
//                   <Descriptions.Item label={t("tableTabs.driverName")}>
//                     {transport?.driver?.driverName}
//                   </Descriptions.Item>
//                   <Descriptions.Item label={t("tableTabs.driverId")}>
//                     {transport?.driver?.driverIdNumber}
//                   </Descriptions.Item>
//                   <Descriptions.Item label={t("tableTabs.driverAddress")}>
//                     {transport?.driver?.driverAddress}
//                   </Descriptions.Item>
//                   <Descriptions.Item
//                     label={`${t("tableTabs.driverId")} ${t(
//                       "tableTabs.upload"
//                     )}`}
//                   >
//                     <img
//                       className="img"
//                       onClick={() => {
//                         window.open(transport?.driver?.driverIdUpload);
//                       }}
//                       alt={"Click to View"}
//                       src={transport?.driver?.driverIdUpload}
//                     />
//                   </Descriptions.Item>
//                   <Descriptions.Item label={t("tableTabs.driverLicenseUpload")}>
//                     <img
//                       className="img"
//                       onClick={() => {
//                         window.open(transport?.driver?.driverLicenseUpload);
//                       }}
//                       alt={"Click to View"}
//                       src={transport?.driver?.driverLicenseUpload}
//                     />
//                   </Descriptions.Item>
//                   <Descriptions.Item label={t("tableTabs.driverPortIdUpload")}>
//                     <img
//                       className="img"
//                       onClick={() => {
//                         window.open(transport?.driver?.portIdUpload);
//                       }}
//                       alt={"Click to View"}
//                       src={transport?.driver?.portIdUpload}
//                     />
//                   </Descriptions.Item>
//                   <Descriptions.Item label={t("tableTabs.truck")}>
//                     {transport?.truck?.truckModel}
//                   </Descriptions.Item>
//                   <Descriptions.Item label={t("tableTabs.truckNumber")}>
//                     {transport?.truck?.truckPlate}
//                   </Descriptions.Item>
//                   <Descriptions.Item label={t("tableTabs.truckCapacity")}>
//                     {transport?.truck?.truckCapacity}
//                   </Descriptions.Item>
//                   <Descriptions.Item label={t("tableTabs.truckHeight")}>
//                     {transport?.truck?.truckHeight}
//                   </Descriptions.Item>
//                   <Descriptions.Item label={t("tableTabs.truckType")}>
//                     {transport?.truck?.truckType}
//                   </Descriptions.Item>
//                   <Descriptions.Item label={t("tableTabs.truckLicenseUpload")}>
//                     <img
//                       className="img"
//                       onClick={() => {
//                         window.open(transport?.truck?.truckLicenseUpload);
//                       }}
//                       alt={"Click to View"}
//                       src={transport?.truck?.truckLicenseUpload}
//                     />
//                   </Descriptions.Item>
//                 </>
//               ) : (
//                 <Descriptions.Item label={t("tableTabs.notSendedYet")} />
//               )}
//             </Descriptions>
//           </div>
//         </>
//       </Modal>
//     </div>
//   );
// }

import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Descriptions,
  Space,
  Button,
  Image,
  Steps,
  Divider,
} from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
// import ActiveRideMap from "../Modals/a";
import ActiveRideMap from "../Requests/ActiveRideMap";
// import "./viewRequest.css";
import { useEffect } from "react";
import { useState } from "react";
import { getDoc, doc } from "firebase/firestore";
// import { db } from "../../firebase";
import { db } from "../firebase";
export default function ViewRequestModal() {
  const navigate = useNavigate();
  const { state } = useLocation();
  // console.log("🚀 ~ file: ViewRequestModel.jsx:326 ~ ViewRequestModal ~ state:", state)
  const { request } = state;
  const [ownerDetails, setOwnerDetails] = useState({});
  const [truckDetails, setTruckDetails] = useState({});
  const [driverDetails, setDriverDetails] = useState({});
  const { t } = useTranslation();
  const getDatas = async () => {
    const owner = await getDoc(doc(db, "owners", request.acceptedBy?.ownerId));
    setOwnerDetails(owner.data());
    const drive = await getDoc(
      doc(db, "drivers", request.acceptedBy?.driverId)
    );
    setDriverDetails(drive.data());
    const truck = await getDoc(doc(db, "trucks", drive.data()?.truckId));
    setTruckDetails(truck.data());
  };

  useEffect(() => {
    if (request?.driverStarted) {
      getDatas();
    }
  }, []);
  return (
    <div>
      <div>
        <div>
          <h3 className="car-img">{`${t("tableTabs.requst")} ${t(
            "tableTabs.detail"
          )}`}</h3>
        </div>
        <div>
          <div>
            <div className="upper_btn_div car-img">
              <Space>
                <Button
                  type="light"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  {t("tableTabs.back")}
                </Button>
              </Space>
            </div>
          </div>
          <div className="main-view-div">
            <div className="view-request">
              <Row className="mb-3">
                <Col span={2}>
                  <p className="car-img">
                    <b>{t("Request Id:")}</b>
                  </p>
                </Col>
                <Col span={22}>
                  <p className="car-img">{request?.requestId}</p>
                </Col>
              </Row>
              <Row>
                <Col span={2}>
                  <p className="car-img">
                    <b>{t(" Date:")}</b>
                  </p>
                </Col>
                <Col span={15}>
                  <p className="car-img">
                    {moment(request?.iroDate?.seconds * 1000).format("lll")}
                  </p>
                </Col>
                <Col span={2}>
                  <p>
                    <b className="car-img1">{t("tableTabs.price")}</b>
                  </p>
                </Col>
                <Col span={5}>{request?.price}</Col>
              </Row>
              {/* <Row type="flex" justify="center" align="middle"> */}
              <Row>
                <div className="car-img" style={{ marginTop: "10px" }}>
                  <Steps
                    direction="vertical"
                    progressDot
                    current={1}
                    items={[
                      {
                        title: (
                          <Space size="large">
                            <p>
                              <b >{t("placeholders.from")}:</b>
                            </p>
                            <p
                              style={{
                                marginLeft: "30px",
                              }}
                            >
                              {request.from}
                            </p>
                          </Space>
                        ),
                      },
                      {
                        title: (
                          <Space size="large">
                            <p>
                              <b>{t("tableTabs.to")}:</b>
                            </p>
                            <p
                              style={{
                                marginLeft: "30px",
                              }}
                            >
                              {request.to}
                            </p>
                          </Space>
                        ),
                      },
                    ]}
                  />
                </div>
              </Row>
              <Divider />
              <Row>
                <Col span={3}>
                  <Space direction="vertical" className="car-img">
                    <p>
                      <b>{t("tableTabs.name")}</b>
                    </p>
                    <p>{request?.name}</p>
                  </Space>
                </Col>
                <Col span={3}>
                  <Space direction="vertical" className="car-img">
                    <p>
                      <b>{t("tableTabs.type")}</b>
                    </p>
                    <p>{request?.type}</p>
                  </Space>
                </Col>
                <Col span={4}>
                  <Space direction="vertical" className="car-img">
                    <p>
                      <b>{t("tableTabs.capacity")}</b>
                    </p>
                    <p>{request?.capacity}</p>
                  </Space>
                </Col>
                <Col span={3}>
                  <Space direction="vertical" className="car-img2 ">
                    <p>
                      <b>{t("tableTabs.hight")}</b>
                    </p>
                    <p>{request?.height}</p>
                  </Space>
                </Col>
                <Col span={4}>
                  <Space direction="vertical" className="car-img1">
                    <p>
                      <b>{t("tableTabs.senderContact")}</b>
                    </p>
                    <p>{request?.senderContact}</p>
                  </Space>
                </Col>
                <Col span={4}>
                  <Space direction="vertical" className="car-img1">
                    <p>
                      <b>{t("tableTabs.receiverContact")}</b>
                    </p>
                    <p>{request?.receiverContact}</p>
                  </Space>
                </Col>
                <Col span={3}>
                  <Space direction="vertical" className="car-img1">
                    <p>
                      <b>{t("tableTabs.distance")}</b>
                    </p>
                    <p>{request?.distance}Km</p>
                  </Space>
                </Col>
              </Row>
              <Divider />
              <Descriptions
                bordered
                title={t("Attachments")}
                layout={"vertical"}
              >
                <Descriptions.Item label={t("tableTabs.finalByan")}>
                  {state?.request?.finalByan ? (
                    <img
                      className="img"
                      onClick={() => {
                        window.open(state?.request?.finalByan);
                      }}
                      alt={"Click to View"}
                      src={state?.request?.finalByan}
                    />
                  ) : (
                    <span>{t("Not Upload Yet")}</span>
                  )}
                </Descriptions.Item>
                <Descriptions.Item label={t("tableTabs.ticket")}>
                  {state?.request?.ticket ? (
                    <img
                      className="img"
                      onClick={() => {
                        window.open(state?.request?.ticket);
                      }}
                      alt={"Click to View Doc"}
                      src={state?.request?.ticket}
                    />
                  ) : (
                    <span>{t("Not Upload Yet")}</span>
                  )}
                </Descriptions.Item>
                <Descriptions.Item label={`${t("tableTabs.blNumber")} ${t("tableTabs.upload")}`}>
                  {state?.request?.blNumberUpload ? (
                    <img
                      className="img"
                      onClick={() => {
                        window.open(state?.request?.blNumberUpload);
                      }}
                      alt={"Click to View"}
                      src={state?.request?.blNumberUpload}
                    />
                  ) : (
                    <span>Not upload yet</span>
                  )}
                </Descriptions.Item>

                <Descriptions.Item label={t("Receipt")}>
                  {state?.request?.receipt ? (
                    <img
                      className="img"
                      onClick={() => {
                        window.open(state?.request?.receipt);
                      }}
                      alt={"Click to View Doc"}
                      src={state?.request?.receipt}
                    />
                  ) : (
                    <span>{t("Not Upload Yet")}</span>
                  )}
                </Descriptions.Item>
                {/* <Descriptions.Item label="Receipt  ">
                  <img
                    className="img"
                    onClick={() => {
                      window.open(state?.request?.receipt);
                    }}
                    alt={"Not Upload Yet"}
                    src={state?.request?.receipt}
                  />
                </Descriptions.Item> */}
          
                <Descriptions.Item label={`IRO ${t("tableTabs.upload")}`}>
                  {state?.request?.iroUpload ? (
                    <img
                      className="img"
                      onClick={() => {
                        window.open(state?.request?.iroUpload);
                      }}
                      alt={"Click to View"}
                      src={state?.request?.iroUpload}
                    />
                  ) : (
                    <span>Not upload yet</span>
                  )}
                </Descriptions.Item>

                <Descriptions.Item label={t("tableTabs.deliveryNote")}>
                  {state?.request?.deliveryNote ? (
                    <img
                      className="img"
                      onClick={() => {
                        window.open(state?.request?.deliveryNote);
                      }}
                      alt={"Click to View"}
                      src={state?.request?.deliveryNote}
                    />
                  ) : (
                    <span>Not upload yet</span>
                  )}
                </Descriptions.Item>
                <Descriptions.Item label={"EIR"}>
                  {state?.request?.eir ? (
                    <img
                      className="img"
                      onClick={() => {
                        window.open(state?.request?.eir);
                      }}
                      alt={"Click to View Doc"}
                      src={state?.request?.eir}
                    />
                  ) : (
                    <span>Not upload yet</span>
                  )}
                </Descriptions.Item>
                <Descriptions.Item label={t("Wrong Invoice")}>
                  {state?.request?.invoice !== undefined &&
                  state?.request?.invoice !== null ? (
                    <img
                      className="img"
                      onClick={() => {
                        window.open(state?.request?.invoice);
                      }}
                      alt={"Click to View Doc"}
                      src={state?.request?.invoice}
                    />
                  ) : (
                    <span>Not upload yet</span>
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="O/D">
                  {state?.request?.odUpload !== undefined && state?.request?.odUpload !== ""  &&
                  state?.request?.odUpload !== null ? (
                    <img
                      className="img"
                      onClick={() => {
                        window.open(state?.request?.odUpload);
                      }}
                      alt={"Click to View Doc"}
                      src={state?.request?.odUpload}
                    />
                  ) : (
                    <span>Not Upload Yet</span>
                  )}
                </Descriptions.Item>
              </Descriptions>
              
              <Row>
                <Col span={24}>
                  <Descriptions
                    bordered
                    title={t("tableTabs.sendTo")}
                    layout={"vertical"}
                  >
                    {request.isAccept ? (
                      <>
                        <Descriptions.Item label={t("tableTabs.driverName")}>
                          {driverDetails?.driverName}
                        </Descriptions.Item>
                        <Descriptions.Item label={t("Driver ID")}>
                          {driverDetails?.driverIdNumber}
                        </Descriptions.Item>
                        <Descriptions.Item label={t("Driver Address")}>
                          {driverDetails?.driverAddress}
                        </Descriptions.Item>
                        <Descriptions.Item label={t("tableTabs.truck")}>
                          {truckDetails?.truckModel}
                        </Descriptions.Item>
                        <Descriptions.Item label={t("tableTabs.truckCapacity")}>
                          {truckDetails.truckCapacity}
                        </Descriptions.Item>
                        <Descriptions.Item label={t("tableTabs.truckHeight")}>
                          {truckDetails.truckHeight}
                        </Descriptions.Item>
                        <Descriptions.Item label={t("tableTabs.truckNumber")}>
                          {truckDetails?.truckPlate}
                        </Descriptions.Item>

                        <Descriptions.Item label={t("tableTabs.truckType")}>
                          {truckDetails?.truckType}
                        </Descriptions.Item>
                        <Descriptions.Item label={t("driver Id Upload")}>
                          <img
                            className="img"
                            onClick={() => {
                              window.open(driverDetails?.driverIdUpload);
                            }}
                            alt={"Click to View"}
                            src={driverDetails?.driverLicenseUpload}
                          />
                        </Descriptions.Item>
                        <Descriptions.Item
                          label={t("tableTabs.driverLicenseUpload")}
                        >
                          <img
                            className="img"
                            onClick={() => {
                              window.open(driverDetails?.driverLicenseUpload);
                            }}
                            alt={"Click to View"}
                            src={driverDetails?.driverLicenseUpload}
                          />
                        </Descriptions.Item>
                        <Descriptions.Item
                          label={t("tableTabs.driverPortIdUpload")}
                        >
                          <img
                            className="img"
                            onClick={() => {
                              window.open(driverDetails?.portIdUpload);
                            }}
                            alt={"Click to View"}
                            src={driverDetails?.portIdUpload}
                          />
                        </Descriptions.Item>

                        <Descriptions.Item
                          label={t("tableTabs.truckLicenseUpload")}
                        >
                          <img
                            className="img"
                            onClick={() => {
                              window.open(truckDetails?.truckLicenseUpload);
                            }}
                            alt={"Click to View"}
                            src={truckDetails?.truckLicenseUpload}
                          />
                        </Descriptions.Item>
                      </>
                    ) : (
                      <Descriptions.Item label={t("tableTabs.notSendedYet")} />
                    )}
                  </Descriptions>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <ActiveRideMap
          coordinates={state?.request?.coordinates}
          id={state?.request?.uid}
        />
      </div>
    </div>
  );
}
