import { useTranslation } from "react-i18next";
import { Tabs } from "antd";
import MaintenanceTable from "../Tables/MaintenanceTable";
import MaintenanceRequestTable from "../Tables/MaintenanceRequestTable";

const Maintenance = () => {
  const { t } = useTranslation();
  const { TabPane } = Tabs;

  return (
    <div>
      <div>
        <h3>{t("routes.maintenance")}</h3>
      </div>
      <div>
        <Tabs defaultActiveKey="1">
          <TabPane tab={t("services")} key="1">
            <MaintenanceTable />
          </TabPane>
          <TabPane tab={t("servicerequest")} key="2">
            <MaintenanceRequestTable />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default Maintenance;
