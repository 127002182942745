import React, { useEffect, useState } from "react";
import { Table, Button, Input, Space, Popconfirm, Tag, Pagination, message } from "antd";
import "./table.css";
import {
  PlusCircleOutlined,
  SearchOutlined,
  UploadOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import AddDriverModal from "../Modals/AddDriverModal";
import EditDriverModal from "../Modals/EditDriverModal";
// import useDrivers from "../Hooks/useDrivers";
import { DeleteDriver } from "../API/API";
import { useTranslation } from "react-i18next";
import useGetMyDrivers from "../Hooks/useDrivers";
import Loader from "../Loaders/Loader";
import { DELETE_DRIVER } from "../../Graphql/mutation/driverMutation";
import { useMutation } from "@apollo/client";
import SimpleLoader from "../Loaders/simpleLoader";

export default function DriverManagmentTable({ownerId}) {
  // const { drivers } = useDrivers();
  const [alldriver, setDrivers] = useState([]);
  
  const [loading, setLoading] = useState(false);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [paginationFilters, setPaginationFilters] = useState({
    pageNo: 1,
    pageSize: 5,
    limitSkip: 0,
    limit: 10,
  });
  const { drivers, count} = useGetMyDrivers(updateTrigger,paginationFilters,ownerId);
  console.log("🚀 ~ file: DriverManagmentTable.jsx:34 ~ DriverManagmentTable ~ drivers:", drivers)
  // setDrivers(drivers);
  const handleChange = (value, pageSize) => {
    const limitSkip = value * pageSize;
    const limit = pageSize;
    setPaginationFilters({ pageNo: value, pageSize, limitSkip, limit });
    setLoading(true);
    setUpdateTrigger(!updateTrigger);
  };
  const [deleteDriver] = useMutation(DELETE_DRIVER);
  const [search, setSearch] = useState("");
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [driver, setDriver] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { t } = useTranslation();
  const showModal = () => {
    setIsModalVisible(!isModalVisible);
  };
 const showEditModal = () => {
   setIsEditModalVisible(!isEditModalVisible);
 };
 const confirm = async (driver, action) => {
  debugger;
  setUpdateTrigger(false);
  try {
    const { data } = await deleteDriver({
      variables: { id: driver._id, isArchive:true },
    });
    // Handle the response data if needed
    console.log(data);
    // Remove the deleted user from the list
    setDrivers((prevUsers) =>
      prevUsers.filter((user) => user._id !== driver._id)
    );
    // Update the total count of users
    // setCount(count - 1);
    message.success("Cutomer Deleted Successfully");
    setUpdateTrigger(true);
  } catch (error) {
    // Handle the error if needed
    console.log(error);
  }
};
  const columns = [
    {
      title: t("tableTabs.name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("tableTabs.age"),
      dataIndex: "age",
      key: "age",
    },
    {
      title: t("tableTabs.nationality"),
      dataIndex: "nationality",
      key: "nationality",
    },
    {
      title: t("tableTabs.address"),
      dataIndex: "address",
      key: "address",
    },
    {
      title: t("tableTabs.merged"),
      render: (record) => (
        <span>
          <Tag color={record.isTruck ? "green" : "pink"}>
            {record.isTruck ? "Yes" : "No"}
          </Tag>
        </span>
      ),
    },
    {
      title: t("tableTabs.createdAt"),
      render: (record) => (
        <span>
          {record?.createdAt}
        </span>
      ),
    },
    {
      title: t("tableTabs.action"),
      dataIndex: "",
      key: "x",
      align: "center",
      render: (record) => (
        <Space>
          <span
            onClick={() => {
              setDriver(record);
              showEditModal();
            }}
            className="ant-btn ant-btn-primary"
          >
            <EditOutlined />
          </span>
          <Popconfirm
            title={"Are you sure you want to delete?"}
            okText="Ok"
            cancelText="Cancel"
            onConfirm={() => confirm(record, "del")}
            // onConfirm={() => {
            //   if (record?.isTruck) {
            //     alert("Please remove merging tarnsport first");
            //   } else {
            //     DeleteDriver(record?.uid, record);
            //   }
            // }}
          >
            <span className="ant-btn ant-btn-danger">
              <DeleteOutlined />
            </span>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  useEffect(() => {
    // setDrivers(drivers);
    if (drivers.length > 0) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [drivers]);
  return (
    <div>
      <AddDriverModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        ownerId={ownerId}
      />
      <EditDriverModal
        isModalVisible={isEditModalVisible}
        setIsModalVisible={setIsEditModalVisible}
        data={driver}
      />

      <div className="table_topar_add_div_">
        <Button
          type="light"
          // shape="circle"
          icon={<PlusCircleOutlined />}
          size={"middle"}
          onClick={showModal}
        >
          {t("tableTabs.addDriver")}
        </Button>
      </div>
      <div className="table_search_div">
        <Input
          allowClear
          size="middle"
          placeholder={t("placeholders.search")}
          prefix={<SearchOutlined />}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      </div>
      {loading ? <SimpleLoader /> : ""}
      <Table
      pagination={false}
        columns={columns}
        // expandable={{
        //   expandedRowRender: (record) => (
        //     <p
        //       style={{
        //         margin: 0,
        //       }}
        //     >
        //       {record.description}
        //     </p>
        //   ),
        //   rowExpandable: (record) => record.name !== "Not Expandable",
        // }}

        dataSource={drivers.filter((val) => {
          if (search == "") {
            return val;
          } else if (
            val &&
            Object.keys(val).some((v) =>
              val[v]
                .toString()
                .toLowerCase()
                .includes(search.toString().toLowerCase())
            )
          ) {
            return val;
          }
        })}
      />
       <Pagination
          defaultCurrent={1}
          defaultPageSize={paginationFilters.pageSize}
          onChange={handleChange}
          total={count}
          className="Pagination"
        />
    </div>
  );
}
