import React, { useEffect, useState } from "react";
import {
  collection,
  query,
  onSnapshot,
  where,
  orderBy,
} from "firebase/firestore";
import { db } from "../firebase";
export default function useOwners() {
  const [owners, setowners] = useState([]);
  const getowners = async () => {
    let q = query(collection(db, "owners"), orderBy("createdAt", "desc"));
    const unsub = onSnapshot(q, (querySnapShot) => {
      setowners([]);
      if (!querySnapShot.empty) {
        let arr = [];
        querySnapShot.forEach((val) => {
          arr.push(val.data())
        });
        if (arr.length == querySnapShot.size) {
          setowners([...arr])
        }
      }
    });

    return () => unsub();
  };
  useEffect(() => {
    getowners();
  }, []);

  // console.log(owners, "onwershooks");
  return { owners };
}
