import React, { useEffect, useState } from "react";
import { collection, query, onSnapshot, where,orderBy } from "firebase/firestore";
import { db } from "../firebase";
export default function useCustomers() {

  const [customers, setcustomers] = useState([]);
  const getcustomers = async () => {
    let q = query(collection(db, "customers"),orderBy('createdAt','desc'));
    const unsub = onSnapshot(q, (querySnapShot) => {
      setcustomers([]);
      if (!querySnapShot.empty) {
        querySnapShot.forEach((val) => {   
          setcustomers((prev) => [...prev, val.data()]);
        });
      }
    });

    return () => unsub();
  };
  useEffect(() => {
    getcustomers();
  }, []);

  return { customers };
}
// import { useEffect, useState, useCallback } from "react"; // Import your GraphQL query for drivers
// import { useQuery } from "@apollo/client";
// import { GET_ALL_CUSTOMER } from "../../Graphql/queries/customerQueries";

// function useGetAllCustomer(trigger, paginationFilters) {
//   const [customers, setCustomer] = useState([]);
//   const [count, setCount] = useState(0);

//   const { loading, error, data, refetch } = useQuery(GET_ALL_CUSTOMER, {
//     variables: { page: paginationFilters?.pageNo },
//     fetchPolicy: "network-only",
//   });

//   const getAllCustomer = useCallback(async () => {
//     if (loading) return;
//     if (error) {
//       console.log("Error:", error.message);
//       return;
//     }
//     const { allCustomers } = data;
//     if (!allCustomers) {
//       console.log("No Owner data available.");
//       return;
//     }
//     const { customers, totalDocs } = allCustomers;
//     setCustomer(customers);
//     setCount(totalDocs);
//   }, [loading, error, data]);

//   useEffect(() => {
//     getAllCustomer();
//     if (trigger) {
//       refetch({ page: paginationFilters?.pageNo });
//     }
//   }, [trigger, getAllCustomer, paginationFilters?.pageNo, refetch]);

//   return { loading, error, customers, count };
// }

// export default useGetAllCustomer;