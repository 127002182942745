import { gql } from "@apollo/client";

const ADMIN_CREATE = gql`
mutation CreateAdmin($registerAdminInput: RegisterAdminInput) {
  createAdmin(registerAdminInput: $registerAdminInput) {
    code
    message
    success
    data {
      _id
      email
      password
      token
      url
      userName
    }
  }
}
`;
const ADMIN_LOGIN = gql`
mutation Mutation($loginInput: LoginInput) {
  loginAdmin(loginInput: $loginInput) {
    code
    message
    success
    data {
      _id
      email
      password
      token
      url
      userName
    }
  }
}
`;
const ADMIN_DELETE = gql`
  mutation DeleteAdmin($id: ID!) {
    deleteAdmin(ID: $id) {
      code
      message
      data {
        email
      }
    }
  }
`;
export { ADMIN_LOGIN, ADMIN_CREATE, ADMIN_DELETE };
