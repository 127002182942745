import { Input, Modal, Row, Col, Button, Space } from "antd";
import { doc, updateDoc } from "firebase/firestore";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { sendNotification, uploadImage } from "../API/API";
import { db } from "../firebase";
import Loader from "../Loaders/Loader";
export default function ClearanceInvoice({
  isModalVisibleC,
  setIsModalVisibleC,
  state,
}) {
  const [uploadAttach1, setUploadAttach1] = useState("");
  const [uploadAttch2, setUploadAttach2] = useState("");
  const [uploadAttach3, setUploadAttach3] = useState("");
  const [cAttach1, setCAttach1] = useState("");
  const [cAttach2, setCAttach2] = useState("");
  const [cAttach3, setCAttach3] = useState("");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const handleOk = () => {
    setIsModalVisibleC(!isModalVisibleC);
  };
  
  const handleCancel = () => {
    setIsModalVisibleC(!isModalVisibleC);
  };



  const handleOdUploads = async () => {
    if (uploadAttach1 !== "") {
      try {
        setLoading(true);
        await uploadImage("clearance/", uploadAttach1).then(async (res) => {
          setCAttach1(res);
          await updateDoc(doc(db, "clearance_request", state.uid), {
            attach1:res,
          });
          setLoading(false);

        });
      } catch (e) {
        setLoading(false);
        console.log(e);
      }
    } else {
      alert("choose file first");
    }
  };
  const handleBiUploads = async () => {
    if (uploadAttch2 !== "") {
      try {
        setLoading(true);
        await uploadImage("clearance/", uploadAttch2).then(async (res) => {
          setCAttach2(res);
          await updateDoc(doc(db, "clearance_request", state.uid), {
            attach2:res,
          });
          setLoading(false);

        });
      } catch (e) {
        setLoading(false);
        console.log(e);
      }
    } else {
      alert("choose file first");
    }
  };

  const handleIroUploads = async () => {
    if (uploadAttach3 !== "") {
      try {
        setLoading(true);
        await uploadImage("clearance/", uploadAttach3).then(async (res) => {
          setCAttach3(res);
          await updateDoc(doc(db, "clearance_request", state.uid), {
            attach3:res,
          });
          setLoading(false);

        });
      } catch (e) {
        setLoading(false);
        console.log(e);
      }
    } else {
      alert("choose file first");
    }
  };

  



  useEffect(() => {
 

    if (state?.attach1 !== undefined) {
      setCAttach1(state?.attach1);
    } else {
      setCAttach1("");
    }

    if (state?.attach2 !== undefined) {
      setCAttach2(state?.attach2);
    } else {
      setCAttach2("");
    }

    if (state?.attach3 !== undefined) {
      setCAttach3(state?.attach3);
    } else {
      setCAttach3("");
    }
  }, [state]);

  return (
    <div>
      {loading ? <Loader /> : ""}
      <Modal
        title={t("Modal.attachFile")}
        visible={isModalVisibleC}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose
        footer={null}
      >
       
    <div>
            <h4>File</h4>
          </div>
          <Space direction="vertical">
        <Row gutter={"24"}>
            <Col span={"18"}>
              <Input
                style={{ border: "2px solid #2d808b " }}
                type="file"
                onClick={(e) => e.target.value}
                onChange={(e) => {
                  setUploadAttach3(e.target.files[0]);
                }}
              />
            </Col>
            <Col span={"6"}>
              <Button onClick={handleIroUploads} className="check-btn" size="large">
                {t("Modal.upload")}
              </Button>
            </Col>
          </Row>
          {cAttach3 !== "" ? (
            <Space>
              <img
                className="img"
                src={cAttach3}
                width={100}
                height={100}
                alt={"Click to view"}
                onClick={() => {
                  window.open(cAttach3);
                }}
              />
              <span>{t("Modal.receipt")}</span>
            </Space>
          ) : (
            <span>{t("Modal.notYetUpload")}</span>
          )}
        </Space>

          
          <div>
            <h4>File</h4>
          </div>
          <Space direction="vertical">
        <Row gutter={"24"}>
            <Col span={"18"}>
              <Input
                style={{ border: "2px solid #2d808b " }}
                type="file"
                onClick={(e) => e.target.value}
                onChange={(e) => {
                  setUploadAttach2(e.target.files[0]);
                }}
              />
            </Col>
            <Col span={"6"}>
              <Button onClick={handleBiUploads} className="check-btn" size="large">
                {t("Modal.upload")}
              </Button>
            </Col>
          </Row>
          {cAttach2 !== "" ? (
            <Space>
              <img
                className="img"
                src={cAttach2}
                width={100}
                height={100}
                alt={"Click to view"}
                onClick={() => {
                  window.open(cAttach2);
                }}
              />
              <span>{t("Modal.receipt")}</span>
            </Space>
          ) : (
            <span>{t("Modal.notYetUpload")}</span>
          )}
            </Space>

          <div>
            <h4>File</h4>
          </div>
          <Space direction="vertical">
        <Row gutter={"24"}>
            <Col span={"18"}>
              <Input
                style={{ border: "2px solid #2d808b " }}
                type="file"
                onClick={(e) => e.target.value}
                onChange={(e) => {
                  setUploadAttach1(e.target.files[0]);
                }}
              />
            </Col>
            <Col span={"6"}>
              <Button onClick={handleOdUploads} className="check-btn" size="large">
                {t("Modal.upload")}
              </Button>
            </Col>
          </Row>
          {cAttach1 !== "" ? (
            <Space>
              <img
                className="img"
                src={cAttach1}
                width={100}
                height={100}
                alt={"Click to view"}
                onClick={() => {
                  window.open(cAttach1);
                }}
              />
              <span>{t("Modal.receipt")}</span>
            </Space>
          ) : (
            <span>{t("Modal.notYetUpload")}</span>
          )}
            </Space>
         

      

      </Modal>
    </div>
  );
}
