import React, { useState } from "react";
import {
  Table,
  Button,
  Input,
  Space,
  Popconfirm,
  Pagination,
  Switch,
  Tag,
} from "antd";
import "../Tables/table.css";
import {
  PlusCircleOutlined,
  SearchOutlined,
  UploadOutlined,
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import useOwners from "../Hooks/useOwners";
import {  RestoreOwner, UpdateOwner } from "../API/API";
import { useNavigate } from "react-router-dom";
import Loader from "../Loaders/Loader";
import { useTranslation } from "react-i18next";
import { MdOutlineRestore } from "react-icons/md";

const OwnerArchive = () => {

    const { owners } = useOwners();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const filteredOwner = owners.filter((ele => ele.isDeleted == false))
    const showModal = () => {
      setIsModalVisible(!isModalVisible);
    };
  
    const columns = [
      {
        title: t("tableTabs.name"),
        dataIndex: "name",
        key: "name",
      },
      {
        title: t("tableTabs.email"),
        dataIndex: "email",
        key: "email",
      },
      {
        title: t("tableTabs.verified"),
        render: (recored) => (
          <span>
            <Tag
              key={recored.uid}
              color={recored.emailVerified ? "green" : "pink"}
            >
              {recored.emailVerified ? "Yes" : "No"}
            </Tag>
          </span>
        ),
      },
      {
        title: t("tableTabs.approved"),
        key: "approval",
        render: (recored) => (
          <Space>
            <Switch
              checked={
                recored.isApproved !== undefined && recored.isApproved === true
              }
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              onChange={async (value) => {
                if (recored.emailVerified) {
                  let data = { isApproved: value };
                  await UpdateOwner(recored.uid, data);
                } else {
                  if (
                    window.confirm(
                      "Are you sure to approve this user is not verified!"
                    )
                  ) {
                    let data = { isApproved: value };
                    await UpdateOwner(recored.uid, data);
                  }
                }
              }}
            />
          </Space>
        ),
      },
  
      {
        title: t("tableTabs.action"),
  
        dataIndex: "",
        key: "x",
        align: "center",
        render: (record) => (
          <Space>
            <Popconfirm
              title={"Are you sure you want to Restore?"}
              okText="Ok"
              cancelText="Cancel"
              onConfirm={() => {
                RestoreOwner(record?.uid, record);
              }}
            >
              <span className="ant-btn ant-btn-danger">
                <MdOutlineRestore />
              </span>
            </Popconfirm>
          </Space>
        ),
      },
    ];

  return (
    <div>
    {loading ? <Loader /> : ""}

    <div className="table_search_div">
      <Input
        allowClear
        size="middle"
        placeholder={t("placeholders.search")}
        prefix={<SearchOutlined />}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
      />
    </div>
    <Table
      showHeader={true}
      // loading={owners ? true : false}
      columns={columns}
      dataSource={filteredOwner.filter((val) => {
        if (search == "") {
          return val;
        } else if (
          val &&
          Object.keys(val).some((v) =>
            val[v]
              .toString()
              .toLowerCase()
              .includes(search.toString().toLowerCase())
          )
        ) {
          return val;
        }
      })}
    />
  </div>
  )
}

export default OwnerArchive