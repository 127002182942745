import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Input,
  Form,
  Row,
  Col,
  Select,
  Switch,
  message,
} from "antd";
import Loader from "../Loaders/Loader";
import { getPreciseDistance } from "geolib";
import useCapacites from "../Hooks/useCapacities";
import useCities from "../Hooks/useCities";
import useHeights from "../Hooks/useHeights";
import useTypes from "../Hooks/useTypes";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { COMBINATION_CREATE } from "../../Graphql/mutation/combinationMutation";
import useGetAllCombination from "../Hooks/useCombinations";
export default function MakeCombinationModal({
  isModalVisible,
  setIsModalVisible,
}) {
  const [form] = Form.useForm();
  const { Option } = Select;
  const [loading, setLoading] = useState(false);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [paginationFilters, setPaginationFilters] = useState({
    pageNo: 1,
    pageSize: 5,
    limitSkip: 0,
    limit: 10,
  });
  const { cities } = useCities(updateTrigger, paginationFilters);
  const { services } = useCapacites(updateTrigger, paginationFilters);
  const citiesmethod = () => {
    setUpdateTrigger(!updateTrigger);
  };
  const capasitymethod = () => {
    setUpdateTrigger(!updateTrigger);
  };
  const heightmethod = () => {
    setUpdateTrigger(!updateTrigger);
  };
  const { types } = useTypes(updateTrigger, paginationFilters);
  const { heights } = useHeights(updateTrigger, paginationFilters);
  const { refetch } = useGetAllCombination(updateTrigger, paginationFilters);

  const [type, setType] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [height, setHeight] = useState("");
  const [capacity, setCapacity] = useState("");
  const [commission, setcommission] = useState(0);
  const [commissionPrice, setcommissionPrice] = useState(0);
  const [vat, setVat] = useState(0);
  const [vatPrice, setVatPrice] = useState(0);
  const [isToggleOn, setIsToggleOn] = useState(false);
  const [createCombination] = useMutation(COMBINATION_CREATE);
  const [cprice, setCPrice] = useState(0);
  const [distance, setDistance] = useState("");
  const [price, setPrice] = useState("");
  const { t } = useTranslation();

  const onChangeChecked = (checked) => {
    setIsToggleOn(checked);
  };
  const handleOk = () => {
    setIsModalVisible(!isModalVisible);
  };

  const handleCancel = () => {
    setIsModalVisible(!isModalVisible);
  };
  const onFinish = async (values) => {
    debugger;
    setLoading(true);
    let data = {
      ...values,
      isRelation: isToggleOn
    };

    await createCombination({ variables: { combinationInput: data } })
      .then((response) => {
        setLoading(false);
        form.resetFields();
        setCapacity("");
        setHeight("");
        setType("");
        setFrom("");
        setTo("");
        updateTrigger(true);
        // Access the result data if needed
        const { data, message, success } = response.data.createCombination;
        message.success(message);
        refetch();
        handleCancel();
      })
      .catch((e) => {
        setLoading(false);
        message.error(JSON.stringify(e));
      });
  };
  const getDistanceTimePrice = () => {
    let distance = 2;
    // console.log(distance);
    let estimateTime = (parseInt(distance * 0.7 + 30) / 60).toFixed(2);
    let typePrices =
      parseFloat(capacity.price);
    let price = typePrices * distance;
    price = price.toFixed(2);
    // form.setFieldsValue({ time: time });
    // form.setFieldsValue({ distance: distance });
    // form.setFieldsValue({ price: parseInt(price) });
    setPrice(parseInt(price));
    setCPrice(parseInt(price));
  };

 useEffect(() => {
  // Calculate distance, time, and price when dependencies change
  if (type !== "" && height !== "" && capacity !== "" && from !== "" && to !== "") {
    getDistanceTimePrice();
  }

  // Update commission price when cprice or commission changes
  if (cprice !== 0 && commission !== 0) {
    setcommissionPrice((parseInt(commission) * parseInt(cprice)) / 100);
  }

  // Update VAT price when cprice or vat changes
  if (cprice !== 0 && vat !== 0) {
    setVatPrice((parseInt(vat) * parseInt(cprice)) / 100);
  }
}, [type, height, capacity, from, to, cprice, commission, vat]);


  return (
    <div>
      <Modal
        title={t("Modal.makeNewCombination")}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose
        footer={null}
      >
        {loading ? <Loader /> : ""}
        <div>
          <Form form={form} name="make_request_form" onFinish={onFinish}>
            <Input.Group>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="from"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Select
                      style={{ border: "2px solid  #2d808b" }}
                      placeholder={t("placeholders.from")}
                      onClick={citiesmethod}
                      onChange={(val) => {
                        let value = cities[val];
                        form.setFieldsValue({ from: value.cityName });
                        setFrom(value);
                      }}
                    >
                      {cities &&
                        cities.map((v, i) => {
                          return (
                            <Option key={i} value={i}>
                              {v.cityName}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="to"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Select
                      style={{ border: "2px solid  #2d808b" }}
                      placeholder={t("placeholders.to")}
                      onClick={citiesmethod}
                      onChange={(val) => {
                        console.log(
                          "🚀 ~ file: MakeCombinationModal.jsx:217 ~ val:",
                          val
                        );
                        let value = cities[val];
                        form.setFieldsValue({ to: value.cityName });
                        setTo(value);
                      }}
                    >
                      {cities &&
                        cities.map((v, i) => {
                          return (
                            <Option key={i} value={i}>
                              {v.cityName}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Input.Group>

            <Input.Group>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="capacity"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Select
                      style={{ border: "2px solid  #2d808b" }}
                      placeholder={t("placeholders.selectCapacity")}
                      onClick={capasitymethod}
                      onChange={(val) => {
                        console.log(
                          "🚀 ~ file: MakeCombinationModal.jsx:254 ~ val:",
                          val
                        );
                        let value = services[val];
                        form.setFieldsValue({ capacity: value.capacity });
                        setCapacity(value);
                      }}
                    >
                      {services &&
                        services.map((v, i) => {
                          return (
                            <Option key={i} value={i}>
                              {v.capacity}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="height"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Select
                      style={{ border: "2px solid  #2d808b" }}
                      placeholder={t("placeholders.selectHight")}
                      onClick={heightmethod}
                      onChange={(val) => {
                        let value = heights[val];
                        form.setFieldsValue({ height: value.height });
                        setHeight(value);
                      }}
                    >
                      {heights &&
                        heights.map((v, i) => {
                          return (
                            <Option key={i} value={i}>
                              {v.height}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Input.Group>
            <Input.Group>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="type"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Select
                      style={{ border: "2px solid  #2d808b" }}
                      placeholder={t("placeholders.selectType")}
                      onChange={(val) => {
                        let value = types[val];
                        form.setFieldsValue({ type: value.type });
                        setType(value);
                      }}
                    >
                      {types &&
                        types.map((v, i) => {
                          return (
                            <Option key={i} value={i}>
                              {v.type}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="price"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Input
                      style={{ border: "2px solid  #2d808b" }}
                      type={"number"}
                      value={price}
                      placeholder={t("placeholders.price")}
                      addonAfter={t("Modal.riyal")}
                      onChange={(e) => {
                        setCPrice(e.target.value);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Input.Group>
            <Input.Group>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="estimateTime"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Input
                      placeholder={t("placeholders.estimateTime")}
                      style={{ border: "2px solid  #2d808b" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="distance"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Input
                      style={{ border: "2px solid  #2d808b" }}
                      value={distance}
                      placeholder={t("placeholders.distance")}
                      addonAfter={t("Modal.km")}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Input.Group>
            <Input.Group>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="commission"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Input
                      type={"number"}
                      // addonBefore={commissionPrice}
                      placeholder={t("placeholders.commission")}
                      style={{ border: "2px solid  #2d808b" }}
                      addonAfter={"%"}
                      onChange={(e) => {
                        setcommission(e.target.value);
                        form.setFieldsValue({ commission: e.target.value });
                        console.log(e.target.value);
                      }}
                    />
                    <span>
                      {commissionPrice > 0
                        ? `Commision in Price ${commissionPrice} Riyal`
                        : ""}
                    </span>
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="vat"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Input
                      type={"number"}
                      // addonBefore={commissionPrice}
                      placeholder="VAT"
                      style={{ border: "2px solid  #2d808b" }}
                      addonAfter={"%"}
                      onChange={(e) => {
                        setVat(e.target.value);
                        form.setFieldsValue({ vat: e.target.value });
                        console.log(e.target.value);
                      }}
                    />
                    <span>
                      {vatPrice > 0
                        ? `Commision in Price ${vatPrice} Riyal`
                        : ""}
                    </span>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <label
                    htmlFor=""
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "20px",
                    }}
                  >
                    <p>Is Relation</p>
                    <Switch
                      defaultChecked={isToggleOn}
                      onChange={onChangeChecked}
                    />
                  </label>
                </Col>
              </Row>
            </Input.Group>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-btn"
                // className="login-form-button"
              >
                {t("Modal.make")}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
}
