import React, { useEffect, useState } from "react";
import {
  collection,
  query,
  onSnapshot,
  orderBy,
  getDoc,
  doc,
} from "firebase/firestore";
import { db } from "../firebase";
export default function useFinance() {
  const [finance, setFinance] = useState([]);
  const getData = async () => {
    let q = query(collection(db, "finance"));
    const unsub = onSnapshot(q, (querySnapShot) => {
      if (!querySnapShot.empty) {
        setFinance([]);
        let arr = [];
        querySnapShot.forEach(async (val) => {
          let data = {};
          let owner = {};
          let customer = {};
          try {
            let o = await getDoc(doc(db, "owners", val.data().ownerId));
            owner = o.data();
          } catch (e) {}

          try {
            let c = await getDoc(doc(db, "customers", val.data().customerId));
            customer = c.data();
          } catch (e) {}

          // if (ownersnap.exists() && customersnap.exists()) {

          data = {
            ...val.data(),
            customer,
            owner,
          };
          arr.push(data);
          setFinance(arr);
        });
      }
    });

    return () => unsub();
  };
  useEffect(() => {
    getData();
  }, []);

  return { finance };
}
