import React, { useEffect, useState } from "react";
import {
  collection,
  query,
  onSnapshot,
  where,
  orderBy,
  getDoc,
  getDocs,
} from "firebase/firestore";
import { db } from "../firebase";
import useOwners from "./useOwners";
import useTransports from "./useTransports";
export default function useAcceptedRequests() {
  const { owners } = useOwners();
  const { transports } = useTransports();
  const [acceptedRequests, setacceptedRequests] = useState([]);
  const getAllRequests = async () => {
    let q = query(
      collection(db, "customer_transport_requests"),
      orderBy("createdAt", "desc")
    );
    let Transports = await getDocs(collection(db, "transports"));
    const unsub = onSnapshot(q, (querySnapShot) => {
      setacceptedRequests([]);


      if (!querySnapShot.empty) {
        querySnapShot.forEach((val) => {
          if (
            (val.data().isStarted === undefined ||
              val.data().isStarted === false) &&
            val.data().isAccept !== undefined &&
            val.data().isAccept &&
            owners.length > 0 &&
            transports.length > 0
          ) {
            let ownerId = val.data().acceptedBy?.ownerId;
            let transportId = val.data().acceptedBy?.transportId;
            let owner = owners.find((v) => v.uid === ownerId);
            let transport = transports.find((v) => v.uid === transportId);
            setacceptedRequests((prev) => [
              ...prev,
              { request: val.data(), owner, transport: transport },
            ]);
          }
        });
      }
    });

    return () => unsub();
  };

  useEffect(() => {
    getAllRequests();
  }, [JSON.stringify(transports), JSON.stringify(owners)]);

  return { acceptedRequests };
}
