import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SearchOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { MdOutlineCancelPresentation } from "react-icons/md";
import { Table, Input, Popconfirm, Space } from "antd";
import useAcceptedRequests from "../Hooks/useAcceptedRequests";
import {
  AddFinance,
  sendMessage,
  sendNotification,
  updateRequest,
} from "../API/API";
//firebase
import { db } from "../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import Loader from "../Loaders/Loader";
import { useTranslation } from "react-i18next";

export default function AcceptedRequestsTable() {
  const navigate = useNavigate();
  const { acceptedRequests } = useAcceptedRequests();
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const start = async (record) => {
    // setLoading(true);

    let data = {
      isStarted: true,
      sendTo: [record.request.acceptedBy.ownerId],
      driverStarted: false,
    };
    let financeData = {
      receivedFromCustomer: true,
      sendToOwner: false,
      ownerId: record.request.acceptedBy.ownerId,
      customerId: record.request.customerId,
      driverId: record.request.acceptedBy.driverId,
      commission: record.request.commission,
      fare: record.request.price,
      from: record.request.from,
      to: record.request.to,
      requestId: record.request.requestId,
    };
    await updateDoc(
      doc(db, "customer_transport_requests", record.request.uid),
      data
    )
      .then(async () => {
        let data = {
          title: "Request Approved!",
          desc: `Request from ${record.request.name} for  ${record.request.from} to ${record.request.to} are approved  `,
          to: [
            record.request.acceptedBy.ownerId,
            record.request.customerId,
            record.request.acceptedBy.driverId,
            "admin",
          ],
          type: "request",
          status: "approved",
        };

        await sendNotification(record.request.notificationId, data).then(
          async () => {
            sendPushNotification(record)
              .then(async () => {
                await AddFinance(record.request.uid, financeData);
              })
              .catch((e) => {
                setLoading(false);
                alert(e.code);
              });
          }
        );
      })
      .catch((e) => {
        setLoading(false);
        alert(e.code);
      });
  };
  const cancel = async (record) => {
    // setLoading(true);
    let arr = record.request.sendTo;
    let index = record.request.sendTo.indexOf(
      record.request.acceptedBy.ownerId
    );
    arr.splice(index, 1);

    let data = {
      isStarted: false, 
      acceptedBy: {},
      sendTo: arr,
      isAccept: false,
    };
    let nData = {
      title: "Cancel Request!",
      desc: "Your Request For Order Was Cancelled By Admin",
      type: "request",
      to: [record.request.acceptedBy.ownerId],
      status: "cancelled",
    };
    await sendNotification(record.request.notificationId, nData);
    await updateDoc(
      doc(db, "customer_transport_requests", record.request.uid),
      data
    )
      .then(async () => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const sendPushNotification = async (record) => {
    let ids = [
      record.request.acceptedBy.ownerId,
      record.request.customerId,
      record.request.acceptedBy.driverId,
    ];
    // await Promise.all(
    //   ids.map(async (id) => {
    //     let snap = await getDoc(doc(db, "fcm_tokens", id));
    //     if (snap.exists() && snap.data().token !== "") {
    //       let data = {
    //         title: "Approved Ride!",
    //         body: `Your Ride ${record.request.from} to ${record.request.to} Approved by Admin`,
    //         data: record,
    //         token: snap.data().token,
    //       };
    //       await sendMessage(data);
    //     }
    //   })
    // );
  };
  let columns = [
    {
      title: t("tableTabs.requst"),
      children: [
        {
          title: t("tableTabs.id"),
          render: (record) => (
            <span title={record?.request?.requestId}>
              {record?.request?.requestId}
            </span>
          ),
        },
        {
          title: t("tableTabs.name"),
          render: (recored) => <span>{recored.request?.name}</span>,
        },
        {
          title: t("tableTabs.phoneNumber"),
          render: (recored) => <span>{recored.request?.phoneNumber}</span>,
        },
        {
          title: t("tableTabs.from"),
          render: (recored) => <span>{recored.request?.from}</span>,
        },
        {
          title: t("tableTabs.to"),
          render: (recored) => <span>{recored.request?.to}</span>,
        },
        {
          title: t("tableTabs.price"),
          render: (recored) => <span>{recored.request?.price}</span>,
        },
      ],
    },
    // 
    {
      title: t("tableTabs.acceptedBy"),
      children: [
        {
          title: t("tableTabs.owner"),
          render: (recored) => <span>{recored.owner?.name}</span>,
        },
        {
          title: t("tableTabs.phoneNumber"),
          render: (recored) => <span>{recored.owner?.phoneNumber}</span>,
        },
        {
          title: t("tableTabs.driver"),
          render: (recored) => (
            <span>{recored?.transport?.driver?.driverName}</span>
          ),
        },
        {
          title: t("tableTabs.driverId"),
          render: (recored) => (
            <span>{recored?.transport?.driver?.driverIdNumber}</span>
          ),
        },
        {
          title: t("tableTabs.truck"),
          render: (recored) => (
            <span>{recored?.transport?.truck?.truckPlate}</span>
          ),
        },
      ],
    },
    {
      title: t("tableTabs.action"),
      dataIndex: "",
      key: "x",
      align: "center",
      render: (record) => (
        <Space>
          <span
            title="View Request"
            className="eyes-btn"
            onClick={() => {
              navigate(`/request_detail/${record.request.uid}`, {
                state: record,
              });
            }}
          >
            <EyeOutlined />
          </span>
          {record.request.isStarted === true ? (
            ""
          ) : (
            <>
              <Popconfirm
                title={"Are you sure you want to delete?"}
                okText="Ok"
                cancelText="Cancel"
                onConfirm={() => {
                  start(record);
                }}
              >
                <span title="start riding" className="ant-btn ant-btn-primary">
                  {/* <DeleteOutlined /> */}
                  {"start"}
                </span>
              </Popconfirm>
              <Popconfirm
                title={"Are you sure you want to delete?"}
                okText="Ok"
                cancelText="Cancel"
                onConfirm={() => {
                  cancel(record);
                }}
              >
                <span className="ant-btn ant-btn-danger">
                  {/* <DeleteOutlined /> */}
                  <i title="cancel">
                    <MdOutlineCancelPresentation />
                  </i>
                </span>
              </Popconfirm>
            </>
          )}
        </Space>
      ),
    },
  ];

  return (
    <div>
      {loading ? <Loader /> : ""}
      <div>
        <div className="table_search_div">
          <Input
            allowClear
            size="middle"
            placeholder={t("placeholders.search")}
            prefix={<SearchOutlined />}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </div>
        <Table
          bordered
          columns={columns}
          dataSource={
            acceptedRequests &&
            acceptedRequests?.length > 0 &&
            acceptedRequests?.filter((val) => {
              if (search == "") {
                return val;
              } else if (
                val &&
                Object.keys(val).some((v) =>
                  val[v]
                    .toString()
                    .toLowerCase()
                    .includes(search.toString().toLowerCase())
                )
              ) {
                return val;
              }
            })
          }
        />
      </div>
    </div>
  );
}
