// import React, { useEffect, useState } from "react";
// import {
//   collection,
//   query,
//   onSnapshot,
//   where,
//   orderBy,
// } from "firebase/firestore";
// import { db } from "../firebase";
// export default function useAllRequests() {
//   const id = JSON.parse(sessionStorage.getItem("uid"));
//   const [allRequests, setAllRequests] = useState([]);
//   const getAllRequests = async () => {
//     let q = query(
//       collection(db, "customer_transport_requests"),
//       orderBy("createdAt", "desc")
//     );
//     const unsub = onSnapshot(q, (querySnapShot) => {
//       setAllRequests([]);
//       if (!querySnapShot.empty) {
//         querySnapShot.forEach((val) => {
//           setAllRequests((prev) => [...prev, val.data()]);
//         });
//       }
//     });

//     return () => unsub();
//   };
//   useEffect(() => {
//     getAllRequests();
//   }, []);

//   return { allRequests };
// }
import { useEffect, useState, useCallback } from "react";
import { useQuery } from "@apollo/client";
import { GET_ALL_REQUEST } from "../../Graphql/queries/transportationQueries";

function useGetAllRequests(trigger, paginationFilters) {
  const [requests, setRequests] = useState([]);
  const [count, setCount] = useState(0);

  const { loading, error, data, refetch } = useQuery(GET_ALL_REQUEST, {
    variables: { page: paginationFilters?.pageNo },
    fetchPolicy: "network-only",
  });

  const getAllRequests = useCallback(async () => {
    if (loading) return;
    if (error) {
      console.log("Error:", error.message);
      return;
    }
    
    const { customerRequests } = data;
    if (!customerRequests) {
      console.log("No Owner data available.");
      return;
    }
    const { requests, totalDocs } = customerRequests;
    setRequests(requests);
    setCount(totalDocs);
  }, [loading, error, data]);

  useEffect(() => {
    getAllRequests();
    if (trigger) {
      refetch({ page: paginationFilters?.pageNo });
    }
  }, [trigger, getAllRequests, paginationFilters?.pageNo, refetch]);

  return { loading, error, requests, count };
}

export default useGetAllRequests;
