// import React, { useEffect, useState } from "react";
// import { collection, query, onSnapshot } from "firebase/firestore";
// import { db } from "../firebase";
// export default function useExtraCare() {
//   const [extracare, setExtracare] = useState([]);
//   const getData = async () => {
//     let q = query(collection(db, "services", "data", "extracharges"));
//     const unsub = onSnapshot(q, (querySnapShot) => {
//       setExtracare([]);
//       if (!querySnapShot.empty) {
//         querySnapShot.forEach((val) => {
//           setExtracare((prev) => [...prev, val.data()]);
//         });
//       }
//     });

//     return () => unsub();
//   };
//   useEffect(() => {
//     getData();
//   }, []);

//   return { extracare };
// }
// import React, { useEffect, useState } from "react";
// import { collection, query, onSnapshot } from "firebase/firestore";
// import { db } from "../firebase";
// export default function useDestination() {
//   const [destination, setExtracare] = useState([]);
//   const getData = async () => {
//     let q = query(collection(db, "services", "data", "destination"));
//     const unsub = onSnapshot(q, (querySnapShot) => {
//       setExtracare([]);
//       if (!querySnapShot.empty) {
//         querySnapShot.forEach((val) => {
//           setExtracare((prev) => [...prev, val.data()]);
//         });
//       }
//     });

//     return () => unsub();
//   };
//   useEffect(() => {
//     getData();
//   }, []);

//   return { destination };
// }
import { useEffect, useState, useCallback } from "react"; // Import your GraphQL query for destination
import { useQuery } from "@apollo/client";
import {
  GET_All_DRIVER,
  GET_MY_DRIVER,
} from "../../Graphql/queries/driverQueries";
import { GET_ALL_EXTRACARE } from "../../Graphql/queries/servicesQueries";

// ... other imports ...

function useGetAllExtraCare(trigger, paginationFilters) {
  const [extraCare, setExtracare] = useState([]);
  const [count, setCount] = useState(0);

  const { loading, error, data, refetch } = useQuery(
    GET_ALL_EXTRACARE, // Use GET_All_DRIVER when ownerId is not provided
    {
      variables: { page: paginationFilters?.pageNo },
      fetchPolicy: "network-only",
    }
  );

  const getDestination = useCallback(() => {
    if (loading) return;
    if (error) {
      console.log("Error:", error.message);
      return;
    }
    const {allCares} = data
    if (!allCares) {
      console.log("No driver data available.");
      return;
    }
    const { cares, totalDocs } = allCares;
    setExtracare(cares);
    setCount(totalDocs);
  }, [loading, error, data]);

  useEffect(() => {
    getDestination();
    if (trigger) {
      refetch({ page: paginationFilters?.pageNo });
    }
  }, [trigger, getDestination, paginationFilters?.pageNo, refetch]);

  return { loading, error, extraCare, count };
}

export default useGetAllExtraCare;
