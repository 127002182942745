import React, { useState, useEffect } from "react";
import "./login.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { LockOutlined, UserOutlined, MailOutlined } from "@ant-design/icons";
import { Button, Form, Input, Radio, message } from "antd";
import { isSupported } from "firebase/messaging";

//Api
import { FindUser } from "../API/API";
//loader
import Loader from "../Loaders/Loader";
//Redux
import { Language } from "../../Redux/Actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { ADMIN_LOGIN } from "../../Graphql/mutation/adminMutation";
import { useMutation } from "@apollo/client";
export default function Login() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { lng } = useSelector((state) => state.userReducer);
  const navigate = useNavigate();
  const [loginAdmin] = useMutation(ADMIN_LOGIN);
  const [loading, setLoading] = useState(false);
  const onFinishLogin = async (values) => {
    debugger;
    setLoading(true);
    let user = {
      ...values,
      userEmail: values.userEmail.toLowerCase(),
    };
    // const res = await loginAdmin({
    //   variables: {
    //     loginInput: {
    //       email: values.userEmail,
    //       password: values.userPassword,
    //     },
    //   },
    // })
   
    const admin = await FindUser(user) // Pass loginAdmin as an argument
    debugger;
    console.log('here is the admin',admin)
    const { code, message, status, data } = admin??{};
        console.log("🚀 ~ file: Login.jsx:43 ~ onFinishLogin ~ data:", admin,status)
        if (status ==='success') {
          sessionStorage.setItem("token", JSON.stringify(data.token));
          sessionStorage.setItem("info", JSON.stringify(data));
          setLoading(false);
          // message.success("Login Successfully!");
          navigate("/owners");
        } else {
          setLoading(false);
          message.error(code);
        }
        setLoading(false);
        // Rest of your error handling logic
      
  };

  useEffect(() => {
    isSupported()
      .then((value) => {
        if (value) {
          Notification.requestPermission().then((permission) => {
            if (permission === "granted") {
              console.log("Notification permission granted.");
            } else {
              alert(
                "Notification are not allowed for this site please check setting and allow notifications for this site"
              );
            }
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <div className="main_login_div">
      {loading ? <Loader /> : ""}
      <div className="login_background_div">
        <div className="login_content_div">
          <div className="login_wlp_div"></div>
          <div className="login_form_div">
            <div className="login_form_content_div">
              <div className="login_form_content_upper_div">
                <div className="login_form_content_upper_logo_div">
                  <div className="logo_div">
                    <img className="logo_img" src="/assets/logo.png" />
                  </div>
                </div>
                <div className="login_form_content_upper_lng_div">
                  <div className="change-locale">
                    <button
                      style={{ marginTop: "10px" }}
                      className={` ar ${lng == "ar" ? "hello-arabic" : ""}`}
                      onClick={() => {
                        dispatch(Language("ar"));
                        i18n.changeLanguage("ar");
                      }}
                    >
                      العربية
                    </button>
                    <button
                      className={` ar ${lng == "en" ? "hello-english" : ""}`}
                      onClick={() => {
                        dispatch(Language("en"));
                        i18n.changeLanguage("en");
                      }}
                    >
                      English
                    </button>
                  </div>
                </div>
              </div>
              <div className="login_form_content_from_div">
                <div className="form_div">
                  <div>
                    <h2>{t("welcome")}</h2>
                  </div>

                  <>
                    <Form
                      name="normal_login"
                      className="login-form"
                      onFinish={onFinishLogin}
                    >
                      <Form.Item
                        name="userEmail"
                        hasFeedback
                        rules={[
                          {
                            type: "email",
                            message: t("errorsTxt.invalidEmail"),
                          },
                          {
                            required: true,
                            message: t("errorsTxt.requiredField"),
                          },
                        ]}
                      >
                        <Input
                          type="email"
                          prefix={
                            <MailOutlined className="site-form-item-icon" />
                          }
                          placeholder={t("placeholders.inputEmail")}
                        />
                      </Form.Item>
                      <Form.Item
                        name="userPassword"
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: t("errorsTxt.requiredField"),
                          },
                          {
                            min: 8,
                            message: t("errorsTxt.minimumLength"),
                          },
                        ]}
                      >
                        <Input.Password
                          prefix={
                            <LockOutlined className="site-form-item-icon" />
                          }
                          type="password"
                          placeholder={t("placeholders.inputPass")}
                          minLength={8}
                        />
                      </Form.Item>

                      <Form.Item>
                        <Button
                          htmlType="submit"
                          className="login-form-button submit_btn login-btn"
                        >
                          <b>{t("buttonsTxt.login")}</b>
                        </Button>
                      </Form.Item>
                    </Form>
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
