import React, { useState } from "react";
import { Table, Button, Input, Space, Popconfirm, Pagination } from "antd";
import "./table.css";
import { SearchOutlined } from "@ant-design/icons";

import useOwners from "../Hooks/useOwners";
import useAllRequests from "../Hooks/useAllRequests";
import { SendRequest, updateRequest } from "../API/API";
import { useNavigate, useParams } from "react-router-dom";

export default function DriverManagmentTable() {
  const { owners } = useOwners();
  const { allRequests } = useAllRequests();
  const { id } = useParams();
  const navigate = useNavigate();

  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState([]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
  ];
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(
      //   `selectedRowKeys: ${selectedRowKeys}`,
      //   "selectedRows: ",
      //   selectedRows
      // );
      setSelected(selectedRowKeys);
    },
    // onSelect: (record, selected, selectedRows) => {
    //   console.log(record, selected, selectedRows);
    // },
    // onSelectAll: (selected, selectedRows, changeRows) => {
    //   console.log(selected, selectedRows, changeRows);
    // },
  };

  return (
    <div>
      <div className="table_topar_add_div_">
        <Button
          type="primary"
          size={"middle"}
          onClick={async () => {
            if (selected.length > 0) {
              let request =
                allRequests && allRequests.find((v) => v.uid === id);
              let data = {
                ...request,
                sendTo: selected,
                status: "underProcess",
              };
              await SendRequest(id, data).then(() => {
                navigate(-1);
              });
            } else {
              alert("select owner first");
            }
          }}
        >
          {"Send"}
        </Button>
      </div>
      <div className="table_search_div">
        <Input
          allowClear
          size="middle"
          placeholder="Serach"
          prefix={<SearchOutlined />}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      </div>
      <Table
        columns={columns}
        rowKey={(record) => record.uid}
        rowSelection={{
          ...rowSelection,
          checkStrictly: true,
          // defaultSelectedRowKeys: ["jUcvD6Cz1IZoJKhTp1aftgV3Rus2"],
          // selectedRowKeys: ["jUcvD6Cz1IZoJKhTp1aftgV3Rus2"],
        }}
        dataSource={owners
          .filter((v) => v.isApproved && v.emailVerified && v.isAccept)
          .filter((val) => {
            if (search == "") {
              return val;
            } else if (
              val &&
              Object.keys(val).some((v) =>
                val[v]
                  .toString()
                  .toLowerCase()
                  .includes(search.toString().toLowerCase())
              )
            ) {
              return val;
            }
          })}
      />
    </div>
  );
}
