import React, { useEffect, useState } from "react";
import { Tabs, Badge, Pagination } from "antd";
import { useTranslation } from "react-i18next";
import AllRequestsTable from "../Tables/AllRequestsTable";
import NewRequestTable from "../Tables/NewRequestTable";
import UnderProcessRequestTable from "../Tables/UnderProcessRequestTable";
import PendingTable from "../Tables/PendingRequestsTable";
import FinishedRequestTable from "../Tables/FinishedRequestsTable";
import AcceptedRequestsTable from "../Tables/AcceptedRequestsTable";
import OnTheWayRequestsTable from "../Tables/OnTheWayRequestsTable";
import useNotifications from "../Hooks/useNotifications";
import { readNotifications } from "../API/API";
import useAllRequests from "../Hooks/useAllRequests";
import useAcceptedRequests from "../Hooks/useAcceptedRequests";
import useOnTheWayRequests from "../Hooks/useOntheWayRequests";
import useCheckActiveTab from "../Hooks/useCheckActiveTab";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import useGetAllRequests from "../Hooks/useAllRequests";
import { REQUEST_UPDATE } from "../../Graphql/mutation/transportationMutation";
import { useMutation } from "@apollo/client";
// import useGetAllRequests from "../Hooks/useAllRequests";

export default function Requests() {
  const { activeTab, handleTabChange } = useCheckActiveTab();
  const [updateRequest] = useMutation(REQUEST_UPDATE);
  // const { allRequests } = useAllRequests();
  // const requests = allRequests;
  const [loading, setLoading] = useState(false);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [paginationFilters, setPaginationFilters] = useState({
    pageNo: 1,
    pageSize: 5,
    limitSkip: 0,
    limit: 10,
  });
  const { requests, count} = useGetAllRequests(updateTrigger,paginationFilters);
  // setDrivers(drivers);
  const handleChange = (value, pageSize) => {
    const limitSkip = value * pageSize;
    const limit = pageSize;
    setPaginationFilters({ pageNo: value, pageSize, limitSkip, limit });
    setLoading(true);
    setUpdateTrigger(!updateTrigger);
  };
  const filterLength = requests?.filter((ele => ele.isSeen !== true )) 
  const filterFinish = requests?.filter((ele => ele.isFinished !== true)) 
  const filterNewReq = requests?.filter((ele => ele.isNewRequest !== true)) 


  const { notifications } = useNotifications();
  const { TabPane } = Tabs;
  const { t } = useTranslation();
  const { acceptedRequests } = useAcceptedRequests();
  const { onthewayrequests } = useOnTheWayRequests();
  const [val, setVal] = useState(0);
  const [val_oldd, setVal_oldd] = useState(0);
  const created =
    notifications &&
    notifications.filter(
      (v) => !v.isRead && v.status?.toLowerCase().includes("created")
    );

  const accepted =
    notifications &&
    notifications.filter(
      (v) => !v.isRead && v.status?.toLowerCase().includes("accepted")
    );
  const underProcess =
    notifications &&
    notifications.filter(
      (v) =>
        !v.isRead &&
        v.status?.toLowerCase().includes("underProcess".toLowerCase())
    );
  const pending =
    notifications &&
    notifications.filter(
      (v) => !v.isRead && v.status?.toLowerCase().includes("pending")
    );
  const ontheway =
    notifications &&
    notifications.filter(
      (v) =>
        !v.isRead &&
        (v.status?.toLowerCase().includes("approved") ||
          v.status?.toLowerCase().includes("started"))
    );
  const finished =
    notifications &&
    notifications.filter(
      (v) => !v.isRead && v.status?.toLowerCase().includes("finished")
    );


 
  const handleViewDate = async (values) => {
    console.log("🚀 ~ file: Requests.jsx:98 ~ handleViewDate ~ values:", values)
    debugger;
    try {
      for (const val of filterLength) {
        console.log("🚀 ~ file: Requests.jsx:101 ~ handleViewDate ~ val:", val)
        // const Ref = doc(db, "customer_transport_requests", val.uid);
        // await updateDoc(Ref, {
        //   isSeen: true
        // });
        await updateRequest({
          variables: {
            id: val._id,
            updateRequestInput: {
              isSeen: true,
            },
          },
        });
      }
    } catch (error) {
      console.error('Error updating status:', error);
      alert('An error occurred while updating the status.');
    }
  };
  const handleViewFinish = async () => {
    debugger;
    try {
      for (const val of filterFinish) {
        // const Ref = doc(db, "customer_transport_requests", val.uid);
        // await updateDoc(Ref, {
        //   isFinished: true
        // });
        await updateRequest({
          variables: {
            id: val._id,
            updateRequestInput: {
              isFinished: true,
            },
          },
        });
      }
    } catch (error) {
      console.error('Error updating status:', error);
      alert('An error occurred while updating the status.');
    }
  };
  const handleViewNewReq = async () => {
    debugger;
    try {
      for (const val of filterNewReq) {
        // const Ref = doc(db, "customer_transport_requests", val.uid);
        // await updateDoc(Ref, {
        //   isNewRequest: true
        // });
        await updateRequest({
          variables: {
            id: val._id,
            updateRequestInput: {
              isNewRequest: true,
            },
          },
        });
      }
    } catch (error) {
      console.error('Error updating status:', error);
      alert('An error occurred while updating the status.');
    }
  };
  


  return (
    <div>
      <div>
        <h3>{t("tableTabs.allRequests")}</h3>
      </div>
      <div>
        <Tabs onChange={handleTabChange} activeKey={activeTab}>
          <TabPane
            tab={
              <Badge
                onClick={() => {
                  readNotifications(created);
                  handleViewDate();

                }}
                offset={[5, -6]}
                count={
                  <span
                    style={{ backgroundColor: "green" }}
                    className="Span-reqest"
                  >
                    {requests?.length}
                  </span>
                }
              >
                <span>{t("tableTabs.allRequests")}</span>
              </Badge>
            }
            key="1"
          >
            <AllRequestsTable />
          </TabPane>
         <TabPane
            tab={
              <Badge
                onClick={() => {
                  readNotifications(created);
                  handleViewNewReq()
                }}
                offset={[5, -6]}
                count={
                  <span
                    style={{ backgroundColor: "blue" }}
                    className="Span-reqest"
                  >
                    {
                      requests?.filter(
                        (v) =>
                          // v.status === "pending" &&
                          v?.offlinePaid !== true &&
                          v?.isPaid !== true 
                      ).length
                    }
                  </span>
                }
                dot={created && created.length > 0 ? true : false}
              >
                <span>{t("tableTabs.newRequests")}</span>
              </Badge>
            }
            key="2"
          >
            <NewRequestTable />
          </TabPane>

           <TabPane
            tab={
              <Badge
                onClick={() => {
                  readNotifications(pending);

                }}
                offset={[5, -6]}
                count={
                  <span
                    style={{ backgroundColor: "brown" }}
                    className="Span-reqest"
                  >
                    {
                      requests?.filter(
                        (v) =>
                          // v.status === "pending" &&
                          v?.offlinePaid === true &&
                          v?.isPaid !== false
                      ).length
                    }
                  </span>
                }
                dot={pending && pending.length > 0 ? true : false}
              >
                <span>{t("tableTabs.needToPay")}</span>
              </Badge>
            }
            key="3"
          >
            <PendingTable />
          </TabPane>
         <TabPane
            tab={
              <Badge
                onClick={() => {
                  readNotifications(underProcess);
                }}
                offset={[5, -6]}
                count={
                  <span
                    style={{ backgroundColor: "#001529" }}
                    className="Span-reqest"
                  >
                    {
                      requests?.filter((v) => v.status === "underProcess")
                        .length
                    }
                  </span>
                }
                dot={underProcess && underProcess.length > 0 ? true : false}
              >
                <span>{t("tableTabs.underProcess")}</span>
              </Badge>
            }
            key="4"
          >
            <UnderProcessRequestTable />
          </TabPane>
            <TabPane
            tab={
              <Badge
                onClick={() => {
                  readNotifications(accepted);
                }}
                offset={[5, -6]}
                count={
                  <span
                    className="Span-reqest"
                    style={{ backgroundColor: "#d06d2c " }}
                  >
                    {acceptedRequests.length}
                  </span>
                }
                dot={accepted && accepted.length > 0 ? true : false}
              >
                <span>{t("tableTabs.acceptedRequests")}</span>
              </Badge>
            }
            key="5"
          >
            <AcceptedRequestsTable />
          </TabPane>
          <TabPane
            tab={
              <Badge
                onClick={() => {
                  readNotifications(ontheway);
                }}
                offset={[5, -6]}
                count={
                  <span
                    style={{ backgroundColor: " seagreen" }}
                    className="Span-reqest"
                  >
                    {onthewayrequests.length}
                  </span>
                }
                dot={ontheway && ontheway.length > 0 ? true : false}
              >
                <span>{t("tableTabs.onTheWayRequests")}</span>
              </Badge>
            }
            key="6"
          >
            <OnTheWayRequestsTable />
          </TabPane>

          <TabPane
            tab={
              <Badge
                onClick={() => {
                  readNotifications(finished);
                  handleViewFinish()
                }}
                offset={[5, -6]}
                count={
                  requests?.filter((v) => v.status === "finished").length
                }
                dot={finished && finished.length > 0 ? true : false}
              >
                <span>{t("tableTabs.finishedRequests")}</span>
              </Badge>
            }
            key="7"
          >
            <FinishedRequestTable />
          </TabPane>
        </Tabs>
      
      </div>
      {/*  */}
    </div>
  );
}
