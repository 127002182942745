import { gql } from "@apollo/client";

const GET_MY_DRIVER_TRUCK = gql`
query MyDriverTrucks($ownerId: ID!, $page: Int!) {
  myDriverTrucks(ownerId: $ownerId, page: $page) {
    currentPage
    totalDocs
    totalPages
    driverTrucks {
      _id
      createdAt
      driver {
        _id
        age
        createdAt
        idNo
        address
        imageUrl
        name
        nationality
        owner {
          _id
        }
      }
      driverName
      truck {
        _id
        createdAt
        height
        imageUrl
        model
        noPlate
        capacity
        serviseType
        status
        type
      }
      truckNo
    }
  }
}
`;
const GET_DRIVER_TRUCK_ID = gql`
  query Customer($id: ID!) {
    customer(ID: $id) {
      email
    }
  }
`;
const GET_DRIVER_TRUCK_EMAIL = gql`
  query CustomerEmail($email: String) {
    customerEmail(email: $email) {
      email
      firebaseEmail
      id
      token
      uid
      userName
      url
    }
  }
`;

export { GET_DRIVER_TRUCK_ID, GET_MY_DRIVER_TRUCK, GET_DRIVER_TRUCK_EMAIL };
