// import React, { useEffect, useState } from "react";
// import {
//   collection,
//   query,
//   onSnapshot,
//   where,
//   getDoc,
//   doc,
// } from "firebase/firestore";
// import { db } from "../firebase";
// export default function useTransports() {

//   const ownerId = JSON.parse(sessionStorage.getItem("ownerId"));
//   const [transportss, setTransports] = useState([]);
//   const getTrucks = async () => {
//     let q = query(
//       collection(db, "transports"), where("ownerId", "==", ownerId)
//     );
//     const unsub = onSnapshot(q, (querySnapShot) => {
//       setTransports([]);
//       if (!querySnapShot.empty) {
//         setTransports([]);
//         querySnapShot.forEach(async (val) => {
//           let driver = await getDoc(doc(db, "drivers", val.data().driver.uid));
//           let truck = await getDoc(doc(db, "trucks", val.data().truck.uid));
//           if (driver.exists() && truck.exists()) {
//             let data = {
//                ...val.data(),
//               driver: driver.data(),
//               truck: truck.data(),
//             };
//             setTransports((prev) => [...prev, data]);
//           }
//         });
//       }
//     });

//     return () => unsub();
//   };
//   useEffect(() => {
//     getTrucks();
//   }, []);
//   let key = "uid";
//   let transports = [
//     ...new Map(transportss.map((item) => [item[key], item])).values(),
//   ];

//   return { transports };
// }
// import React, { useEffect, useState } from "react";
// import { collection, query, onSnapshot, where } from "firebase/firestore";
// import { db } from "../firebase";
// export default function useTrucks() {
//   const ownerId = JSON.parse(sessionStorage.getItem("ownerId"));
//   const [trucks, setTrucks] = useState([]);
//   const getTrucks = async () => {
//     let q = query(collection(db, "trucks"), where("ownerId", "==", ownerId));
//     const unsub = onSnapshot(q, (querySnapShot) => {
//       setTrucks([]);
//       if (!querySnapShot.empty) {
//         querySnapShot.forEach((val) => {
//           setTrucks((prev) => [...prev, val.data()]);
//         });
//       }
//     });

//     return () => unsub();
//   };
//   useEffect(() => {
//     getTrucks();
//   }, []);


//   return { trucks };
// }
// import React, { useEffect, useState } from "react";
// import { collection, query, onSnapshot,where, } from "firebase/firestore";
// import { db } from "../firebase";
// export default function useDrivers() {
//  const ownerId = JSON.parse(sessionStorage.getItem("ownerId"));
//   const [trucks, setTrucks] = useState([]);
//   const getDrivers = async () => {
//     let q = query(collection(db, "trucks"), where("ownerId", "==", ownerId));
//     const unsub = onSnapshot(q, (querySnapShot) => {
//       setTrucks([]);
//       if (!querySnapShot.empty) {
//         querySnapShot.forEach((val) => {
//           setTrucks((prev) => [...prev, val.data()]);
//         });
//       }
//     });

//     return () => unsub();
//   };
//   useEffect(() => {
//     getDrivers();
//   }, []);

//   return { trucks };
// }
// import { getDoc, doc } from "firebase/firestore";
// import { useEffect, useState } from "react";
// import { db } from "../firebase";

// export default function useOwner() {
//   const [owner, setOwner] = useState({});
//   let uid = JSON.parse(sessionStorage.getItem("ownerId"));

//   const getData = async () => {
//     let userRef = doc(db, "trucks", uid);
//     let snap = await getDoc(userRef);
//     if (snap.exists()) {
//       setOwner(snap.data());
//     }
//   };

//   useEffect(() => {
//     getData();
//   }, [uid]);

//   return { owner };
// }
import { useEffect, useState, useCallback } from "react"; // Import your GraphQL query for trucks
import { useQuery } from "@apollo/client";
import { GET_MY_DRIVER_TRUCK } from "../../Graphql/queries/driverTrucksQueries";

function useGetAllRequests(trigger, paginationFilters, ownerId) {
  console.log("🚀 ~ file: useTrucks.js:79 ~ useGetMyTrucks ~ paginationFilters:", paginationFilters)
  const [driverTrucks, setDriverTrucks] = useState([]);
  const [count, setCount] = useState(0);

  const { loading, error, data, refetch } = useQuery(GET_MY_DRIVER_TRUCK, {
    variables: { page: paginationFilters?.pageNo, ownerId },
    fetchPolicy: "network-only",
  });

  const getMyDriverTrucks = useCallback(async () => {
    if (loading) return;
    if (error) {
      console.log("Error:", error.message);
      return;
    }
    const { myDriverTrucks } = data;
    if (!myDriverTrucks) {
      console.log("No Owner data available.");
      return;
    }
    const { driverTrucks, totalDocs } = myDriverTrucks;
    setDriverTrucks(driverTrucks);
    setCount(totalDocs);
  }, [loading, error, data]);

  useEffect(() => {
    getMyDriverTrucks();
    if (trigger) {
      refetch({ page: 1 });
    }
  }, [trigger, getMyDriverTrucks, 1, refetch]);

  return { loading, error, driverTrucks, count };
}

export default useGetAllRequests;

