import React, { useState, useEffect } from "react";
import "./header.css";
import moment from "moment";
import {
  Layout,
  Avatar,
  Dropdown,
  Radio,
  Card,
  Popconfirm,
  Badge,
  Drawer,
  notification,
  Tag,
} from "antd";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
  SettingOutlined,
  EditOutlined,
  EllipsisOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { IoNotificationsOutline } from "react-icons/io5";
//Hooks
import useNotifications from "../../Hooks/useNotifications";
//i18n
import { useTranslation } from "react-i18next";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { Language } from "../../../Redux/Actions/userActions";
import { Logout } from "../../API/API";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";

const { Header } = Layout;
const { Meta } = Card;

//
export default function MainHeader({ collapsed, setCollapsed }) {
  const { t, i18n } = useTranslation();
  const { alrt, alrtNotifications, notifications } = useNotifications();
  const dispatch = useDispatch();
  const { lng } = useSelector((state) => state.userReducer);
  const [visible, setVisible] = useState(false);
  const [adminName, setAdminName] = useState("");

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const getAdminDoc = async () => {
    console.log("🚀 ~ file: Header.jsx:61 ~ getAdminDoc ~ async:")
    
    // const id = JSON.parse(sessionStorage.getItem("uid"));
    // // const docRef = doc(db, "admin", id);
    // // const docSnap = await getDoc(docRef);
    // if (docSnap.exists()) {
    //   const id = docSnap?.data()?.name;
    //   console.log("Document data:", docSnap.data(), id);
    //   setAdminName(id);
    // } else {
    //   sessionStorage.clear();
    // }
  };

  useEffect(() => {
    getAdminDoc();
  }, []);

  const Profile = () => {
    return (
      <Card
        style={{
          width: 250,
        }}
        actions={[
          <LogoutOutlined key="logout" onClick={Logout} />,
          // <EditOutlined key="edit" />,
        ]}
      >
        <Meta
          avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
          title={adminName ? adminName : "admin"}
          description={"OAC ADMIN"}
        />
      </Card>
    );
  };
  const openNotification = (title, desc) => {
    const args = {
      message: title,
      description: desc,
      // duration: ,
    };
    notification.open(args);
  };
  useEffect(() => {
    if (alrt) {
      alrtNotifications.map((v, i) => {
        openNotification(v.title, v.desc);
      });
    }
  }, [alrt]);

  return (
    <div>
      <>
        <Drawer
          title="Notifications"
          placement="right"
          onClose={onClose}
          visible={visible}
        >
          {notifications &&
            notifications
              ?.sort((a, b) =>
                b.updatedAt
                  ? b.updatedAt - a.updatedAt
                  : b.createdAt - a.createdAt
              )
           
              .map((v, i) => {
                return (
                  <p key={i}>
                    <Badge.Ribbon
                      //  text={`From ${v.from}`}
                      text={` ${moment(
                        v.updatedAt
                          ? v.updatedAt.seconds * 1000
                          : v.createdAt?.seconds * 1000
                      ).calendar()}`}
                      color={"primary"}
                    >
                      <Card title={v.title} size="small">
                        <Badge dot={!v.isRead}>
                          <span>{v.desc}</span>
                        </Badge>
                        <Tag
                          key={v.uid}
                          color={
                            v.from === ("Customer" || "customers")
                              ? "orange"
                              : "cyan"
                          }
                        >
                          {`From ${v.from}`}
                        </Tag>
                      </Card>
                    </Badge.Ribbon>
                  </p>
                );
              })}
        </Drawer>
      </>
      <Header className="site-layout-background owner_header">
        <div className="header_main_div">
          <div className="header_trigger_div">
            {React.createElement(
              collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className: "trigger",
                onClick: () => setCollapsed(!collapsed),
              }
            )}
          </div>
          <div className="header_content_div">
            <div className="header_contnet_name_div">
              <h1>OAC ADMIN</h1>
            </div>
            <div className="header_content_profile_div">
              <div className="change-locale">
                <button
                  style={{ marginTop: "10px" }}
                  className={` ar ${lng == "ar" ? "hello-arabic" : ""}`}
                  onClick={() => {
                    dispatch(Language("ar"));
                    i18n.changeLanguage("ar");
                  }}
                >
                  العربية
                </button>
                <button
                  className={` ar ${lng == "en" ? "hello-english" : ""}`}
                  onClick={() => {
                    dispatch(Language("en"));
                    i18n.changeLanguage("en");
                  }}
                >
                  English
                </button>
              </div>

              <div className="header_profile_div">
                <div className="profile_avatar">
                  <Dropdown
                    overlay={Profile}
                    trigger={["click"]}
                    className="profile-pic"
                  >
                    <a onClick={(e) => e.preventDefault()}>
                      <Avatar
                        style={{
                          backgroundColor: "#87d068",
                        }}
                        icon={<UserOutlined />}
                      />
                    </a>
                  </Dropdown>
                </div>
                <div className="profile_dropdown_div">
                  <Dropdown overlay={Profile} trigger={["click"]}>
                    <a onClick={(e) => e.preventDefault()}>
                      <div
                        // style={{ marginTop: "4px" }}
                        className="admin-header"
                      >
                        {"Admin"}
                      </div>
                    </a>
                  </Dropdown>
                </div>
                <div>
                  <span>
                    <Badge
                      onClick={showDrawer}
                      count={
                        notifications &&
                        notifications.filter((v) => v.isRead === false).length
                      }
                    >
                      <i className="n_icon">
                        <IoNotificationsOutline />
                      </i>
                    </Badge>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Header>
    </div>
  );
}
