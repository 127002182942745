import { Button, Input, message, Popconfirm, Space, Table } from "antd";
import useMaintenanceAllServices from "../Hooks/useMaintenanceAllServices";
import React, { useState } from "react";
import {
  PlusCircleOutlined,
  SearchOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import MaintenanceModal from "../Modals/MaintenanceModal";
import { useTranslation } from "react-i18next";
import { doc, deleteDoc } from "firebase/firestore";
import { db } from "../firebase";
const MaintenanceTable = () => {
  const { maintenanceServices } = useMaintenanceAllServices();
  const [search, setSearch] = useState("");
  const { t } = useTranslation();

  const filteredData = maintenanceServices.filter((el) =>
    el.service.toLowerCase().includes(search.toLocaleLowerCase())
  );

  const deleteServiceHandler = async (id) => {
    try {
      await deleteDoc(doc(db, "maintenance-services", id));
      message.success("deleted!");
    } catch (error) {
      alert(error.message);
    }
  };

  const columns = [
    {
      title: t("service"),
      dataIndex: "service",
      key: "service",
    },
    {
      title: t("serviceamount"),
      dataIndex: "serviceamount",
      key: "serviceamount",
    },
    {
      title: t("serviceradius"),
      dataIndex: "serviceradius",
      key: "serviceradius",
    },
    {
      title: t("tableTabs.action"),
      dataIndex: "",
      key: "x",
      align: "center",
      render: (record) => (
        <Space>
          <Popconfirm
            title={"Are you sure you want to delete?"}
            okText="Ok"
            cancelText="Cancel"
            onConfirm={() => {
              deleteServiceHandler(record.uid);
            }}
          >
            <span className="ant-btn ant-btn-danger">
              <DeleteOutlined />
            </span>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <div>
        <MaintenanceModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
        <div className="table_topar_add_div_" style={{ width: "100%" }}>
          <Button
            className="login-btn"
            icon={<PlusCircleOutlined />}
            size={"middle"}
            onClick={showModal}
          >
            {t("createservice")}
          </Button>
          <Input
            allowClear
            size="middle"
            placeholder="search..."
            prefix={<SearchOutlined />}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            style={{ margin: ".5rem 0" }}
          />

          <Table
            style={{ width: "100%" }}
            dataSource={filteredData}
            columns={columns}
            scroll={{ x: true }}
          />
        </div>
      </div>
    </>
  );
};

export default MaintenanceTable;
