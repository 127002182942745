import {gql} from '@apollo/client';

export const Relation_Query = gql`
query Relations($page: Int!) {
  relations(page: $page) {
    currentPage
    relations {
      _id
      admin {
        _id
      }
      createdAt
      customer {
        _id
        crNumber
        crNumberUpload
        name
      }
      owner {
        _id
        currentPage
        owners {
          _id
          name
        }
        name
        totalDocs
        totalPages
      }
    }
  }
}
`;

export const Relations_Query = gql`
query Relations($page: Int!) {
    relations(page: $page) {
      currentPage
      relations {
        _id
        admin {
          _id
        }
        createdAt
        customer {
          _id
          crNumber
          crNumberUpload
          name
        }
        owner {
          _id
          currentPage
          owners {
            _id
            name
          }
          name
          totalDocs
          totalPages
        }
      }
    }
  }
`;
