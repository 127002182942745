
import React, { useState } from "react";
import { usePlacesWidget } from "react-google-autocomplete";
import { useTranslation } from "react-i18next";
export default function SelectLocation({ formData, setFormData }) {
  const { t } =useTranslation();
  const options = {
    fields: [
      "address_components",
      "geometry",
      // "icon",
      "name",
      "formatted_address",
    ],
    strictBounds: false,
    // types: ["establishment"],
    types: ["(regions)"],

    // types: ["(cities)"],
    // componentRestrictions: { country: "ksa" },
  };

  const { ref } = usePlacesWidget({
    apiKey: "AIzaSyABX4LTqTLQGg_b3jFOH8Z6_H5CDqn8tbc",

    onPlaceSelected: (place) => {
      formData.city = place.name;
      formData.origin = {
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
      };
      setFormData({...formData})
      
    },
    options,
  });

  return (
    <div className="App_div">
      <input
        onFocus={(e) => {
          e.target.value = "";
        }}
        defaultValue={formData.city}
        className="ant-input"
        ref={ref}
        placeholder={`${t("tableTabs.city")} ${t("tableTabs.name")}`}
      />
    </div>
  );
}
