// import { useState } from "react";
import { db, auth, storage, fcmToken } from "../firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  deleteUser,
} from "firebase/auth";
import {
  collection,
  doc,
  setDoc,
  updateDoc,
  deleteDoc,
  getDocs,
  query,
  where,
  serverTimestamp,
  arrayRemove,
  getDoc,
  snapshot,
  addDoc,
  arrayUnion,
  deleteField,
} from "firebase/firestore";
import { isSupported } from "firebase/messaging";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { message } from "antd";
import axios from "axios";

export const setFCMToken = async (id, data) => {
  let Ref = doc(collection(db, "fcm_tokens"), id);
  setDoc(Ref, { ...data });
};
export const FindUser = async (data) => {
  return new Promise(async (resolve, reject) => {
    await signInWithEmailAndPassword(auth, data.userEmail, data.userPassword)
      .then(async (upperValues) => {
        let id = auth.currentUser.uid;
        let snap = await getDoc(doc(db, "admin", id));

        if (snap.exists() && snap.data().email === data.userEmail) {
          sessionStorage.setItem("uid", JSON.stringify(id));
          sessionStorage.setItem("info", JSON.stringify(snap.data()));
          console.log('here is the data sss',snap.data())
          let data={token:await upperValues.user.getIdToken()}
          isSupported().then((value) => {
            if (value) {
              Notification.requestPermission().then(async (permission) => {
                if (permission === "granted") {
                  await fcmToken().then(async (res) => {
                    if (res !== "") {
                      let tokenData = {
                        token: res,
                        type: "admin",
                        uid: id,
                      };
                      await setFCMToken(id, tokenData).then(() => {
                        resolve({
                          status: "success",
                          message: "ok",
                          data:data
                        });
                      });
                    } else {
                      resolve({
                        status: "success",
                        message: "ok",
                        data:data
                      });
                    }
                  });
                } else {
                  resolve({
                    status: "success",
                    message: "ok",
                    data:data
                  });
                }
              });
            } else {
              resolve({
                status: "success",
                message: "ok",
                data:value
              });
            }
          });
        } else {
          auth.signOut();
          reject({
            status: "fail",
            message: "sorry you are not admin!",
            data:null

          });
        }
      })
      .catch((e) => {
        reject({
          status: "fail",
          message: e.code,
        });
      });
  });
};

export const uploadImage = async (path, file) => {
  const Id = JSON.parse(sessionStorage.getItem("uid"));
  if (file !== "" && file.name && file.name !== undefined) {
    return new Promise((resolve, reject) => {
      const storageRef = ref(storage, "uploads/" + path + Id + "/" + file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
        },
        (error) => {
          console.log(error);
          reject(error);
        },

        async () => {
          await getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
            resolve(url);
          });
        }
      );
    });
  }
};
export const Logout = async () => {
  if (window.confirm("Are you sure?")) {
    await auth
      .signOut()
      .then(() => {
        sessionStorage.clear();
        window.location.replace("/");
      })
      .catch((e) => {
        alert(e);
      });
  }
};
///Owner
export const AddDriver = async (data) => {
  const Id = JSON.parse(sessionStorage.getItem("ownerId"));
  const Ref = doc(collection(db, "drivers"));
  await setDoc(Ref, {
    ...data,
    uid: Ref.id,
    ownerId: Id,
    createdAt: serverTimestamp(),
  });
};
export const UpdateDriver = async (id, data) => {
  let Ref = doc(db, "drivers", id);
  await updateDoc(Ref, data).then(() => {});
};

export const DeleteDriver = async (id, data) => {
  if (data.isTruck) {
    let Ref = doc(db, "trucks", data.truckId);
    await updateDoc(Ref, { isDriver: false, driverId: "" }).then(async () => {
      let Ref = doc(db, "drivers", id);
      await deleteDoc(Ref);
    });
  } else {
    const Ref = doc(db, "drivers", id);
    await updateDoc(Ref, {
      isDeleted: false,
    });
  }
};

//archive to drivers
export const RestoreDriver = async (id, data) => {
  const Ref = doc(db, "drivers", id);
  await updateDoc(Ref, {
    isDeleted: true,
  });
};

export const AddTruck = async (data) => {
  const Id = JSON.parse(sessionStorage.getItem("ownerId"));
  const Ref = doc(collection(db, "trucks"));
  await setDoc(Ref, { ...data, uid: Ref.id, ownerId: Id }).then(async () => {});
};
export const UpdateTruck = async (id, data) => {
  let Ref = doc(db, "trucks", id);
  await updateDoc(Ref, data).then(async () => {});
};
export const DeleteTruck = async (id, data) => {
  if (data.isDriver) {
    let Ref = doc(db, "drivers", data.driverId);
    await updateDoc(Ref, { isTruck: false, truckId: "" }).then(async () => {
      let Ref = doc(db, "trucks", id);
      await deleteDoc(Ref);
    });
  } else {
    let Ref = doc(db, "trucks", id);
    await deleteDoc(Ref);
  }
};
export const MergeTruckDriver = async (data) => {
  const Id = JSON.parse(sessionStorage.getItem("ownerId"));

  let Ref = doc(collection(db, "transports"));
  await setDoc(Ref, { ...data, uid: Ref.id, ownerId: Id }).then(async () => {
    let Ref = doc(db, "drivers", data.driver.uid);
    await updateDoc(Ref, { isTruck: true, truckId: data.truck.uid }).then(
      async () => {
        let Ref = doc(db, "trucks", data.truck.uid);
        await updateDoc(Ref, { isDriver: true, driverId: data.driver.uid });
      }
    );
  });
};
export const DeleteTruckDriver = async (id, data) => {
  let Ref = doc(db, "drivers", data.driver.uid);
  await updateDoc(Ref, { isTruck: false, truckId: "" })
    .then(async () => {
      let Ref = doc(db, "trucks", data.truck.uid);
      await updateDoc(Ref, { isDriver: false, driverId: "" });
    })
    .then(async () => {
      let Ref = doc(db, "transports", id);
      await deleteDoc(Ref);
    });
};

///Customer

export const DeleteRequest = async (id, data) => {
  let Ref = doc(db, "customer_transport_requests", id);
  await deleteDoc(Ref);
};
export const DeleteCustomer = async (id, data) => {
  const Ref = doc(db, "customers", id);
  await updateDoc(Ref, {
    isDeleted: false,
  });
  // let Ref = doc(db, "customers", id);
  // let requestQuery = query(
  //   collection(db, "customer_transport_requests"),
  //   where("customerId", "==", id)
  // );
  // await getDocs(requestQuery).then((snap) => {
  //   if (!snap.empty) {
  //     snap.forEach(async (val) => {
  //       await deleteDoc(val.ref);
  //     });
  //   }
  // });
  // await deleteDoc(doc(db, "fcm_tokens", id));

  // await signInWithEmailAndPassword(auth, data.email, atob(data.password)).then(
  //   async () => {
  //     let user = auth.currentUser;
  //     await deleteUser(user).then(async () => {
  //       await deleteDoc(Ref);
  //     });
  //   }
  // );
};

// customer restore from archive to customer page
export const RestoreCustomer = async (id, data) => {
  const Ref = doc(db, "customers", id);
  await updateDoc(Ref, {
    isDeleted: true,
  });
};

export const DeleteOwner = async (id, data) => {
  let Ref = doc(db, "owners", id);
  await updateDoc(Ref, {
    isDeleted: false,
  });

  // let driverQuery = query(
  //   collection(db, "drivers"),
  //   where("ownerId", "==", id)
  // );
  // await getDocs(driverQuery).then((snap) => {
  //   if (!snap.empty) {
  //     snap.forEach(async (val) => {
  //       await deleteDoc(doc(db, "fcm_tokens", val.id));
  //       await deleteDoc(val.ref);
  //     });
  //   }
  // });
  // let truckQuery = query(collection(db, "trucks"), where("ownerId", "==", id));
  // await getDocs(truckQuery).then((snap) => {
  //   if (!snap.empty) {
  //     snap.forEach(async (val) => {
  //       await deleteDoc(val.ref);
  //     });
  //   }
  // });
  // let transportQuery = query(
  //   collection(db, "transports"),
  //   where("ownerId", "==", id)
  // );
  // await getDocs(transportQuery).then((snap) => {
  //   if (!snap.empty) {
  //     snap.forEach(async (val) => {
  //       await deleteDoc(val.ref);
  //     });
  //   }
  // });
  // await deleteDoc(doc(db, "fcm_tokens", id));
  // await signInWithEmailAndPassword(auth, data.email, data.password).then(
  //   async () => {
  //     let user = auth.currentUser;
  //     await deleteUser(user).then(async () => {
  //       await deleteDoc(Ref);
  //     });
  //   }
  // );
};
// cowner restore from archive to customer page
export const RestoreOwner = async (id, data) => {
  const Ref = doc(db, "owners", id);
  await updateDoc(Ref, {
    isDeleted: true,
  });
};
export const UpdateOwner = async (id, data) => {
  let Ref = doc(db, "owners", id);
  updateDoc(Ref, data).then(() => {
    //     const nRef = doc(collection(db, "notifications"));
    // let dta = {
    //   title: "Approved User",
    //   desc: `Your request for new account has been approved`,
    //   status: "approve_user",
    //   to: [id],
    //   from: 'Admin',
    //   isRead: false,
    //   uid: nRef.id,
    //   createdAt: serverTimestamp(),
    // };
    // setDoc(nRef, dta);
  });
};

export const UpdateCustomer = async (id, data) => {
  let Ref = doc(db, "customers", id);
  updateDoc(Ref, data);
};
export const UpdateMaintenance = async (id, data) => {
  let Ref = doc(db, "maintenance-service_request", id);
  updateDoc(Ref, data);
};
export const addService = async (path, data) => {
  const Ref = doc(collection(db, "services", "data", path));
  await setDoc(Ref, { ...data, uid: Ref.id })
    .then(() => {
      message.success("add successfully!");
    })
    .catch((e) => {
      alert(e.code);
    });
};
export const updateService = async (path, data) => {
  const Ref = doc(db, "services", "data", path, data.uid);
  await updateDoc(Ref, data)
    .then(() => {
      message.success("update successfully!");
    })
    .catch((e) => {
      alert(e.code);
    });
};
export const deleteService = async (path, data) => {
  const Ref = doc(db, "services", "data", path, data.uid);
  await deleteDoc(Ref)
    .then(() => {
      message.success("remove successfully!");
    })
    .catch((e) => {
      alert(e.code);
    });
};

export const SendRequest = async (id, data) => {
  let Ref = doc(db, "customer_transport_requests", id);
  updateDoc(Ref, { ...data }).then(async () => {
    let dta = {
      title: "New Request!",
      desc: `Request from ${data.name} for ${data.from} to ${data.to} are commming.`,
      type: "request",
      status: data.status,
      to: data.sendTo,
    };
    await sendNotification(data.notificationId, dta);
  });
};
export const updateRequest = async (id, data) => {
  let Ref = doc(db, "customer_transport_requests", id);
  updateDoc(Ref, { ...data }).then(() => {
    let dta = {
      title: `Request ${data.status}!`,
      desc: `Request from ${data.name} for ${data.from} to ${data.to} are ${data.status}`,
      type: "request",
      status: data.status,
      to: [data.customerId],
    };
    sendNotification(data.notificationId, dta);
  });
};

export const AddCombination = async (data) => {
  const Ref = doc(collection(db, "combinations"));
  await setDoc(Ref, { ...data, uid: Ref.id, createdAt: serverTimestamp() });
};
export const UpdateCombinations = async (data, uid, form) => {
  // console.log(data.from ,'data')
  // console.log(uid ,'idddddddddddddd')
  try {
    const docRef = doc(db, "combinations", uid);
    await updateDoc(docRef, {
      ...data,
      uid: docRef.id,
      type: data.type,
      height: data.height,
      capacity: data.capacity,
      from: data.from,
      to: data.to,
      price: data.price,
      distance: data.distance,
      time: data.time,
      createdAt: serverTimestamp(),
      commission: data.commission,
    });
    // console.log("++++++",data)

    // Handle success or other actions
    message.success("Update Successfully!");
  } catch (error) {
    // Handle error
    message.error(error.message);
    console.log(error);
  }
};

export const deleteCombination = async (id, data) => {
  let Ref = doc(db, "combinations", id);
  deleteDoc(Ref);
};

export const deleteRelationCombination = async (
  uid,
  dataUidToRemove,
  customerId
) => {
  try {
    const documentRef = doc(db, "relations", customerId);

    const documentSnapshot = await getDoc(documentRef);

    if (!documentSnapshot.exists()) {
      console.log(`Document with ID '${customerId}' does not exist.`);
      return;
    }

    const dataArray = documentSnapshot.data().data || [];

    const dataIndex = dataArray.findIndex(
      (item) => item.uid === dataUidToRemove.uid
    );

    if (dataIndex !== -1) {
      dataArray.splice(dataIndex, 1);
    } else {
      // console.log(`Object with UID '${dataUidToRemove}' not found in data array.`);
      return;
    }

    await updateDoc(documentRef, { data: dataArray });

    // console.log('Object removed successfully.');
    message.error("Deleted successfully !");
  } catch (error) {
    console.error("Error removing object:", error);
  }
};

export const sendMessage = async (data) => {
  let url = "https://fcm.googleapis.com/fcm/send";
  let headers = {
    "Content-Type": "application/json",
    Authorization:
      "key=AAAA8rN4ed4:APA91bHi2tICNsH0Ijok2Yir7tjeB5JPCEwTfKyUJ-cjxm3Er6LkC_6qfzc6AZ4eRHZOIUKbB_DV4Ao0MSOGBP_0jgae7PSHAm-cLTWMYsvorhrAQWIb8hveMbA3diTnhfa3ZR-UMcrO",
  };
  let body = JSON.stringify({
    notification: {
      title: data.title,
      body: data.body,
    },
    // data: {
    //   ...data.data,
    // },
    to: data.token,
  });
  axios({
    method: "post",
    url: url,
    data: body,
    headers: headers,
  }).then((res) => {
    if (res.status === 200) {
      // console.log(res);
      // message.success('notification send succssfully!');
    }
  });
};

export const sendNotification = async (id, data) => {
  const nRef = doc(db, "notifications", id);
  await updateDoc(nRef, {
    ...data,
    isRead: false,
    from: "Admin",
    updatedAt: serverTimestamp(),
  });
};
export const readNotifications = async (data) => {
  if (data && data.length > 0) {
    data.map((v) => {
      updateDoc(doc(db, "notifications", v.uid), { isRead: true });
    });
  }
};

export const AddFinance = async (uid, data) => {
  const Ref = doc(collection(db, "finance"), uid);
  await setDoc(Ref, { ...data, uid: uid, createdAt: serverTimestamp() });
};
export const updateFinance = async (id, data) => {
  const Ref = doc(db, "finance", id);
  await updateDoc(Ref, { ...data });
};

export const UpdateUser = async (id, type, data) => {
  let Ref = doc(db, type, id);
  await updateDoc(Ref, data).then(() => {
    message.success("update Successfully!");
  });
};

//

export const addDataToRelations = async (data, customerId) => {
  // console.log("🚀 ~ file: API.js:528 ~ addDataToRelations ~ data:", data)
  const uid = data.uid;
  // console.log("🚀 ~ file: API.js:531 ~ addDataToRelations ~ uid:", uid)
  try {
    const Ref = doc(collection(db, "relations"), customerId);
    const docRef = doc(db, "combinations", uid);

    const newData = { ...data, isAddbutton: true };
    await updateDoc(Ref, {
      data: arrayUnion(newData),
    });

    await updateDoc(docRef, {
      ...data,
      uid: docRef.id,
      isAddbutton: true,
    });

    message.success("Combination added");
  } catch (error) {
    console.error("Error adding data to relations:", error);
    throw error;
  }
};
