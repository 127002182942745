import React, { useState } from "react";
import {
  Modal,
  Button,
  Switch,
  Input,
  Radio,
  Checkbox,
  Form,
  Upload,
  Row,
  Col,
  message,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { AddDriver, uploadImage } from "../API/API";
// import SimpleLoader from "../Loaders/simpleLoader";
import Loader from "../Loaders/Loader";
// import useDrivers from "../Hooks/useDrivers";
import useAllDrivers from "../Hooks/useAllDrivers";
import { useTranslation } from "react-i18next";
import { DRIVER_CREATE } from "../../Graphql/mutation/driverMutation";
import { useMutation, useQuery } from "@apollo/client";
import { GET_All_DRIVER, GET_MY_DRIVER } from "../../Graphql/queries/driverQueries";
export default function AddDriverModal({ isModalVisible, setIsModalVisible, ownerId }) {
  // const { drivers } = useDrivers();
  const { refetch, drivers } = useQuery(GET_MY_DRIVER, {
    variables: { page: 1, ownerId },
    skip: true,
  });
  const [createDriver] = useMutation(DRIVER_CREATE);
  // const { allDrivers } = useAllDrivers();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({
    driverId: "",
    driverLicense: "",
    portId: "",
  });
  const handleOk = () => {
    setIsModalVisible(!isModalVisible);
  };

  const handleCancel = () => {
    setIsModalVisible(!isModalVisible);
  };
  const onFinish = async (values) => {
    if (!drivers?.some((v) => v.idNo === values.driverIdNumber)) {
      setLoading(true);
      if (formData.driverId !== "") {
        await uploadImage("Drivers/", formData.driverId).then((res) => {
          formData.driverId = res;
          setFormData({ ...formData });
        });
      }
      if (formData.driverLicense !== "") {
        await uploadImage("Drivers/", formData.driverLicense).then((res) => {
          formData.driverLicense = res;
          setFormData({ ...formData });
        });
      }
      if (formData.portId !== "") {
        await uploadImage("Drivers/", formData.portId).then((res) => {
          formData.portId = res;
          setFormData({ ...formData });
        });
      }
      // let data = {
      //   ...values,
      //   driverIdUpload: formData.driverId,
      //   driverLicenseUpload: formData.driverLicense,
      //   portIdUpload: formData.portId,
      //   isTruck: false,
      // };
      debugger;
      let data = {
        age: values.driverAge,
        idNo: values.driverIdNumber,
        address: values.driverAddress,
        idUpload: formData.portId,
        license: formData.driverLicense,
        driverId: formData.driverId,
        imageUrl: formData.driverLicense, // You can update this to the actual image URL
        name: values.driverName,
        nationality: values.driverNationality,
        owner: ownerId, // Update with the owner ID
        password: values.password,
        status: "active",
      };
      await createDriver({
        variables: {
          registerDriverInput: data,
        },
      })
        .then((response) => {
          const {data} = response
          refetch()
          console.log("🚀 ~ file: AddDriverModal.jsx:93 ~ .then ~ response:", response)
          setLoading(false);
          form.resetFields();
          handleCancel();
          message.success(data.createDriver.message);
          
        })
        .catch((error) => {
          setLoading(false);
          message.error(error.message);
        });
        await refetch();
    } else {
      alert("This ID number already exists. Please change it.");
    }
    //   await AddDriver(data)
    //     .then(() => {
    //       setLoading(false);
    //       form.resetFields();
    //       handleCancel();
    //       message.success("Add Successull!");
    //     })
    //     .catch((e) => {
    //       setLoading(false);
    //       message.error(JSON.stringify(e));
    //     });
    // } else {
    //   alert("this id number already exist please change it");
    // }
  };

  return (
    <div>
      <Modal
        title={t("Modal.addNewDriver")}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose
        footer={null}
      >
        {loading ? <Loader /> : ""}
        <div>
          <Form
            form={form}
            name="add_driver_form"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
            <Input.Group>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="driverName"
                    // label="Name"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Input placeholder={t("placeholders.name")} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="driverAge"
                    // label="Age"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Input
                      type={"number"}
                      placeholder={t("placeholders.age")}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Input.Group>
            <Input.Group>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="driverNationality"
                    // label="Nationality"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Input placeholder={t("placeholders.nationality")} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="driverAddress"
                    // label="Address"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Input placeholder={t("placeholders.address")} />
                  </Form.Item>
                </Col>
              </Row>
            </Input.Group>
            <Input.Group>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                      {
                        min: 8,
                        message: "Minimun length of 8 characters",
                      },
                    ]}
                  >
                    <Input.Password
                      type={"password"}
                      placeholder={t("placeholders.password")}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="driverIdNumber"
                    // label="Address"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Input placeholder={t("placeholders.idNumber")} />
                  </Form.Item>
                </Col>
              </Row>
            </Input.Group>
            {/* <div className="form_row_div"> */}
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  hasFeedback
                  name="driverIdUpload"
                  rules={[
                    {
                      required: true,
                      message: "Requerd Field!",
                    },
                  ]}
                >
                  <Upload
                    listType="picture"
                    multiple={false}
                    maxCount={1}
                    onChange={(e) => {
                      if (e.file.status === "removed") {
                        formData.driverId = "";
                        setFormData({
                          ...formData,
                        });
                      } else {
                        formData.driverId = e.file.originFileObj;
                        setFormData({
                          ...formData,
                        });
                      }
                    }}
                  >
                    <Button icon={<UploadOutlined />}>
                      {t("placeholders.uploadDriverId")}
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  hasFeedback
                  name="driverLicenseUpload"
                  rules={[
                    {
                      required: true,
                      message: "Requerd Field!",
                    },
                  ]}
                >
                  <Upload
                    listType="picture"
                    multiple={false}
                    maxCount={1}
                    onChange={(e) => {
                      if (e.file.status === "removed") {
                        formData.driverLicense = "";
                        setFormData({
                          ...formData,
                        });
                      } else {
                        formData.driverLicense = e.file.originFileObj;
                        setFormData({
                          ...formData,
                        });
                      }
                    }}
                  >
                    <Button icon={<UploadOutlined />}>
                      {t("placeholders.uploadLiense")}
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
            {/* </div> */}
            {/* <div className="form_row_div"> */}
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  hasFeedback
                  name="portIdUpload"
                  rules={[
                    {
                      required: true,
                      message: "Requerd Field!",
                    },
                  ]}
                >
                  <Upload
                    listType="picture"
                    multiple={false}
                    maxCount={1}
                    onChange={(e) => {
                      if (e.file.status === "removed") {
                        formData.portId = "";
                        setFormData({
                          ...formData,
                        });
                      } else {
                        formData.portId = e.file.originFileObj;
                        setFormData({
                          ...formData,
                        });
                      }
                    }}
                  >
                    <Button icon={<UploadOutlined />}>
                      {t("placeholders.uploadPortId")}
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
            {/* </div> */}

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                {t("Modal.add")}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
}
