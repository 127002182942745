import React, { Children, useState, useEffect } from "react";
import { Table, Input, Popconfirm, Space, Button } from "antd";
import {
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import useCombinations from "../Hooks/useCombinations";
import useRelation from "../Hooks/useRelation";
import MakeCombinationModal from "../Modals/MakeCombinationModal";
import { deleteCombination, addDataToRelations } from "../API/API";
import { useTranslation } from "react-i18next";
import UpdateCombination from "../Modals/UpdateCombination";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { collection, onSnapshot, query } from "firebase/firestore";
import { db } from "../firebase";

const AddCombination = () => {
  const { customerId } = useParams();
  const { combinations } = useCombinations();
  const { relation } = useRelation();

  const data = relation.filter((item) => item.customerId === customerId);
  const filteredObjects = combinations.filter((item) => {
    const isToggleOn = item.isToggleOn === true;
    const uidNotPresent = !data[0]?.data?.some((val) => val.uid === item.uid);

    return isToggleOn && uidNotPresent;
  });

  // console.log(filteredObjects);



  const [search, setSearch] = useState("");

  const navigate = useNavigate();
  const [id, setId] = useState("");
  // const [formData, setFormData] = useState({});
  const [selectedRecord, setSelectedRecord] = useState(null);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
  const { t } = useTranslation();

  const showModal = () => {
    setIsModalVisible(!isModalVisible);
  };
  const showUpdateModal = () => {
    setIsUpdateModalVisible(!isUpdateModalVisible);
  };

  const [isAdded, setIsAdded] = useState(false);
  const [addedCombinations, setAddedCombinations] = useState(
    () => JSON.parse(localStorage.getItem("addedCombinations")) || {}
  );

  useEffect(() => {
    localStorage.setItem(
      "addedCombinations",
      JSON.stringify(addedCombinations)
    );
  }, [addedCombinations]);

  const addData = (record) => {
    addDataToRelations(record, customerId)
      .then((response) => {
        console.log("Data added successfully!", response);
        setIsAdded(true);

        setAddedCombinations((prevAddedCombinations) => ({
          ...prevAddedCombinations,
          [record.uid]: true,
        }));
      })
      .catch((error) => {
        console.error("Error adding data:", error);
      });
  };

  const columns = [
    {
      title: t("tableTabs.from"),
      dataIndex: "from",
      key: "from",
    },
    {
      title: t("tableTabs.to"),
      dataIndex: "to",
      key: "to",
    },
    {
      title: t("tableTabs.distance"),
      dataIndex: "distance",
      key: "distance",
    },
    {
      title: t("tableTabs.price"),
      dataIndex: "price",
      key: "price",
    },
    {
      title: t("tableTabs.time"),
      dataIndex: "time",
      key: "time",
    },

    {
      title: t("tableTabs.type"),
      dataIndex: "type",
      key: "type",
    },
    {
      title: t("tableTabs.capacities"),
      dataIndex: "capacity",
      key: "capacity",
    },
    {
      title: t("tableTabs.hight"),
      dataIndex: "height",
      key: "height",
    },
    {
      title: "VAT",
      dataIndex: "vat",
      key: "vat",
    },

    {
      title: t("tableTabs.action"),
      dataIndex: "",
      key: "x",
      align: "center",
      render: (record) => (
        <Space>
          <Popconfirm
            title={"Are you sure you want to Add?"}
            okText="Ok"
            cancelText="Cancel"
            onConfirm={() => {
              addData(record);
            }}
          >
            <span className={`eyes-btn`}>Add</span>
          </Popconfirm>
          <Popconfirm
            title={"Are you sure you want to delete?"}
            okText="Ok"
            cancelText="Cancel"
            onConfirm={() => {
              deleteCombination(record?.uid, record);
            }}
          >
            <span className="ant-btn ant-btn-danger">
              <DeleteOutlined />
            </span>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      {/* <MakeCombinationModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      /> */}
      <UpdateCombination
        isUpdateModalVisible={isUpdateModalVisible}
        setIsUpdateModalVisible={setIsUpdateModalVisible}
        record={selectedRecord}
      />

      <div className="table_topar_add_div_">
        <Button
          className="login-btn"
          size={"middle"}
          onClick={() => navigate("/relations")}
        >
          back
        </Button>
      </div>
      {/* <div className="table_topar_add_div_">
        <Button
          className="login-btn"
          // shape="circle"
          icon={<PlusCircleOutlined />}
          size={"middle"}
          onClick={showModal}
        >
          {t("tableTabs.makeNewCombination")}
        </Button>
      </div> */}
      <div className="table_search_div">
        <Input
          allowClear
          size="middle"
          placeholder={t("placeholders.search")}
          prefix={<SearchOutlined />}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      </div>
      <Table
        columns={columns}
        dataSource={
          filteredObjects &&
          filteredObjects.length > 0 &&
          filteredObjects.filter((val) => {
            if (search == "") {
              return val;
            } else if (
              val &&
              Object.keys(val).some((v) =>
                val[v]
                  .toString()
                  .toLowerCase()
                  .includes(search.toString().toLowerCase())
              )
            ) {
              return val;
            }
          })
        }
      />
    </div>
  );
};

export default AddCombination;
