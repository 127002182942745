import { gql } from "@apollo/client";

const GET_ALL_CUSTOMER = gql`
query AllCustomers($page: Int!) {
  allCustomers(page: $page) {
    currentPage
    totalDocs
    totalPages
    customers {
      _id
      crNumber
      crNumberUpload
      createdAt
      email
      emailVerified
      idNumber
      idNumberUpload
      isApproved
      isRelation
      isDeleted
      isHold
      name
      phoneNumber
      proVerified
      profileImage
      token
      type
      userName
    }
  }
}
`;
const GET_CUSTOMER_ID = gql`
query Customer($id: ID!) {
  customer(ID: $id) {
    _id
    crNumber
    crNumberUpload
    createdAt
    email
    emailVerified
    idNumber
    isRelation
    idNumberUpload
    isApproved
    isDeleted
    isHold
    name
    phoneNumber
    proVerified
    profileImage
    token
    type
    userName
  }
}
`;
const GET_CUSTOMER_EMAIL = gql`
query CustomerEmail($email: String) {
  customerEmail(email: $email) {
    _id
    crNumber
    crNumberUpload
    createdAt
    email
    emailVerified
    idNumber
    isRelation
    idNumberUpload
    isApproved
    isDeleted
    isHold
    name
    phoneNumber
    proVerified
    profileImage
    token
    type
    userName
  }
}
`;
const CUSTOMERS = gql`
query Customers {
  customers {
    _id
    crNumber
    crNumberUpload
    createdAt
    email
    idNumber
    emailVerified
    idNumberUpload
    isApproved
    isDeleted
    isHold
    name
    phoneNumber
    proVerified
    profileImage
    token
    type
    userName
  }
}

`;
export { GET_CUSTOMER_ID, GET_ALL_CUSTOMER, GET_CUSTOMER_EMAIL, CUSTOMERS };
