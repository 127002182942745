import { gql } from "@apollo/client";
const TRUCK_CREATE = gql`
mutation Mutation($registerTruckInput: RegisterTruckInput) {
    createTruck(registerTruckInput: $registerTruckInput) {
      code
      message
      success
      data {
        _id
        createdAt
        height
        isDriver
        capacity
        imageUrl
        model
        noPlate
        owner {
          _id
        }
        serviseType
        status
        type
      }
    }
  }
`;

const UPDATE_TRUCK = gql`
mutation UpdateTruck($id: ID!, $updateTruckInput: UpdateTruckInput) {
  updateTruck(ID: $id, updateTruckInput: $updateTruckInput) {
    code
    message
    success
    data {
      _id
      capacity
      createdAt
      height
      imageUrl
      isDriver
      model
      noPlate
      owner {
        _id
      }
      serviseType
      status
      type
    }
  }
}
`;

const DELETE_TRUCK = gql`
mutation DeleteTruck($id: ID!) {
    deleteTruck(ID: $id) {
      code
      message
      success
      data {
        _id
        createdAt
        isDriver
        capacity
        height
        noPlate
      }
    }
  }
`;

export { TRUCK_CREATE, DELETE_TRUCK, UPDATE_TRUCK };
