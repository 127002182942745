import React, { useState, useEffect } from "react";
import AppRoutes from "./Routes/Routes";
import "antd/dist/antd.min.css";

import { ConfigProvider, notification } from "antd";
import { useSelector } from "react-redux/es/exports";
import { onMessageListener, GetToken, fcmToken } from "./Components/firebase";
import { auth } from "./Components/firebase";
import { Helmet } from "react-helmet";

function App() {
  <div className="application">
    <Helmet>
      <meta charSet="utf-8" />
      <title>dmo</title>
      <link rel="canonical" href="http://mysite.com/example" />
    </Helmet>
    ...
  </div>;

  const [isTokenFound, setTokenFound] = useState(false);

  const ShowMessage = async () => {
    GetToken(setTokenFound);
    if (isTokenFound) {
      await onMessageListener()
        .then((payload) => {
          openNotification(
            payload.notification.title,
            payload.notification.body
          );
        })
        .catch((err) => console.log("failed: ", err));
    }
  };

  const openNotification = (title, description) => {
    const args = {
      message: title,
      description: description,
      // duration: ,
    };
    notification.open(args);
  };

  //   useEffect(() => {
  //     window.onload = async function () {
  //    await auth
  //       .signOut()
  //       .then(() => {
  //         sessionStorage.clear();
  //       })
  // };
  //   }, [])

  const { lng } = useSelector((state) => state.userReducer);

  return (
    <>
      <ConfigProvider direction={lng === "en" ? "ltr" : "rtl"}>
        {/* <button onClick={sendMessage}>Send Notification</button> */}
        <AppRoutes />
      </ConfigProvider>
    </>
  );
}

export default App;
