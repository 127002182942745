import React from "react";
import { useTranslation } from "react-i18next";
import CustomersTable from '../Tables/CustomersTable'
export default function Customers() {
  const { t } = useTranslation();
  return (
    <div>
      <div>
        <h3>{t("routes.customers")}</h3>
      </div>
      <div>
        <CustomersTable />
      </div>
    </div>
  );
}
