import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Input, Space, Popconfirm, Row, Col, message, Pagination } from "antd";
import "./table.css";
import {
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import Loader from "../Loaders/Loader";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { CREATE_DESTINATION, DELETE_DESTINATION, UPDATE_DESTINATION } from "../../Graphql/mutation/servicesMutation";

import useGetAllDestination from "../Hooks/useDestination";

export default function AddTypesTable() {
  // const { destination } = useDestination();
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");
  const [isAdd, setIsAdd] = useState(false);
  const { t } = useTranslation();
  const [formData, setFormData] = useState({ destination: ""});
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [paginationFilters, setPaginationFilters] = useState({
    pageNo: 1,
    pageSize: 5,
    limitSkip: 0,
    limit: 10,
  });
  const [deleteDestination] = useMutation(DELETE_DESTINATION);
  const [updateDestination] = useMutation(UPDATE_DESTINATION);
  const [createDestination] = useMutation(CREATE_DESTINATION);
  const { destination, count} = useGetAllDestination(updateTrigger, paginationFilters);
  console.log("🚀 ~ file: DestinationTable.jsx:35 ~ AddTypesTable ~ destination:", destination)
  // setDrivers(drivers);;
  const handleChange = (value, pageSize) => {
    const limitSkip = value * pageSize;
    const limit = pageSize;
    setPaginationFilters({ pageNo: value, pageSize, limitSkip, limit });
    setLoading(true);
    setUpdateTrigger(!updateTrigger);
  };
  const handleAdd = async () => {
    debugger;
    try {
      const registerDestinationInput = {
        destination: formData.destination,
        admin:"64f5b4c14091bd9d3c2fdaa2"
        // You might need to include other fields as needed
      };
  
      const { data } = await createDestination({
        variables: {
          registerDestinationInput,
        },
      });
  
      if (data.createDestination.success) {
        // City created successfully, you can reset your form or do any other necessary actions
        setFormData({ destination: "" });
        setIsAdd(false);
        setUpdateTrigger(true);
        setId(""); // This will trigger a re-fetch of your city data
      } else {
        // Handle the case where city creation was not successful
        // You can display an error message or take appropriate action
      }
    } catch (error) {
      // Handle any errors that occur during the mutation
      console.error(error);
      // You can display an error message or take appropriate action
    }
  };
  const handleEdit = async (data) => {
    debugger
   try {
    await updateDestination({
      variables: {
        id: data._id, // Provide the customer ID to be updated
        updateDestinationInput:{
          destination:formData.destination
        }, // Pass the updated values
      },
    });
    message.success("Destination Updated Successfully");
    setFormData({ destination: "" });
    setUpdateTrigger(true);
    setId("");
   } catch (error) {
    console.log("🚀 ~ file: AddCitiesTables.jsx:66 ~ handleEdit ~ error:", error)
    
   }
  };

  const confirm = async (Destination, action) => {
    debugger;
    setUpdateTrigger(false);
    try {
      const { data } = await deleteDestination({
        variables: { id: Destination._id},
      });
      console.log("🚀 ~ file: AddCitiesTables.jsx:84 ~ confirm ~ data:", data)
      message.success("Destination Deleted Successfully");
      setUpdateTrigger(true);
    } catch (error) {
      // Handle the error if needed
      console.log(error);
    }
  };

  const columns = [
    {
    //   title: t("tableTabs.destination"),
    title:"Destination",
      render: (record) =>
        id === record._id ? (
          <input
            // placeholder={t("tableTabs.destination")}
            placeholder="Destination"
            className="ant-input"
            defaultValue={record.destination}
            onChange={(e) => {
              formData.destination = e.target.value;
              setFormData({ ...formData });
            }}
          />
        ) : (
          <span>{record.destination}</span>
        ),
    },
 

    {
      title: t("tableTabs.action"),
      dataIndex: "",
      key: "x",
      align: "center",
      render: (record) => (
        <Space>
          {id === record._id ? (
            <Space>
              <button
                className="ant-btn ant-btn-danger"
                onClick={() => {
                  setId("");
                  setFormData({ destination: "" });
                }}
              >
                cancel
              </button>
              <button
                className="ant-btn ant-btn-primary"
                onClick={() => {
                  handleEdit(record);
                }}
              >
                Done
              </button>
            </Space>
          ) : (
            <span
              className="eyes-btn"
              onClick={() => {
                setFormData({ destination: record.destination});
                setId(record._id);
              }}
            >
              <EditOutlined />
            </span>
          )}

          <Popconfirm
            title={"Are you sure you want to delete?"}
            okText="Ok"
            cancelText="Cancel"
            onConfirm={() => confirm(record, "del")}

          >
            <span className="ant-btn ant-btn-danger">
              <DeleteOutlined />
            </span>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      {loading ? <Loader /> : ""}

      <div className="table_topar_add_div_">
        <button
          className="ant-btn ant-btn-primary login-btn"
          onClick={() => {
            setIsAdd(!isAdd);
          }}
        >
          Add Destination
        </button>
      </div>
      {isAdd && (
        <div className="add_div">
          <Row gutter={24}>
            <Col span={10}>
              <Input
                // placeholder={t("tableTabs.destination")}
                placeholder="destination"
                value={formData.destination}
                onChange={(e) => {
                  formData.destination = e.target.value;
                  setFormData({ ...formData });
                }}
              />
            </Col>

            
            <Col span={4}>
              <button
                className="ant-btn ant-btn-primary login-btn"
                onClick={handleAdd}
              >
                {t("tableTabs.add")}
              </button>
            </Col>
          </Row>
        </div>
      )}
     
      <Table pagination={false} columns={columns} dataSource={destination.length > 0 && destination} />
      <Pagination
          defaultCurrent={1}
          defaultPageSize={paginationFilters.pageSize}
          onChange={handleChange}
          total={count}
          className="Pagination"
        />
    </div>
  );
}
