import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
import { Table, Input, Space, Popconfirm, Row, Col, Pagination, message } from "antd";
import "./table.css";
import {
  EditOutlined,
  DeleteOutlined,
  // SearchOutlined,
} from "@ant-design/icons";
import Loader from "../Loaders/Loader";

// import { addService, updateService, deleteService } from "../API/API";
//Hooks
// import useSaberPrice from "../Hooks/useSaberPrice";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { CREATE_SABER, DELETE_SABER, UPDATE_SABER } from "../../Graphql/mutation/servicesMutation";
import useGetAllExtraSaber from "../Hooks/useSaberPrice";

export default function SaberPrice() {
  // const { saber } = useSaberPrice();
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");
  const [isAdd, setIsAdd] = useState(false);
  const { t } = useTranslation();
  const [formData, setFormData] = useState({ saber: ""});
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [paginationFilters, setPaginationFilters] = useState({
    pageNo: 1,
    pageSize: 5,
    limitSkip: 0,
    limit: 10,
  });
  const [deleteSaber] = useMutation(DELETE_SABER);
  const [updateSaber] = useMutation(UPDATE_SABER);
  const [createSaber] = useMutation(CREATE_SABER);
  const { saber, count } = useGetAllExtraSaber(
    updateTrigger,
    paginationFilters
  );
  const handleChange = (value, pageSize) => {
    const limitSkip = value * pageSize;
    const limit = pageSize;
    setPaginationFilters({ pageNo: value, pageSize, limitSkip, limit });
    setLoading(true);
    setUpdateTrigger(!updateTrigger);
  };
  const handleAdd = async () => {
    debugger;
    try {
      const registerSaberInput = {
        saber: formData.saber,
        admin: "64f5b4c14091bd9d3c2fdaa2",
        // You might need to include other fields as needed
      };

      const { data } = await createSaber({
        variables: {
          registerSaberInput,
        },
      });

      if (data.createSaber.success) {
        // City created successfully, you can reset your form or do any other necessary actions
        setFormData({ saber: "" });
        setIsAdd(false);
        setUpdateTrigger(true);
        setId(""); // This will trigger a re-fetch of your city data
      } else {
        // Handle the case where city creation was not successful
        // You can display an error message or take appropriate action
      }
    } catch (error) {
      // Handle any errors that occur during the mutation
      console.error(error);
      // You can display an error message or take appropriate action
    }
  };
  const handleEdit = async (data) => {
    debugger;
    try {
      await updateSaber({
        variables: {
          id: data._id, // Provide the customer ID to be updated
          updateSaberInput: {
            saber: formData.saber,
          }, // Pass the updated values
        },
      });
      message.success("Saber Updated Successfully");
      setFormData({ saber: "" });
      setUpdateTrigger(true);
      setId("");
    } catch (error) {
      console.log(
        "🚀 ~ file: AddCitiesTables.jsx:66 ~ handleEdit ~ error:",
        error
      );
    }
  };

  const confirm = async (saber, action) => {
    debugger;
    setUpdateTrigger(false);
    try {
      const { data } = await deleteSaber({
        variables: { id: saber._id },
      });
      console.log("🚀 ~ file: AddCitiesTables.jsx:84 ~ confirm ~ data:", data);
      message.success("Saber Deleted Successfully");
      setUpdateTrigger(true);
    } catch (error) {
      // Handle the error if needed
      console.log(error);
    }
  };

  const columns = [
    {
    //   title: t("tableTabs.saber"),
    title:"Price",
      render: (record) =>
        id === record._id ? (
          <input
            // placeholder={t("tableTabs.saber")}
            placeholder="Saber Price"
            className="ant-input"
            defaultValue={record.saber}
            onChange={(e) => {
              formData.saber = e.target.value;
              setFormData({ ...formData });
            }}
          />
        ) : (
          <span>{record.saber}</span>
        ),
    },
 

    {
      title: t("tableTabs.action"),
      dataIndex: "",
      key: "x",
      align: "center",
      render: (record) => (
        <Space>
          {id === record._id ? (
            <Space>
              <button
                className="ant-btn ant-btn-danger"
                onClick={() => {
                  setId("");
                  setFormData({ saber: "" });
                }}
              >
                cancel
              </button>
              <button
                className="ant-btn ant-btn-primary"
                onClick={() => {
                  handleEdit(record);
                }}
              >
                Done
              </button>
            </Space>
          ) : (
            <span
              className="eyes-btn"
              onClick={() => {
                setFormData({ saber: record.saber});
                setId(record._id);
              }}
            >
              <EditOutlined />
            </span>
          )}

          <Popconfirm
            title={"Are you sure you want to delete?"}
            okText="Ok"
            cancelText="Cancel"
            onConfirm={() => confirm(record, "del")}
          >
            <span className="ant-btn ant-btn-danger">
              <DeleteOutlined />
            </span>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      {loading ? <Loader /> : ""}

      <div className="table_topar_add_div_">
        <button
          className="ant-btn ant-btn-primary login-btn"
          onClick={() => {
            setIsAdd(!isAdd);
          }}
        >
          Add Saber Price
        </button>
      </div>
      {isAdd && (
        <div className="add_div">
          <Row gutter={24}>
            <Col span={10}>
              <Input
                // placeholder={t("tableTabs.saber")}
                placeholder="saber"
                value={formData.saber}
                onChange={(e) => {
                  formData.saber = e.target.value;
                  setFormData({ ...formData });
                }}
              />
            </Col>

            
            <Col span={4}>
              <button
                className="ant-btn ant-btn-primary login-btn"
                onClick={handleAdd}
              >
                {t("tableTabs.add")}
              </button>
            </Col>
          </Row>
        </div>
      )}
     
      <Table pagination={false} columns={columns} dataSource={saber.length > 0 && saber} />
      <Pagination
        defaultCurrent={1}
        defaultPageSize={paginationFilters.pageSize}
        onChange={handleChange}
        total={count}
        className="Pagination"
      />
    </div>
  );
}
