import React, { useState } from "react";
import { Button, Descriptions, Image, Space } from "antd";
import useOwner from "../Hooks/useOwner";
import EditProfileModal from "../Modals/EditProfileModal";
import profileImage from '../../assets/profile.png';
import { useTranslation } from "react-i18next";
export default function OwnerProfile({owner}) {
  const imageUrl = owner?.imageUrl?.includes(
    "https://firebasestorage.googleapis.com"
  )
    ? owner?.imageUrl
    : profileImage;
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(!isModalVisible);
  };
  return (
    <div>
      <EditProfileModal
        setIsModalVisible={setIsModalVisible}
        isModalVisible={isModalVisible}
        uid={owner._id}
        type={owner.type}
      />
      <div>
        <h3>{t("tableTabs.ownerProfile")}</h3>
      </div>
      <div>
        <Space>
          <button className="ant-btn ant-btn-primary" onClick={showModal}>
            {t("Modal.edit")}
          </button>
        </Space>
      </div>

      <div>
        <Descriptions
          bordered
          layout="vertical"
          title={t("tableTabs.ownerInfo")}
        >
          <Descriptions.Item span={3} label={t("tableTabs.profileImage")}>
            <Image
              width={200}
              height={200}
              src={imageUrl}
              alt={"No Upload"}
            />
          </Descriptions.Item>
          <Descriptions.Item label={t("tableTabs.name")}>
            {owner.name}
          </Descriptions.Item>
          <Descriptions.Item label={t("tableTabs.email")}>
            {owner.email}
          </Descriptions.Item>
          <Descriptions.Item label={t("tableTabs.phoneNumber")}>
            {owner.phoneNumber}
          </Descriptions.Item>
          <Descriptions.Item label="Benificiary Name">
            {owner?.bankInfo?.benificiaryName}
          </Descriptions.Item>
          <Descriptions.Item label="IBAN Number">
            {owner?.bankInfo?.iBanNumber}
          </Descriptions.Item>
          <Descriptions.Item label="Bank ID">
            {owner?.bankInfo?.bankIdCode}
          </Descriptions.Item>
          <Descriptions.Item label={t("tableTabs.idNumber")}>
            {owner.idNumber}
          </Descriptions.Item>
          <Descriptions.Item
            span={2}
            label={`${t("tableTabs.upload")} ${t("tableTabs.idNumber")}`}
          >
            <img
              className="img"
              src={owner.idNumberUpload}
              alt={"Click To View"}
              onClick={() => {
                window.open(owner.idNumberUpload);
              }}
            />
          </Descriptions.Item>

          <Descriptions.Item label={t("CR Number")}>
            {owner.crNumber}
          </Descriptions.Item>
          <Descriptions.Item span={2} label={t("CR Number Upload")}>
            <img
              className="img"
              src={owner.crNumberUpload}
              alt={"Click To View"}
              onClick={() => {
                window.open(owner.crNumberUpload);
              }}
            />
          </Descriptions.Item>
        </Descriptions>
      </div>
    </div>
  );
}
