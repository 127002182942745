import React, { useEffect, useState } from "react";
import { collection, query, onSnapshot,where, } from "firebase/firestore";
import { db } from "../firebase";
export default function useDrivers() {
 const ownerId = JSON.parse(sessionStorage.getItem("ownerId"));
  const [drivers, setDrivers] = useState([]);
  const getDrivers = async () => {
    let q = query(collection(db, "drivers"), where("ownerId", "==", ownerId));
    const unsub = onSnapshot(q, (querySnapShot) => {
      setDrivers([]);
      if (!querySnapShot.empty) {
        querySnapShot.forEach((val) => {
          setDrivers((prev) => [...prev, val.data()]);
        });
      }
    });

    return () => unsub();
  };
  useEffect(() => {
    getDrivers();
  }, []);

  return { drivers };
}
// import { getDoc, doc } from "firebase/firestore";
// import { useEffect, useState } from "react";
// import { db } from "../firebase";

// export default function useOwner() {
//   const [owner, setOwner] = useState({});
//   let uid = JSON.parse(sessionStorage.getItem("ownerId"));

//   const getData = async () => {
//     let userRef = doc(db, "drivers", uid);
//     let snap = await getDoc(userRef);
//     if (snap.exists()) {
//       setOwner(snap.data());
//     }
//   };

//   useEffect(() => {
//     getData();
//   }, [uid]);

//   return { owner };
// }
// import { useEffect, useState, useCallback } from "react"; // Import your GraphQL query for drivers
// import { useQuery } from "@apollo/client";
// import { GET_All_DRIVER, GET_MY_DRIVER } from "../../Graphql/queries/driverQueries";

// // ... other imports ...

// function useGetMyDrivers(trigger, paginationFilters, ownerId) {
//   const [drivers, setDrivers] = useState([]);
//   const [count, setCount] = useState(0);

//   const { loading, error, data, refetch } = useQuery(
//     ownerId ? GET_MY_DRIVER : GET_All_DRIVER, // Use GET_All_DRIVER when ownerId is not provided
//     {
//       variables: ownerId
//         ? { page: paginationFilters?.pageNo, ownerId }
//         : { page: paginationFilters?.pageNo },
//       fetchPolicy: "network-only",
//     }
//   );

//   const getDrivers = useCallback(() => {
//     if (loading) return;
//     if (error) {
//       console.log("Error:", error.message);
//       return;
//     }
//     const driversData = ownerId ? data.myDrivers : data.allDrivers;
//     if (!driversData) {
//       console.log("No driver data available.");
//       return;
//     }
//     const { drivers, totalDocs } = driversData;
//     setDrivers(drivers);
//     setCount(totalDocs);
//   }, [loading, error, data, ownerId]);

//   useEffect(() => {
//     getDrivers();
//     if (trigger) {
//       refetch({ page: paginationFilters?.pageNo });
//     }
//   }, [trigger, getDrivers, paginationFilters?.pageNo, refetch]);

//   return { loading, error, drivers, count };
// }

// export default useGetMyDrivers;

